import {
  LedgerAccountTypeEnum,
  MARKET_ACCOUNT_LEDGER_EVENT,
  SUB_ACCOUNT_LEDGER_EVENT,
  useSubscription,
  type LedgerEvent,
} from '@talos/kyoko';
import { useMemo } from 'react';

interface UseAccountLedgerEventsParams {
  tag: string;
  asset?: string;
  account: string;
  accountType: LedgerAccountTypeEnum;
  StartDate?: string;
  EndDate?: string;
}

interface AccountLedgerEventsFilter {
  MarketAccounts?: string[];
  SubAccounts?: string[];
  Symbols?: string[];
  StartDate?: string;
  EndDate?: string;
}

/**
 * Given an AccountLedgerEventsFilter, returns a data observable for LedgerEvents
 */
export const useAccountLedgerEvents = ({
  asset,
  account,
  accountType,
  tag,
  StartDate,
  EndDate,
}: UseAccountLedgerEventsParams) => {
  const filter: AccountLedgerEventsFilter = useMemo(() => {
    const _filter: AccountLedgerEventsFilter = {
      Symbols: asset ? [asset] : undefined,
      StartDate,
      EndDate,
    };

    if (accountType === LedgerAccountTypeEnum.MarketAccount) {
      _filter.MarketAccounts = [account];
    }

    if (accountType === LedgerAccountTypeEnum.SubAccount) {
      _filter.SubAccounts = [account];
    }

    return _filter;
  }, [asset, account, accountType, StartDate, EndDate]);

  const request = useMemo(
    () => ({
      name:
        accountType === LedgerAccountTypeEnum.MarketAccount ? MARKET_ACCOUNT_LEDGER_EVENT : SUB_ACCOUNT_LEDGER_EVENT,
      tag: `${tag}/useAccountLedgerEvents`,
      ...filter,
    }),
    [tag, filter, accountType]
  );
  const { data } = useSubscription<LedgerEvent>(request);

  return data;
};
