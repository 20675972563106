import {
  Divider,
  HStack,
  IndicatorBadge,
  InlineFormattedNumber,
  LedgerAccountTypeEnum,
  Text,
  VStack,
  formattedDate,
  formattedDateWithMilliseconds,
  useAssetsContext,
  useDateNow,
  useObservableValue,
  type LedgerEvent,
  type SubscriptionResponse,
} from '@talos/kyoko';
import type { ReactNode } from 'react';
import { scan, type Observable } from 'rxjs';
import { useAccount } from './useAccount';

interface AccountLedgerEventsHeaderProps {
  account: string;
  asset?: string;
  accountType: LedgerAccountTypeEnum;
  startDate: string | undefined;
  endDate?: string;
  ledgerEventsObs: Observable<SubscriptionResponse<LedgerEvent>>;
}

interface LedgerEventsStreamMetadata {
  firstEvent?: LedgerEvent;
  lastEvent?: LedgerEvent;
  totalEvents?: number;
}

export const AccountLedgerEventsHeader = ({
  account: accountName,
  asset,
  accountType,
  startDate,
  endDate,
  ledgerEventsObs,
}: AccountLedgerEventsHeaderProps) => {
  const account = useAccount(accountName, accountType);

  const { getAssetDisplaySymbol } = useAssetsContext();
  const assetDisplaySymbol = asset ? getAssetDisplaySymbol(asset) : 'All';

  const showAssetLevelMetadata = !!asset;

  const ledgerEventsMetadata = useObservableValue(
    () =>
      ledgerEventsObs.pipe(
        scan((metadata, message) => {
          if (message.initial) {
            metadata = {};
          }

          if (message.data.length === 0) {
            return metadata;
          }

          if (!metadata.firstEvent) {
            metadata.firstEvent = message.data.at(0);
          }

          // These two lines here assume that we only receive incremental data points (we never receive updates for already received events)
          metadata.lastEvent = message.data.at(-1);

          metadata.totalEvents = (metadata.totalEvents ?? 0) + message.data.length;
          return metadata;
        }, {} as LedgerEventsStreamMetadata)
      ),
    [ledgerEventsObs]
  );

  const dateNow = useDateNow({ updateIntervalMs: 1000 });
  // The conditional here is because we only want to show milliseconds if its an actual date. If we're showing the "now" date,
  // just show seconds.
  const endDatePattern = endDate ? '{yyyy}-{MM}-{dd} {HH}:{mm}:{ss}.{SSS}' : '{yyyy}-{MM}-{dd} {HH}:{mm}:{ss}';

  return (
    <HStack background="colors.gray.030" p="spacingComfortable" gap="spacingComfortable" justifyContent="flex-start">
      <HeaderItem
        label={accountType === LedgerAccountTypeEnum.MarketAccount ? 'Market Account' : 'Sub Account'}
        testid="account-ledger-events-header-account-name"
        content={<Text color="colorTextImportant">{account?.DisplayName ?? accountName}</Text>}
      />
      <Divider orientation="vertical" />

      <HeaderItem
        label="Asset"
        testid="account-ledger-events-header-asset"
        content={<Text color="colorTextImportant">{assetDisplaySymbol}</Text>}
      />
      <Divider orientation="vertical" />

      <HeaderItem
        label="Events"
        testid="account-ledger-events-header-total-events"
        content={<Text color="colorTextImportant">{ledgerEventsMetadata?.totalEvents ?? '-'}</Text>}
      />
      <Divider orientation="vertical" />

      <HeaderItem
        label="Starting Time"
        testid="account-ledger-events-header-starting-time"
        content={<Text color="colorTextImportant">{startDate ? formattedDateWithMilliseconds(startDate) : '-'}</Text>}
      />
      <Divider orientation="vertical" />

      <HeaderItem
        label="Ending Time"
        testid="account-ledger-events-header-ending-time"
        content={<Text color="colorTextImportant">{formattedDate(endDate ? endDate : dateNow, endDatePattern)}</Text>}
      />
      <Divider orientation="vertical" />

      {showAssetLevelMetadata && (
        <>
          <HeaderItem
            label="Starting Balance"
            testid="account-ledger-events-header-starting-balance"
            content={
              ledgerEventsMetadata?.firstEvent ? (
                <InlineFormattedNumber
                  number={ledgerEventsMetadata.firstEvent.startingAmount}
                  currency={ledgerEventsMetadata.firstEvent.amountAssetWithFallback}
                  truncate={false}
                />
              ) : (
                <Text color="colorTextImportant">-</Text>
              )
            }
          />
          <Divider orientation="vertical" />

          <HeaderItem
            label="Ending Balance"
            testid="account-ledger-events-header-ending-balance"
            content={
              ledgerEventsMetadata?.lastEvent ? (
                <InlineFormattedNumber
                  number={ledgerEventsMetadata.lastEvent.ResultingAmount}
                  currency={ledgerEventsMetadata.lastEvent.amountAssetWithFallback}
                  truncate={false}
                />
              ) : (
                <Text color="colorTextImportant">-</Text>
              )
            }
          />
          <Divider orientation="vertical" />
        </>
      )}
    </HStack>
  );
};

interface HeaderItemProps {
  label: string;
  content: ReactNode;
  testid?: string;
}

function HeaderItem({ label, content, testid }: HeaderItemProps) {
  return (
    <VStack gap="spacingSmall" alignItems="flex-start" data-testid={testid}>
      <IndicatorBadge children={label} />
      {content}
    </VStack>
  );
}
