import { type DefaultTheme, useTheme } from 'styled-components';
import { Box, Grid } from '../Core';
import { Icon, IconName } from '../Icons';
import { AlertBannerWrapper, Content, LeftStripes, RightStripes } from './styles';

import { mix } from 'polished';
import type { ReactNode } from 'react';
import { AlertVariants } from '../Alert/types';

export type AlertBannerVariants = AlertVariants.Negative | AlertVariants.Positive | AlertVariants.Warning;

export function AlertBanner({
  variant,
  children,
  icon: propsIcon,
}: {
  variant: AlertBannerVariants;
  children: ReactNode;
  icon?: IconName;
}) {
  const theme = useTheme();
  const { backgroundColor, contentColor, icon: defaultIcon } = getAlertBannerColor(variant, theme);
  const icon = propsIcon ?? defaultIcon;

  return (
    <Grid
      w="100%"
      gridTemplateColumns="1fr auto 1fr"
      position="relative"
      background={backgroundColor}
      gap="spacingSmall"
    >
      <LeftStripes />
      <Content color={contentColor}>
        <Icon icon={icon} />
        <Box>{children}</Box>
      </Content>
      <RightStripes />
      <AlertBannerWrapper color={backgroundColor} />
    </Grid>
  );
}

const getAlertBannerColor = (
  variant: AlertBannerVariants,
  theme: DefaultTheme
): { backgroundColor: string; contentColor: string; icon: IconName } => {
  switch (variant) {
    case AlertVariants.Positive:
      return {
        backgroundColor: mix(0.4, theme.colors.green.default, theme.colors.gray.main),
        contentColor: theme.colors.green.lighten,
        icon: IconName.InformationCircleSolid,
      };
    case AlertVariants.Negative:
      return {
        backgroundColor: mix(0.4, theme.colors.red.default, theme.colors.gray.main),
        contentColor: theme.colors.red.lighten,
        icon: IconName.ExclamationSolid,
      };
    case AlertVariants.Warning:
      return {
        backgroundColor: theme.colors.yellow.lighten,
        contentColor: theme.colors.gray['000'],
        icon: IconName.ExclamationSolid,
      };
  }
};
