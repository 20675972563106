import type { Options, SeriesBarOptions } from 'highcharts/highcharts';
import { BaseChart, type BaseChartProps } from './BaseChart';
import { useChartOptionsOverrides, useHighchartsRef, useSeriesOptions } from './hooks';
import type { SharedChartProps } from './types';

export interface BarChartProps extends SharedChartProps<SeriesBarOptions>, BaseChartProps {}

export const BarChart: React.FC<BarChartProps> = ({
  series,
  seriesObservable,
  options,
  onChartCreated,
  mergeSeriesUpdates,
  ...props
}) => {
  const { chartRef, isLoaded, setChartObject } = useHighchartsRef({ onChartCreated });

  const seriesOptions = useSeriesOptions({ series, seriesObservable, chartRef, isLoaded, mergeSeriesUpdates });
  const combinedOptions = useChartOptionsOverrides(DEFAULT_BARCHART_OPTIONS, options, seriesOptions);

  return <BaseChart options={combinedOptions} onChartCreated={setChartObject} chartRef={chartRef} {...props} />;
};

const DEFAULT_BARCHART_OPTIONS: Options = {
  chart: {
    type: 'bar',
  },
  plotOptions: {
    bar: {
      stacking: 'normal',
    },
  },
};
