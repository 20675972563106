import {
  Box,
  type BoxProps,
  Divider,
  FormControlSizes,
  HStack,
  InlineFormattedNumber,
  LoaderTalos,
  ProductTypeEnum,
  Text,
  toBigWithDefault,
  Toggle,
  ToggleHorizontal,
  type ToggleHorizontalOption,
  useCurrency,
  useMarketAccountsContext,
  useMarketsContext,
  useSyncedRef,
  VStack,
} from '@talos/kyoko';
import Big from 'big.js';
import { useCallback, useMemo } from 'react';
import { useTheme } from 'styled-components';
import { useAppStateDispatch } from '../../../../providers/AppStateProvider';
import { useDisplaySettings } from '../../../../providers/DisplaySettingsProvider';
import { Module } from '../../components/Module';
import {
  getPortfolioViewActions,
  usePortfolioViewStateSelector,
} from '../../PortfolioManagement/stateManagement/portfolioViewLayoutSlice.hooks';
import type { OpsPosition } from '../types';
import { OpsBalancesChart, type OpsBalancesChartProps } from './OpsBalancesChart';
import { OpsBalancesChartTooltip } from './OpsBalancesChartTooltip';
import type { OpsBalancesChartDimension } from './types';

const { updateOpsOverviewBalancesChartShowByAsset, updateOpsOverviewBalancesChartDimension } =
  getPortfolioViewActions();

type OpsBalancesChartModuleProps = Pick<OpsBalancesChartProps<OpsPosition>, 'entities'> & {
  /* ... */
};

/**
 * Wraps the chart itself, provides the correct props, connects to contexts, etc
 */
export const OpsBalancesChartModule = ({ entities }: OpsBalancesChartModuleProps) => {
  const theme = useTheme();
  const dispatch = useAppStateDispatch();
  const { homeCurrency } = useDisplaySettings();
  const homeCurrencyInfo = useCurrency(homeCurrency);
  const { marketAccountsByName } = useMarketAccountsContext();
  const marketAccountsByNameRef = useSyncedRef(marketAccountsByName);
  const { marketsByName } = useMarketsContext();
  const marketsByNameRef = useSyncedRef(marketsByName);

  const {
    opsOverviewShowBy,
    opsOverviewBalancesChartShowByAsset: showByAsset,
    opsOverviewBalancesChartDimension: dimension,
  } = usePortfolioViewStateSelector();

  const dimensionOptions: ToggleHorizontalOption<OpsBalancesChartDimension>[] = useMemo(() => {
    return [
      {
        value: 'balances',
        displayName: 'Balances',
      },
      {
        value: 'delta',
        displayName: `Delta ${homeCurrency}`,
      },
    ];
  }, [homeCurrency]);

  const getValue = useCallback(
    (position: OpsPosition) => {
      if (dimension === 'balances') {
        return position.AssetType === ProductTypeEnum.Spot ? toBigWithDefault(position.Equivalent?.Amount, 0) : Big(0);
      } else {
        // dimension = delta
        return toBigWithDefault(position.Equivalent?.Delta, 0);
      }
    },
    [dimension]
  );

  const sums = useMemo(() => {
    if (!entities) {
      return {};
    }

    let negativeValueSum = Big(0);
    let positiveValueSum = Big(0);
    let uPnLSum = Big(0);

    if (dimension === 'balances') {
      for (const entity of entities) {
        if (entity.AssetType === ProductTypeEnum.Spot) {
          const amountBig = toBigWithDefault(entity.Equivalent?.Amount, 0);
          if (amountBig.gt(0)) {
            positiveValueSum = positiveValueSum.plus(amountBig);
          } else {
            negativeValueSum = negativeValueSum.plus(amountBig);
          }
        } else {
          // derivative
          uPnLSum = uPnLSum.plus(toBigWithDefault(entity.Equivalent?.UnrealizedPnL, 0));
        }
      }
    }

    if (dimension === 'delta') {
      for (const entity of entities) {
        const deltaEquivBig = toBigWithDefault(entity.Equivalent?.Delta, 0);
        if (deltaEquivBig.gt(0)) {
          positiveValueSum = positiveValueSum.plus(deltaEquivBig);
        } else {
          negativeValueSum = negativeValueSum.plus(deltaEquivBig);
        }

        uPnLSum = uPnLSum.plus(toBigWithDefault(entity.Equivalent?.UnrealizedPnL, 0));
      }
    }

    return {
      negativeValueSum,
      positiveValueSum,
      uPnLSum,
      netBalSum: positiveValueSum.plus(negativeValueSum),
    };
  }, [entities, dimension]);

  const getLabel = useCallback(
    (position: OpsPosition) => {
      if (showByAsset) {
        return position.underlying;
      }

      if (opsOverviewShowBy === 'Market') {
        const mkt = position.market ? marketsByNameRef.current.get(position.market) : undefined;
        return mkt?.DisplayName ?? position.market ?? 'Unknown';
      }

      // market account
      const mktAcc = marketAccountsByNameRef.current.get(position.MarketAccount);
      return mktAcc?.DisplayName ?? position.MarketAccount;
    },
    [showByAsset, opsOverviewShowBy, marketAccountsByNameRef, marketsByNameRef]
  );

  const getGroupBy = useCallback(
    (position: OpsPosition) => {
      if (showByAsset) {
        return position.underlying;
      }

      if (opsOverviewShowBy === 'MarketAccount') {
        return position.MarketAccount;
      }

      return position.market;
    },
    [showByAsset, opsOverviewShowBy]
  );

  const getTooltip = useCallback(
    (point: Highcharts.Point) => {
      return (
        <OpsBalancesChartTooltip
          point={point}
          dimension={dimension}
          showingByAsset={showByAsset}
          opsOverviewShowBy={opsOverviewShowBy}
          homeCurrency={homeCurrencyInfo}
          theme={theme}
        />
      );
    },
    [dimension, showByAsset, opsOverviewShowBy, homeCurrencyInfo, theme]
  );

  const moduleLabelPrefix = dimension === 'balances' ? 'Balances' : 'Delta';
  const moduleLabelSuffix = showByAsset ? 'Asset' : opsOverviewShowBy === 'MarketAccount' ? 'Market Account' : 'Market';
  const summaryLabelSuffix = dimension === 'balances' ? 'Balance' : 'Delta';
  const chartValueAxisTitle = dimension === 'balances' ? `Balance (${homeCurrency})` : `Delta (${homeCurrency})`;

  return (
    <Module
      data-testid="balances-chart-module"
      title={`${moduleLabelPrefix} by ${moduleLabelSuffix}`}
      flex="1 1 50%"
      h="100%"
      suffix={
        <HStack gap="spacingDefault" h="100%" p="spacingDefault">
          <Toggle
            size={FormControlSizes.Small}
            checked={showByAsset}
            onChange={value => dispatch(updateOpsOverviewBalancesChartShowByAsset(value))}
            label="View by Asset"
            data-testid="view-by-asset-toggle"
          />

          <Divider orientation="vertical" />
          <Text fontSize="fontSizeSm">Dimension</Text>
          <ToggleHorizontal
            value={dimension}
            options={dimensionOptions}
            onChange={value => dispatch(updateOpsOverviewBalancesChartDimension(value))}
          />
        </HStack>
      }
    >
      <HStack
        w="100%"
        gap="spacingDefault"
        justifyContent="space-between"
        px="spacingComfortable"
        pt="spacingComfortable"
      >
        {sums.negativeValueSum && (
          <Summary
            value={sums.negativeValueSum}
            label={`Short ${summaryLabelSuffix}`}
            data-testid="balances-chart-module-short-summary"
          />
        )}
        {sums.netBalSum && (
          <Summary
            value={sums.netBalSum}
            label={`Net ${summaryLabelSuffix}`}
            data-testid="balances-chart-module-net-summary"
          />
        )}
        {sums.positiveValueSum && (
          <Summary
            value={sums.positiveValueSum}
            label={`Long ${summaryLabelSuffix}`}
            data-testid="balances-chart-module-long-summary"
          />
        )}
        {sums.uPnLSum && (
          <Summary value={sums.uPnLSum} label="Unrealized P&L" data-testid="balances-chart-module-upnl-summary" />
        )}
      </HStack>
      <Box flex="1" overflow="hidden" w="100%">
        <Box p="spacingComfortable" h="100%" w="100%">
          {entities == null ? (
            <LoaderTalos />
          ) : entities.length === 0 ? (
            <VStack h="100%">
              <Text>No data found</Text>
            </VStack>
          ) : (
            <OpsBalancesChart
              entities={entities}
              getValue={getValue}
              getGroupBy={getGroupBy}
              getLabel={getLabel}
              getTooltip={getTooltip}
              showingByAsset={showByAsset}
              chartValueAxisTitle={chartValueAxisTitle}
            />
          )}
        </Box>
      </Box>
    </Module>
  );
};

const Summary = ({ value, label, ...boxProps }: { value: Big; label: string } & BoxProps) => {
  const { homeCurrency } = useDisplaySettings();
  const homeCurrencyInfo = useCurrency(homeCurrency);
  const theme = useTheme();

  const isNegative = value.lt(0);

  return (
    <VStack gap="spacingSmall" alignItems="flex-start" {...boxProps}>
      <Text>{label}</Text>
      <InlineFormattedNumber
        number={value}
        currency={homeCurrency}
        increment={homeCurrencyInfo?.DefaultIncrement}
        highlightColor={isNegative ? theme.colorTextNegative : undefined}
        round
      />
    </VStack>
  );
};
