import { invariant } from '@epic-web/invariant';
import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { LookbackOption, type DateRangeOrLookback } from '@talos/kyoko';
import type { SerializedDockview } from 'dockview';
import type { WritableDraft } from 'immer';
import { omitBy } from 'lodash';
import type { AppStateListenerStart } from 'providers/AppStateProvider/types';
import type { OpsBalancesChartDimension } from '../../OperationsOverview/BalancesChart/types';
import type { OperationsOverviewShowBy, OpsOverviewFilter } from '../../OperationsOverview/types';
import type { OperationsOverviewShowByOld } from '../../OperationsOverviewOld/types';
import { getPortfolioLayoutOptions } from '../layoutConfiguration/getPortfolioLayoutOptions';
import type { LayoutOptionTabProps } from '../portfolioDashboard.types';
import type { PortfolioViewType } from '../portfolioDashboardLayouts';
import type { OverviewChartDisplayType, PortfolioViewState, RiskAggMode, RiskPivotType } from '../types/types';

export interface PortfolioViewLayoutState {
  view: PortfolioViewType;
  selectedTab: {
    [P in PortfolioViewType]?: LayoutOptionTabProps;
  };
  /** Storage mapping for DockView storage.
   * It's a bit tough to more strongly type the key as the key is dependent on the workspace and tab type */
  userLayoutMapForViewTab: { [key: string]: SerializedDockview };
  viewState: PortfolioViewState;
}

interface PortfolioViewLayoutActionPayload {
  view: PortfolioViewType;
  tabLabel: string;
  userLayout: SerializedDockview;
}

const USER_LAYOUT_STORAGE_KEY = 'portfolioview.DockViewUserLayout-5';
const userLayoutFromStorage = localStorage.getItem(USER_LAYOUT_STORAGE_KEY);
const storedStateMap = userLayoutFromStorage ? JSON.parse(userLayoutFromStorage) : {};

/** Create the initial portfolio view state based on prior state
 * - if any input prop values are undefined, the default overrides it
 */
export function getInitPortfolioViewState(
  viewState: Partial<PortfolioViewLayoutState['viewState']>
): PortfolioViewLayoutState['viewState'] {
  return {
    selectedPortfolioId: undefined,
    selectedMarketAccountIds: undefined,
    showRollupHierarchy: false,
    showZeroBalances: false,
    dateRange: {
      lookback: LookbackOption.Past24Hours,
    },
    includeCash: false,
    overviewChartDisplayType: 'byInstrument',
    riskPivotAggMode: 'Net',
    riskPivotType: 'Tenor',
    opsOverviewShowByOld: 'MarketAccount',
    opsOverviewFilterOld: {},
    opsOverviewShowBy: 'MarketAccount',
    opsOverviewFilter: {},
    opsOverviewBalancesChartDimension: 'balances',
    opsOverviewBalancesChartShowByAsset: false,
    ...omitBy(viewState, value => value === undefined),
  };
}
const initialState: PortfolioViewLayoutState = {
  viewState: getInitPortfolioViewState({}),
  view: 'portfolio-management',
  selectedTab: {},
  userLayoutMapForViewTab: storedStateMap,
};

export function getKeyForUserLayout(view: PortfolioViewType, tabLabel: string): string {
  return `${view}::${tabLabel}`;
}

export function getLayoutForViewTab(
  userLayoutMap: PortfolioViewLayoutState['userLayoutMapForViewTab'],
  view: PortfolioViewType,
  tabLabel: string
): SerializedDockview {
  return userLayoutMap[getKeyForUserLayout(view, tabLabel)];
}

export function getDefaultLayoutForViewTab(view: PortfolioViewType, tabLabel: string): SerializedDockview {
  const layoutOptionTab = getPortfolioLayoutOptions()
    .find(option => option.value === view)
    ?.tabs.filter(tab => tab.layoutType === 'flexible')
    .find(tab => tab.label === tabLabel);
  invariant(layoutOptionTab, `Could not find layout option for view ${view} and tab ${tabLabel}`);
  return layoutOptionTab.getDefaultLayout();
}

export const portfolioViewLayoutSlice = createSlice({
  name: 'portfolioViewLayout',
  initialState,
  reducers: {
    setPortfolioViewState(state, action: PayloadAction<PortfolioViewLayoutState['viewState']>) {
      state.viewState = action.payload;
    },
    changeSelectedPortfolioId: (
      state,
      action: PayloadAction<{
        selectedPortfolioId: number;
        onlyIfNotSet?: boolean;
      }>
    ) => {
      if (action.payload.onlyIfNotSet && state.viewState.selectedPortfolioId !== undefined) {
        return;
      }
      state.viewState.selectedPortfolioId = action.payload.selectedPortfolioId;
    },
    updateDateRange: (state, action: PayloadAction<DateRangeOrLookback>) => {
      state.viewState.dateRange = action.payload;
    },
    changeMarketAccountIds: (state, action: PayloadAction<string[] | undefined>) => {
      state.viewState.selectedMarketAccountIds = action.payload;
    },
    updateTab: (
      state,
      action: PayloadAction<{
        view: PortfolioViewType;
        tab: LayoutOptionTabProps;
      }>
    ) => {
      state.selectedTab[action.payload.view] = action.payload.tab;
    },
    changeView: (state, action: PayloadAction<PortfolioViewType>) => {
      state.view = action.payload;
      state.selectedTab[action.payload] = getPortfolioLayoutOptions().find(
        option => option.value === action.payload
      )?.tabs[0];
    },
    updateLayout: (
      state: WritableDraft<PortfolioViewLayoutState>,
      action: { payload: PortfolioViewLayoutActionPayload; type: string }
    ) => {
      state.userLayoutMapForViewTab[getKeyForUserLayout(action.payload.view, action.payload.tabLabel)] =
        action.payload.userLayout;
    },
    updateShowRollupHierarchy(state, action: PayloadAction<boolean>) {
      state.viewState.showRollupHierarchy = action.payload;
    },
    updateShowZeroBalances(state, action: PayloadAction<boolean>) {
      state.viewState.showZeroBalances = action.payload;
    },
    updateIncludeCash(state, action: PayloadAction<boolean>) {
      state.viewState.includeCash = action.payload;
    },
    updateOverviewChartDisplayType(state, action: PayloadAction<OverviewChartDisplayType>) {
      state.viewState.overviewChartDisplayType = action.payload;
    },
    updateRiskPivotType(state, action: PayloadAction<RiskPivotType>) {
      state.viewState.riskPivotType = action.payload;
    },
    updateRiskPivotAggMode(state, action: PayloadAction<RiskAggMode>) {
      state.viewState.riskPivotAggMode = action.payload;
    },
    updateOpsOverviewShowByOld(state, action: PayloadAction<OperationsOverviewShowByOld>) {
      state.viewState.opsOverviewShowByOld = action.payload;
    },
    updateOpsOverviewFilterOld(state, action: PayloadAction<OpsOverviewFilter>) {
      state.viewState.opsOverviewFilterOld = action.payload;
    },
    updateOpsOverviewShowBy(state, action: PayloadAction<OperationsOverviewShowBy>) {
      state.viewState.opsOverviewShowBy = action.payload;
    },
    updateOpsOverviewFilter(state, action: PayloadAction<OpsOverviewFilter>) {
      state.viewState.opsOverviewFilter = action.payload;
    },
    updateOpsOverviewBalancesChartDimension(state, action: PayloadAction<OpsBalancesChartDimension>) {
      state.viewState.opsOverviewBalancesChartDimension = action.payload;
    },
    updateOpsOverviewBalancesChartShowByAsset(state, action: PayloadAction<boolean>) {
      state.viewState.opsOverviewBalancesChartShowByAsset = action.payload;
    },
  },
});

export function setupListeners(startListening: AppStateListenerStart) {
  const unsubscribe = startListening({
    predicate: action => action.type === portfolioViewLayoutSlice.actions.updateLayout.type,
    effect: async (_action, listenerApi) => {
      const newState = listenerApi.getState();
      localStorage.setItem(
        USER_LAYOUT_STORAGE_KEY,
        JSON.stringify(newState.portfolioViewLayout.userLayoutMapForViewTab)
      );
    },
  });
  return () => {
    unsubscribe({ cancelActive: true });
  };
}
