import {
  Dialog,
  Field,
  Flex,
  FormGroup,
  type MarketAccount,
  NotificationVariants,
  Text,
  Toggle,
  type UseDisclosureReturn,
  useGlobalToasts,
  useMarketAccountGroups,
} from '@talos/kyoko';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Autocomplete } from '../../../components/Form';
import { useMarketAccountRequests } from '../../../hooks/useMarketAccountRequests';
import { GROUP_CLIENT_HIDDEN } from './types';
import { VALIDATION_RULES } from './validation';

type BulkModifyMarketAccountGroupModalProps = { marketAccounts: MarketAccount[] } & UseDisclosureReturn;

export const BulkModifyMarketAccountGroupModal = ({
  marketAccounts,
  ...disclosure
}: BulkModifyMarketAccountGroupModalProps) => {
  const { add: addToast } = useGlobalToasts();
  const { updateMarketAccount } = useMarketAccountRequests();

  const [group, setGroup] = useState(new Field<string>({ value: '', isRequired: false /* can be empty */ }));
  const [hide, setHide] = useState(false);

  useEffect(() => {
    if (disclosure.isOpen) {
      // Reset the state of the "form" when the disclosure is opened a-new
      setGroup(new Field<string>({ value: '', isRequired: false /* can be empty */ }));
      setHide(false);
    }
  }, [disclosure.isOpen]);

  const marketAccountGroups = useMarketAccountGroups();
  const groupOptions = useMemo(() => {
    return marketAccountGroups.map(group => ({ id: group, label: group }));
  }, [marketAccountGroups]);

  const handleConfirm = useCallback(() => {
    const requests = marketAccounts.map(ma =>
      updateMarketAccount(ma.Name, ma.Counterparty, { Group: group.value, DisplayName: ma.DisplayName })
    );
    Promise.allSettled(requests).then(promises => {
      const countFulfilled = promises.filter(p => p.status === 'fulfilled').length;
      const countRejected = promises.filter(p => p.status === 'rejected').length;

      if (countFulfilled > 0) {
        addToast({
          variant: NotificationVariants.Positive,
          text: `Successfully updated ${countFulfilled} accounts.`,
        });
      }

      if (countRejected > 0) {
        addToast({
          variant: NotificationVariants.Negative,
          text: `Failed to update ${countRejected} accounts.`,
        });
      }
    });
  }, [marketAccounts, updateMarketAccount, addToast, group]);

  const handleGroupChange = useCallback((value: string | undefined) => {
    setGroup(curr => curr.updateValue(value).setTouched(true).validate(VALIDATION_RULES.group));
  }, []);

  const handleHideToggleChanged = useCallback(
    (checked: boolean) => {
      if (checked) {
        setGroup(curr => curr.updateValue(GROUP_CLIENT_HIDDEN)); // dont validate
      } else {
        // Set the group back to whatever it was before (or empty)
        handleGroupChange('');
      }
      setHide(checked);
    },
    [handleGroupChange]
  );

  return (
    <Dialog
      {...disclosure}
      width="350px"
      title="Modify Group"
      showClose
      confirmLabel="Update"
      stretchButtons
      contentOverflow="visible"
      onConfirm={handleConfirm}
    >
      <Flex flexDirection="column" gap="spacingDefault" justifyContent="flex-start">
        <Text textAlign="left">You are setting the Group property for {marketAccounts.length} Market Accounts.</Text>
        {!hide && (
          <FormGroup label="Group" w="100%">
            <Autocomplete
              isCentered
              getItemValue={item => item.label}
              inputProps={{
                type: 'text',
                'data-testid': 'bulk-market-account-group-input',
              }}
              menuStyle={{
                /* There's some weird overflow things going on with this dropdown.
            Have to set a height smaller than the modal's height so all the items can be shown. */
                maxHeight: '100px',
              }}
              items={groupOptions}
              shouldItemRender={(item, value) => item.label.indexOf(value) !== -1}
              value={group.value}
              sortItems={(a, b) => a.label.localeCompare(b.label)}
              onChange={(e, val) => {
                handleGroupChange(val);
              }}
              onSelect={val => {
                handleGroupChange(val);
              }}
            />
          </FormGroup>
        )}

        <FormGroup
          label="Hide Market Accounts"
          w="100%"
          tooltip="Enable to hide these Market Accounts from other parts of the application. Hiding puts the Market Accounts into a hidden group, meaning that you no longer can specify a group of your own."
          inline
          alignItems="center"
          justifyContent="space-between"
        >
          <Toggle onChange={handleHideToggleChanged} checked={hide} data-testid="bulk-market-account-hide-toggle" />
        </FormGroup>
      </Flex>
    </Dialog>
  );
};
