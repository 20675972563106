import type { AgGridSearchSelectDropdownProps, FilledPercentColumnParams, Leaves } from '@talos/kyoko';
import {
  BaseSelectCellRenderer,
  BlotterTable,
  FormControlSizes,
  HStack,
  Icon,
  IconName,
  Order,
  OrderStatus,
  Text,
  getOrderStatusColor,
  logger,
  useBlotterTable,
  type ColumnDef,
  type MinimalSubscriptionResponse,
} from '@talos/kyoko';

import type { CellEditRequestEvent, ICellRendererParams, IRowNode, ValueFormatterParams } from 'ag-grid-community';
import type { CustomCellEditorProps } from 'ag-grid-react';
import { compact, identity } from 'lodash';
import { useCallback, useMemo } from 'react';
import type { Observable } from 'rxjs';
import type { DefaultTheme } from 'styled-components';
import { bulkOrdStatusIconMapping, bulkOrdStatusTextMapping } from '../../../../../hooks/useBulkOrderSubmissionSending';
import type { BulkClosePositionEntity } from './types';

interface BulkCloseStatusBlotterProps {
  dataObservable: Observable<MinimalSubscriptionResponse<BulkClosePositionEntity>>;
  /**
   * Whether or not to display the blotter in "confirming" mode. As in, there will be no execution to report in this blotter's life time.
   * Setting this to true will remove some default columns to focus on just showing the user what they are submitting instead of giving an overview
   * of execution of async order submissions.
   *
   * Defaults to false.
   */
  confirming?: boolean;

  /** Called when the symbol of a row is requested to be edited by the user */
  onSymbolChanged: (clOrdId: string, newSymbol: string) => void;

  /** Called when a row was deleted. Invoced with the id of the row (ClOrdId in this case). */
  onRowDeleted: (clOrdId: string) => void;
}

export const BulkClosePositionBlotter = ({
  dataObservable,
  onSymbolChanged,
  onRowDeleted,
  confirming = false,
}: BulkCloseStatusBlotterProps) => {
  const columns = useBulkClosePositionBlotterColumns({ confirming, onRowDeleted });

  // This function takes in all cell edit requests. We then filter and make sure we only propagate the ones that are correct to their respective handlers (for now just 1)
  const handleCellEditRequest = useCallback(
    (event: CellEditRequestEvent<BulkClosePositionEntity>) => {
      // Asserting on event.data.editable does type narrowing for us here.
      if (!onSymbolChanged || !event.data.editable) {
        return;
      }

      const field = event.column.getColDef().field;
      // For now, we only allow editing of the symbol.
      if (field !== ('order.Symbol' satisfies Leaves<BulkClosePositionEntity>)) {
        logger.error(
          new Error('Bulk Close Position Dialog - failed to edit row. Edited field was not exactly order.Symbol'),
          {
            extra: {
              colId: event.column.getColId(),
              field,
              oldValue: event.oldValue,
              newValue: event.newValue,
            },
          }
        );
        return;
      }

      onSymbolChanged(event.data.order.ClOrdID, event.newValue);
    },
    [onSymbolChanged]
  );

  const blotterTable = useBlotterTable({
    dataObservable,
    rowID: 'rowID' satisfies keyof BulkClosePositionEntity,
    columns,
    gridOptions: {
      onCellEditRequest: handleCellEditRequest,
      readOnlyEdit: true, // dont modify data on edit, instead let us handle data modification via the event
      singleClickEdit: true, // open editors with just one click on the cell
    },
  });

  return <BlotterTable {...blotterTable} />;
};

const useBulkClosePositionBlotterColumns = ({
  confirming,
  onRowDeleted,
}: {
  /** Whether or not the blotter is in "confirming" mode where the user can make edits */
  confirming: boolean;
  onRowDeleted: (id: string) => void;
}) => {
  const columns = useMemo(() => {
    return compact([
      getSecurityColumn(confirming),
      { field: 'order.Side', title: 'Side', type: 'side' },
      {
        field: 'order.OrderQty',
        title: 'Order Qty',
        type: 'size',
        params: {
          currencyField: 'order.Currency',
        },
      },
      {
        type: 'custom',
        title: 'Ord Status',
        field: 'order.OrdStatus',
        params: {
          cellRenderer: (params: ICellRendererParams<BulkClosePositionEntity>) => {
            const order = params.data?.order;
            if (order == null) {
              return <></>;
            }

            if (order instanceof Order) {
              const { OrdStatus, OrderQty, DecisionStatus, CumQty, PricingMode, Text } = order;
              return (
                <OrderStatus
                  theme={params.context.current.theme}
                  ordStatus={OrdStatus}
                  decisionStatus={DecisionStatus}
                  cumQty={CumQty}
                  orderQty={OrderQty}
                  pricingMode={PricingMode}
                  text={Text}
                  iconPlacement="left"
                />
              );
            } else {
              // synthetic order, meaning that we have a BulkOrdStatus
              return (
                <HStack gap="spacingSmall">
                  <Icon icon={bulkOrdStatusIconMapping[order.OrdStatus]} color="colors.blue.lighten" />
                  <Text>{bulkOrdStatusTextMapping[order.OrdStatus]}</Text>
                </HStack>
              );
            }
          },
          width: 150,
        },
      },
      !confirming && {
        field: 'order.CumQty',
        type: 'size',
        sortable: true,
        title: 'Filled Qty',
        params: { currencyField: 'order.Currency' },
        width: 120,
      },
      !confirming && {
        id: 'filledPercent',
        type: 'filledPercent',
        title: 'Filled %',
        params: {
          filledQtyField: 'order.CumQty',
          totalQtyField: 'order.OrderQty',
          getColor: getFilledPercentColor,
        } satisfies FilledPercentColumnParams<BulkClosePositionEntity>,
      },
      !confirming && {
        field: 'order.filledPx',
        type: 'price',
        sortable: true,
        title: 'Filled Price',
        params: { assetField: 'order.Symbol' },
      },

      {
        field: 'order.tradedMarketAccounts',
        type: 'marketAccounts',
        title: 'Market Accounts',
      },
      { field: 'order.Strategy', type: 'strategy', title: 'Strategy' },
      confirming && {
        // this column is only visible while editing
        id: 'delete',
        type: 'iconButton',
        pinned: 'right',
        width: 50,
        suppressColumnsToolPanel: true,
        params: {
          onClick: ({ node }: { node: IRowNode<BulkClosePositionEntity> }) =>
            node.data && onRowDeleted(node.data.order.ClOrdID),
          icon: IconName.Trash,
        },
      },
    ]) satisfies ColumnDef<BulkClosePositionEntity>[];
  }, [confirming, onRowDeleted]);

  return columns;
};

type EditableSecurityColumnValue = string | undefined;

// Returns the security column. This is a custom security column doing very bespoke editing functionality to support the bulk close position workflow.
function getSecurityColumn(editable: boolean): ColumnDef<BulkClosePositionEntity> {
  return {
    type: 'custom',
    title: 'Symbol',
    id: 'order.Symbol',
    editable: editable,
    params: {
      field: 'order.Symbol',
      editable: editable,
      cellRenderer: (params: ICellRendererParams<BulkClosePositionEntity, EditableSecurityColumnValue>) => {
        const colId = params.colDef?.colId;
        if (!colId) {
          return null;
        }

        // Doesn't make sense to show the cell as editable if there's only one selection anyway
        const nodeEditable = params.data?.editable && params.data.closingDetails.possibleSecurities.length > 1;

        // Show editing mode if both the column is editable and the node is editable
        if (editable && nodeEditable) {
          return (
            <BaseSelectCellRenderer
              baseSelectProps={{
                value: params.valueFormatted,
                getLabel: identity,
                size: FormControlSizes.Small,
              }}
              cellRendererParams={params}
              colId={colId}
            />
          );
        }

        return params.valueFormatted;
      },
      valueFormatter: ({
        value,
        context,
      }: ValueFormatterParams<BulkClosePositionEntity, EditableSecurityColumnValue>): string => {
        if (!value) {
          return '';
        }

        return context.current.securitiesBySymbol?.get(value)?.DisplaySymbol ?? value ?? '';
      },
      cellEditor: 'searchSelectDropdown',
      minWidth: 120,
      suppressKeyboardEvent: () => true,
      cellEditorPopup: true,
      cellEditorParams: (params: CustomCellEditorProps<BulkClosePositionEntity, EditableSecurityColumnValue>) => {
        if (!params.data.editable) {
          return undefined;
        }

        return {
          ...params,
          showDropdownSearch: true,
          useSearchSelectParams: {
            items: params.data.closingDetails.possibleSecurities.map(instr => instr.Symbol),
            getLabel: (security: string) =>
              params.context.current.securitiesBySymbol?.get(security)?.DisplaySymbol ?? security,
          },
          portalize: true,
        } satisfies AgGridSearchSelectDropdownProps<string>;
      },
    },
  };
}

function getFilledPercentColor(node: IRowNode<BulkClosePositionEntity>, theme: DefaultTheme): string | undefined {
  if (node.data?.order instanceof Order) {
    return getOrderStatusColor(node.data.order, theme);
  }

  return undefined;
}
