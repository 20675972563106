import {
  FilterClauseType,
  IconName,
  TradeStatusEnum,
  cleanupInitialFilterDateRange,
  filterExistsAndExcludes,
  getTypedKeys,
  useSymbolsFilter,
  useTradingMarketAccountsFilter,
  type BlotterTableClientLocalFilter,
  type BlotterTableFilter,
  type DateRangeFilter,
  type FilterClause,
  type FilterableProperty,
  type ITradeAllocation,
} from '@talos/kyoko';
import { isEqual, pick, values } from 'lodash';
import { useCallback, useMemo, useState, type SetStateAction } from 'react';
import { useSidesFilter, useUsersFilter } from '../../../hooks';

export interface TradeAllocationFilter extends DateRangeFilter {
  _statuses?: string[];
  Statuses?: TradeStatusEnum[];
  Users?: string[];
  MarketAccounts?: string[];
  Sides?: string[];
  Symbols?: string[];
}

export const useTradeAllocationFilter = ({
  initialFilter,
  saveFilter,
}: {
  initialFilter?: TradeAllocationFilter;
  saveFilter: (newFilter: any) => void;
}) => {
  const [filter, setFilter] = useState(() => cleanupInitialFilterDateRange(initialFilter));

  const changeFilter = useCallback(
    (action: SetStateAction<BlotterTableFilter>) => {
      const priorFilter = filter;
      const newFilter = action instanceof Function ? action(filter) : action;
      if (!isEqual(priorFilter, newFilter)) {
        setFilter(newFilter);
        saveFilter(newFilter);
      }
    },
    [filter, saveFilter]
  );

  const clientSideFilter = useCallback<BlotterTableClientLocalFilter<ITradeAllocation>>(
    row => {
      const data = row.data;

      if (filterExistsAndExcludes(filter, 'MarketAccounts', data, 'MarketAccount')) {
        return false;
      }
      if (filterExistsAndExcludes(filter, 'Sides', data, 'Side')) {
        return false;
      }
      if (filterExistsAndExcludes(filter, 'Statuses', data, 'TradeStatus')) {
        return false;
      }
      if (filterExistsAndExcludes(filter, 'Users', data, 'User')) {
        return false;
      }
      if (filterExistsAndExcludes(filter, 'Symbols', data, 'Symbol')) {
        return false;
      }
      return true;
    },
    [filter]
  );

  const tradingMarketAccountsFilter = useTradingMarketAccountsFilter();
  const usersFilter = useUsersFilter();
  const sidesFilter = useSidesFilter();
  const symbolFilter = useSymbolsFilter();

  const filterableProperties: FilterableProperty[] = useMemo(
    () =>
      [
        tradingMarketAccountsFilter,
        sidesFilter,
        {
          key: 'Statuses',
          label: 'Status',
          icon: IconName.CheckCircle,
          options: values(TradeStatusEnum),
          getOptionLabel: (option: string) => option,
        },
        symbolFilter,
        usersFilter,
      ].compact(),
    [tradingMarketAccountsFilter, usersFilter, sidesFilter, symbolFilter]
  );

  const initialFilterClauses = useMemo(() => {
    const clauses: FilterClause[] = [];
    if (filter) {
      getTypedKeys(filter).forEach(key => {
        const selections = filter[key];
        if (key === '_start' || key === 'StartDate') {
          return;
        }
        if (Array.isArray(selections)) {
          clauses.push({
            key: key,
            type: FilterClauseType.INCLUSIVE,
            selections,
          });
        }
      });
    }
    return clauses;
  }, [filter]);

  return {
    filter,
    filterableProperties,
    initialFilterClauses,
    changeFilter,
    clientSideFilter,
  };
};

export function onlyServerFilterKeys(filter?: TradeAllocationFilter) {
  return filter == null ? filter : pick(filter, ['StartDate', 'EndDate', 'Statuses', 'Symbols']);
}

export function colIDToFilterBuilderKey(id: string): keyof TradeAllocationFilter | undefined {
  switch (id as keyof ITradeAllocation) {
    case 'Symbol':
      return 'Symbols';
    case 'MarketAccount':
      return 'MarketAccounts';
    case 'Side':
      return 'Sides';
    case 'TradeStatus':
      return '_statuses';
    case 'User':
      return 'Users';
  }
  return undefined;
}
