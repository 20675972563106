import {
  DateTimePicker,
  Dialog,
  EMPTY_ARRAY,
  FormGroup,
  HStack,
  Input,
  LedgerUpdateTypeEnum,
  NOW,
  NotificationVariants,
  NumberInput,
  ProductTypeEnum,
  SearchSelect,
  prettifyLedgerUpdateTypeEnum,
  productTypeLabel,
  useAssetsContext,
  useCurrenciesContext,
  useDynamicCallback,
  useGlobalToasts,
  useMarketAccountsContext,
  type DialogProps,
  type Position,
} from '@talos/kyoko';
import { identity, isEmpty, keys } from 'lodash';
import { useEffect, useMemo, useState, type ChangeEvent } from 'react';
import { useSymbolSelector } from '../../../components/SymbolSelector/useSymbolSelector';
import { PositionTypeEnum, usePositionRequests, type UpdatePositionParams } from '../../../hooks';
import { useSubAccounts } from '../../../providers';
import type { UpdatePositionForm } from './types';
import { useUpdatePositionValidation } from './useUpdatePositionValidation';

interface UpdatePositionsDialogProps {
  dialogProps: DialogProps;
  positionType: PositionTypeEnum;
  positionPrimer?: Position;
}

const updateTypeOptions = [
  LedgerUpdateTypeEnum.Trade,
  LedgerUpdateTypeEnum.TradingFee,
  LedgerUpdateTypeEnum.TradeCancel,
  LedgerUpdateTypeEnum.TradeAmend,
  LedgerUpdateTypeEnum.FundingPnLDelta,
  LedgerUpdateTypeEnum.RealizedPnLDelta,
  LedgerUpdateTypeEnum.Deposit,
  LedgerUpdateTypeEnum.Withdrawal,
  LedgerUpdateTypeEnum.TransferFee,
  LedgerUpdateTypeEnum.ReconAdjustment,
];

function getUpdateTypeLabel(updateType: LedgerUpdateTypeEnum): string {
  return prettifyLedgerUpdateTypeEnum(updateType);
}

export const UpdatePositionsDialog = ({ dialogProps, positionType, positionPrimer }: UpdatePositionsDialogProps) => {
  const { add: addToast } = useGlobalToasts();
  const { updatePosition } = usePositionRequests();
  const { allSubAccounts, subAccountsByName } = useSubAccounts();
  const { assetsList, getAssetDisplaySymbol, resolveSecurityOrCurrency } = useAssetsContext();
  const { currenciesList } = useCurrenciesContext();
  const { marketAccountsList, marketAccountsByName } = useMarketAccountsContext();
  const [form, setForm] = useState<UpdatePositionForm>({});

  // Get the width we should be using for the symbol selector dropdown
  const { dropdownWidth: symbolSelectorDropdownWidth } = useSymbolSelector();

  // This useEffect performs priming of the form based on a possibly passed positionPrimer.
  // Whenever we switch to open, or the positionPrimer changes, we reset the form to the initial (maybe primed) state.
  useEffect(() => {
    if (dialogProps.isOpen) {
      setForm({
        // Attempt to prime, if there is no positionPrimer this just becomes an empty object which is fine
        updateType: undefined,
        subAccount: positionPrimer?.SubAccount,
        marketAccount: positionPrimer?.MarketAccount,
        asset: positionPrimer?.Asset,
        avgCostCurrency: positionPrimer?.AvgCostCurrency,
        avgCost: positionPrimer?.AvgCost,
      });
    }
  }, [dialogProps.isOpen, positionPrimer]);

  const { errors, touched, setTouched, setAllTouched } = useUpdatePositionValidation(form, positionType);

  useEffect(() => {
    // When the dialog is closed, we clear the form.
    if (!dialogProps.isOpen) {
      setForm({});
      setTouched({});
    }
  }, [dialogProps.isOpen, setTouched]);

  const updateForm = useDynamicCallback((update: Partial<UpdatePositionForm>) => {
    setForm(prev => ({ ...prev, ...update }));

    // Set touched after updates run to not hit any weird annoying timing ux issues (just put at back of queue)
    setTimeout(() => {
      setTouched(prev => {
        // Map each update to just true, then apply to the touched state
        const update: Record<string, boolean> = {};
        keys(update).forEach(key => {
          update[key] = true;
        });
        return { ...prev, ...update };
      });
    }, 0);
  });

  const subAccountOptions = useMemo(() => allSubAccounts?.map(s => s.Name), [allSubAccounts]);
  const marketAccountOptions = useMemo(() => marketAccountsList.map(ma => ma.Name), [marketAccountsList]);
  const assetOptions = useMemo(() => assetsList.map(a => a.Symbol), [assetsList]);
  const currencyOptions = useMemo(() => currenciesList.map(c => c.Symbol), [currenciesList]);

  const getAssetGroup = useDynamicCallback((asset: string) => {
    const { currency, security } = resolveSecurityOrCurrency(asset);
    if (currency) {
      return productTypeLabel(ProductTypeEnum.Spot);
    } else if (security) {
      return productTypeLabel(security.ProductType);
    } else {
      return 'Unknown';
    }
  });

  const getAssetDescription = useDynamicCallback((asset: string) => {
    const { currency, security } = resolveSecurityOrCurrency(asset);
    return (currency ?? security)?.Description ?? '';
  });

  const getSubAccountLabel = useDynamicCallback((subAccountName: string | undefined) =>
    subAccountName ? subAccountsByName?.get(subAccountName)?.DisplayName ?? subAccountName : subAccountName ?? ''
  );

  const getMarketAccountLabel = useDynamicCallback((marketAccountName: string | undefined) =>
    marketAccountName
      ? marketAccountsByName.get(marketAccountName)?.DisplayName ?? marketAccountName
      : marketAccountName ?? ''
  );

  const handleChangeUpdateType = useDynamicCallback((updateType?: LedgerUpdateTypeEnum) => {
    updateForm({ updateType });
  });

  const handleChangeSubAccount = useDynamicCallback((subAccount?: string) => {
    updateForm({ subAccount });
  });

  const handleChangeMarketAccount = useDynamicCallback((marketAccount?: string) => {
    updateForm({ marketAccount });
  });

  const handleChangeAsset = useDynamicCallback((asset?: string) => {
    updateForm({ asset });
  });

  const handleChangeAmount = useDynamicCallback((amount?: string) => {
    updateForm({ amount });
  });

  const handleChangeAvgCost = useDynamicCallback((avgCost?: string) => {
    updateForm({ avgCost });
  });

  const handleChangeAvgCostCurrency = useDynamicCallback((avgCostCurrency?: string) => {
    updateForm({ avgCostCurrency });
  });

  const handleTimestampChange = useDynamicCallback((timestamp?: Date | null) => {
    updateForm({ timestamp: timestamp ?? undefined });
  });

  const handleChangeComments = useDynamicCallback((event: ChangeEvent<HTMLInputElement>) => {
    updateForm({ comments: event.target.value });
  });

  const handleConfirm = useDynamicCallback(() => {
    // This should already be the case since the confirm button is disabled otherwise, but do this here too
    const account = positionType === PositionTypeEnum.SubAccountPosition ? form.subAccount : form.marketAccount;
    if (form.updateType == null || account == null || form.asset == null || form.amount == null) {
      return;
    }
    const params: UpdatePositionParams = {
      Type: LedgerUpdateTypeEnum.BalanceDelta,
      IntendedType: form.updateType,
      AccountName: account,
      Asset: form.asset,
      Amount: form.amount,
      AvgCost: form.avgCost,
      AvgCostCurrency: form.avgCostCurrency,
      LastUpdateTime: form.timestamp?.toISOString(),
      Comments: form.comments,
    };

    updatePosition(params, positionType)
      .then(() => {
        addToast({
          text: 'Successfully updated position.',
          variant: NotificationVariants.Positive,
        });
      })
      .catch((e: ErrorEvent) => {
        addToast({
          text: `Failed to update position: ${e?.toString()}`,
          variant: NotificationVariants.Negative,
        });
      });
  });

  return (
    <Dialog
      {...dialogProps}
      title={positionType === PositionTypeEnum.SubAccountPosition ? 'Update Sub Account Position' : 'Update Position'}
      width={400}
      confirmLabel="Update Position"
      confirmDisabled={!isEmpty(errors)}
      onConfirm={handleConfirm}
      onConfirmMouseOver={setAllTouched}
    >
      <FormGroup label="Update Type*" error={touched.updateType && errors.updateType ? errors.updateType : undefined}>
        <SearchSelect
          selection={form.updateType}
          options={updateTypeOptions}
          onChange={handleChangeUpdateType}
          getLabel={getUpdateTypeLabel}
          data-testid="update-position-update-type"
        />
      </FormGroup>

      {/* Either a sub account or market account control depending on the position type we're editing */}
      {positionType === PositionTypeEnum.SubAccountPosition ? (
        <FormGroup label="Sub Account*" error={touched.subAccount && errors.subAccount ? errors.subAccount : undefined}>
          <SearchSelect
            selection={form.subAccount}
            options={subAccountOptions ?? EMPTY_ARRAY}
            onChange={handleChangeSubAccount}
            getLabel={getSubAccountLabel}
            data-testid="update-position-sub-account"
          />
        </FormGroup>
      ) : (
        <FormGroup
          label="Market Account*"
          error={touched.marketAccount && errors.marketAccount ? errors.marketAccount : undefined}
        >
          <SearchSelect
            selection={form.marketAccount}
            options={marketAccountOptions ?? EMPTY_ARRAY}
            onChange={handleChangeMarketAccount}
            getLabel={getMarketAccountLabel}
            data-testid="update-position-market-account"
          />
        </FormGroup>
      )}

      <HStack gap="spacingMedium" alignItems="flex-start">
        <FormGroup label="Asset*" flexBasis="50%" error={touched.asset && errors.asset ? errors.asset : undefined}>
          <SearchSelect
            selection={form.asset}
            options={assetOptions}
            onChange={handleChangeAsset}
            getGroup={getAssetGroup}
            showGroupTabs
            getLabel={getAssetDisplaySymbol}
            getDescription={getAssetDescription}
            data-testid="update-position-asset"
            dropdownWidth={symbolSelectorDropdownWidth}
            maxHeight={400}
          />
        </FormGroup>

        <FormGroup label="Amount*" flexBasis="50%" error={touched.amount && errors.amount ? errors.amount : undefined}>
          <NumberInput value={form.amount ?? ''} onChange={handleChangeAmount} data-testid="update-position-amount" />
        </FormGroup>
      </HStack>

      <HStack gap="spacingMedium" alignItems="flex-start">
        <FormGroup
          label="Avg Cost Currency"
          flexBasis="50%"
          error={touched.avgCostCurrency && errors.avgCostCurrency ? errors.avgCostCurrency : undefined}
        >
          <SearchSelect
            selection={form.avgCostCurrency}
            options={currencyOptions}
            onChange={handleChangeAvgCostCurrency}
            getLabel={identity}
            showClear
            data-testid="update-position-avg-cost-currency"
          />
        </FormGroup>

        <FormGroup
          label="Avg Cost"
          flexBasis="50%"
          error={touched.avgCost && errors.avgCost ? errors.avgCost : undefined}
        >
          <NumberInput
            value={form.avgCost ?? ''}
            onChange={handleChangeAvgCost}
            data-testid="update-position-avg-cost"
          />
        </FormGroup>
      </HStack>

      <FormGroup label="Update Timestamp" error={touched.timestamp && errors.timestamp ? errors.timestamp : undefined}>
        <DateTimePicker
          value={form.timestamp ?? null}
          onChange={handleTimestampChange}
          portalize={false} // renders behind modal otherwise
          showMilliseconds
          shortcuts={{ [NOW]: 'Now' }}
        />
      </FormGroup>

      <FormGroup
        mb="spacingMedium"
        label="Comments*"
        error={touched.comments && errors.comments ? errors.comments : undefined}
      >
        <Input
          value={form.comments ?? ''}
          autoComplete="off"
          onChange={handleChangeComments}
          data-testid="update-position-comments"
        />
      </FormGroup>
    </Dialog>
  );
};
