import { Box, Checkbox, HelpIcon, MarketTypeEnum, SideEnum } from '@talos/kyoko';
import { MultilegDetails } from 'components/Multileg';
import { sortBy } from 'lodash';
import { useMemo, type ChangeEvent } from 'react';
import { useTheme } from 'styled-components';
import { STRATEGY_GROUP } from '../../../tokens/order';
import {
  DARK_DISABLED_STRATEGY,
  DEALERS_DISABLED_STRATEGY,
  EXCHANGES_DISABLED_STRATEGY,
} from '../../../tokens/tooltips';
import { isMultiLegSecurity } from '../../../utils/security';
import { MarketAccountListItem } from '../MarketAccountListItem';
import {
  CheckboxTitle,
  ListWrapper,
  MarketAccountGroup,
  MarketAccountGroupWrapper,
  MarketAccountListHeader,
  MarketName,
  Wrapper,
} from '../styles';
import type { InnerMarketAccountSelectionListProps, MarketAccountObject, MarketAccountSelectionProps } from '../types';

const MARKET_TYPES = {
  [MarketTypeEnum.Exchange]: {
    label: 'Exchanges',
    disabledText: EXCHANGES_DISABLED_STRATEGY,
  },
  [MarketTypeEnum.Dealer]: {
    label: 'Dealers',
    disabledText: DEALERS_DISABLED_STRATEGY,
  },
  [MarketTypeEnum.Dark]: {
    label: 'Dark',
    disabledText: DARK_DISABLED_STRATEGY,
  },
};

export const SelectionList = (props: MarketAccountSelectionProps & InnerMarketAccountSelectionListProps) => {
  const {
    security,
    scrollContent,
    strategyGroup = STRATEGY_GROUP.ALL,
    marketType,
    showBuyingPower,
    buyingPower,
    buyingPowerUseMaxOrderSize,
    quantity,
    side,

    exchangeChecked,
    exchangeIndeterminate,
    selectableExchangeMarketAccountObjs,
    handleExchangeCheckbox,
    selectedExchangeMarkets,
    exchangeMarketObjGroups,

    dealerChecked,
    dealerIndeterminate,
    selectableDealerMarketAccountObjs,
    handleDealerCheckbox,
    selectedDealerMarkets,
    dealerMarketObjGroups,

    darkChecked,
    darkIndeterminate,
    selectableDarkMarketAccountObjs,
    handleDarkCheckbox,
    selectedDarkMarkets,
    darkMarketObjGroups,

    handleMarketAccountChange,
    handleMarketChange,

    onEditMultileg,
    legParams,
  } = props;

  const availableGroupsCount = useMemo(
    () =>
      getAvailableGroupsCount([
        selectableExchangeMarketAccountObjs,
        selectableDealerMarketAccountObjs,
        selectableDarkMarketAccountObjs,
      ]),
    [selectableExchangeMarketAccountObjs, selectableDealerMarketAccountObjs, selectableDarkMarketAccountObjs]
  );

  if (security && isMultiLegSecurity(security)) {
    const sideEnum = stringToSideEnum(side);
    return (
      <Wrapper data-testid="market-account-selection-list">
        <MultilegDetails security={security} onEdit={onEditMultileg} legParams={legParams} side={sideEnum} />
      </Wrapper>
    );
  }

  if (availableGroupsCount === 0) {
    return (
      <Box p="spacingSmall" color="colorTextMuted" textAlign="center">
        No markets available for the selected symbol
        {
          // If strategy group is all, the selected strategy does not play a part in empty markets list
          strategyGroup === STRATEGY_GROUP.ALL ? '' : ' and strategy'
        }
      </Box>
    );
  }

  const isStrategyGroupDealer = strategyGroup === STRATEGY_GROUP.DEALER;
  const isStrategyGroupExchange = strategyGroup === STRATEGY_GROUP.EXCHANGE;
  const isStrategyGroupDark = strategyGroup === STRATEGY_GROUP.DARK;
  return (
    <Wrapper data-testid="market-account-selection-list">
      <ListWrapper scrollContent={Boolean(scrollContent)}>
        <MarketAccountsGroup
          groupMarketType={MarketTypeEnum.Exchange}
          marketAccounts={selectableExchangeMarketAccountObjs}
          selectedMarketAccounts={selectedExchangeMarkets}
          groups={exchangeMarketObjGroups}
          checked={exchangeChecked}
          indeterminate={exchangeIndeterminate}
          handleGroupCheckbox={handleExchangeCheckbox}
          handleMarketAccountChange={handleMarketAccountChange}
          handleMarketChange={handleMarketChange}
          quantity={quantity}
          security={security}
          showBuyingPower={showBuyingPower}
          buyingPower={buyingPower}
          buyingPowerUseMaxOrderSize={buyingPowerUseMaxOrderSize}
          marketType={marketType}
          availableGroupsCount={availableGroupsCount}
          disabled={isStrategyGroupDealer || isStrategyGroupDark}
          side={side}
        />

        <MarketAccountsGroup
          groupMarketType={MarketTypeEnum.Dealer}
          marketAccounts={selectableDealerMarketAccountObjs}
          selectedMarketAccounts={selectedDealerMarkets}
          groups={dealerMarketObjGroups}
          checked={dealerChecked}
          indeterminate={dealerIndeterminate}
          handleGroupCheckbox={handleDealerCheckbox}
          handleMarketAccountChange={handleMarketAccountChange}
          handleMarketChange={handleMarketChange}
          quantity={quantity}
          security={security}
          showBuyingPower={showBuyingPower}
          buyingPower={buyingPower}
          buyingPowerUseMaxOrderSize={buyingPowerUseMaxOrderSize}
          marketType={marketType}
          availableGroupsCount={availableGroupsCount}
          disabled={isStrategyGroupExchange || isStrategyGroupDark}
          side={side}
        />

        <MarketAccountsGroup
          groupMarketType={MarketTypeEnum.Dark}
          marketAccounts={selectableDarkMarketAccountObjs}
          selectedMarketAccounts={selectedDarkMarkets}
          groups={darkMarketObjGroups}
          checked={darkChecked}
          indeterminate={darkIndeterminate}
          handleGroupCheckbox={handleDarkCheckbox}
          handleMarketAccountChange={handleMarketAccountChange}
          handleMarketChange={handleMarketChange}
          quantity={quantity}
          security={security}
          showBuyingPower={showBuyingPower}
          buyingPower={buyingPower}
          buyingPowerUseMaxOrderSize={buyingPowerUseMaxOrderSize}
          marketType={marketType}
          availableGroupsCount={availableGroupsCount}
          disabled={isStrategyGroupExchange || isStrategyGroupDealer}
          side={side}
        />
      </ListWrapper>
    </Wrapper>
  );
};

type MarketAccountsGroupProps = {
  marketAccounts: MarketAccountObject[];
  selectedMarketAccounts: string[];
  groups: { [key: string]: MarketAccountObject[] };
  checked: boolean;
  indeterminate: boolean;
  marketType: MarketTypeEnum | undefined;
  groupMarketType: MarketTypeEnum;
  disabled: boolean;
  availableGroupsCount: number;
  handleGroupCheckbox: (checked: boolean) => void;
} & Pick<
  MarketAccountSelectionProps,
  'security' | 'buyingPower' | 'showBuyingPower' | 'buyingPowerUseMaxOrderSize' | 'quantity' | 'side'
> &
  Pick<InnerMarketAccountSelectionListProps, 'handleMarketAccountChange' | 'handleMarketChange'>;

function MarketAccountsGroup({
  marketAccounts,
  selectedMarketAccounts,
  groups,
  checked,
  indeterminate,
  marketType,
  groupMarketType,
  disabled,
  availableGroupsCount,
  showBuyingPower,
  security,
  buyingPower,
  buyingPowerUseMaxOrderSize,
  side,
  quantity,
  handleGroupCheckbox,
  handleMarketAccountChange,
  handleMarketChange,
}: MarketAccountsGroupProps) {
  const theme = useTheme();

  if (marketAccounts.length === 0 || (marketType && marketType !== groupMarketType)) {
    return null;
  }

  return (
    <>
      {(availableGroupsCount > 1 || showBuyingPower) && (
        <MarketAccountListHeader>
          {availableGroupsCount > 1 && (
            <>
              <Checkbox
                checked={checked}
                indeterminate={indeterminate}
                disabled={disabled}
                onChange={(e: ChangeEvent<HTMLInputElement>) => handleGroupCheckbox(e.target.checked)}
                data-testid={`group-${groupMarketType}-checkbox`}
              >
                <CheckboxTitle>
                  {MARKET_TYPES[groupMarketType].label} ({selectedMarketAccounts.length}/{marketAccounts.length})
                </CheckboxTitle>
              </Checkbox>
              {disabled && (
                <HelpIcon
                  tooltip={MARKET_TYPES[groupMarketType].disabledText}
                  style={{ marginLeft: theme.spacingTiny }}
                />
              )}
            </>
          )}
          {showBuyingPower && <div>Max Available</div>}
        </MarketAccountListHeader>
      )}
      {!disabled &&
        Object.keys(groups).map(marketName => {
          const marketAccounts = groups[marketName];
          return (
            <MarketAccounts
              key={marketName}
              marketName={marketName}
              marketAccounts={marketAccounts}
              security={security}
              buyingPower={buyingPower}
              showBuyingPower={showBuyingPower}
              buyingPowerUseMaxOrderSize={buyingPowerUseMaxOrderSize}
              side={side}
              quantity={quantity}
              handleMarketAccountChange={handleMarketAccountChange}
              handleMarketChange={handleMarketChange}
            />
          );
        })}
    </>
  );
}

type MarketAccountsProps = {
  marketName: string;
  marketAccounts: MarketAccountObject[];
} & Pick<
  MarketAccountSelectionProps,
  'security' | 'buyingPower' | 'showBuyingPower' | 'buyingPowerUseMaxOrderSize' | 'side' | 'quantity'
> &
  Pick<InnerMarketAccountSelectionListProps, 'handleMarketAccountChange' | 'handleMarketChange'>;

function MarketAccounts({
  marketName,
  marketAccounts,
  security,
  buyingPower,
  showBuyingPower,
  buyingPowerUseMaxOrderSize,
  side,
  quantity,
  handleMarketAccountChange,
  handleMarketChange,
}: MarketAccountsProps) {
  return (
    <MarketAccountGroupWrapper key={marketName}>
      <MarketName>{marketAccounts.length > 1 && marketName}</MarketName>
      <MarketAccountGroup>
        {sortBy(marketAccounts, item => item.label).map(m =>
          m.marketAccount != null ? (
            <MarketAccountListItem
              {...m}
              key={m.name}
              onCheckboxChange={() => handleMarketAccountChange(m)}
              security={security}
              buyingPower={buyingPower?.get(m.marketAccount.Name)}
              quantity={quantity}
              showBuyingPower={showBuyingPower}
              buyingPowerUseMaxOrderSize={buyingPowerUseMaxOrderSize}
              side={side}
            />
          ) : (
            <MarketAccountListItem
              {...m}
              key={m.name}
              onCheckboxChange={() => handleMarketChange(m)}
              security={security}
              quantity={quantity}
              showBuyingPower={showBuyingPower}
              buyingPowerUseMaxOrderSize={buyingPowerUseMaxOrderSize}
              side={side}
            />
          )
        )}
      </MarketAccountGroup>
    </MarketAccountGroupWrapper>
  );
}

// Get available groups count for each Market
function getAvailableGroupsCount(groups: Array<MarketAccountObject[]>) {
  return groups.reduce((acc, group) => {
    return (
      acc +
      group.reduce((acc2, mao) => {
        acc2.add(mao.market.DisplayName);
        return acc2;
      }, new Set()).size
    );
  }, 0);
}

function stringToSideEnum(str: string | undefined): SideEnum | undefined {
  if (str === SideEnum.Buy) {
    return SideEnum.Buy;
  }

  if (str === SideEnum.Sell) {
    return SideEnum.Sell;
  }

  return undefined;
}
