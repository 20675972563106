import { useRef } from 'react';
import { useEffectOnce } from 'react-use';
import { useRestBlotterTable, type Column } from '../BlotterTable';
import { EntityAdminPage } from './EntityAdminPage';
import type { EntityPageClass, EntityPageRecord } from './types';
import { useEntityAdminPage, type useEntityAdminPageProps } from './useEntityAdminPage';

export interface EntityAdminPageRESTProps<TRecord extends EntityPageRecord, TDrawerRecord extends TRecord = TRecord>
  extends useEntityAdminPageProps<TRecord, TDrawerRecord> {
  /** The initial path for the Websocket API endpoint. */
  path: string;

  /** Callback function when columns are ready. */
  onColumnsReady?: (columns: Column<EntityPageRecord>[]) => void;

  /** The number of records to paginate until */
  blotterRowCount?: number;

  /** The number of records to paginate at a time */
  paginationSize?: number;
}

export const EntityAdminPageREST = <TRecord extends EntityPageRecord, TDrawerRecord extends TRecord = TRecord>({
  onColumnsReady,
  blotterRowCount,
  paginationSize,
  ...props
}: EntityAdminPageRESTProps<TRecord, TDrawerRecord>) => {
  const blotterTableApiRef = useRef<{ refresh?: (force?: boolean) => void }>({});

  const { blotterTableProps, ...entityAdminPage } = useEntityAdminPage<TRecord, TDrawerRecord>({
    ...props,
    blotterTableApiRef,
  });

  const blotterTableREST = useRestBlotterTable<EntityPageClass<TRecord>>({
    // "limit" here is misleading - it's actually the number of records to fetch at a time
    request: { path: props.path, limit: paginationSize },
    onColumnsReady,
    blotterRowCount,
    ...blotterTableProps,
  });

  useEffectOnce(() => {
    blotterTableApiRef.current.refresh = blotterTableREST.refresh;
  });

  return <EntityAdminPage<TRecord, TDrawerRecord> blotterTable={blotterTableREST} {...entityAdminPage} />;
};
