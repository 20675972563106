import {
  ACTION,
  Dialog,
  Flex,
  OrderStatus,
  Patch,
  abbreviateId,
  useUserContext,
  type DialogProps,
  type Order,
  type UseDisclosureReturn,
} from '@talos/kyoko';
import { useCallback, useEffect, useState } from 'react';

import { Autocomplete, FormError } from 'components/Form';

import { CancelDialogWrapper, Row } from './styles';

import { useGroups, useRoleAuth } from 'hooks';

type BulkModifyGroupDialogProps = DialogProps &
  UseDisclosureReturn & {
    selectedOrders?: Order[];
  };

export const BulkModifyGroupDialog = ({ selectedOrders, ...props }: BulkModifyGroupDialogProps) => {
  if (!selectedOrders) {
    return null;
  }

  if (selectedOrders.length === 1) {
    return ModifyGroupDialog({ selectedOrder: selectedOrders[0], ...props });
  }

  return null; // No bulk group edit yet
};

type ModifyGroupDialogProps = DialogProps &
  UseDisclosureReturn & {
    selectedOrder?: Order;
  };

interface Group {
  id: string;
  label: string;
}

export const ModifyGroupDialog = ({ selectedOrder, ...props }: ModifyGroupDialogProps) => {
  const [modifyGroupError, setModifyGroupError] = useState<string | undefined>();
  const [group, setGroup] = useState<string>();
  const { orgApiEndpoint } = useUserContext();
  const { isAuthorized } = useRoleAuth();

  const groupOptions: Group[] = useGroups()
    .compact()
    .map(group => ({
      id: group,
      label: group,
    }));

  useEffect(() => {
    setGroup(selectedOrder?.Group || '');
  }, [selectedOrder]);
  const { open, close } = props;
  const modifyGroup = useCallback(
    (orderID: string, group?: string) => {
      Patch(orgApiEndpoint ?? '', `/orders/${orderID}`, { Group: group })
        .then(() => {
          close();
        })
        .catch((e: ErrorEvent) => {
          setModifyGroupError(e?.message || 'Something went wrong.');
          open();
        });
    },
    [open, close, orgApiEndpoint]
  );

  const handleOnGroupModifyDialogConfirm = useCallback(() => {
    if (!selectedOrder) {
      return;
    }
    modifyGroup(selectedOrder?.OrderID, group);
  }, [group, modifyGroup, selectedOrder]);

  if (!selectedOrder) {
    return null;
  }

  return (
    <Dialog
      {...props}
      onConfirm={handleOnGroupModifyDialogConfirm}
      confirmLabel="Update"
      confirmDisabled={!isAuthorized(ACTION.SUBMIT_ORDER)}
      cancelLabel="Cancel"
      contentOverflow="visible"
      title="Modify Group"
      width={300}
      stretchButtons
    >
      <CancelDialogWrapper>
        <Row>
          <h3>
            Order #<b>{abbreviateId(selectedOrder.OrderID)}</b>
          </h3>
          <OrderStatus
            ordStatus={selectedOrder.OrdStatus}
            decisionStatus={selectedOrder.DecisionStatus}
            cumQty={selectedOrder.CumQty}
            orderQty={selectedOrder.OrderQty}
            pricingMode={selectedOrder.PricingMode}
            text={selectedOrder.Text}
          />
        </Row>
        <Row>
          <Flex
            flex={1}
            w="100%"
            justifyContent="space-between"
            alignItems="center"
            gap="spacingBig"
            mt="spacingMedium"
          >
            <span>Group</span>
            <div style={{ flexGrow: 1 }}>
              <Autocomplete
                getItemValue={(item: Group) => item.label}
                inputProps={{
                  id: 'group',
                  autoFocus: false,
                  type: 'text',
                }}
                items={groupOptions}
                isCentered={false}
                shouldItemRender={(item: Group, value: string) => item.label.indexOf(value) !== -1}
                value={group}
                sortItems={(a: Group, b: Group) => a.label.localeCompare(b.label)}
                onChange={(e, val) => setGroup(val)}
                onSelect={val => setGroup(val)}
                style={{ textAlign: 'left' }}
              />
            </div>
          </Flex>
        </Row>
        {modifyGroupError && <FormError style={{ justifyContent: 'flex-end' }}>{modifyGroupError}</FormError>}
      </CancelDialogWrapper>
    </Dialog>
  );
};
