import {
  ConnectionType,
  MarketAccountTypeEnum,
  MarketTypeEnum,
  useConnectionStatusContext,
  useMarketAccountsContext,
  useMarketsContext,
} from '@talos/kyoko';
import { groupBy, isEmpty } from 'lodash';
import { useCallback, useMemo, type Ref } from 'react';
import { useTradingSettings } from '../../providers/TradingSettingsContext';
import type { MarketAccountObject, MarketAccountSelectionProps } from './types';
import { getMarketAccountObjects, getMissingMarkets } from './utils';

export function withMarketAccountSelection(WrappedComponent) {
  return function WrappedWithMarketAccountSelection({
    inputRef,
    ...props
  }: MarketAccountSelectionProps & {
    inputRef?: Ref<HTMLInputElement>;
  }) {
    const {
      availableMarkets,
      selectedMarkets,
      availableMarketAccounts,
      selectedMarketAccounts,
      onChangeMarkets,
      onChangeMarketAccounts,
      connectionType,
      usingCounterCurrency = false,
      showInactiveMarketAccounts,
    } = props;

    const { marketsByName, isMarketConfigured } = useMarketsContext();
    const { marketAccountsByName, marketAccountsByMarket, isMarketAccountActive } = useMarketAccountsContext();
    const { marketCredentialsWithConnectionStatusByMarketAccountName: connectionStatusLookup } =
      useConnectionStatusContext();

    const { allowSyntheticCcy } = useTradingSettings();

    const marketAccountObjs: MarketAccountObject[] = useMemo(() => {
      return getMarketAccountObjects({
        availableMarketAccounts,
        marketAccountsByName,
        marketsByName,
        connectionType,
        isMarketConfigured,
        isMarketAccountActive: showInactiveMarketAccounts
          ? ma => ma?.Type === MarketAccountTypeEnum.Trading
          : ma => {
              if (!ma || ma.Type !== MarketAccountTypeEnum.Trading) {
                return false;
              }
              return (
                isMarketAccountActive(ma) ||
                selectedMarketAccounts.includes(ma.Name) ||
                (selectedMarkets ?? []).includes(ma.Name)
              );
            },
        selectedMarketAccounts,
        usingCounterCurrency,
        allowSyntheticCcy,
        marketAccountsByMarket,
        availableMarkets,
        selectedMarkets,
        connectionStatusLookup,
      });
    }, [
      availableMarketAccounts,
      availableMarkets,
      allowSyntheticCcy,
      connectionType,
      selectedMarketAccounts,
      selectedMarkets,
      usingCounterCurrency,
      marketsByName,
      isMarketConfigured,
      isMarketAccountActive,
      marketAccountsByName,
      marketAccountsByMarket,
      showInactiveMarketAccounts,
      connectionStatusLookup,
    ]);

    const handleMarketAccountChange = useCallback(
      (mao: MarketAccountObject) => {
        const selected = !mao.isSelected;
        if (mao.marketAccount != null) {
          if (selected) {
            const relatedMarketAccounts = marketAccountObjs.filter(amao => amao.market.Name === mao.market.Name);
            const smas = marketAccountObjs.filter(
              sma =>
                selectedMarketAccounts.includes(sma.name) &&
                relatedMarketAccounts.find(rma => rma.market.Name !== sma.market.Name)
            );
            onChangeMarketAccounts([...smas.map(item => item.name), mao.name]);
          } else {
            onChangeMarketAccounts([...selectedMarketAccounts.filter(sma => sma !== mao.marketAccount?.Name)]);
          }
        }
      },
      [onChangeMarketAccounts, selectedMarketAccounts, marketAccountObjs]
    );

    const handleMarketChange = useCallback(
      (mao: MarketAccountObject) => {
        const selected = !mao.isSelected;
        if (mao.market != null && onChangeMarkets != null) {
          if (selected) {
            const relatedMarkets = marketAccountObjs.filter(amao => amao.market.Name === mao.market.Name);
            const smas = marketAccountObjs.filter(
              sma =>
                selectedMarkets?.includes(sma.name) && relatedMarkets.find(rma => rma.market.Name !== sma.market.Name)
            );
            onChangeMarkets([...smas.map(item => item.name), mao.name]);
          } else if (selectedMarkets != null) {
            onChangeMarkets([...selectedMarkets.filter(sma => sma !== mao.market?.Name)]);
          }
        }
      },
      [onChangeMarkets, selectedMarkets, marketAccountObjs]
    );

    const exchangeMarketAccountObjs = useMemo(
      () => marketAccountObjs.filter(m => m.market?.Type === MarketTypeEnum.Exchange && m.isConfigured),
      [marketAccountObjs]
    );

    // There are markets that could be considered both dealer and exchange, like ErisX
    // ErisX has market.Type = Exchange but also RFQ capabilities
    // When specifying ConnectionType == RFQ we want to include these markets as well
    const dealerMarketAccountObjs = marketAccountObjs.filter(
      m => m.isConfigured && (m.market?.Type === MarketTypeEnum.Dealer || connectionType === ConnectionType.RFQ)
    );

    const darkMarketAccountObjs = useMemo(
      () => marketAccountObjs.filter(m => m.market?.Type === MarketTypeEnum.Dark && m.isConfigured),
      [marketAccountObjs]
    );

    const exchangeMarketObjGroups = useMemo(
      () =>
        groupBy(exchangeMarketAccountObjs, m => m.market.DisplayName) as {
          [key: string]: MarketAccountObject[];
        },
      [exchangeMarketAccountObjs]
    );

    const dealerMarketObjGroups = useMemo(
      () =>
        groupBy(dealerMarketAccountObjs, m => m.market.DisplayName) as {
          [key: string]: MarketAccountObject[];
        },
      [dealerMarketAccountObjs]
    );

    const darkMarketObjGroups = useMemo(
      () =>
        groupBy(darkMarketAccountObjs, m => m.market.DisplayName) as {
          [key: string]: MarketAccountObject[];
        },
      [darkMarketAccountObjs]
    );

    const selectedExchangeMarkets = useMemo(
      () =>
        exchangeMarketAccountObjs
          .map(item => item.name)
          .filter(em => selectedMarketAccounts.includes(em) || selectedMarkets?.includes(em)),
      [exchangeMarketAccountObjs, selectedMarketAccounts, selectedMarkets]
    );

    const selectedDealerMarkets = useMemo(
      () =>
        dealerMarketAccountObjs
          .map(item => item.name)
          .filter(dm => selectedMarketAccounts.includes(dm) || selectedMarkets?.includes(dm)),
      [dealerMarketAccountObjs, selectedMarketAccounts, selectedMarkets]
    );

    const selectedDarkMarkets = useMemo(
      () =>
        darkMarketAccountObjs
          .map(item => item.name)
          .filter(dm => selectedMarketAccounts.includes(dm) || selectedMarkets?.includes(dm)),
      [darkMarketAccountObjs, selectedMarketAccounts, selectedMarkets]
    );

    const exchangeChecked = useMemo(
      () =>
        !isEmpty(exchangeMarketObjGroups) &&
        Object.keys(exchangeMarketObjGroups).reduce(
          (checked, marketName) => checked && exchangeMarketObjGroups[marketName].some(ma => ma.isSelected),
          true
        ),
      [exchangeMarketObjGroups]
    );

    const dealerChecked = useMemo(
      () =>
        !isEmpty(dealerMarketObjGroups) &&
        Object.keys(dealerMarketObjGroups).reduce(
          (checked, marketName) => checked && dealerMarketObjGroups[marketName].some(ma => ma.isSelected),
          true
        ),
      [dealerMarketObjGroups]
    );

    const darkChecked = useMemo(
      () =>
        !isEmpty(darkMarketObjGroups) &&
        Object.keys(darkMarketObjGroups).reduce(
          (checked, marketName) => checked && darkMarketObjGroups[marketName].some(ma => ma.isSelected),
          true
        ),
      [darkMarketObjGroups]
    );

    const exchangeIndeterminate = useMemo(
      () => selectedExchangeMarkets.length > 0 && !exchangeChecked,
      [selectedExchangeMarkets, exchangeChecked]
    );

    const dealerIndeterminate = useMemo(
      () => selectedDealerMarkets.length > 0 && !dealerChecked,
      [selectedDealerMarkets, dealerChecked]
    );

    const darkIndeterminate = useMemo(
      () => selectedDarkMarkets.length > 0 && !darkChecked,
      [selectedDarkMarkets, darkChecked]
    );

    const handleExchangeCheckbox = useCallback(
      (checked: boolean) => {
        if (exchangeIndeterminate || checked) {
          const { missingMarketAccounts, missingMarkets } = getMissingMarkets(exchangeMarketObjGroups);
          onChangeMarketAccounts([...selectedMarketAccounts, ...missingMarketAccounts]);
          onChangeMarkets && onChangeMarkets([...(selectedMarkets ?? []), ...missingMarkets]);
        } else {
          onChangeMarketAccounts(selectedMarketAccounts.filter(sm => !selectedExchangeMarkets.includes(sm)));
          onChangeMarkets && onChangeMarkets(selectedMarkets?.filter(sm => !selectedExchangeMarkets.includes(sm)));
        }
      },
      [
        exchangeMarketObjGroups,
        exchangeIndeterminate,
        onChangeMarketAccounts,
        onChangeMarkets,
        selectedExchangeMarkets,
        selectedMarketAccounts,
        selectedMarkets,
      ]
    );

    const handleDealerCheckbox = useCallback(
      (checked: boolean) => {
        if (dealerIndeterminate || checked) {
          const { missingMarketAccounts, missingMarkets } = getMissingMarkets(dealerMarketObjGroups);
          onChangeMarketAccounts([...selectedMarketAccounts, ...missingMarketAccounts]);
          onChangeMarkets && onChangeMarkets([...(selectedMarkets ?? []), ...missingMarkets]);
        } else {
          onChangeMarketAccounts(selectedMarketAccounts.filter(sm => !selectedDealerMarkets.includes(sm)));
          onChangeMarkets && onChangeMarkets(selectedMarkets?.filter(sm => !selectedDealerMarkets.includes(sm)));
        }
      },
      [
        dealerMarketObjGroups,
        dealerIndeterminate,
        onChangeMarketAccounts,
        onChangeMarkets,
        selectedDealerMarkets,
        selectedMarketAccounts,
        selectedMarkets,
      ]
    );

    const handleDarkCheckbox = useCallback(
      (checked: boolean) => {
        if (darkIndeterminate || checked) {
          const { missingMarketAccounts, missingMarkets } = getMissingMarkets(darkMarketObjGroups);
          onChangeMarketAccounts([...selectedMarketAccounts, ...missingMarketAccounts]);
          onChangeMarkets && onChangeMarkets([...(selectedMarkets ?? []), ...missingMarkets]);
        } else {
          onChangeMarketAccounts(selectedMarketAccounts.filter(sm => !selectedDarkMarkets.includes(sm)));
          onChangeMarkets && onChangeMarkets(selectedMarkets?.filter(sm => !selectedDarkMarkets.includes(sm)));
        }
      },
      [
        darkMarketObjGroups,
        darkIndeterminate,
        onChangeMarketAccounts,
        onChangeMarkets,
        selectedDarkMarkets,
        selectedMarketAccounts,
        selectedMarkets,
      ]
    );

    return (
      <WrappedComponent
        {...props}
        ref={inputRef}
        exchangeChecked={exchangeChecked}
        exchangeIndeterminate={exchangeIndeterminate}
        selectableExchangeMarketAccountObjs={exchangeMarketAccountObjs}
        handleExchangeCheckbox={handleExchangeCheckbox}
        selectedExchangeMarkets={selectedExchangeMarkets}
        exchangeMarketObjGroups={exchangeMarketObjGroups}
        dealerChecked={dealerChecked}
        dealerIndeterminate={dealerIndeterminate}
        selectableDealerMarketAccountObjs={dealerMarketAccountObjs}
        handleDealerCheckbox={handleDealerCheckbox}
        selectedDealerMarkets={selectedDealerMarkets}
        dealerMarketObjGroups={dealerMarketObjGroups}
        darkChecked={darkChecked}
        darkIndeterminate={darkIndeterminate}
        selectableDarkMarketAccountObjs={darkMarketAccountObjs}
        handleDarkCheckbox={handleDarkCheckbox}
        selectedDarkMarkets={selectedDarkMarkets}
        darkMarketObjGroups={darkMarketObjGroups}
        handleMarketAccountChange={handleMarketAccountChange}
        handleMarketChange={handleMarketChange}
      />
    );
  };
}
