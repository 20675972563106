import {
  ACTION,
  Flex,
  FormRowStatus,
  FormTable,
  NotificationVariants,
  SubAccountTypeEnum,
  TreeViewTooltip,
  UpdateActionEnum,
  createTreeFromRollupMemberships,
  useDynamicCallback,
  useFormTable,
  useGlobalToasts,
  type Column,
  type ISubaccount,
  type SubAccount,
  type UseFormTable,
} from '@talos/kyoko';
import { useRoleAuth } from 'hooks';
import { compact } from 'lodash';
import { useSubAccountRollupMemberships, useSubAccounts } from 'providers';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { RollupMembersDropdown } from '../RollupMembersDropdown';
import type { IEditSubAccountsDrawerTab } from '../types';

interface SubAccountRollupMembershipsTabProps {
  onAddRollupMembers: (SubAccounts: ISubaccount[]) => void;
  setRollupMembersToAdd: React.Dispatch<React.SetStateAction<ISubaccount[]>>;
  formTable: UseFormTable<ISubaccount>;
  rollupMembersToAdd: ISubaccount[];
  alreadySelected: string[];
  subAccountType: SubAccountTypeEnum;
}

export function SubAccountRollupMembershipsTab({
  onAddRollupMembers,
  setRollupMembersToAdd,
  formTable,
  rollupMembersToAdd,
  alreadySelected,
  subAccountType,
}: SubAccountRollupMembershipsTabProps) {
  return (
    <>
      <Flex flexDirection="column" gap="spacingMedium" w="100%" h="100%">
        <RollupMembersDropdown
          alreadySelected={alreadySelected}
          rollupMembersToAdd={rollupMembersToAdd}
          setRollupMembersToAdd={setRollupMembersToAdd}
          onAddRollupMembers={onAddRollupMembers}
          subAccountType={subAccountType}
        />
        <FormTable background="transparent" promptIfUnsavedChanges={false} {...formTable} />
      </Flex>
    </>
  );
}

export function useSubAccountRollupMembersTab({ subAccount }: { subAccount: SubAccount }): IEditSubAccountsDrawerTab {
  const { add: addToast } = useGlobalToasts();
  const {
    addRollupMemberships,
    deleteRollupMemberships,
    rollupMembershipsByParentChild,
    rollupMembershipsByChildParent,
  } = useSubAccountRollupMemberships();
  const { subAccountsByID } = useSubAccounts();
  const { isAuthorized } = useRoleAuth();
  const canEditRollups = isAuthorized(ACTION.EDIT_ROLLUPS);

  const rollupTree = useMemo(() => {
    const tree = createTreeFromRollupMemberships(
      rollupMembershipsByParentChild,
      rollupMembershipsByChildParent,
      subAccountsByID || new Map()
    );
    return tree;
  }, [subAccountsByID, rollupMembershipsByParentChild, rollupMembershipsByChildParent]);

  const rollupMemberColumns = useMemo(
    () =>
      compact([
        {
          suppressHeaderMenuButton: true,
          minWidth: 300,
          width: 300,
          title: 'Rollup / Sub Account Name',
          sort: '+',
          sortIndex: 0,
          field: 'DisplayName',
          type: 'custom',
          sortable: true,
          params: {
            cellRenderer: ({ data }: { data: ISubaccount }) => {
              return (
                <Flex gap="spacingDefault" alignItems="center">
                  {data.DisplayName}
                  {data.Type === SubAccountTypeEnum.Rollup ? (
                    <TreeViewTooltip
                      tree={rollupTree}
                      subAccount={data}
                      highlight={[subAccount.SubaccountID.toString(), data.SubaccountID.toString()]}
                    />
                  ) : null}
                </Flex>
              );
            },
          },
        },
        subAccount.Type === SubAccountTypeEnum.Rollup
          ? {
              id: 'type',
              type: 'text',
              title: 'Type',
              suppressHeaderMenuButton: true,
              minWidth: 390,
              width: 390,
              field: 'Type',
              sortable: true,
            }
          : null,
        canEditRollups
          ? {
              type: 'remove',
              id: 'remove',
              pinned: 'right',
              title: 'remove',
              suppressHeaderMenuButton: true,
            }
          : null,
      ] satisfies Array<Column | null>),
    [canEditRollups, rollupTree, subAccount]
  );

  const rollupMemberships: ISubaccount[] = useMemo(() => {
    if (!subAccountsByID) {
      return [];
    }
    const rollupMembershipBySubAccount =
      subAccount.Type === SubAccountTypeEnum.Rollup
        ? rollupMembershipsByParentChild.get(subAccount.SubaccountID)
        : rollupMembershipsByChildParent.get(subAccount.SubaccountID);
    if (rollupMembershipBySubAccount) {
      return compact(
        Array.from(rollupMembershipBySubAccount.values()).map(membership => {
          const _subAccount = subAccountsByID.get(
            subAccount?.Type === SubAccountTypeEnum.Rollup ? membership.ChildID : membership.ParentID
          );
          if (!_subAccount) {
            return undefined;
          }
          return {
            ..._subAccount,
          };
        })
      );
    }
    return [];
  }, [
    rollupMembershipsByParentChild,
    rollupMembershipsByChildParent,
    subAccountsByID,
    subAccount.Type,
    subAccount.SubaccountID,
  ]);

  const formTable = useFormTable<SubAccount>({
    rowID: 'SubaccountID' satisfies keyof SubAccount,
    data: rollupMemberships,
    columns: rollupMemberColumns,
  });

  const resetSubAccountsTable = useDynamicCallback(formTable.reset);
  useEffect(() => {
    if (!formTable.isDirty) {
      resetSubAccountsTable(rollupMemberships);
    }
  }, [resetSubAccountsTable, rollupMemberships, formTable.isDirty]);

  const [rollupMembersToAdd, setRollupMembersToAdd] = useState<SubAccount[]>([]);

  const onAddRollupMembers = useCallback(
    (SubAccounts: SubAccount[]) => {
      const rows = formTable.getRows();
      const accountInRowsMap = new Map(rows.map(row => [row.data.SubaccountID, row.data]));
      for (const account of SubAccounts) {
        const rowId = accountInRowsMap.get(account.SubaccountID)?.SubaccountID;

        if (rowId) {
          const row = (rowId && formTable.gridApi?.getRowNode(rowId.toString())) || undefined;
          // Instead of adding a duplicate, lets highlight the already existing entry and toast
          if (row) {
            formTable.gridApi?.flashCells({ rowNodes: [row] });
            addToast({
              text: `There is already an existing row for account: "${account.DisplayName}" in the table. Update the existing one or remove it.`,
              variant: NotificationVariants.Primary,
            });
          }
        } else {
          formTable.addRow(account);
        }
      }
      setRollupMembersToAdd([]);
    },
    [formTable, addToast]
  );

  const handleSaveChanges = useCallback(async () => {
    // subaccounts -> users
    if (formTable.isDirty) {
      const rows = formTable.getRows();

      const updates = compact(
        rows.map(row => {
          if (row.status === FormRowStatus.Added) {
            return { ...row.data, UpdateAction: UpdateActionEnum.Initial };
          }
          if (row.status === FormRowStatus.Removed) {
            return { ...row.data, UpdateAction: UpdateActionEnum.Remove };
          }
          if (row.status === FormRowStatus.Updated) {
            return { ...row.data, UpdateAction: UpdateActionEnum.Update };
          }

          return null;
        })
      );
      const newMemberships = updates
        .filter(update => update.UpdateAction === UpdateActionEnum.Initial)
        .map(update => update.SubaccountID);
      const removeMemberships = updates
        .filter(update => update.UpdateAction === UpdateActionEnum.Remove)
        .map(update => update.SubaccountID);
      if (subAccount.Type === SubAccountTypeEnum.Rollup) {
        if (newMemberships.length > 0) {
          addRollupMemberships(subAccount.SubaccountID, newMemberships);
        }
        if (removeMemberships.length > 0) {
          deleteRollupMemberships(subAccount.SubaccountID, removeMemberships);
        }
      } else {
        newMemberships.map(newMembership => addRollupMemberships(newMembership, [subAccount.SubaccountID]));
        removeMemberships.map(removeMembership => deleteRollupMemberships(removeMembership, [subAccount.SubaccountID]));
      }
      resetSubAccountsTable();
    }
  }, [formTable, resetSubAccountsTable, subAccount, addRollupMemberships, deleteRollupMemberships]);

  return {
    name: subAccount.Type === SubAccountTypeEnum.Rollup ? 'Rollup Members' : 'Rollup Memberships',
    save: handleSaveChanges,
    viewable: canEditRollups,
    isDirty: formTable.isDirty,
    badgeContent: rollupMemberships.length.toString(),
    component: (
      <SubAccountRollupMembershipsTab
        onAddRollupMembers={onAddRollupMembers}
        setRollupMembersToAdd={setRollupMembersToAdd}
        formTable={formTable}
        rollupMembersToAdd={rollupMembersToAdd}
        alreadySelected={[
          ...rollupMemberships,
          {
            ...subAccount,
          },
        ].map(account => account.SubaccountID.toString())}
        subAccountType={subAccount.Type}
      />
    ),
  };
}
