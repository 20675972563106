import { type ColumnDef, getAllParentsOfNodeInclusive, toBigWithDefault } from '@talos/kyoko';
import type { ColDef, IAggFuncParams, IRowNode, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';
import Big from 'big.js';
import { MarketAccountRow, OpsPositionRow } from './rows';
import type { OpsOverviewBlotterRow } from './useOpsOverviewRows';

export const MARKET_ACCOUNT_COLID = 'MarketAccount';

export type WeightValue =
  | {
      valid: true;
      total: Big;
      value: Big;
      share: Big | undefined;
    }
  | {
      valid: false;
    };

// this column is completely coupled to the OpsPositionBlotterRow entity
export const weightColumn = {
  id: 'weight',
  type: 'custom',
  title: 'Weight (%)',
  description: '% of gross position value in home currency.',
  params: {
    valueGetter: (params: ValueGetterParams<OpsOverviewBlotterRow>): WeightValue => {
      const position = params.data;
      if (!position || !(position instanceof OpsPositionRow)) {
        return { valid: false };
      }

      return {
        valid: true,
        total: position.marketAccountTotal,
        value: toBigWithDefault(position.Equivalent?.Amount, 0),
        share: position.shareOfMarketAccountTotal,
      };
    },
    aggFunc: weightColumnAggFunc,
    comparator: (a: WeightValue, b: WeightValue) => {
      if (!a.valid && !b.valid) {
        return 0;
      }

      if (!a.valid) {
        return -1;
      }

      if (!b.valid) {
        return 1;
      }

      return a.value.minus(b.value).toNumber();
    },
    valueFormatter: ({ value }: ValueFormatterParams<OpsOverviewBlotterRow, WeightValue>) => {
      if (!value || !value.valid || !value.share) {
        return '';
      }

      // .round(2) and then .toFixed() without any dp provided will have 1.4545 -> 1.45% but 1 -> 1%
      return `${value.share.times(100).round(2).toFixed()} %`;
    },
  } satisfies ColDef<OpsOverviewBlotterRow>,
} as const satisfies ColumnDef<OpsOverviewBlotterRow>;

// Pulling out into its own function so it can be tested on its own
export function weightColumnAggFunc({
  values: unsanitizedValues,
  rowNode,
}: IAggFuncParams<OpsOverviewBlotterRow, WeightValue>): WeightValue {
  // this filtering is for typing
  const values = unsanitizedValues.filter((v): v is WeightValue => v != null);

  if (values.length === 0) {
    return { valid: false };
  }

  // We only aggregate _at all_ if there is some MarketAccount grouping node above us
  const hasMarketAccountNodeAbove = getAllParentsOfNodeInclusive(rowNode).some(
    (node: IRowNode<OpsOverviewBlotterRow>) => node.data instanceof MarketAccountRow
  );
  if (!hasMarketAccountNodeAbove) {
    return { valid: false };
  }

  // we only aggregate up to the market account row node
  const stopAggregating = rowNode.data instanceof MarketAccountRow;
  if (stopAggregating) {
    return { valid: false };
  }

  let aggValue = Big(0);
  let total = Big(0);
  for (const value of values) {
    if (!value.valid) {
      return { valid: false }; // early return from the entire aggFunc
    }

    aggValue = aggValue.plus(value.value.abs());
    total = value.total; // this total is just being carried over, the totals of all values should be the same
  }

  if (total.eq(0)) {
    return { valid: false };
  }

  return { valid: true, value: aggValue, total, share: aggValue.eq(0) ? Big(0) : aggValue.div(total) };
}
