import { get } from 'lodash';
import { getInitPortfolioViewState } from '../../../containers/Portfolio/PortfolioManagement/stateManagement/portfolioViewLayoutSlice';
import type { AppConfigState } from '../AppConfigProvider.types';

export function opsOverviewStateMigration(config: any) {
  const portfolioViewState: AppConfigState['portfolioViewState'] | undefined = get(config, 'portfolioViewState');

  /** In 2.50 we removed the By Asset grouping. If it is Asset, reset the user to the default opsOverviewShowBy state */
  // @ts-expect-error Asset isnt acceptable anymore (2.50)
  if (portfolioViewState?.opsOverviewShowBy === 'Asset') {
    portfolioViewState.opsOverviewShowBy = getInitPortfolioViewState({}).opsOverviewShowBy;
  }
}
