import { Tab, TabAppearance, TabList, Tabs, TabSize } from '@talos/kyoko';
import type { BalancesBlotterShowBy } from '../../../Blotters/BalancesV2/types';

// This component can be used in several places so put it in the shared folder

interface ShowByButtonsProps {
  showBy: BalancesBlotterShowBy;
  onClick: (newShowBy: BalancesBlotterShowBy) => void;
  size?: TabSize;
}

export const ShowByButtons = ({ showBy, onClick, size = TabSize.Default }: ShowByButtonsProps) => {
  return (
    <Tabs
      selectedIndex={showBy === 'counterparty' ? 0 : 1}
      appearance={TabAppearance.Pill}
      size={size}
      flex="0 0 auto"
      w="auto"
      data-testid="show-by-buttons"
    >
      <TabList suppressOverflowList={true}>
        <Tab
          isSelected={showBy === 'counterparty'}
          onClick={() => onClick('counterparty')}
          data-testid="by-counterparty-button"
          label="By Counterparty"
        />
        <Tab
          isSelected={showBy === 'asset'}
          onClick={() => onClick('asset')}
          data-testid="by-asset-button"
          label="By Instrument"
        />
      </TabList>
    </Tabs>
  );
};
