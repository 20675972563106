import {
  ACTION,
  BlotterDensity,
  Button,
  ButtonVariants,
  DEFAULT_MAX_ROWS,
  DEFAULT_PAGINATION_SIZE,
  EntityAdminPageREST,
  FormControlSizes,
  getTierDrawerOptions,
  IconName,
  useDisclosure,
  useDynamicCallback,
  type AllInTier,
  type ColumnDef,
  type CustomerConfiguration,
  type FeeTier,
  type FilterableProperty,
  type InputsAndDropdownsDrawerOption,
  type SymbolGroup,
} from '@talos/kyoko';
import { useCounterpartyFilter, useModeFilter, usePricingTierFilters, useRoleAuth } from 'hooks';
import { useCustomersByName } from 'hooks/useCustomer';
import { compact } from 'lodash';
import { OrgConfigurationKey, useCustomerPricingTierContext, useOrgConfiguration } from 'providers';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { NewCustomerDialog } from '../Dialogs/NewCustomerDialog';

const CUSTOMER_CONFIGURATIONS_COLUMNS: ColumnDef<CustomerConfiguration>[] = [
  { field: 'Counterparty', type: 'counterparty', sortable: true, sort: '+' },
  { field: 'AllInTier', type: 'text', title: 'Base Tier' },
  { field: 'FeeTier', type: 'text', title: 'Fee Tier' },
  { field: 'SymbolGroup', type: 'text', title: 'Symbol Group' },
  { field: 'UpdateUser', type: 'text', hide: true },
  { field: 'Revision', type: 'text', hide: true },
];

const GET_DRAWER_OPTIONS = (
  allInTierOptions: AllInTier[],
  feeTierOptions: FeeTier[],
  symbolGroupOptions: SymbolGroup[]
): InputsAndDropdownsDrawerOption<CustomerConfiguration>[] => {
  return [
    { field: 'AllInTier', type: 'dropdown', title: 'Base Tier', options: getTierDrawerOptions(allInTierOptions) },
    { field: 'FeeTier', type: 'dropdown', title: 'Fee Tier', options: getTierDrawerOptions(feeTierOptions) },
    {
      field: 'SymbolGroup',
      type: 'dropdown',
      title: 'Symbol Group',
      options: getTierDrawerOptions(symbolGroupOptions),
    },
  ];
};

const GET_PATCH_DELETE_PATH = (entity: CustomerConfiguration) => `/customers/${entity.Counterparty}/configuration`;

export const CustomerConfigurations = () => {
  const { isAuthorized } = useRoleAuth();
  const customersByName = useCustomersByName();
  const [refreshKey, setRefreshKey] = useState(0);
  const { getConfig } = useOrgConfiguration();

  const modeFilter = useModeFilter();
  const counterpartyFilter = useCounterpartyFilter();
  const { allInTierFilter, feeTierFilter, symbolGroupFilter } = usePricingTierFilters();

  const [allInTierOptions, setAllInTierOptions] = useState<AllInTier[]>();
  const [feeTierOptions, setFeeTierOptions] = useState<FeeTier[]>();
  const [symbolGroupOptions, setSymbolGroupOptions] = useState<SymbolGroup[]>();

  const { getAllInTiers, getFeeTiers, getSymbolGroups } = useCustomerPricingTierContext();
  useEffect(() => {
    getAllInTiers().then(({ data }) => setAllInTierOptions(data));
    getFeeTiers().then(({ data }) => setFeeTierOptions(data));
    getSymbolGroups().then(({ data }) => setSymbolGroupOptions(data));
  }, [getAllInTiers, getFeeTiers, getSymbolGroups]);

  const drawerOptions = useMemo(() => {
    if (allInTierOptions != null && feeTierOptions != null && symbolGroupOptions != null) {
      return GET_DRAWER_OPTIONS(allInTierOptions, feeTierOptions, symbolGroupOptions);
    }
  }, [allInTierOptions, feeTierOptions, symbolGroupOptions]);

  const resolveCounterpartyDisplayName = useCallback(
    (entity: CustomerConfiguration) => customersByName?.get(entity.Counterparty)?.DisplayName ?? entity.Counterparty,
    [customersByName]
  );

  const filterableProperties: FilterableProperty[] = useMemo(
    () =>
      compact([
        modeFilter,
        { ...counterpartyFilter, label: 'Customer' },
        allInTierFilter,
        feeTierFilter,
        symbolGroupFilter,
      ]),
    [modeFilter, counterpartyFilter, allInTierFilter, feeTierFilter, symbolGroupFilter]
  );

  const newCustomerDialog = useDisclosure();
  const handleAddCustomerButton = useDynamicCallback(() => {
    newCustomerDialog.open();
  });

  const handleOnNewCustomerSuccess = useDynamicCallback(() => {
    // Refresh the rest blotter to show the new customer
    setRefreshKey(prevKey => prevKey + 1);
  });

  const panelActions = useMemo(
    () => [
      <>
        {isAuthorized(ACTION.EDIT_CUSTOMER_CONFIGURATIONS) && (
          <Button
            startIcon={IconName.Plus}
            onClick={handleAddCustomerButton}
            variant={ButtonVariants.Positive}
            disabled={!isAuthorized(ACTION.DEALER_TRADING)}
            size={FormControlSizes.Small}
          >
            Add Customer
          </Button>
        )}
        <NewCustomerDialog dialog={newCustomerDialog} onSuccess={handleOnNewCustomerSuccess} />
      </>,
    ],
    [handleAddCustomerButton, handleOnNewCustomerSuccess, isAuthorized, newCustomerDialog]
  );

  return (
    <EntityAdminPageREST<CustomerConfiguration>
      key={refreshKey}
      title="Customer Configurations"
      subtitle="View and manage customer configurations."
      entityName="Customer Configuration"
      path="/customer-configurations"
      getPatchDeletePath={GET_PATCH_DELETE_PATH}
      entityIDField="Counterparty"
      density={BlotterDensity.Comfortable}
      columns={CUSTOMER_CONFIGURATIONS_COLUMNS}
      drawerOptions={drawerOptions}
      filterableProperties={filterableProperties}
      allowModeSwitch={isAuthorized(ACTION.EDIT_CUSTOMER_CONFIGURATIONS)}
      allowAddEntity={false} // adding entity is handled by the NewCustomerDialog
      allowEditEntity={isAuthorized(ACTION.EDIT_CUSTOMER_CONFIGURATIONS)}
      allowDeleteEntity={false}
      getEditEntityName={resolveCounterpartyDisplayName}
      panelActions={panelActions}
      persistKey="customer-configurations"
      blotterRowCount={getConfig(OrgConfigurationKey.CustomerConfigurationRowsMax, DEFAULT_MAX_ROWS)}
      paginationSize={getConfig(OrgConfigurationKey.CustomerConfigurationsPageSize, DEFAULT_PAGINATION_SIZE)}
    />
  );
};
