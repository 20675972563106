import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import type { Organization } from '@talos/kyoko';
import {
  Delete,
  FetchError,
  Get,
  LoaderTalos,
  Patch,
  Post,
  Put,
  UserContext,
  logger,
  useAuthContext,
  type CredentialsFormParams,
  type ICounterparty,
  type MarketConfig,
  type User,
} from '@talos/kyoko';
import { isEmpty, isNil, sortBy } from 'lodash';
import type React from 'react';
import { memo, useCallback, useEffect, useState } from 'react';

const fetchUser = () => Get<User>(import.meta.env.VITE_AVA_API_ENDPOINT, `/user`);

export const UserContextProvider = memo(function UserProvider(props: React.PropsWithChildren<unknown>) {
  const { isAuthenticated } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [user, setUser] = useState<User>();
  const [orgApiEndpoint, setOrgApiEndpoint] = useState<string>();
  const [orgWsEndpoint, setOrgWsEndpoint] = useState<string>();
  const [organization, setOrganization] = useState<Organization>();
  const [counterparties, setCounterparties] = useState<ICounterparty[]>();

  useEffect(() => {
    if (user != null) {
      datadogRum.setUser({
        id: user.ID as string,
        name: user.Name,
        organization: user.Organization,
        orgApiEndpoint: user.OrgApiEndpoint,
        isUserMorphed: user.IsMorphed,
        isOrgMorphed: user.UsingOrgID != null,
        usingOrgID: user.UsingOrgID,
      });
      datadogLogs.setUser({
        id: user.ID as string,
        name: user.Name,
        organization: user.Organization,
        orgApiEndpoint: user.OrgApiEndpoint,
        isUserMorphed: user.IsMorphed,
        isOrgMorphed: user.UsingOrgID,
        usingOrgID: user.UsingOrgID,
      });
    }
  }, [user]);

  useEffect(() => {
    if (isAuthenticated && user == null) {
      setIsLoading(true);
      // [UI-5739] TODO: Refactor this component to have this call to be outside the UserContextProvider with a spinner, then the rest of this
      // behavior can be safely based on the user being present.
      fetchUser()
        .then(user => {
          if (import.meta.env.VITE_AVA_ENV === 'local') {
            // In local environments only, the proxy code replaces the email address domain to match
            // the domain we are serving the app from.
            // Revert that here so that everywhere else in the app, the user's email address is correct.
            user.Email = user.Email.replace('.localhost:3000', '.com');
          }
          setUser(user);
        })
        .catch(e => {
          if (e instanceof FetchError && e?.response?.status === 401) {
            // If we get a 401, the user is no longer authenticated.
            // The backend would have deleted the cookie, so we need to reload the page to force the user to log in again.
            window.location.reload();
          }
          logger.error(e as Error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [isAuthenticated, user]);

  const refetchCounterparties = useCallback(
    () =>
      orgApiEndpoint ? Get(orgApiEndpoint, `/organization/counterparties`) : Promise.reject('Missing orgApiEndpoint'),
    [orgApiEndpoint]
  );

  useEffect(() => {
    let isMounted = true;
    if (orgApiEndpoint && counterparties == null) {
      refetchCounterparties()
        .then(list => {
          if (isMounted) {
            setCounterparties(list);
          }
        })
        .catch(e => {
          logger.error(e as Error);
        });
    }
    return () => {
      isMounted = false;
    };
  }, [orgApiEndpoint, counterparties, refetchCounterparties]);

  const refetchUser = () =>
    fetchUser().then(user => {
      setUser(user);
      return user;
    });

  const getOrganization = useCallback(() => {
    if (organization != null) {
      return Promise.resolve(organization);
    }
    return Get(import.meta.env.VITE_AVA_API_ENDPOINT, `/organization`).then((org: Organization) => {
      const sortedUsers = sortBy(org.Users ?? [], item => item.Name) ?? [];
      const newOrganization = { ...org, Users: sortedUsers };
      setOrganization(newOrganization);
      return newOrganization;
    });
  }, [organization]);

  const createApiKey = useCallback((whitelistedIPs: string[] | undefined) => {
    const args: {
      whitelistedIPs?: string[];
    } = {};
    if (whitelistedIPs != null) {
      args.whitelistedIPs = whitelistedIPs;
    }
    return Post(import.meta.env.VITE_AVA_API_ENDPOINT, `/user/api_key`, args).then(apiKey => {
      refetchUser();
      return apiKey;
    });
  }, []);

  const deleteApiKey = useCallback(() => {
    return Delete(import.meta.env.VITE_AVA_API_ENDPOINT, `/user/api_key`).then(() => refetchUser());
  }, []);

  const updateApiKeyWhitelistedIPs = useCallback((whitelistedIPs: string[]) => {
    return Put(import.meta.env.VITE_AVA_API_ENDPOINT, `/user/api_key/whitelist`, { whitelistedIPs }).then(() =>
      refetchUser()
    );
  }, []);

  const updateApiKeyRoles = useCallback((roles: string[]) => {
    return Put(import.meta.env.VITE_AVA_API_ENDPOINT, `/user/api_key/roles`, {
      roles: roles,
    }).then(() => refetchUser());
  }, []);

  const listMarketConfigs = useCallback(() => {
    return Get(import.meta.env.VITE_AVA_API_ENDPOINT, `/markets`).then((markets: MarketConfig[]) =>
      markets?.map(market => {
        market.guide || (market.guide = []);
        return market;
      })
    );
  }, []);

  useEffect(() => {
    setOrgApiEndpoint(user?.OrgApiEndpoint && `${window.location.protocol}//${user.OrgApiEndpoint}/v1`);
  }, [user?.OrgApiEndpoint]);

  useEffect(() => {
    setOrgWsEndpoint(
      user?.OrgWsEndpoint && `${window.location.protocol === 'https:' ? 'wss:' : 'ws:'}//${user.OrgWsEndpoint}/ws/v1`
    );
  }, [user?.OrgWsEndpoint]);

  const listMarketCredentials = useCallback(() => {
    return orgApiEndpoint ? Get(orgApiEndpoint, `/organization/markets`) : Promise.reject('Missing orgApiEndpoint');
  }, [orgApiEndpoint]);

  // FYI this sends the request as form data as some of these flows require us to send a file
  const createMarketCredential = useCallback(
    (marketName: string, values: { [key: string]: string | Blob }) => {
      const formData = new FormData();
      for (const key in values) {
        values[key] != null && formData.append(key, values[key]);
      }
      return orgApiEndpoint
        ? Post(
            orgApiEndpoint,
            `/organization/markets/${encodeURIComponent(marketName)}/credentials`,
            {},
            {
              headers: {},
              body: formData,
            }
          )
        : Promise.reject('Missing orgApiEndpoint');
    },
    [orgApiEndpoint]
  );

  const updateMarketCredential = useCallback(
    (marketName: string, credentialName: string, values: CredentialsFormParams) => {
      const formData = new FormData();
      for (const key in values) {
        values[key] != null && formData.append(key, values[key]);
      }
      return orgApiEndpoint
        ? Put(
            orgApiEndpoint,
            `/organization/markets/${encodeURIComponent(marketName)}/credentials/${encodeURIComponent(credentialName)}`,
            {},
            {
              headers: {},
              body: formData,
            }
          )
        : Promise.reject('Missing orgApiEndpoint');
    },
    [orgApiEndpoint]
  );

  const patchMarketCredential = useCallback(
    (marketName: string, credentialName: string, values: CredentialsFormParams) => {
      const formData = new FormData();
      for (const key in values) {
        values[key] != null && formData.append(key, values[key]);
      }
      return orgApiEndpoint
        ? Patch(
            orgApiEndpoint,
            `/organization/markets/${encodeURIComponent(marketName)}/credentials/${encodeURIComponent(credentialName)}`,
            {},
            {
              headers: {},
              body: formData,
            }
          )
        : Promise.reject('Missing orgApiEndpoint');
    },
    [orgApiEndpoint]
  );

  const deleteMarketCredential = useCallback(
    (marketName: string, credentialName: string) => {
      return orgApiEndpoint
        ? Delete(
            orgApiEndpoint,
            `/organization/markets/${encodeURIComponent(marketName)}/credentials/${encodeURIComponent(credentialName)}`
          )
        : Promise.reject('Missing orgApiEndpoint');
    },
    [orgApiEndpoint]
  );

  const orgMetadata = useCallback(() => {
    return orgApiEndpoint ? Get(orgApiEndpoint, `/organization`) : undefined;
  }, [orgApiEndpoint]);

  useEffect(() => {
    if (!isNil(user) && !isLoading && !isEmpty(orgApiEndpoint) && !isEmpty(orgWsEndpoint)) {
      setIsLoaded(true);
    }
  }, [user, isLoading, orgApiEndpoint, orgWsEndpoint]);

  if (isAuthenticated && (isLoading || !isLoaded)) {
    return <LoaderTalos />;
  }

  return (
    <UserContext.Provider
      value={{
        isLoaded,
        user: user as User,
        getOrganization,
        createApiKey,
        deleteApiKey,
        updateApiKeyWhitelistedIPs,
        updateApiKeyRoles,
        listMarketConfigs,
        orgWsEndpoint: orgWsEndpoint!,
        orgApiEndpoint: orgApiEndpoint!,
        listMarketCredentials,
        updateMarketCredential,
        patchMarketCredential,
        createMarketCredential,
        deleteMarketCredential,
        orgMetadata,
        counterparties,
        refetchCounterparties,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
});
