import { type HedgeControlStatusEnum, HEDGER_STATUS, useObservableValue, useStaticSubscription } from '@talos/kyoko';
import { map } from 'rxjs/operators';

export interface HedgerStatus {
  OrderHedgingStatus: HedgeControlStatusEnum;
  PositionHedgingStatus?: string;
  SessionID?: string;
  Timestamp?: string;
}

export function useHedgerStatus(): HedgerStatus | undefined {
  const { data: subscription } = useStaticSubscription<HedgerStatus>({
    name: HEDGER_STATUS,
    tag: 'useHedgerStatus',
  });

  return useObservableValue(() => subscription.pipe(map(json => json.data.at(0))), [subscription]);
}
