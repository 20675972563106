import { forwardRef, useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { useTheme } from 'styled-components';

import {
  ACTION,
  Alert,
  AlertVariants,
  ConnectionCapabilitiesEnum,
  ConnectionModeEnum,
  Dialog,
  Flex,
  FormGroup,
  getTypedValues,
  Input,
  toBigWithDefault,
  Toggle,
  useConstant,
  useGlobalToasts,
  useUserContext,
  type DialogProps,
  type SizeBucket,
} from '@talos/kyoko';
import { useRoleAuth } from 'hooks';
import { updateSecurityStatus } from './helpers';
import type { MarketSecurityMode, MarketSecurityStatusLocal } from './types';

enum Action {
  Initialize = 'Init',
  Mode = 'Mode',
  SizeBuckets = 'SizeBuckets',
  MinPriceIncrement = 'MinPriceIncrement',
  MinSizeIncrement = 'MinSizeIncrement',
  MinimumSize = 'MinimumSize',
  MaximumSize = 'MaximumSize',
  MinimumAmount = 'MinimumAmount',
  MarketDataMode = 'MarketDataMode',
}

interface EditMarketSecModeDialogProps extends DialogProps {
  mktSecStatus: MarketSecurityStatusLocal;
}

export const SYMBOL_VALUES_OUT_OF_RANGE_WARNING =
  'Changing these values to be smaller (or larger) than the default setup could result in rejection from the underlying market, please ensure you have discuss this change with Talos or the underlying LP.';

export const EditMarketSecModeDialog = forwardRef<HTMLDivElement | null, EditMarketSecModeDialogProps>(
  function EditMarketSecModeDialog(props: EditMarketSecModeDialogProps, ref) {
    const theme = useTheme();
    const { mktSecStatus } = props;

    const [changedValue, setChangedValue] = useState(false);
    const [state, dispatch] = useReducer(editMarketSecModeReducer, {
      status: mktSecStatus,
      Mode: ConnectionModeEnum.Down,
      SizeBuckets: '',
      MinPriceIncrement: '',
      MinSizeIncrement: '',
      MinimumSize: '',
      MaximumSize: '',
      MinimumAmount: '',
      MarketDataMode: false,
    });
    const { orgApiEndpoint } = useUserContext();
    const { add: addToast } = useGlobalToasts();
    const { isAuthorized } = useRoleAuth();
    const styleWarning = useConstant({ border: `1px solid ${theme.colors.yellow.default}` });

    const handleConfirm = useCallback(() => {
      if (mktSecStatus === undefined) {
        return;
      }
      const diff = makeDiff(state, mktSecStatus);
      return updateSecurityStatus({
        orgApiEndpoint,
        mktSecStatus,
        data: diff,
        addToast,
      });
    }, [addToast, mktSecStatus, orgApiEndpoint, state]);

    const handleChange = useCallback(
      (action: Action, value: any) => {
        switch (action) {
          case Action.Initialize:
            break;
          default:
            if (!changedValue) {
              setChangedValue(true);
            }
            dispatch({
              type: action,
              value,
            });
            break;
        }
      },
      [dispatch, changedValue]
    );

    const warnings = useMemo<Array<keyof EditMarketSecFormKeys>>(() => {
      const isLessThan = (key: keyof EditMarketSecFormKeys): boolean => {
        const value = toBigWithDefault(state[key] as string, 0);
        return state[key] !== '' && state[key] !== mktSecStatus[key] && value.lt(mktSecStatus[key]);
      };
      const isGreaterThan = (key: keyof EditMarketSecFormKeys): boolean => {
        const value = toBigWithDefault(state[key] as string, 0);
        return state[key] !== '' && state[key] !== mktSecStatus[key] && value.gt(mktSecStatus[key]);
      };

      const warnings: Array<keyof EditMarketSecFormKeys> = [];
      if (isLessThan('MinPriceIncrement')) {
        warnings.push('MinPriceIncrement');
      }
      if (isLessThan('MinSizeIncrement')) {
        warnings.push('MinSizeIncrement');
      }
      if (isGreaterThan('MaximumSize')) {
        warnings.push('MaximumSize');
      }
      if (isLessThan('MinimumSize')) {
        warnings.push('MinimumSize');
      }
      if (isLessThan('MinimumAmount')) {
        warnings.push('MinimumAmount');
      }

      return warnings;
    }, [state, mktSecStatus]);

    const errors = useMemo(() => {
      const errors: Partial<Record<keyof EditMarketSecModeState, string>> = {
        MinPriceIncrement:
          state.MinPriceIncrement !== '' && toBigWithDefault(state.MinPriceIncrement, 0).lte(0)
            ? 'Value must be positive'
            : '',
        MinSizeIncrement:
          state.MinSizeIncrement !== '' && toBigWithDefault(state.MinSizeIncrement, 0).lte(0)
            ? 'Value must be positive'
            : '',
        MinimumSize:
          state.MinimumSize !== '' && toBigWithDefault(state.MinimumSize, 0).lte(0) ? 'Value must be positive' : '',
        MaximumSize:
          state.MaximumSize !== '' && toBigWithDefault(state.MaximumSize, 0).lte(0) ? 'Value must be positive' : '',
        MinimumAmount:
          state.MinimumAmount !== '' && toBigWithDefault(state.MinimumAmount, 0).lte(0) ? 'Value must be positive' : '',
      };
      return errors;
    }, [state]);
    const hasError = Object.values(errors).some(err => err);

    // When binding to a new mktSecStatus (and on the first render), initialize the state of the reducer
    useEffect(() => {
      if (mktSecStatus == null) {
        return;
      }
      dispatch({
        type: Action.Initialize,
        state: {
          status: mktSecStatus,
          Mode: mktSecStatus.RequestedEnabled !== undefined ? mktSecStatus.RequestedEnabled : mktSecStatus.Enabled,
          SizeBuckets:
            mktSecStatus.RequestedSizeBuckets !== undefined
              ? sizeBucketsToString(mktSecStatus.RequestedSizeBuckets)
              : '',
          MinPriceIncrement:
            mktSecStatus.RequestedMinPriceIncrement !== undefined ? mktSecStatus.RequestedMinPriceIncrement : '',
          MinSizeIncrement:
            mktSecStatus.RequestedMinSizeIncrement !== undefined ? mktSecStatus.RequestedMinSizeIncrement : '',
          MinimumSize: mktSecStatus.RequestedMinimumSize !== undefined ? mktSecStatus.RequestedMinimumSize : '',
          MaximumSize: mktSecStatus.RequestedMaximumSize !== undefined ? mktSecStatus.RequestedMaximumSize : '',
          MinimumAmount: mktSecStatus.RequestedMinimumAmount !== undefined ? mktSecStatus.RequestedMinimumAmount : '',
          MarketDataMode:
            mktSecStatus.RequestedCapabilities?.[ConnectionCapabilitiesEnum.MarketData] !== undefined
              ? mktSecStatus.RequestedCapabilities?.[ConnectionCapabilitiesEnum.MarketData] === true
              : mktSecStatus.Capabilities?.[ConnectionCapabilitiesEnum.MarketData] === true,
        },
      });
    }, [mktSecStatus]);

    return (
      <Dialog
        {...props}
        ref={ref}
        onConfirm={handleConfirm}
        title={`${mktSecStatus.Symbol} @ ${mktSecStatus.MarketAccount}`}
        width={360}
        confirmDisabled={!changedValue || hasError}
      >
        <Flex justifyContent="space-between" mr="spacingMedium">
          <p>Enable</p>
          <Toggle
            checked={state.Mode === ConnectionModeEnum.Up}
            onChange={(val: boolean) =>
              handleChange(Action.Mode, val ? ConnectionModeEnum.Up : ConnectionModeEnum.Down)
            }
          />
        </Flex>
        <Flex justifyContent="space-between" mr="spacingMedium">
          <p>Enable Market Data</p>
          <Toggle
            checked={state.MarketDataMode}
            onChange={(val: boolean) => handleChange(Action.MarketDataMode, val)}
          />
        </Flex>
        <FormGroup label="Size Buckets">
          <Input
            type="text"
            placeholder={sizeBucketsToString(state.status?.MarketSizeBuckets || state.status?.SizeBuckets || [])}
            onChange={evt => handleChange(Action.SizeBuckets, evt.target.value)}
            value={state.SizeBuckets}
            clearable
          />
        </FormGroup>
        {isAuthorized(ACTION.EDIT_SECMASTER_ADVANCED) && (
          <FormGroup label="Min Price Increment" error={errors.MinPriceIncrement}>
            <Input
              invalid={errors.MinPriceIncrement !== ''}
              data-testid="min-price-increment"
              style={warnings.includes('MinPriceIncrement') ? styleWarning : undefined}
              type="text"
              placeholder={state.status?.MarketMinPriceIncrement || state.status?.MinPriceIncrement}
              onChange={evt => handleChange(Action.MinPriceIncrement, evt.target.value)}
              value={state.MinPriceIncrement}
              clearable
            />
          </FormGroup>
        )}
        {isAuthorized(ACTION.EDIT_SECMASTER_ADVANCED) && (
          <FormGroup label="Min Size Increment" error={errors.MinSizeIncrement}>
            <Input
              invalid={errors.MinSizeIncrement !== ''}
              data-testid="min-size-increment"
              style={warnings.includes('MinSizeIncrement') ? styleWarning : undefined}
              type="text"
              placeholder={state.status?.MarketMinPriceIncrement || state.status?.MinSizeIncrement}
              onChange={evt => handleChange(Action.MinSizeIncrement, evt.target.value)}
              value={state.MinSizeIncrement}
              clearable
            />
          </FormGroup>
        )}
        {isAuthorized(ACTION.EDIT_SECMASTER_ADVANCED) && (
          <FormGroup label="Max Size" error={errors.MaximumSize}>
            <Input
              invalid={errors.MaximumSize !== ''}
              data-testid="maximum-size"
              style={warnings.includes('MaximumSize') ? styleWarning : undefined}
              type="text"
              placeholder={state.status?.MarketMaximumSize || state.status?.MaximumSize}
              onChange={evt => handleChange(Action.MaximumSize, evt.target.value)}
              value={state.MaximumSize}
              clearable
            />
          </FormGroup>
        )}
        {isAuthorized(ACTION.EDIT_SECMASTER_ADVANCED) && (
          <FormGroup label="Min Size" error={errors.MinimumSize}>
            <Input
              invalid={errors.MinimumSize !== ''}
              data-testid="minimum-size"
              style={warnings.includes('MinimumSize') ? styleWarning : undefined}
              type="text"
              placeholder={state.status?.MarketMinimumSize || state.status?.MinimumSize}
              onChange={evt => handleChange(Action.MinimumSize, evt.target.value)}
              value={state.MinimumSize}
              clearable
            />
          </FormGroup>
        )}
        {isAuthorized(ACTION.EDIT_SECMASTER_ADVANCED) && (
          <FormGroup label="Min Amount" error={errors.MinimumAmount}>
            <Input
              invalid={errors.MinimumAmount !== ''}
              data-testid="minimum-amount"
              style={warnings.includes('MinimumAmount') ? styleWarning : undefined}
              type="text"
              placeholder={state.status?.MarketMinimumAmount || state.status?.MinimumAmount}
              onChange={evt => handleChange(Action.MinimumAmount, evt.target.value)}
              value={state.MinimumAmount}
              clearable
            />
          </FormGroup>
        )}
        {!hasError && warnings.length > 0 && (
          <Alert variant={AlertVariants.Warning}>{SYMBOL_VALUES_OUT_OF_RANGE_WARNING}</Alert>
        )}
      </Dialog>
    );
  }
);

function stringToBuckets(str: string): string[] {
  return str.split(',').map(s => {
    return s.trim();
  });
}

function sizeBucketsToString(buckets: SizeBucket[] | undefined): string {
  return buckets?.map(bucket => bucket.Size).join(', ') || '';
}

const writeableProps = getTypedValues(Action).filter(a => a !== 'Init');

// Based on the state of the reducer, generate a diff that will be sent to the server to execute the change.
export function makeDiff(state: EditMarketSecModeState, orig: MarketSecurityStatusLocal): Partial<MarketSecurityMode> {
  const diff: Partial<MarketSecurityMode> = {};
  writeableProps.forEach(prop => {
    if (orig[prop as keyof typeof orig] !== state[prop]) {
      switch (prop) {
        case Action.SizeBuckets: {
          const oldBuckets = sizeBucketsToString(orig.SizeBuckets);
          if (state.SizeBuckets === '') {
            diff.SizeBuckets = null;
          } else if (oldBuckets !== state.SizeBuckets) {
            diff.SizeBuckets = stringToBuckets(state.SizeBuckets);
          }
          break;
        }
        case Action.MarketDataMode:
          if (state.MarketDataMode !== orig.Capabilities?.MarketData) {
            diff.Capabilities = {
              ...orig.Capabilities,
              ...{
                MarketData: state[Action.MarketDataMode],
              },
            };
          }
          break;
        case Action.Mode:
          if (state.Mode !== orig.RequestedEnabled) {
            diff.Mode = state.Mode;
          }
          break;
        default:
          if (state[prop] === '') {
            diff[prop] = null;
          } else {
            diff[prop] = state[prop];
          }
      }
    }
  });
  return diff;
}

export interface EditMarketSecModeState {
  status?: MarketSecurityStatusLocal;
  Mode: ConnectionModeEnum;
  SizeBuckets: string;
  MinPriceIncrement: string;
  MinSizeIncrement: string;
  MinimumSize: string;
  MaximumSize: string;
  MinimumAmount: string;
  MarketDataMode: boolean;
}

type EditMarketSecFormKeys = Pick<
  EditMarketSecModeState,
  'MinPriceIncrement' | 'MinSizeIncrement' | 'MinimumSize' | 'MaximumSize' | 'MinimumAmount'
>;

interface InitializeAction {
  type: Action.Initialize;
  state: EditMarketSecModeState;
}

interface SetModeAction {
  type: Action.Mode;
  value: ConnectionModeEnum;
}

interface SetStringAction {
  type:
    | Action.SizeBuckets
    | Action.MinPriceIncrement
    | Action.MinSizeIncrement
    | Action.MaximumSize
    | Action.MinimumSize
    | Action.MinimumAmount;
  value: string;
}

interface SetBoolAction {
  type: Action.MarketDataMode;
  value: boolean;
}

type EditAction = InitializeAction | SetModeAction | SetStringAction | SetBoolAction;

const editMarketSecModeReducer = (state: EditMarketSecModeState, action: EditAction): EditMarketSecModeState => {
  switch (action.type) {
    case Action.Initialize:
      return { ...state, ...action.state };
    default:
      return { ...state, [action.type]: action.value };
  }
};
