import type { FilterableProperty } from '@talos/kyoko';
import { IconName, TimeInForceEnum } from '@talos/kyoko';
import { values } from 'lodash';
import { useMemo } from 'react';

/**
 * This hook creates the filter property definition for TimeInForce properties.
 *
 * @param overrides Any overrides to make to the defaults here (e.g. `key`, etc.)
 * @returns Filter Property definition to use for TimeInForce filters
 */
export function useTimeInForceFilter<TKey extends string = 'TimeInForces', P = FilterableProperty<TKey>>(
  overrides?: { [K in keyof P]: K extends keyof FilterableProperty<TKey> ? P[K] : never } & { key?: TKey }
) {
  return useMemo(
    () => ({
      ...({
        key: 'TimeInForces' as TKey,
        field: 'TimeInForce',
        label: 'Time In Force',
        icon: IconName.ArrowUpDown,
        options: values(TimeInForceEnum),
        getOptionLabel: (option: string) => option,
      } satisfies FilterableProperty<TKey>),
      ...overrides,
    }),
    [overrides]
  );
}
