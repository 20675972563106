import {
  Box,
  Checkbox,
  ConnectionType,
  Dropdown,
  EMPTY_ARRAY,
  ProductTypeEnum,
  useDropdownPopper,
  useDynamicCallback,
  useMarketAccountsContext,
  type Security,
  type StringSelectItem,
} from '@talos/kyoko';
import type { CustomCellEditorProps } from 'ag-grid-react';
import { useMemo, useRef } from 'react';
import styled from 'styled-components';
import { useTradableMarketAccountNames } from '../../../hooks';
import { MarketAccountSelectionList } from '../../MarketAccountSelectionList';

export interface AgMarketAccountSelectorProps {
  onChange: (accounts: string[]) => void;
  security?: Security;
  isDisabled?: boolean;
}

export const AgMarketAccountSelector = ({
  onChange,
  security,
  isDisabled = false,
  eGridCell,
  api,
  value: unsanitizedValue,
  onValueChange,
  ...cellEditorParams
}: CustomCellEditorProps<unknown, StringSelectItem[]> & AgMarketAccountSelectorProps) => {
  const availableMarketAccountNames = useTradableMarketAccountNames({
    security,
    subAccountAllocations: -1,
    connectionType: ConnectionType.Orders,
    isUnifiedLiquidityEnabled: false,
  });

  const value = unsanitizedValue ?? EMPTY_ARRAY;
  const supportAllMarketToggle = useMemo(() => security?.ProductType === ProductTypeEnum.Spot, [security]);
  const useAllMarkets = supportAllMarketToggle && value.length === 0;
  const selectedMarketAccountNames = useMemo(() => value.map(item => item.value), [value]);

  const { marketAccountsByName } = useMarketAccountsContext();

  const handleMarketAccountsChange = useDynamicCallback((selectedMarketAccounts: string[] = []) => {
    const marketAccounts = selectedMarketAccounts.map(marketAccount => ({
      value: marketAccount,
      label: marketAccountsByName.get(marketAccount)?.DisplayName || marketAccount,
    }));

    onValueChange(marketAccounts);
  });

  const dropdownRef = useRef<HTMLElement>(eGridCell);
  const popperPlacement = 'bottom-start';
  const handleClickOutside = useDynamicCallback(() => {
    cellEditorParams.stopEditing();
  });
  const dropdownPopper = useDropdownPopper({
    isOpen: true,
    referenceElement: dropdownRef.current,
    dropdownWidth: '240px',
    onClickOutside: handleClickOutside,
    dropdownPlacement: popperPlacement,
  });

  return (
    <Dropdown {...dropdownPopper} portalize={true}>
      {supportAllMarketToggle ? (
        <Box
          fontWeight={600}
          background="colors.gray.020"
          borderColor="colors.gray.040"
          borderBottom="solid 1px"
          p="spacingDefault"
        >
          <Checkbox
            autoFocus={useAllMarkets}
            checked={useAllMarkets}
            onChange={() => onValueChange(EMPTY_ARRAY)}
            disabled={false}
          >
            All Markets
          </Checkbox>
        </Box>
      ) : null}

      <Wrapper>
        <MarketAccountSelectionList
          availableMarketAccounts={availableMarketAccountNames}
          selectedMarketAccounts={selectedMarketAccountNames}
          security={security}
          onChangeMarketAccounts={handleMarketAccountsChange}
          connectionType={ConnectionType.Orders}
          showBuyingPower={false}
          showInactiveMarketAccounts={false}
        />
      </Wrapper>
    </Dropdown>
  );
};

const Wrapper = styled(Box)`
  scrollbar-gutter: stable both-edges;
`;
Wrapper.defaultProps = {
  maxHeight: '400px',
  overflow: 'auto',
  py: 'spacingDefault',
  background: 'colors.gray.030',
};
