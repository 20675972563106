import {
  BarChart,
  getCurrencyColor,
  renderToHTML,
  toBigWithDefault,
  unboundedAbbreviate,
  useConstant,
  useDynamicCallback,
  useHighchartsRef,
} from '@talos/kyoko';
import Big from 'big.js';
import { groupBy, map, sortBy, sumBy } from 'lodash';
import { darken } from 'polished';
import { useEffect, useMemo } from 'react';
import { useUpdateEffect } from 'react-use';
import { type DefaultTheme, useTheme } from 'styled-components';

import { getPointCustomAttrs, type OpsBalancesChartDirection, type OpsBalancesChartPointCustom } from './types';

export interface OpsBalancesChartProps<T> {
  entities: T[] | undefined;
  /** Grab the property to group on from the balance. If undefined is returned, the value will not be included in any resulting groupings. */
  getGroupBy: (item: T) => string | undefined;
  /** Get the label of the item. Should be the label corresponding to the key we're grouping by */
  getLabel: (item: T) => string;
  /** Get the numeric value of the entity to be summed into the group's aggregate value */
  getValue: (item: T) => Big;
  /** Whether or not the ShowByAsset toggle is enabled */
  showingByAsset: boolean;
  /** The title to render on the bottom axis (describing the value) */
  chartValueAxisTitle: string;
  /** Given the hovered point, return a react element to render within the highcharts tooltip. */
  getTooltip: (point: Highcharts.Point) => React.ReactElement;
}

export const OpsBalancesChart = <T,>({
  entities,
  getGroupBy,
  getValue,
  getLabel,
  getTooltip,
  showingByAsset,
  chartValueAxisTitle,
}: OpsBalancesChartProps<T>) => {
  const theme = useTheme();
  const { chartRef, setChartObject, isLoaded } = useHighchartsRef();

  const chartData = useMemo(() => {
    if (!entities) {
      return undefined;
    }

    return getSplitData({ entities, theme, getValue, getGroupBy, getLabel, showingByAsset });
  }, [entities, theme, getValue, getGroupBy, getLabel, showingByAsset]);

  useEffect(() => {
    if (!isLoaded || !chartData) {
      return;
    }

    const positiveSeries = chartRef.current?.series.find(series => series.index === 0);
    const negativeSeries = chartRef.current?.series.find(series => series.index === 1);

    positiveSeries?.setData(
      [
        // These two surrounding dummy points are just here for nicer spacing and responsivity in the chart. Try removing them and see the difference.
        { x: -1, y: 0, dataLabels: { enabled: false } },
        ...chartData.positiveData,
        { x: 1, y: 0, dataLabels: { enabled: false } },
      ],
      false,
      false,
      false // animate
    );
    negativeSeries?.setData(chartData.negativeData, true, true, false);
  }, [isLoaded, chartData, chartRef]);

  const initialChartValueAxisTitle = useConstant(chartValueAxisTitle);

  // Create a stable function to get the tooltip. This allows us to keep the tooltip option in the highcharts option stable,
  // while also allowing us to pass up to date component-level state into the tooltip (when its created)
  const getTooltipHTML = useDynamicCallback((point: Highcharts.Point) => {
    return renderToHTML(getTooltip(point), theme);
  });

  const options = useMemo(() => {
    return {
      chart: {
        type: 'bar',
        marginBottom: 55, // aligns the bottom axis title nicely with the outer border of the component
        plotBorderWidth: 1,
        plotBorderColor: theme.borderColorChartAxis,
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        useHTML: true,
        padding: 0,
        outside: false,
        backgroundColor: 'transparent',
        formatter: function () {
          return getTooltipHTML(this.point);
        },
      },
      plotOptions: {
        bar: {
          stacking: 'normal',
          borderColor: theme.backgroundContent,
          borderWidth: 1,
          boostBlending: 'darken',
          pointPadding: 0,
          groupPadding: 0,
          maxPointWidth: 75,

          dataLabels: {
            enabled: true,
            shadow: true,
            style: {
              textOutline: 'none',
              fontWeight: 'normal',
              fontSize: '0.8em',
              fontFamily: theme.fontFamily,
              color: theme.colorTextImportant,
            },
            formatter: function () {
              const value = this.point.y ?? 0;
              if (value === 0) {
                return '';
              }

              const custom = getPointCustomAttrs(this.point);
              return `${this.point.name}<br/>${custom?.percentageString}`;
            },
          },
        },
      },
      xAxis: {
        visible: false,
      },
      yAxis: {
        title: {
          margin: 10,
          text: initialChartValueAxisTitle,
          style: {
            color: theme.colorTextMuted,
          },
        },
        plotLines: [
          {
            id: 'zero',
            value: 0,
            color: theme.colors.gray['070'],
            zIndex: 3, // above the bars, above the axis native grid lines, below the tooltip
            width: 1,
          },
        ],
        labels: {
          formatter: function () {
            if (typeof this.value === 'string') {
              return '';
            }
            const style = this.value < 0 ? `color: ${theme.colorTextNegative}` : '';
            return `<span style="${style}">${unboundedAbbreviate(this.value, {
              precision: 2,
            })}</span>`;
          },
        },
      },
      series: [
        {
          type: 'bar',
          id: 'positive',
          index: 0,
          stack: 'positive',
          dataLabels: {},
        },
        {
          type: 'bar',
          id: 'negative',
          index: 1,
          stack: 'negative',
        },
      ],
    } satisfies Highcharts.Options;
  }, [theme, initialChartValueAxisTitle, getTooltipHTML]);

  useUpdateEffect(() => {
    if (isLoaded && chartRef.current) {
      chartRef.current.axes[1].setTitle({ text: chartValueAxisTitle }, true);
    }
  }, [chartValueAxisTitle]);

  return <BarChart onChartCreated={setChartObject} options={options} throttleResizing={0} />;
};

/**
 * getSplitData splits the provided entities by value sign (positive and negative) before aggregating within the provided grouping.
 *
 * This means that each group, if it has some amount of positive and negative contributors, will be present twice in the chart. The effect
 * of this is that we do not show net values for any given grouping, we instead show the complete negative and complete positive values.
 */
function getSplitData<T>({
  entities,
  showingByAsset,
  theme,
  getValue,
  getGroupBy,
  getLabel,
}: {
  entities: T[];
  theme: DefaultTheme;
  showingByAsset: OpsBalancesChartProps<T>['showingByAsset'];
  getValue: OpsBalancesChartProps<T>['getValue'];
  getGroupBy: OpsBalancesChartProps<T>['getGroupBy'];
  getLabel: OpsBalancesChartProps<T>['getLabel'];
}) {
  const positiveEntities: T[] = [];
  const negativeEntities: T[] = [];

  for (const entity of entities) {
    const value = getValue(entity);
    if (value.lt(0)) {
      negativeEntities.push(entity);
    } else if (value.gt(0)) {
      positiveEntities.push(entity);
    }
  }

  const positiveTotal = positiveEntities.reduce((total, entity) => total.plus(getValue(entity)), Big(0));
  const positiveGroups = groupBy(positiveEntities, entity => getGroupBy(entity));
  const positiveDataPoints = map(positiveGroups, (entities, key) => ({
    key,
    // the label of the grouping can be grabbed from any member of the grouped array since that's what they're grouped on
    label: getLabel(entities[0]),
    value: sumBy(entities, b => getValue(b).toNumber()),
  }));

  const positiveData = getDataPoints({
    dataPoints: positiveDataPoints,
    showingByAsset,
    total: positiveTotal,
    direction: 'positive',
    theme,
  });

  const negativeTotal = negativeEntities.reduce((total, entity) => total.plus(getValue(entity)), Big(0));
  const negativeGroups = groupBy(negativeEntities, entity => getGroupBy(entity));
  const negativeDataPoints = map(negativeGroups, (entities, key) => ({
    key,
    // the label of the grouping can be grabbed from any member of the grouped array since that's what they're grouped on
    label: getLabel(entities[0]),
    value: sumBy(entities, b => getValue(b).toNumber()),
  }));

  const negativeData = getDataPoints({
    dataPoints: negativeDataPoints,
    showingByAsset,
    total: negativeTotal,
    direction: 'negative',
    theme,
  });

  return {
    positiveData,
    negativeData,
  };
}

function getDataPoints({
  dataPoints,
  total,
  showingByAsset,
  direction,
  theme,
}: {
  dataPoints: { key: string; label: string; value: number }[];
  total: Big | undefined;
  showingByAsset: boolean;
  direction: OpsBalancesChartDirection;
  theme: DefaultTheme;
}) {
  const sortedDataPoints = sortBy(dataPoints, dp => dp.value);
  if (direction === 'positive') {
    sortedDataPoints.reverse();
  }

  const data = sortedDataPoints.map((dp, i) => {
    let color = '';

    if (showingByAsset) {
      color = getCurrencyColor(dp.key) ?? theme.colorTextDefault;
    } else {
      // by market / account
      color = theme.colorDataBlue;
    }

    if (direction === 'negative') {
      color = darken(0.15, color);
    }

    return {
      id: `${direction}-${dp.key}`,
      y: dp.value,
      x: 0, // only one x value, puts all data points onto one bar
      custom: {
        key: dp.key,
        nonAbsValue: dp.value,
        absValue: Math.abs(dp.value),
        percentageString: total ? getSafePercentage(total, dp.value) : undefined,
        direction,
      } satisfies OpsBalancesChartPointCustom,
      color,
      name: dp.label,
      stack: direction, // split this one bar (x=0) into two stacks, one going either direction (positive and negative)
      // this lets us find points by id in cypress tests and interact with them
      // Also, we cant grab classnames with / in cypress. So replace any / with - here for ease of testing.
      className: `${direction}-${dp.key}`.replaceAll('/', '-'),
    };
  });

  return data;
}

function getSafePercentage(total: Big, value: number): string | undefined {
  const valueBig = toBigWithDefault(value, 0);
  if (total.eq(0) || valueBig.eq(0)) {
    return undefined;
  }

  return `${valueBig.div(total).times(100).toFixed(2)}%`;
}
