import { invariant } from '@epic-web/invariant';
import { getCellRendererFunc, getPositionID, type TreeRow } from '@talos/kyoko';
import type { ICellRendererParams, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';
import { OpsPosition } from '../types';
import type { OpsOverviewBlotterTypeCheckedColumnIDs } from './useOperationsOverviewBlotterColumns';

/**
 * This file defines the types of rows we use to build the OpsOverview blotter. There are four types:
 * MarketRow,
 *   MarketAccountRow,
 *     UnderlierRow
 *       OpsPositionRow (Instrument-level)
 *
 * Each row defines properties relevant to its inclusion in a tree, but is not itself opinionated in regards to where in the tree it is placed. Decoupling
 * tree layering business logic from each individual row allows us to much more easily add different types of layerings and new types of rows
 * in the future.
 *
 * To achieve this, the rows expose common getters such as `getPathComponent`, `getGroupColumnID`, etc.
 *
 * In the future, I also look forward to adding some kind of `isRedundant(childRows)` function which could be used to dynamically collapse
 * nodes in a tree structure based on their children subtree - also implemented generically per type of row!
 *
 * Lastly, these extra getters referenced above could probably be enshrined into TreeRow. But TBD on that, would like to experiment further first.
 */

export class OpsPositionRow extends OpsPosition implements TreeRow {
  /** The column id of which column this grouping level is grouping on. Ties this level of grouping to a column. */
  static getGroupColumnID() {
    return 'Asset' satisfies OpsOverviewBlotterTypeCheckedColumnIDs;
  }

  get groupColumnID() {
    return OpsPositionRow.getGroupColumnID();
  }

  /** A static function returning the data path component this class (level) represents */
  static getPathComponent(position: OpsPosition) {
    return getPositionID(position.PositionSource, position.MarketAccount, position.Asset, position.SubAccount);
  }

  /** A static function to allow you to preview the row id for a given position */
  static getRowID(dataPath: string[]): string {
    invariant(dataPath.length > 0, 'Unable to call OpsPositionRow.getRowID with dataPath parameter of length 0');
    return dataPath.at(-1)!; // our rowID is just our part of the data path, see getPathComponent above
  }

  dataPath: string[];
  override get rowID() {
    return OpsPositionRow.getRowID(this.dataPath);
  }

  groupColumnValueGetter(params: ValueGetterParams<MarketAccountRow>) {
    return this.Asset; // this will need to be parameterized in the future via the constructor in some way, probably!
  }

  groupColumnValueFormatter({ value, context }: ValueFormatterParams<MarketAccountRow, string>): string {
    if (!value) {
      return '';
    }

    return context.current.marketAccountsByName?.get(value)?.DisplayName ?? value;
  }

  groupColumnFilterValueGetter(params: ValueGetterParams<MarketAccountRow>) {
    return this.groupColumnValueGetter(params);
  }

  groupColumnInnerCellRenderer(params: ICellRendererParams<UnderlierRow, string>) {
    const cellRendererFunc = getCellRendererFunc(params.api, OpsPositionRow.getGroupColumnID());

    if (cellRendererFunc) {
      return cellRendererFunc(params);
    }

    return params.value ?? '';
  }

  constructor(dataPath: string[], position: OpsPosition) {
    super(position, position.marketAccountTotal, position.market);
    this.dataPath = dataPath;
  }
}

export class MarketRow implements TreeRow {
  /** The column id of which column this grouping level is grouping on. Ties this level of grouping to a column. */
  static getGroupColumnID() {
    return 'market' satisfies OpsOverviewBlotterTypeCheckedColumnIDs;
  }

  get groupColumnID() {
    return MarketRow.getGroupColumnID();
  }

  /** A static function returning the data path component this class (level) represents */
  static getPathComponent(position: OpsPosition) {
    return position.market;
  }

  /** A static function to allow you to preview the row id for a given position */
  static getRowID(dataPath: string[]) {
    return dataPath.join('-');
  }

  dataPath: string[];
  get rowID() {
    return MarketRow.getRowID(this.dataPath);
  }

  groupColumnValueGetter(params: ValueGetterParams<MarketRow>) {
    return this.dataPath.at(-1);
  }

  groupColumnValueFormatter({ value, context }: ValueFormatterParams<MarketRow, string>): string {
    if (!value) {
      return '';
    }

    return context.current.marketDisplayNameByName?.get(value) ?? value;
  }

  groupColumnFilterValueGetter(params: ValueGetterParams<MarketRow>) {
    return this.groupColumnValueGetter(params);
  }

  constructor(dataPath: string[]) {
    this.dataPath = dataPath;
  }
}

export class MarketAccountRow implements TreeRow {
  /** The column id of which column this grouping level is grouping on. Ties this level of grouping to a column. */
  static getGroupColumnID() {
    return 'market' satisfies OpsOverviewBlotterTypeCheckedColumnIDs;
  }

  get groupColumnID() {
    return MarketAccountRow.getGroupColumnID();
  }

  /** A static function returning the data path component this class (level) represents */
  static getPathComponent(position: OpsPosition) {
    return position.MarketAccount;
  }

  /** A static function to allow you to preview the row id for a given position */
  static getRowID(dataPath: string[]) {
    return dataPath.join('-');
  }

  dataPath: string[];
  get rowID() {
    return MarketAccountRow.getRowID(this.dataPath);
  }

  groupColumnValueGetter(params: ValueGetterParams<MarketAccountRow>) {
    return this.dataPath.at(-1);
  }

  groupColumnValueFormatter({ value, context }: ValueFormatterParams<MarketAccountRow, string>): string {
    if (!value) {
      return '';
    }

    return context.current.marketAccountsByName?.get(value)?.DisplayName ?? value;
  }

  groupColumnFilterValueGetter(params: ValueGetterParams<MarketAccountRow>) {
    return this.groupColumnValueGetter(params);
  }

  constructor(dataPath: string[]) {
    this.dataPath = dataPath;
  }
}

export class UnderlierRow implements TreeRow {
  /** The column id of which column this grouping level is grouping on. Ties this level of grouping to a column. */
  static getGroupColumnID() {
    return 'underlying' satisfies OpsOverviewBlotterTypeCheckedColumnIDs;
  }

  get groupColumnID() {
    return UnderlierRow.getGroupColumnID();
  }

  /** A static function returning the data path component this class (level) represents */
  static getPathComponent(position: OpsPosition) {
    return position.underlying;
  }

  /** A static function to allow you to preview the row id for a given position */
  static getRowID(dataPath: string[]) {
    return dataPath.join('-');
  }

  dataPath: string[];
  get rowID() {
    return UnderlierRow.getRowID(this.dataPath);
  }

  groupColumnValueGetter(params: ValueGetterParams<UnderlierRow>) {
    return this.dataPath.at(-1);
  }

  groupColumnValueFormatter({ value }: ValueFormatterParams<UnderlierRow, string>): string {
    return value ?? '';
  }

  groupColumnFilterValueGetter(params: ValueGetterParams<UnderlierRow>) {
    return this.groupColumnValueGetter(params);
  }

  groupColumnInnerCellRenderer(params: ICellRendererParams<UnderlierRow, string>) {
    const cellRendererFunc = getCellRendererFunc(params.api, UnderlierRow.getGroupColumnID());

    if (cellRendererFunc) {
      return cellRendererFunc(params);
    }

    return params.value ?? '';
  }

  constructor(dataPath: string[]) {
    this.dataPath = dataPath;
  }
}
