import { Link } from '../styles';

export const ALL_KEYS_INFO = {
  Symbol: (
    <>
      Talos symbol e.g. BTC-USD or bybit:BTC-PERP, refer to{' '}
      <Link
        href="https://docs.google.com/spreadsheets/d/1zDgZ9PFUTtkEeY8AAeh-_0YkCWnkPNqNp097D2Fbjvw/edit#gid=113374571"
        target="_blank"
      >
        link
      </Link>{' '}
      for supported symbols
    </>
  ),
  Market: 'Market or market account names',
  Side: 'Side, either Buy or Sell',
  OrderQty: 'Order quantity in units of Currency',
  Currency: 'The currency that the order quantity is specified in',
  Strategy: 'Strategy for the order',
  SubAccount: 'Sub Account is a mandatory field if they are configured in the system',
  InitialDecisionStatus: 'Set to Staged to import a Staged order',
  EndTime: 'End time for the order',
  Price: 'Price for the order',
  UnifiedLiquidity: 'Unified Liquidity order',
  ShowQty: 'Qty to show',
};

export const REQUIRED_KEYS: Array<keyof typeof ALL_KEYS_INFO> = [
  'Symbol',
  'Market',
  'Side',
  'OrderQty',
  'Currency',
  'Strategy',
];
