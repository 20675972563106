import { BlotterTableNativeStorageContext } from '@talos/kyoko';
import { useAppStateDispatch, useAppStateSelector } from 'providers/AppStateProvider';
import { useMemo, type ContextType, type PropsWithChildren } from 'react';
import { getBlotter3SliceActions } from './Blotters3Slice';
import { selectFullAppConfig } from './selectors';

/** Redux-based column state storage for useBlotterTableNative */
export const BlotterTableNativeStorageProvider = ({ children }: PropsWithChildren) => {
  const blotterStateMap = useAppStateSelector(state => selectFullAppConfig(state).blotters3);
  const dispatch = useAppStateDispatch();

  const contextValue = useMemo<NonNullable<ContextType<typeof BlotterTableNativeStorageContext>>>(
    () => ({
      blotterStateMap,
      setGridState: (blotterId, gridState) => {
        dispatch(getBlotter3SliceActions().setGridState({ blotterId, gridState }));
      },
      setFilterState: (blotterId, blotterFilter) => {
        dispatch(getBlotter3SliceActions().setFilterState({ blotterId, blotterFilter }));
      },
      resetGridState: blotterId => {
        dispatch(getBlotter3SliceActions().resetGridState(blotterId));
      },
    }),
    [blotterStateMap, dispatch]
  );

  return (
    <BlotterTableNativeStorageContext.Provider value={contextValue}>
      {children}
    </BlotterTableNativeStorageContext.Provider>
  );
};
