import type React from 'react';

import {
  AccordionGroup,
  AggregationsProvider,
  ApiPermissionActionEnum,
  AppwideDrawerProvider,
  AssetsProvider,
  ConnectionStatusProvider,
  CurrenciesProvider,
  CurrencyConversionProvider,
  DialogProvider,
  ExposureDefinitionsProvider,
  GlobalToastsProvider,
  HeartbeatsProvider,
  IntlProvider,
  MarketAccountsProvider as KyokoMarketAccountsProvider,
  LoaderWrapper,
  MarketAccountCurrenciesProvider,
  MarketPriceContextProvider,
  MarketsProvider,
  NotesProvider,
  PowerSearchProvider,
  RefRateContextProvider,
  RefreshButton,
  SecuritiesProvider,
  SparklineProvider,
  TourProvider,
  UnifiedLiquidityProvider,
  WebSocketClientProvider,
  composeComponents,
  logger,
  useUserContext,
} from '@talos/kyoko';

import { IntercomProvider } from 'providers/IntercomContext';
import { SubAccountsProvider } from 'providers/SubAccountsProvider';
import { TradingLimitsProvider } from 'providers/TradingLimitsProvider';
import { MixpanelProvider } from './MixpanelProvider';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Loader } from 'components/Loader';
import { useFeatureFlag } from 'hooks/useFeatureFlag';
import { AppConfigProvider } from 'providers/AppConfigProvider';
import { BlotterTableContextProvider } from 'providers/BlotterTableContextProvider';
import { CustomerBalanceProvider } from 'providers/CustomerBalancesProvider';
import { CustomerCreditProvider } from 'providers/CustomerCreditProvider';
import { CustomerSecuritiesProvider } from 'providers/CustomerSecuritiesProvider';
import { CustomersProvider } from 'providers/CustomersProvider';
import { EndpointsContextProvider } from 'providers/EndpointsContextProvider';
import { ExecutionReportsProvider } from 'providers/ExecutionReportsProvider';
import { ExposuresProvider } from 'providers/ExposuresProvider';
import { FeesProvider } from 'providers/FeesProvider';
import { OrdersProvider } from 'providers/OrdersProvider';
import { OrganizationProvider } from 'providers/OrganizationProvider';
import { PricingRulesProvider } from 'providers/PricingRulesProvider';
import { ProvidersGate } from 'providers/ProvidersGate';
import { QuotesProvider } from 'providers/QuotesProvider';
import { ServiceWorkerProvider } from 'providers/ServiceWorkerContext';
import { SoundProvider } from 'providers/SoundContext';
import { StrategiesProvider } from 'providers/StrategiesProvider';
import { SubAccountRollupsProvider } from 'providers/SubAccountRollupsProvider';
import { TransfersProvider } from 'providers/TransfersProvider';
import { UserGroupsProvider } from 'providers/UserGroupsProvider';
import type { PropsWithChildren } from 'react';
import { getWsClient } from 'ws';
import { tours } from '../tokens/tours';
import { BlotterTableNativeStorageProvider } from './AppConfigRedux/BlotterTableNativeStorageProvider';
import { AppInstallPromptProvider } from './AppInstallPromptProvider';
import { AppLayoutContextProvider } from './AppLayoutContextProvider/AppLayoutContextProvider';
import { AppStateProvider } from './AppStateProvider';
import { ContextBlotterFilterProvider } from './ContextBlotterFilterProvider';
import { CustomerMarketAccountsProvider } from './CustomerMarketAccountsProvider';
import { CustomerPricingTiersProvider } from './CustomerPricingTiersProvider';
import { EquityMarginRatioLimitProvider } from './EquityMarginRatioLimitProvider';
import { FixingIndexProvider } from './FixingIndexProvider';
import { HedgeOrderStatusesProvider } from './HedgeOrderStatusProvider';
import { HedgePositionStatusProvider } from './HedgePositionStatusProvider';
import { HomeCurrencyContextProvider } from './HomeCurrencyProvider';
import { MarketAccountPermissionFiltersProvider } from './MarketAccountPermissionFiltersProvider';
import { MarketExposuresProvider } from './MarketExposuresProvider';
import { MarketSecurityStatusesProvider } from './MarketSecurityStatusProvider';
import { OrgConfigurationProvider } from './OrgConfigurationProvider';
import { PortfolioAccountsProvider } from './PortfolioAccountsProvider';
import { PositionDetailsModalProvider } from './PositionDetailsModalProvider';
import { RefDataCacheProvider } from './RefDataCacheProvider';
import { SubAccountPermissionFiltersProvider } from './SubAccountPermissionFiltersProvider';
import { SubAccountPositionLimitsProvider } from './SubAccountPositionLimitsProvider';
import { SubAccountRollupMembershipsProvider } from './SubAccountRollupMembershipsProvider';
import { SubAccountWindowLimitsProvider } from './SubAccountWindowLimitsProvider';
import { UsersContextProvider } from './UsersContextProvider';

const queryClient = new QueryClient();

const QueryProvider = ({ children }: PropsWithChildren) => (
  <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
);

const Providers = composeComponents([
  // App State Provider for useBlotterTableNative
  BlotterTableNativeStorageProvider,

  IntercomProvider,
  MixpanelProvider,
  NotesProvider,
  AppInstallPromptProvider,

  ServiceWorkerProvider,
  IntlProvider,
  RefDataCacheProvider,
  CurrenciesProvider,
  SecuritiesProvider,
  MarketAccountCurrenciesProvider,
  SubAccountRollupsProvider,
  SubAccountsProvider,
  UsersContextProvider,
  StrategiesProvider,
  OrganizationProvider,
  GlobalToastsProvider,
  UnifiedLiquidityProvider,

  ProvidersGate,

  QueryProvider,
  AssetsProvider,
  FixingIndexProvider,
  MarketSecurityStatusesProvider,
  SubAccountRollupMembershipsProvider,
  SubAccountPermissionFiltersProvider,
  MarketAccountPermissionFiltersProvider,
  HeartbeatsProvider,
  FeesProvider,
  ConnectionStatusProvider,
  HedgeOrderStatusesProvider,
  OrdersProvider,
  ExecutionReportsProvider,
  QuotesProvider,
  AggregationsProvider,
  HomeCurrencyContextProvider,
  CurrencyConversionProvider,
  PortfolioAccountsProvider,
  TransfersProvider,
  ExposureDefinitionsProvider,
  UserGroupsProvider,
  PricingRulesProvider,
  CustomersProvider,
  CustomerPricingTiersProvider,
  CustomerSecuritiesProvider,
  CustomerBalanceProvider,
  CustomerMarketAccountsProvider,
  CustomerCreditProvider,
  TradingLimitsProvider,
  SubAccountPositionLimitsProvider,
  SubAccountWindowLimitsProvider,
  ExposuresProvider,
  MarketExposuresProvider,
  BlotterTableContextProvider,
  SoundProvider,
  AppwideDrawerProvider,
  PositionDetailsModalProvider,
  AppLayoutContextProvider,
  AccordionGroup,
  HedgePositionStatusProvider,
  EquityMarginRatioLimitProvider,
  ContextBlotterFilterProvider,
]);

export function ContextProvider(props: React.PropsWithChildren) {
  const { children } = props;
  const { user, isLoaded: isUserLoaded } = useUserContext();

  // This should never happen. UserContextProvider resolves to a defined User before ContextProvider is called.
  // Prevents Talos users from being stuck in faulty prodmon morphs.
  if (isUserLoaded && user == null) {
    logger.error(new Error(`User does not exist. ${window.location.href}`));
    return (
      <LoaderWrapper>
        User does not exist.
        <RefreshButton />
      </LoaderWrapper>
    );
  }

  if (user == null) {
    return null;
  }

  return (
    <EndpointsContextProvider>
      <WebSocketClientProvider wsClient={getWsClient()}>
        <DialogProvider>
          <MarketsProvider>
            <OrgConfigurationProvider>
              <AppStateProvider user={user}>
                <AppConfigProvider user={user}>
                  <MarketAccountsProvider>
                    <Providers>
                      <TourProvider tours={tours}>
                        <MarketPriceContextProvider>
                          <RefRateContextProvider>
                            <SparklineProvider>
                              <PowerSearchProvider>{children}</PowerSearchProvider>
                            </SparklineProvider>
                          </RefRateContextProvider>
                        </MarketPriceContextProvider>
                      </TourProvider>
                    </Providers>
                  </MarketAccountsProvider>
                </AppConfigProvider>
              </AppStateProvider>
            </OrgConfigurationProvider>
          </MarketsProvider>
        </DialogProvider>
      </WebSocketClientProvider>
    </EndpointsContextProvider>
  );
}

const MarketAccountsProvider = ({ children }: PropsWithChildren) => {
  const {
    isLoaded: isFeatureFlagsLoaded,
    filterMarketAccountsByPermissionActionAll,
    enableAccountSegregation,
  } = useFeatureFlag();
  if (!isFeatureFlagsLoaded) {
    return (
      <LoaderWrapper>
        <Loader />
      </LoaderWrapper>
    );
  }

  /** Two Jira's affect the permission:
   * - [PORT-16073] If Account Segregation (enableAccountSegregation) is enabled, we need to filter the Market Accounts by permission action "Read".
   * - [DEAL-4518] Allow Filtering the Market Accounts subscription by providing a Permission Action.
   * If filterMarketAccountsByPermissionActionAll is true, then we will filter the market accounts by permission action "All".
   * This makes it so that Customer and Custodian Market Accounts are not returned on the MarketAccount subscription.
   * */
  const apiPermission = enableAccountSegregation
    ? ApiPermissionActionEnum.Read
    : filterMarketAccountsByPermissionActionAll
    ? ApiPermissionActionEnum.All
    : undefined;
  return <KyokoMarketAccountsProvider permissionAction={apiPermission}>{children}</KyokoMarketAccountsProvider>;
};

export { MarketAccountsProvider as AvaMarketAccountsProvider };
