import { type Currency, Divider, Flex, HStack, InlineFormattedNumber, PopoverContent, Text } from '@talos/kyoko';
import type { DefaultTheme } from 'styled-components';
import type { OperationsOverviewShowBy } from '../types';
import { getPointCustomAttrs } from './types';

interface OpsEMRChartTooltipProps {
  point: Highcharts.Point;
  showBy: OperationsOverviewShowBy;
  homeCurrency: Currency | undefined;
  theme: DefaultTheme;
}

export const OpsEMRChartTooltip = ({ point, showBy, homeCurrency, theme }: OpsEMRChartTooltipProps) => {
  const attrs = getPointCustomAttrs(point);
  if (!attrs) {
    return <div>Error</div>;
  }

  return (
    <PopoverContent>
      <Flex
        color="colorTextDefault"
        flexDirection="column"
        gap="spacingDefault"
        minWidth="220px"
        maxWidth="300px"
        data-testid="emr-bubble-chart-tooltip"
      >
        <HStack justifyContent="space-between" gap="spacingDefault">
          <Text color="colorTextImportant" fontWeight="bold">
            {point.name}
          </Text>
          <Text textAlign="right">{showBy === 'Market' ? 'Market' : 'Market Account'}</Text>
        </HStack>
        <Divider orientation="horizontal" />

        <HStack justifyContent="space-between" gap="spacingDefault">
          <Text>Equity</Text>
          <InlineFormattedNumber
            number={attrs.equity}
            currency={homeCurrency?.Symbol}
            increment={homeCurrency?.DefaultIncrement}
            highlightColor={attrs.equity < 0 ? theme.colorTextNegative : undefined}
            round
          />
        </HStack>

        <HStack justifyContent="space-between" gap="spacingDefault">
          <Text>EMR</Text>
          <InlineFormattedNumber
            number={attrs.emr}
            increment="0.01"
            highlightColor={attrs.emr < 0 ? theme.colorTextNegative : undefined}
            round
          />
        </HStack>

        <HStack justifyContent="space-between" gap="spacingDefault">
          <Text>Gross Position</Text>
          <InlineFormattedNumber
            number={attrs.size}
            currency={homeCurrency?.Symbol}
            increment={homeCurrency?.DefaultIncrement}
            highlightColor={attrs.size < 0 ? theme.colorTextNegative : undefined}
            round
          />
        </HStack>
      </Flex>
    </PopoverContent>
  );
};
