import {
  Box,
  HelpIcon,
  HStack,
  IndicatorBadge,
  IndicatorBadgeSizes,
  IndicatorBadgeVariants,
  LoaderTalos,
  ProductTypeEnum,
  Text,
  toBigWithDefault,
  useCurrency,
  useMarketAccountsContext,
  useMarketsContext,
  useSyncedRef,
  VStack,
} from '@talos/kyoko';
import { useCallback, useMemo } from 'react';
import { useTheme } from 'styled-components';
import { useDisplaySettings } from '../../../../providers/DisplaySettingsProvider';
import { Module } from '../../components/Module';
import { usePortfolioViewStateSelector } from '../../PortfolioManagement/stateManagement/portfolioViewLayoutSlice.hooks';
import type { OpsPosition } from '../types';
import { OpsEMRChart, type OpsEMRChartProps } from './OpsEMRChart';
import { OpsEMRChartTooltip } from './OpsEMRChartTooltip';

type OpsEMRChartModuleProps = Pick<OpsEMRChartProps<OpsPosition>, 'entities'> & {
  /* ... */
};

export const OpsEMRChartModule = ({ entities }: OpsEMRChartModuleProps) => {
  const theme = useTheme();
  const { homeCurrency } = useDisplaySettings();
  const homeCurrencyInfo = useCurrency(homeCurrency);
  const { marketAccountsByName } = useMarketAccountsContext();
  const marketAccountsByNameRef = useSyncedRef(marketAccountsByName);
  const { marketsByName } = useMarketsContext();
  const marketsByNameRef = useSyncedRef(marketsByName);
  const { opsOverviewShowBy } = usePortfolioViewStateSelector();

  const getGroupBy = useCallback(
    (position: OpsPosition) => {
      return opsOverviewShowBy === 'Market' ? position.market ?? 'Unknown' : position.MarketAccount;
    },
    [opsOverviewShowBy]
  );

  // we only want to show bubbles (accounts/market groupings) where there is at least one derivatives position within that grouping
  const relevantEntities = useMemo(() => {
    if (!entities) {
      return undefined;
    }

    // reduce the positions array into a set of groupings which describes which groupings are to be shown
    const groupingsWithOpenDerivsPosition = new Set<string>();
    for (const position of entities) {
      if (position.AssetType !== ProductTypeEnum.Spot) {
        groupingsWithOpenDerivsPosition.add(getGroupBy(position));
      }
    }

    // go over the entire entities array again and pass through any position which is in a shown group
    return entities.filter(position => groupingsWithOpenDerivsPosition.has(getGroupBy(position)));
  }, [entities, getGroupBy]);

  const getSize = useCallback((position: OpsPosition) => {
    // .abs() because we are showing gross positions (meaning: aggregate in absolute terms)
    return toBigWithDefault(position.Equivalent?.Amount, 0).abs();
  }, []);

  const getLabel = useCallback(
    (position: OpsPosition) => {
      if (opsOverviewShowBy === 'Market') {
        const mkt = position.market ? marketsByNameRef.current.get(position.market) : undefined;
        return mkt?.DisplayName ?? position.market ?? 'Unknown';
      }

      // else market account
      const mktAcc = marketAccountsByNameRef.current.get(position.MarketAccount);
      return mktAcc?.DisplayName ?? position.MarketAccount;
    },
    [opsOverviewShowBy, marketsByNameRef, marketAccountsByNameRef]
  );

  const getEMR = useCallback((position: OpsPosition) => {
    return toBigWithDefault(position.emr, 0);
  }, []);

  const getEquity = useCallback((position: OpsPosition) => {
    return toBigWithDefault(position.talosEquityEquivalent, 0);
  }, []);

  const getEMRTooltip = useCallback(
    (point: Highcharts.Point) => {
      return (
        <OpsEMRChartTooltip point={point} showBy={opsOverviewShowBy} theme={theme} homeCurrency={homeCurrencyInfo} />
      );
    },
    [opsOverviewShowBy, theme, homeCurrencyInfo]
  );

  const moduleLabelSuffix = opsOverviewShowBy === 'MarketAccount' ? 'Market Account' : 'Market';

  return (
    <Module
      data-testid="emr-bubble-chart-module"
      title={
        <HStack gap="spacingSmall">
          <Text>Equity and EMR by Position and {moduleLabelSuffix}</Text>
          <HelpIcon tooltip="Click and drag to zoom. Hold shift, click and drag to pan." />
        </HStack>
      }
      flex="1 1 50%"
      h="100%"
      suffix={
        <IndicatorBadge
          whiteSpace="nowrap"
          size={IndicatorBadgeSizes.Large}
          children={
            <HStack gap="spacingDefault">
              <Circles />
              <Text color="colorTextImportant" fontWeight={500}>
                Gross Position {homeCurrency}
              </Text>
            </HStack>
          }
          variant={IndicatorBadgeVariants.Default}
        />
      }
    >
      <Box p="spacingComfortable" h="100%" w="100%">
        {relevantEntities == null ? (
          <LoaderTalos />
        ) : relevantEntities.length === 0 ? (
          <VStack h="100%">
            <Text>No data found</Text>
          </VStack>
        ) : (
          <OpsEMRChart
            entities={relevantEntities}
            getSize={getSize}
            getEMR={getEMR}
            getEquity={getEquity}
            getGroupBy={getGroupBy}
            getLabel={getLabel}
            getTooltip={getEMRTooltip}
          />
        )}
      </Box>
    </Module>
  );
};

// draws 3 circles that look like this: O o . in svg
const Circles = () => {
  const theme = useTheme();
  return (
    <HStack gap="1px">
      {[12, 10, 8].map(height => (
        /* I don't really know what I'm doing with svgs but this works surprisingly well and looks good :+1: */
        <svg height={height} viewBox={`0 0 ${height} ${height}`} xmlns="http://www.w3.org/2000/svg" key={height}>
          <circle
            cx={height / 2}
            cy={height / 2}
            r={height / 2 - 1}
            stroke={theme.colors.gray['080']}
            fill={theme.colors.gray['070']}
          />
        </svg>
      ))}
    </HStack>
  );
};
