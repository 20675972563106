import {
  ACTION,
  Button,
  ButtonVariants,
  Flex,
  FormControlSizes,
  HStack,
  HedgeControlStatusEnum,
  Icon,
  IconName,
  IndicatorBadge,
  IndicatorBadgeVariants,
  MixpanelEvent,
  ModeEnum,
  Popover,
  PreviewWrapperBox,
  Spinner,
  Tab,
  TabList,
  TabSize,
  Tabs,
  Text,
  useDisclosureWithContext,
  useMixpanel,
  useObservableValue,
  usePopoverState,
} from '@talos/kyoko';

import { Input } from '@talos/kyoko';
import { useCallback, useState } from 'react';
import { map } from 'rxjs';
import { HeaderButton } from '../../containers/Header/styles';
import { useRoleAuth } from '../../hooks';
import { useHedgePositionStatus } from '../../providers/HedgePositionStatusProvider';
import { ConfirmModePreview } from './ConfirmModePreview';
import { SmallHedgePositionStatusBlotterTable } from './SmallHedgePositionStatusBlotterTable';
import { SynchronizeHedgerButton } from './SynchronizeHedgerButton';
import { useNavigateToHedgeRulesSetup } from './useNavigateToHedgeRulesSetup';

const POPOVER_TARGET_STYLE = { height: '100%' };

/**
 * Button which shows the current status of the hedger and allows the user to activate or deactivate the hedger.
 */
export function AutoHedgingButton() {
  const { isAuthorized } = useRoleAuth();
  const mixpanel = useMixpanel();
  const previewView = useDisclosureWithContext<ModeEnum>({
    onClose: () => {
      mixpanel.track(MixpanelEvent.ExitConfirmGlobalAutohedgingCommand);
    },
  });
  const popover = usePopoverState({
    placement: 'bottom-end',
    closeOnClickOutside: true,
    noPaddingAndBorder: true,
    onOpen: () => {
      mixpanel.track(MixpanelEvent.ClickHeaderAutoHedgingButton);
    },
    onClose: () => {
      previewView.close();
    },
  });
  const { close: handleClose, open: handleClickOpen } = popover;
  const { hedgePositionStatusObs, globalHedgeRule } = useHedgePositionStatus();
  const { errorCount, hedgingCount } = useObservableValue(
    () =>
      hedgePositionStatusObs.pipe(
        map(hedgePositionStatus => {
          return {
            errorCount:
              Array.from(hedgePositionStatus.values()).filter(
                h => h.HedgeControlStatus === HedgeControlStatusEnum.Error
              ).length ?? 0,
            hedgingCount:
              Array.from(hedgePositionStatus.values()).filter(
                h => h.HedgeControlStatus === HedgeControlStatusEnum.Hedging
              ).length ?? 0,
          };
        })
      ),
    [hedgePositionStatusObs],
    { errorCount: 0, hedgingCount: 0 }
  );

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const navigateToHedgeRules = useNavigateToHedgeRulesSetup();
  const handleClickSettingsCog = useCallback(() => {
    navigateToHedgeRules();
    handleClose();
  }, [handleClose, navigateToHedgeRules]);

  const hedgeRulesLoading = !globalHedgeRule;
  const hedgerEnabled = Boolean(
    globalHedgeRule && globalHedgeRule?.HedgeControlStatus !== HedgeControlStatusEnum.Disabled
  );
  const modeToBeChangedTo = hedgerEnabled ? ModeEnum.Disabled : ModeEnum.Enabled;

  const [search, setSearch] = useState<string>('');
  const handleOnInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  }, []);

  return (
    <Popover {...popover} targetStyle={POPOVER_TARGET_STYLE}>
      <HedgingButton
        errorCount={errorCount}
        hedgeRulesLoading={hedgeRulesLoading}
        hedgerEnabled={hedgerEnabled}
        onClick={handleClickOpen}
      />

      <PreviewWrapperBox w="830px">
        <ConfirmModePreview key={previewView.openId} previewView={previewView} />
        <Flex
          alignItems="center"
          justifyContent="space-between"
          gap="spacingSmall"
          background="colors.gray.050"
          px="spacingDefault"
          py="spacingSmall"
        >
          <Flex alignItems="inherit" gap="inherit">
            <Text>Position Autohedging</Text>
          </Flex>
        </Flex>

        <Flex w="100%" flexDirection="column" gap="0">
          <Flex>
            <Tabs size={TabSize.Large} selectedIndex={selectedTabIndex} onSelect={setSelectedTabIndex}>
              <TabList>
                <Tab label="All" />
                <Tab
                  label={StatusForIdx[1]}
                  suffix={
                    <IndicatorBadge ml="spacingSmall" children={errorCount} variant={IndicatorBadgeVariants.Negative} />
                  }
                />
                <Tab
                  label={StatusForIdx[2]}
                  suffix={
                    <IndicatorBadge
                      ml="spacingSmall"
                      children={hedgingCount}
                      variant={IndicatorBadgeVariants.Positive}
                    />
                  }
                />
              </TabList>
            </Tabs>

            <Flex
              h="fit-content"
              p="spacingSmall"
              py="spacingDefault"
              gap="spacingDefault"
              justifyContent="flex-end"
              w="100%"
              alignItems="center"
            >
              <SynchronizeHedgerButton />
              <Flex>
                <Input
                  prefix={<Icon icon={IconName.Search} />}
                  size={FormControlSizes.Small}
                  autoFocus
                  value={search}
                  onChange={handleOnInputChange}
                  style={{ borderWidth: 0 }}
                />
              </Flex>

              <Button
                size={FormControlSizes.Tiny}
                variant={ButtonVariants.Default}
                startIcon={IconName.Preferences}
                onClick={handleClickSettingsCog}
                data-testid="autohedging-rules-button"
              >
                Open Rules
              </Button>

              <Button
                data-testid="open-command-preview-button"
                size={FormControlSizes.Small}
                variant={
                  hedgeRulesLoading
                    ? ButtonVariants.Default
                    : modeToBeChangedTo === ModeEnum.Enabled
                    ? ButtonVariants.Positive
                    : ButtonVariants.Negative
                }
                onClick={() => {
                  mixpanel.track(MixpanelEvent.ClickGlobalAutoHedgingActionButton, {
                    type: globalHedgeRule?.HedgeControlStatus,
                  });
                  previewView.open(modeToBeChangedTo);
                }}
                disabled={hedgeRulesLoading || !isAuthorized(ACTION.EDIT_AUTOHEDGING)}
              >
                {hedgeRulesLoading ? (
                  <>
                    Loading <Spinner size={8} />
                  </>
                ) : (
                  <>{modeToBeChangedTo === ModeEnum.Enabled ? 'Activate' : 'Disable'}</>
                )}
              </Button>
            </Flex>
          </Flex>
          <SmallHedgePositionStatusBlotterTable search={search} status={StatusForIdx[selectedTabIndex]} />
        </Flex>
      </PreviewWrapperBox>
    </Popover>
  );
}

const StatusForIdx: Record<number, HedgeControlStatusEnum> = {
  1: HedgeControlStatusEnum.Error,
  2: HedgeControlStatusEnum.Hedging,
};

const HedgingButton = ({
  hedgeRulesLoading,
  errorCount,
  hedgerEnabled,
  onClick,
}: {
  hedgeRulesLoading: boolean;
  errorCount: number;
  hedgerEnabled: boolean;
  onClick: () => void;
}) => {
  return (
    <HeaderButton
      border="left"
      variant={ButtonVariants.Default}
      size={FormControlSizes.Small}
      ghost
      onClick={onClick}
      data-testid="hedging-button"
    >
      <HStack gap="spacingSmall">
        Autohedging
        {hedgeRulesLoading ? (
          <IndicatorBadge
            data-testid="hedging-button-loading"
            children={
              <>
                <Spinner size={8} />
                <Text ml="spacingTiny">Loading</Text>
              </>
            }
            variant={IndicatorBadgeVariants.Default}
          />
        ) : hedgerEnabled ? (
          <IndicatorBadge
            data-testid="hedging-button-enabled"
            children={
              <>
                <Icon icon={IconName.Check} />
                <Text ml="spacingTiny">Enabled</Text>
              </>
            }
            variant={IndicatorBadgeVariants.Positive}
          />
        ) : (
          <IndicatorBadge
            data-testid="hedging-button-disabled"
            children={
              <>
                <Icon icon={IconName.Close} />
                <Text ml="spacingTiny">Disabled</Text>
              </>
            }
            variant={IndicatorBadgeVariants.Negative}
          />
        )}
        {errorCount > 0 && (
          <IndicatorBadge
            data-testid="hedging-button-error"
            children={
              <>
                <Icon icon={IconName.CloseCircle} />
                <Text ml="spacingTiny">{errorCount}</Text>
              </>
            }
            variant={IndicatorBadgeVariants.Negative}
          />
        )}
      </HStack>
    </HeaderButton>
  );
};
