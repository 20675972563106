import { getCredentialAgeWarningPrefixGivenDays, HStack, Text } from '@talos/kyoko';
import { OrgConfigurationKey, useOrgConfiguration } from '../../../providers';

interface CredentialAgeProps {
  ageInDays: number;
}

export function CredentialAge({ ageInDays }: CredentialAgeProps) {
  const { getConfig } = useOrgConfiguration();

  const ageWarningDays = getConfig(OrgConfigurationKey.CredentialAgeWarningDays, 0);
  const ageAlertDays = getConfig(OrgConfigurationKey.CredentialAgeAlertDays, 0);
  const result = getCredentialAgeWarningPrefixGivenDays(ageInDays, ageWarningDays, ageAlertDays);

  return (
    <HStack gap="spacingSmall" justifyContent="flex-start" data-testid="credential-age">
      {result.prefix}
      <Text color={result.color}>
        {ageInDays} day{ageInDays !== 1 ? 's' : ''}
      </Text>
    </HStack>
  );
}
