import type { Leaves } from '@talos/kyoko';
import { toBigWithDefault } from '@talos/kyoko';
import Big from 'big.js';
import { UnifiedPosition } from '../../Blotters/PositionsV3/Unified/UnifiedPosition';

export type OperationsOverviewShowBy = 'Market' | 'MarketAccount';

export interface OpsOverviewFilter {
  Symbols?: string[];
}

export const SHOW_BY_DICT = {
  Market: { showBy: 'Market', label: 'By Market', key: 'market' },
  MarketAccount: { showBy: 'MarketAccount', label: 'By Market Account', key: 'MarketAccount' },
} as const satisfies {
  [key in OperationsOverviewShowBy]: { showBy: key; label: string; key: Leaves<OpsPosition> };
};

export class OpsPosition extends UnifiedPosition {
  marketAccountTotal: Big;
  // override market to always be defined
  override market: string;

  get shareOfMarketAccountTotal(): Big | undefined {
    if (this.marketAccountTotal.eq(0)) {
      return undefined;
    }

    const positionBig = toBigWithDefault(this.Equivalent?.Amount, 0);
    if (positionBig.eq(0)) {
      return Big(0);
    }

    return positionBig.div(this.marketAccountTotal);
  }

  /** Mock value for now, this is wrong too but just wanna get some numbers going any way possible */
  get emr() {
    const equityBig = toBigWithDefault(this.talosEquityEquivalent, 0);
    if (equityBig.eq(0)) {
      return '0';
    }

    const initialMarginBig = toBigWithDefault(this.Equivalent?.initialMargin, 0);
    if (initialMarginBig.eq(0)) {
      return '0';
    }

    return equityBig.div(initialMarginBig).toFixed();
  }

  constructor(up: UnifiedPosition, marketAccountTotal: Big, market: string) {
    super({
      position: up,
      metadata: {
        underlying: up.underlying,
        marketAccountName: up.MarketAccount,
        marketName: up.market,
        marketAccountGroup: up.marketAccountGroup,
      },
    });

    this.marketAccountTotal = marketAccountTotal;
    this.market = market;
  }
}
