import {
  Box,
  Card,
  ControlPrefix,
  DateRangePicker,
  Flex,
  FormControlSizes,
  HStack,
  LookbackOption,
  Text,
  TimePickerPrecision,
  formattedDateForSubscription,
  isLookbackWindow,
  lookbackOptionToDate,
  usePortal,
  type DateRange,
  type LedgerAccountTypeEnum,
  type LookbackWindow,
  type ShortcutOption,
} from '@talos/kyoko';
import { useMemo, useState } from 'react';
import { useTheme } from 'styled-components';
import { AccountLedgerEventsHeader } from './AccountLedgerEventsHeader';
import { ACCOUNT_LEDGER_EVENTS_BLOTTER_BUTTONS_PORTAL, LedgerEventsBlotter } from './LedgerEventsBlotter';
import { useAccountLedgerEvents } from './useAccountLedgerEventsObs';

const SHORTCUT_OPTIONS: ShortcutOption[] = [
  { value: LookbackOption.Past5Min },
  { value: LookbackOption.PastHour },
  { value: LookbackOption.Today },
  { value: LookbackOption.Past24Hours },
  { value: LookbackOption.PastWeek },
  { value: LookbackOption.Past30Days },
];

interface AccountLedgerEventsDetailsProps {
  account: string;
  accountType: LedgerAccountTypeEnum;
  asset?: string;
}

export const AccountLedgerEventsDetails = ({ account, accountType, asset }: AccountLedgerEventsDetailsProps) => {
  const theme = useTheme();

  const [dateRangeValue, setDateRangeValue] = useState<DateRange | LookbackWindow>({
    lookback: LookbackOption.PastWeek,
  });

  const { StartDate, EndDate } = useMemo(() => {
    // Map the dateRangeValue to a StartDate and EndDate
    if (isLookbackWindow(dateRangeValue)) {
      return {
        StartDate: formattedDateForSubscription(lookbackOptionToDate(dateRangeValue.lookback)),
        EndDate: undefined, // No enddate, lookbacks are live
      };
    }

    return {
      StartDate: formattedDateForSubscription(dateRangeValue.from),
      EndDate: formattedDateForSubscription(dateRangeValue.to),
    };
  }, [dateRangeValue]);

  const ledgerEventsObs = useAccountLedgerEvents({
    account,
    accountType,
    asset,
    StartDate,
    EndDate,
    tag: 'AccountLedgerEventsDetails',
  });
  const { setPortalRef } = usePortal(ACCOUNT_LEDGER_EVENTS_BLOTTER_BUTTONS_PORTAL);

  return (
    <Flex h="100%" flexDirection="column" data-testid="account-ledger-events-page-wrapper">
      <AccountLedgerEventsHeader
        account={account}
        accountType={accountType}
        asset={asset}
        startDate={StartDate}
        endDate={EndDate}
        ledgerEventsObs={ledgerEventsObs}
      />

      <Box flex="1" w="100%" h="100%" p="spacingComfortable" overflow="auto" background="colors.gray.main">
        <Card flex="1" h="100%" p="0" minHeight="450px" background="colors.gray['020']">
          <HStack
            justifyContent="space-between"
            borderBottom={`1px solid ${theme.backgroundDivider}`}
            px="spacingMedium"
          >
            <Text color="colorTextImportant" py="spacingComfortable">
              Account Ledger Events
            </Text>
            <HStack gap="spacingDefault">
              {/* Portalized util buttons from the blotter up to the header of the card */}
              <Box ref={setPortalRef} />

              <Box>
                <DateRangePicker
                  value={dateRangeValue}
                  shortcuts={SHORTCUT_OPTIONS}
                  onChange={setDateRangeValue}
                  maxTimePickerPrecision={TimePickerPrecision.MILLISECOND}
                  size={FormControlSizes.Small}
                  prefix={<ControlPrefix>Date Range</ControlPrefix>}
                  clearable={false}
                />
              </Box>
            </HStack>
          </HStack>

          <LedgerEventsBlotter ledgerEventsObs={ledgerEventsObs} accountType={accountType} />
        </Card>
      </Box>
    </Flex>
  );
};
