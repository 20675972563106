import type { InitialFilterClause, ToggleHorizontalOption } from '@talos/kyoko';
import {
  Accordion,
  AccordionBody,
  Box,
  FilterBuilder,
  FilterBuilderClearAllButton,
  FilterBuilderToggleButton,
  FormControlSizes,
  HStack,
  ToggleHorizontal,
  useAccordionFilterBuilder,
} from '@talos/kyoko';
import { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { useAppStateDispatch } from '../../../../providers/AppStateProvider';
import {
  getPortfolioViewActions,
  usePortfolioViewStateSelector,
} from '../../PortfolioManagement/stateManagement/portfolioViewLayoutSlice.hooks';
import { useOperationsOverviewFilters } from '../providers/OperationsOverviewFiltersProvider';
import { useOperationsOverviewInteractions } from '../providers/OperationsOverviewInteractionsProvider';
import { SHOW_BY_DICT, type OperationsOverviewShowBy } from '../types';

const SHOW_BY_OPTIONS: ToggleHorizontalOption<OperationsOverviewShowBy>[] = Object.values(SHOW_BY_DICT).map(entry => ({
  value: entry.showBy,
  displayName: entry.label,
}));

interface ControlPanelProps {
  initialFilterClauses: InitialFilterClause[];
}

const { updateOpsOverviewShowBy } = getPortfolioViewActions();
export function ControlPanel({ initialFilterClauses }: ControlPanelProps) {
  const { filterableProperties, onFilterClausesChanged } = useOperationsOverviewFilters();
  const { setOpenClause } = useOperationsOverviewInteractions();

  const { opsOverviewShowBy: showBy } = usePortfolioViewStateSelector();
  const dispatch = useAppStateDispatch();

  const handleShowByChange = useCallback(
    (type: OperationsOverviewShowBy) => {
      dispatch(updateOpsOverviewShowBy(type));
    },
    [dispatch]
  );

  const { accordion, filterBuilder, openClause } = useAccordionFilterBuilder({
    filterBuilderProps: { initialFilterClauses, properties: filterableProperties, onFilterClausesChanged },
  });

  // Register the openClause function with the interactions provider
  useEffect(() => {
    setOpenClause(() => openClause);
  }, [openClause, setOpenClause]);

  return (
    <ControlPanelWrapper>
      <HStack px="spacingDefault" gap="spacingDefault" justifyContent="space-between" w="100%" py="spacingDefault">
        <ToggleHorizontal
          options={SHOW_BY_OPTIONS}
          value={showBy}
          onChange={handleShowByChange}
          optionDataTestId="show-by-selector"
        />

        <HStack gap="spacingDefault">
          <FilterBuilderClearAllButton
            removeAllFilterClauses={filterBuilder.removeAllFilterClauses}
            disabled={filterBuilder.filterClauses.length === 0}
            size={FormControlSizes.Small}
          />
          <FilterBuilderToggleButton
            filterClauses={filterBuilder.filterClauses}
            isOpen={accordion.isOpen}
            onClick={() => accordion.toggle()}
            size={FormControlSizes.Small}
          />
        </HStack>
      </HStack>
      <Box w="100%">
        <Accordion {...accordion}>
          <AccordionBody p="0" px="spacingMedium" borderTopWhenOpened>
            <Box py="spacingMedium">
              <FilterBuilder {...filterBuilder} />
            </Box>
          </AccordionBody>
        </Accordion>
      </Box>
    </ControlPanelWrapper>
  );
}

const ControlPanelWrapper = styled(Box)`
  border-bottom: 2px solid ${({ theme }) => theme.backgroundBody};
  background: ${({ theme }) => theme.colors.gray.main};
`;
