import {
  logger,
  useDynamicCallback,
  type AgGridSearchSelectDropdownProps,
  type ColumnDef,
  type User,
} from '@talos/kyoko';
import type { ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';
import type { CustomCellEditorProps } from 'ag-grid-react';
import { get, set } from 'lodash';
import { useUserGroups } from 'providers';
import { useEffect, useMemo, useState } from 'react';
import type { UserGroup } from 'types';
import { useUsers } from '../../hooks';
import type { UsersAutocompleteItem } from './shared';

interface UseUsersColumnParams<T> {
  includeGroups?: boolean;
  title?: string;
  headerTooltip?: string;
  /** Whether or not to allow wildcard (*) selections */
  wildcard?: boolean;
  /** What field to read off of the entities in the blotter to get the user. We do not support paths. */
  userNameField: keyof T;
  /** The field to read the User Group field off of, if any */
  userGroupField?: keyof T;
}

export const useUsersColumn = <T,>({
  includeGroups,
  title = 'User',
  headerTooltip,
  wildcard,
  userNameField,
  userGroupField,
}: UseUsersColumnParams<T>) => {
  const { listUserGroups } = useUserGroups();
  const [userGroups, setUserGroups] = useState<UserGroup[]>();
  const usersGroupsByID = useMemo(
    () => new Map((userGroups ?? [])?.map(group => [group.GroupID, group])),
    [userGroups]
  );
  const users = useUsers();

  useEffect(() => {
    let isMounted = true;
    listUserGroups()
      .then(response => {
        if (isMounted) {
          setUserGroups(response.data || []);
        }
      })
      .catch(e => {
        logger.error(e);
        if (isMounted) {
          setUserGroups([]);
        }
      });
    return () => {
      isMounted = false;
    };
  }, [listUserGroups]);

  const cellEditorParams = useDynamicCallback((params: CustomCellEditorProps) => {
    let usersAndMaybeGroups: (User | UserGroup)[] = users;
    if (includeGroups && userGroups) {
      usersAndMaybeGroups = usersAndMaybeGroups.concat(userGroups);
    }

    const items: UsersAutocompleteItem[] = usersAndMaybeGroups.map(u => ({
      label: isGroup(u) ? u.DisplayName : u.Name,
      value: u,
      description: isGroup(u) ? 'Group' : 'User',
    }));

    if (wildcard) {
      items.unshift({
        label: '*',
        value: undefined,
        description: includeGroups ? 'All users and groups' : 'All users',
      });
    }
    return {
      ...params,
      useSearchSelectParams: {
        items,
        getLabel: item => item.label,
        getDescription: item => item.description ?? '',
        getGroup: item => {
          if (item.value == null) {
            return 'Wildcard';
          }
          if (isGroup(item.value)) {
            return 'Groups';
          }
          return 'Users';
        },
      },
      useDropdownParams: {
        dropdownWidth: '300px',
      },
      maxHeight: 400,
      searchPlaceholder: includeGroups ? 'User or user group' : 'User',
    } satisfies AgGridSearchSelectDropdownProps<UsersAutocompleteItem>;
  });

  const valueGetter = useDynamicCallback(({ data }: ValueGetterParams<T>): string | undefined => {
    const userName: string | undefined = get(data, userNameField);
    const userGroupID: string | undefined = userGroupField ? get(data, userGroupField) : undefined;
    return userName ?? userGroupID;
  });

  const valueFormatter = useDynamicCallback(({ value }: ValueFormatterParams<T, string | undefined>) => {
    if (value == null) {
      return '*';
    }

    const maybeUserGroup = usersGroupsByID.get(value);
    if (maybeUserGroup) {
      return maybeUserGroup.DisplayName ?? value;
    }

    // Else its a user. We don't need to resolve any display name,. name is already display-ready.
    return value;
  });

  return useMemo(
    () =>
      ({
        type: 'custom',
        id: 'users',
        title,
        params: {
          headerTooltip,
          colId: 'users',
          width: 140,
          editable: true,
          suppressKeyboardEvent: () => true,
          cellEditor: 'searchSelectDropdown',
          cellEditorPopup: true,
          cellEditorParams,
          valueSetter: ({ newValue, data }: { newValue: UsersAutocompleteItem; data: any }) => {
            delete data[userNameField];
            delete data[userGroupField];

            if (newValue?.value) {
              if (isUser(newValue.value)) {
                set(data, userNameField, newValue.value.Name);
              } else if (isGroup(newValue.value) && userGroupField != null) {
                set(data, userGroupField, newValue.value.GroupID);
              }
            }

            return true;
          },
          valueGetter,
          valueFormatter,
        },
      } satisfies ColumnDef<T>),
    [cellEditorParams, valueGetter, valueFormatter, title, headerTooltip, userNameField, userGroupField]
  );
};

function isGroup(value: User | UserGroup): value is UserGroup {
  return 'GroupID' in value;
}

function isUser(value: User | UserGroup): value is User {
  return 'ID' in value;
}
