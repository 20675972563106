export type OpsBalancesChartDirection = 'positive' | 'negative';

export interface OpsBalancesChartPointCustom {
  key: string;
  nonAbsValue: number;
  absValue: number;
  direction: OpsBalancesChartDirection;
  percentageString: string | undefined;
}

/** Super simple function just to tuck away how ugly it is to grab these custom attributes... */
export function getPointCustomAttrs(point: Highcharts.Point): OpsBalancesChartPointCustom | undefined {
  return point['custom'];
}

export type OpsBalancesChartDimension = 'balances' | 'delta';
