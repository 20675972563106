import { startCase } from 'lodash';
import { useMemo } from 'react';
import { CustomerAddressTypeEnum } from '../../../../types';
import { IconName } from '../../../Icons';
import type { FilterableProperty } from '../types';

/**
 * This hook creates the filter property definition for the CustomerAddressTypeEnum.
 */
export function useCustomerAddressTypesFilter() {
  return useMemo(
    () =>
      ({
        key: 'AddressTypes',
        label: 'Address Type',
        field: 'AddressType',
        icon: IconName.ArrowUpDown,
        control: 'multi-select',
        options: Object.keys(CustomerAddressTypeEnum),
        getOptionLabel: (option: string) => startCase(option),
      } as const satisfies FilterableProperty),
    []
  );
}
