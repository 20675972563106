import { Direction, Grid, type UseBlotterTable } from '@talos/kyoko';
import { KPI, KPITypes } from 'components/KPI';
import styled from 'styled-components';
import type { CareOrderBlotterEntity } from './types';
import { useCareOrderHUDData } from './useCareOrderHUDData';

export interface CareOrderBlotterHUDProps {
  blotterTable: UseBlotterTable<CareOrderBlotterEntity>;
}

export function CareOrderBlotterHUD({ blotterTable }: CareOrderBlotterHUDProps) {
  const {
    totalNotionalBuy,
    notionalBuyExecuted,
    notionalBuyOutstanding,
    totalNotionalSell,
    notionalSellExecuted,
    notionalSellOutstanding,
    isLoading,
  } = useCareOrderHUDData(blotterTable);

  return (
    <Wrapper>
      <KPI
        label="Total Notional Buy"
        isLoading={isLoading}
        tooltip="Including filled and open quantity, but excluding canceled quantity."
        positiveDirection={Direction.Asc}
        value={totalNotionalBuy}
        type={KPITypes.Currency}
        currency="USD"
        isEstimate={true}
      />
      <KPI
        label="Notional Buy Executed"
        isLoading={isLoading}
        positiveDirection={Direction.Asc}
        value={notionalBuyExecuted}
        type={KPITypes.Currency}
        currency="USD"
        isEstimate={true}
      />
      <KPI
        label="Notional Buy Outstanding"
        isLoading={isLoading}
        positiveDirection={Direction.Asc}
        value={notionalBuyOutstanding}
        type={KPITypes.Currency}
        currency="USD"
        isEstimate={true}
      />
      <KPI
        label="Total Notional Sell"
        tooltip="Including filled and open quantity, but excluding canceled quantity."
        isLoading={isLoading}
        positiveDirection={Direction.Asc}
        value={totalNotionalSell}
        type={KPITypes.Currency}
        currency="USD"
        isEstimate={true}
      />
      <KPI
        label="Notional Sell Executed"
        isLoading={isLoading}
        positiveDirection={Direction.Asc}
        value={notionalSellExecuted}
        type={KPITypes.Currency}
        currency="USD"
        isEstimate={true}
      />
      <KPI
        label="Notional Sell Outstanding"
        isLoading={isLoading}
        positiveDirection={Direction.Asc}
        value={notionalSellOutstanding}
        type={KPITypes.Currency}
        currency="USD"
        isEstimate={true}
      />
    </Wrapper>
  );
}

const Wrapper = styled(Grid)`
  overflow-x: hidden;
  gap: calc(var(--spacing2) * 1px);
  margin-top: calc(var(--spacing2) * 1px);
  margin-bottom: calc(var(--spacing2) * 1px);
  min-height: 5.125rem;
  flex-wrap: wrap;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));

  @media screen and (max-width: 1000px) {
    grid-template-columns: repeat(3, minmax(180px, 1fr));
  }

  @media screen and (max-width: 600px) {
    grid-template-columns: repeat(2, minmax(180px, 1fr));
  }
`;
