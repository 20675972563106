import {
  ThresholdTypeEnum,
  type Column,
  type ColumnDef,
  type SubAccountNameColumnParams,
  type SubAccountWindowLimit,
} from '@talos/kyoko';
import type { EditableCallbackParams } from 'ag-grid-community';
import { useMemo } from 'react';
import { useRollupTreeRef } from '../../../../hooks';
import { useUsersColumn } from '../../../../utils/columns';
import { useAssetColumn } from '../../../../utils/columns/useAssetColumn';
import {
  LIMIT_CURRENCIES_MAP,
  assetColumnCustomDataModifier,
  customSubAccountLimitComparator,
  subAccountLimitsMatchAssetFilter,
} from '../utils';

export type UseSubAccountWindowLimitsColumns = Pick<
  SubAccountNameColumnParams,
  'subAccountOptionsOverride' | 'initialSelectedItem' | 'getDropdownGroup'
>;

export const useColumns = ({
  subAccountOptionsOverride,
  initialSelectedItem,
  getDropdownGroup,
}: UseSubAccountWindowLimitsColumns) => {
  const assetColumn = useAssetColumn<SubAccountWindowLimit>({
    assetKey: 'MatchAsset',
    comparator: customSubAccountLimitComparator,
    customDataModificationOnChange: assetColumnCustomDataModifier,
    assetFilter: subAccountLimitsMatchAssetFilter,
  });

  const usersColumn = useUsersColumn<SubAccountWindowLimit>({
    wildcard: true,
    userNameField: 'MatchUser',
  });

  const rollupTreeRef = useRollupTreeRef();

  return useMemo<Column[]>(
    () =>
      [
        { type: 'mode', field: 'Mode', title: 'Enabled', width: 80 },
        { type: 'text', field: 'LimitID', width: 110 },
        assetColumn,
        {
          type: 'subAccountName',
          field: 'SubAccount',
          title: 'Sub Account / Rollup',
          editable: true,
          params: {
            wildcard: true,
            subAccountOptionsOverride,
            initialSelectedItem,
            getDropdownGroup,
            rollupTree: rollupTreeRef,
          } satisfies SubAccountNameColumnParams,
        },
        usersColumn,
        { type: 'positionDirection', field: 'Direction', editable: true, width: 100 },
        {
          type: 'number',
          field: 'ThresholdValue',
          title: 'Reject Threshold',
          editable: true,
        },
        {
          type: 'thresholdAsset',
          field: 'ThresholdAsset',
          title: 'Threshold Asset',
          editable: (params: EditableCallbackParams<Partial<SubAccountWindowLimit>>) =>
            params.data?.ThresholdType !== ThresholdTypeEnum.NewOrderCount,
          width: 140,
          params: {
            limitAssetsBySymbol: LIMIT_CURRENCIES_MAP,
            keyOfMatchAssetToIncludeInOptions: 'MatchAsset' satisfies keyof SubAccountWindowLimit,
          },
        },
        {
          type: 'thresholdType',
          field: 'ThresholdType',
          title: 'Threshold Type',
          editable: true,
        },
        {
          type: 'windowDuration',
          field: 'WindowDuration',
          title: 'Window Duration',
          editable: true,
        },
        {
          type: 'text',
          field: 'Description',
          description: 'Give your limit a description to distinguish it from other limits.',
          width: 120,
          editable: true,
        },
        { type: 'filler', id: 'filler' },
        { type: 'remove', id: 'remove', pinned: 'right' },
      ] satisfies ColumnDef<SubAccountWindowLimit>[],
    [subAccountOptionsOverride, initialSelectedItem, getDropdownGroup, assetColumn, usersColumn, rollupTreeRef]
  );
};
