import {
  BlotterTable,
  BlotterTableExtrasMenu,
  BlotterTableFilters,
  Button,
  ButtonVariants,
  FormControlSizes,
  IconButton,
  IconName,
  columnTypes,
  removeEmptyFilters,
  useAccordionFilterBuilder,
  useBlotterTableExtrasMenu,
  useConstant,
  useDateRangeFilter,
  usePersistedBlotterTable,
  useWSFilterPipe,
  useWsBlotterTable,
  type AgGridIconButtonProps,
  type Column,
  type FilterClause,
  type FilterableProperty,
  type ReconMismatch,
} from '@talos/kyoko';
import { isEqual, pick } from 'lodash';
import { useCallback, useMemo } from 'react';
import { v1 as uuid } from 'uuid';
import { useMarketTabs } from '../../../../providers/MarketTabsProvider';
import { useResolveMismatchMenu } from './ReconMismatchMenu';
import {
  clientSideReconMismatchFilterCheck,
  useReconMismatchesFilter,
  type BlotterTableReconMismatchesFilter,
} from './useReconMismatchesFilter';

const getReconMismatchKey = (item: ReconMismatch) => item.rowID;

interface ReconMismatchesBlotterParams {
  blotterID: string;
  tabLabel?: string;
  defaultFilter: BlotterTableReconMismatchesFilter;
  defaultColumns: Column[];

  /** filter and columns are current state to be cloned to new tab */
  onCloneTab: (filter: BlotterTableReconMismatchesFilter, columns: Column[]) => void;

  /** When mounting should the filter accordian be expanded */
  initialIsOpen?: boolean;
}

export function ReconMismatchesBlotter({
  blotterID,
  tabLabel,
  defaultFilter,
  defaultColumns,
  onCloneTab,
  initialIsOpen,
}: ReconMismatchesBlotterParams) {
  const persisted = usePersistedBlotterTable(blotterID, {
    columns: defaultColumns,
    filter: defaultFilter,
    sort: '+MarketAccount',
  });

  const filtering = useReconMismatchesFilter({
    initialFilter: persisted.initialFilter,
    saveFilter: persisted.onFilterChanged,
  });

  const { changeFilter, filterableProperties, initialFilterClauses } = filtering;

  const handleFilterClausesChanged = useCallback(
    (filterClausesByPropertyKey: Map<string, FilterClause>, propertiesByKey: Map<string, FilterableProperty>) => {
      changeFilter(curr => {
        const newFilter = removeEmptyFilters({
          ...curr,
          ...(Object.fromEntries(
            [...propertiesByKey.keys()].map(key => [key, filterClausesByPropertyKey.get(key)?.selections])
          ) as unknown as BlotterTableReconMismatchesFilter),
        });
        if (isEqual(curr, newFilter)) {
          return curr;
        }
        return newFilter;
      });
    },
    [changeFilter]
  );

  const dateRangeFilter = useDateRangeFilter(filtering.filter, changeFilter);

  const blotterTag = useConstant(`RECON_MISMATCHES_${uuid()}`);

  const filterBuilderAccordion = useAccordionFilterBuilder({
    accordionProps: { initialOpen: initialIsOpen },
    filterBuilderProps: {
      initialFilterClauses,
      properties: filterableProperties,
      onFilterClausesChanged: handleFilterClausesChanged,
    },
  });

  const { getContextMenuItems } = useResolveMismatchMenu({
    openClause: filterBuilderAccordion.openClause,
    filterableProperties: filterableProperties,
  });

  const filterFunc = useCallback(
    (mismatch: ReconMismatch) => clientSideReconMismatchFilterCheck(mismatch, filtering.filter),
    [filtering.filter]
  );

  const filterPipe = useWSFilterPipe({ getUniqueKey: getReconMismatchKey, filterFunc });

  const autoGroupColumnDef = useMemo(
    () =>
      columnTypes.group({
        type: 'group',
        id: 'CustomGroup',
        title: 'Market Account',
        editable: false,
        hide: false,
        suppressColumnsToolPanel: false,
        params: {
          suppressCount: true,
          // Can't find any type for this checkbox callback
          checkbox: () => {
            return true;
          },
        },
      }),
    []
  );

  const { prepareReconDetailsMarketTab } = useMarketTabs();
  const columnsWithButtons: Column[] = useMemo(() => {
    return [
      ...persisted.columns,
      {
        type: 'iconButton',
        id: 'mismatch-details',
        pinned: 'right',
        lockVisible: true,
        lockPinned: true,
        width: 60,
        params: {
          icon: IconName.Deepdive,
          onClick: params => {
            if (params.node.data?.ID) {
              prepareReconDetailsMarketTab([params.node.data.ID]);
            }
          },
          hide: params => {
            return !params.node.group;
          },
        } satisfies AgGridIconButtonProps<ReconMismatch>,
      },
    ];
  }, [persisted.columns, prepareReconDetailsMarketTab]);

  const blotterTable = useWsBlotterTable({
    initialRequest: {
      name: 'ReconMismatch',
      tag: blotterTag,
    },
    filter: onlyServerFilterKeys(filtering.filter),
    initialSort: persisted.initialSort,
    pipe: filterPipe,
    rowID: 'rowID' satisfies keyof ReconMismatch,
    columns: columnsWithButtons,
    onColumnsChanged: persisted.onColumnsChanged,
    onSortChanged: persisted.onSortChanged,
    gridOptions: {
      getContextMenuItems,
      groupDisplayType: 'singleColumn',
      rowSelection: {
        mode: 'multiRow',
        checkboxes: false,
        headerCheckbox: false,
        enableClickSelection: true,
        groupSelects: 'filteredDescendants',
      },
      groupRemoveLowestSingleChildren: true,
      showOpenedGroup: true,
      autoGroupColumnDef,
    },
  });

  const { expandAllGroups, collapseAllGroups } = blotterTable;

  const handleCloneTab = useCallback(() => {
    onCloneTab(filtering.filter, blotterTable.getColumns());
  }, [blotterTable, filtering.filter, onCloneTab]);

  const extrasMenuPopover = useBlotterTableExtrasMenu();

  return (
    <>
      <BlotterTableFilters
        {...filterBuilderAccordion}
        {...blotterTable.blotterTableFiltersProps}
        {...dateRangeFilter}
        prefix={
          <>
            <IconButton
              icon={IconName.ListExpand}
              size={FormControlSizes.Small}
              variant={ButtonVariants.Default}
              onClick={expandAllGroups}
              data-testid="recon-mismatches-blotter-expand-all-button"
            />
            <IconButton
              icon={IconName.ListCollapse}
              size={FormControlSizes.Small}
              variant={ButtonVariants.Default}
              onClick={collapseAllGroups}
            />
          </>
        }
        suffix={
          <BlotterTableExtrasMenu {...extrasMenuPopover}>
            <Button
              startIcon={IconName.Duplicate}
              variant={ButtonVariants.Default}
              size={FormControlSizes.Small}
              onClick={handleCloneTab}
            >
              Clone Tab
            </Button>
          </BlotterTableExtrasMenu>
        }
      />
      <BlotterTable {...blotterTable} />
    </>
  );
}

function onlyServerFilterKeys(filter: BlotterTableReconMismatchesFilter | undefined) {
  if (!filter) {
    return filter;
  }
  return pick(filter, ['StartDate', 'EndDate', 'MarketAccounts', 'Symbols']);
}
