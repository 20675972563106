import type {
  BlotterTableFilter,
  BlotterTableFiltersProps,
  FilterableProperty,
  UseFilterBuilderProps,
} from '@talos/kyoko';
import { usePortfolioViewStateSelector } from 'containers/Portfolio/PortfolioManagement/stateManagement/portfolioViewLayoutSlice.hooks';
import { useSubAccountRelationMap } from 'hooks/useSubAccountRelationMap';
import { useSubAccounts } from 'providers/SubAccountsContext';
import { createContext, useContext, useMemo, type PropsWithChildren } from 'react';

/** The keys to pass into the blotter filter function,
 * which will change based on whatever is directing the Context Blotter Filter ReactContext
 */
export type ContextBlotterFilterState = {
  // primary filters
  /** Selected Portfolio (SubAccount or Rollup) name */
  SelectedPortfolio?: string;
  /** Selected Portfolio and related children */
  SubAccounts?: string[];
  MarketAccounts?: string[];
  DateRange?: BlotterTableFiltersProps['dateRange'];

  // minor toggles and menu options
  showZeroBalances?: boolean;
  includeCash?: boolean;
};

export type ContextBlotterFilterMergeArg = {
  initialFilter?: ContextBlotterFilterState | undefined;
  filterBuilderProps?: Partial<UseFilterBuilderProps>;
  filterableProperties?: FilterableProperty[];
  changeFilter?: (action: React.SetStateAction<BlotterTableFilter>) => void;
  setFilter?: (filter: BlotterTableFilter) => void;
};

type ContextBlotterFilterValue = {
  /** The current filtering state for the filters in this context
   * - if there is no filtering to be applied, filter is set to undefined, but we want the
   * object to always exist so we can test for its presence
   */
  filter:
    | {
        keys: Array<keyof ContextBlotterFilterState>;
        state: ContextBlotterFilterState;
      }
    | undefined;
};

const ContextBlotterFilterContext = createContext<ContextBlotterFilterValue | undefined>(undefined);

export type ContextBlotterFilterProviderProps = {
  filterKeys?: Array<keyof ContextBlotterFilterState>;
};

/** External Blotter Filter Context to (optionally) provide a filtering context to blotters within it it
 * - this initial implementation is related to the Portfolio Management System-related Redux selectors, but
 * it could be extended to other Redux selector contexts in the future
 * - if no filterKey is passed, no filtering will be applied
 */
export const ContextBlotterFilterProvider = ({
  children,
  filterKeys,
}: PropsWithChildren<ContextBlotterFilterProviderProps>) => {
  const { subAccountsByID } = useSubAccounts();
  const subAccountRelationMap = useSubAccountRelationMap();
  const { selectedPortfolioId, selectedMarketAccountIds, dateRange, showZeroBalances, includeCash } =
    usePortfolioViewStateSelector();

  // Build the subAccountList based on the subAccount and it's children
  const selectedSubAccountInfo = useMemo(() => {
    if (!filterKeys?.includes('SubAccounts')) {
      return undefined;
    }
    if (!selectedPortfolioId || !subAccountRelationMap.get(selectedPortfolioId)) {
      return undefined;
    }
    const subAccountRelations = subAccountRelationMap.get(selectedPortfolioId);
    const subAccountIds = [selectedPortfolioId, ...(subAccountRelations?.children ?? [])];
    return {
      selectedSubAccount: subAccountsByID.get(selectedPortfolioId)?.Name ?? '',
      subAccountList: subAccountIds.map(id => subAccountsByID.get(id)?.Name ?? ''),
    };
  }, [filterKeys, selectedPortfolioId, subAccountRelationMap, subAccountsByID]);

  const additionalFilterState = useMemo(() => {
    if (!filterKeys) {
      return undefined;
    }

    // Build the filter state based on the filterKeys
    const filter = filterKeys.reduce((acc, key) => {
      let mergeValue: Partial<ContextBlotterFilterState> = {};
      switch (key) {
        case 'SubAccounts':
          mergeValue = {
            SelectedPortfolio: selectedSubAccountInfo?.selectedSubAccount,
            SubAccounts: selectedSubAccountInfo?.subAccountList,
          };
          break;
        case 'MarketAccounts':
          mergeValue = {
            MarketAccounts: selectedMarketAccountIds,
          };
          break;
        case 'DateRange':
          mergeValue = {
            DateRange: dateRange,
          };
          break;
        case 'showZeroBalances':
          mergeValue = {
            showZeroBalances,
          };
          break;
        case 'includeCash':
          mergeValue = {
            includeCash,
          };
          break;
      }
      return {
        ...acc,
        ...mergeValue,
      };
    }, {} as ContextBlotterFilterState);
    return filter;
  }, [
    dateRange,
    filterKeys,
    includeCash,
    selectedMarketAccountIds,
    selectedSubAccountInfo?.selectedSubAccount,
    selectedSubAccountInfo?.subAccountList,
    showZeroBalances,
  ]);

  const contextValue = useMemo((): ContextBlotterFilterValue | undefined => {
    if (!filterKeys || !additionalFilterState) {
      return {
        filter: undefined,
      };
    }
    return {
      filter: {
        keys: filterKeys,
        state: additionalFilterState,
      },
    };
  }, [additionalFilterState, filterKeys]);

  return <ContextBlotterFilterContext.Provider value={contextValue}>{children}</ContextBlotterFilterContext.Provider>;
};

export function useContextBlotterFilterProvider() {
  const context = useContext(ContextBlotterFilterContext);
  if (!context) {
    throw new Error('useContextBlotterFilterProvider must be used within a ContextBlotterFilterProvider');
  }
  return context;
}
