import type { MarketSecurityStatus } from '@talos/kyoko';
import { get } from 'lodash';
import { useMemo } from 'react';
import type { AvailabilityCondition } from '../types';

type CapabilityKey = keyof NonNullable<MarketSecurityStatus['Capabilities']>;

interface UseMSSCapabilityEnabledConditionParams {
  capability: CapabilityKey;
  mssByMarketAccount: Map<string, MarketSecurityStatus> | undefined;
}

/**
 * A AvailabilityCondition which asserts that the MarketSecurityStatus.[Capability] is true.
 *
 * If no MarketSecurityStatus has been resolved and attached to the passed record, returns true.
 * If there is no Capabilities struct on the successfully resolved MarketSecurityStatus record, returns false.
 */
export const useMSSCapabilityEnabledCondition = ({
  capability,
  mssByMarketAccount,
}: UseMSSCapabilityEnabledConditionParams): AvailabilityCondition => {
  return useMemo(
    () =>
      ({
        id: `mss-${capability}-enabled`,
        ready: mssByMarketAccount != null,
        condition: (market, marketAccount) => {
          if (!mssByMarketAccount) {
            return { available: true };
          }

          if (!marketAccount) {
            // MarketSecurityStatuses are only applicable for market accounts
            return { available: true };
          }

          const status = mssByMarketAccount.get(marketAccount.Name);

          // Not all items will have their own MSS resolvable. In these cases, just allow them to proceed.
          if (!status) {
            return { available: true };
          }

          const capabilitiesStruct = status.Capabilities;
          if (!capabilitiesStruct) {
            return {
              available: false,
              failedReason: `Capability ${capability} is not enabled for this Market Account`,
            };
          }

          const capabilityOk = get(capabilitiesStruct, capability) === true;

          return capabilityOk
            ? { available: true }
            : {
                available: false,
                failedReason: `Capability ${capability} is not enabled for this Market Account`,
              };
        },
      } satisfies AvailabilityCondition),
    [capability, mssByMarketAccount]
  );
};
