import {
  EMPTY_OBJECT,
  FilterClauseType,
  IconName,
  PricingModeEnum,
  QUOTE_STATUS_MAPPING,
  QuoteStatusEnum,
  cleanupInitialFilterDateRange,
  filterExistsAndExcludes,
  removeEmptyFilters,
  useDateRangeFilter,
  useSymbolsFilter,
  type BlotterTableClientLocalFilter,
  type BlotterTableFilter,
  type BlotterTableFiltersProps,
  type DateRangeFilter,
  type FilterClause,
  type FilterableProperty,
  type Quote,
  type UseFilterBuilderProps,
  type UsePersistedBlotterTable,
} from '@talos/kyoko';
import { compact, isEqual, keys, startCase, uniq, values } from 'lodash';
import { useCallback, useMemo, useState, type SetStateAction } from 'react';
import { useIDFilter, useMarketsFilter, useSidesFilter, useSubAccountsFilter, useUsersFilter } from '../../../hooks';
import { useSubAccounts } from '../../../providers';

export interface QuotesTableFilter extends DateRangeFilter {
  Markets?: string[];
  Sides?: string[];
  Symbols?: string[];
  /** These are the quote status texts we are filtering by on the client side */
  _statuses?: string[];
  /** These are the quote statuses that we are filtering by on the server */
  Statuses?: QuoteStatusEnum[];
  Users?: string[];
  SubAccounts?: string[];
  TradedSides?: string[];
  RFQID?: string;
  ParentRFQID?: string;
  PricingMode?: string[];
}

export interface UseQuotesFilterParams<TData extends Quote> {
  persistedBlotterTable: UsePersistedBlotterTable<TData>;
}
export function useQuotesFilter<TData extends Quote>({ persistedBlotterTable }: UseQuotesFilterParams<TData>) {
  const { subAccountsEnabled } = useSubAccounts();
  const { onFilterChanged: saveFilter } = persistedBlotterTable;
  const [initialFilter] = useState(() => persistedBlotterTable.initialFilter);
  const [filter, setFilter] = useState(() => cleanupInitialFilterDateRange(initialFilter));

  const changeFilter = useCallback(
    (action: SetStateAction<BlotterTableFilter>) => {
      const priorFilter = filter;
      const newFilter = action instanceof Function ? action(filter) : action;

      if (!isEqual(priorFilter, newFilter)) {
        setFilter(newFilter);
        saveFilter(newFilter);
      }
    },
    [filter, saveFilter]
  );

  const clientSideFilter = useCallback<BlotterTableClientLocalFilter<TData>>(
    row => {
      const data = row.data;

      if (filterExistsAndExcludes(filter, 'Sides', data, 'Side')) {
        return false;
      }
      if (filterExistsAndExcludes(filter, 'Symbols', data, 'Symbol')) {
        return false;
      }
      if (filterExistsAndExcludes(filter, 'Statuses', data, 'QuoteStatus')) {
        return false;
      }
      if (filterExistsAndExcludes(filter, 'Users', data, 'User')) {
        return false;
      }
      if (filterExistsAndExcludes(filter, 'SubAccounts', data, 'SubAccount')) {
        return false;
      }
      if (filterExistsAndExcludes(filter, 'PricingMode', data, 'PricingMode')) {
        return false;
      }
      if (filterExistsAndExcludes(filter, 'TradedSide', data, 'TradedSide')) {
        return false;
      }
      const marketsFilters: string[] = filter.Markets ?? [];
      // Because quote.`Markets` and quote.`SubAccounts` are arrays of non-primitives, we can't use filterExistsAndExcludes here
      if (
        marketsFilters &&
        marketsFilters.length > 0 &&
        !data?.Markets.some(market => marketsFilters.some(m => market.Market === m))
      ) {
        return false;
      }
      const filteredSubAccounts: string[] = filter.SubAccounts ?? [];
      if (
        filteredSubAccounts &&
        filteredSubAccounts.length > 0 &&
        !(
          (data?.SubAccount && filteredSubAccounts.includes(data.SubAccount)) ||
          data?.Allocation?.Allocations.some(alloc => filteredSubAccounts.includes(alloc.SubAccount))
        )
      ) {
        return false;
      }

      return true;
    },
    [filter]
  );

  const subAccountsFilter = useSubAccountsFilter(EMPTY_OBJECT);
  const marketsFilter = useMarketsFilter({ onlyTradingMarkets: true });
  const usersFilter = useUsersFilter();
  const symbolFilter = useSymbolsFilter();
  const sidesFilter = useSidesFilter();
  const idFilter = useIDFilter();

  const filterableProperties = useMemo(
    () =>
      compact<FilterableProperty<keyof QuotesTableFilter>>(
        compact([
          marketsFilter,
          subAccountsEnabled ? subAccountsFilter : null,
          symbolFilter,
          usersFilter,
          sidesFilter,
          { ...sidesFilter, key: 'TradedSides', label: 'Traded Side' },
          {
            key: '_statuses',
            label: 'Status',
            icon: IconName.CheckCircle,
            options: [...keys(QUOTE_STATUS_MAPPING), ...keys(QuoteStatusEnum)],
            getOptionLabel: (option: string) => startCase(option),
          },
          { ...idFilter, key: 'RFQID', label: 'RFQ ID' },
          { ...idFilter, key: 'ParentRFQID', label: 'Parent RFQ ID' },
          {
            key: 'PricingMode',
            label: 'Pricing Mode',
            icon: IconName.CurrencyDollar,
            options: values(PricingModeEnum),
            getOptionLabel: (option: string) => startCase(option),
          },
        ])
      ),
    [marketsFilter, subAccountsEnabled, subAccountsFilter, symbolFilter, usersFilter, sidesFilter, idFilter]
  );
  const initialFilterClauses = useMemo(() => {
    const clauses: FilterClause[] = [];
    if (filter) {
      (keys(filter) as (keyof QuotesTableFilter)[]).forEach((key: keyof QuotesTableFilter) => {
        switch (key) {
          case '_start':
          case 'StartDate':
          case 'EndDate':
          case 'TimestampField':
            return;
          default:
            clauses.push({
              key: key,
              type: FilterClauseType.INCLUSIVE,
              selections: filter[key] as string[],
            });
        }
      });
    }
    return clauses;
  }, [filter]);

  const handleFilterClausesChanged = useCallback(
    (filterClausesByPropertyKey: Map<string, FilterClause>, propertiesByKey: Map<string, FilterableProperty>) => {
      changeFilter(curr => {
        const statusSelections = (filterClausesByPropertyKey.get('_statuses')?.selections || []) as
          | Array<keyof typeof QUOTE_STATUS_MAPPING>
          | undefined;

        const newFilter: QuotesTableFilter = removeEmptyFilters<QuotesTableFilter>({
          ...curr,
          ...(Object.fromEntries(
            [...propertiesByKey.keys()].map(key => [key, filterClausesByPropertyKey.get(key)?.selections])
          ) satisfies QuotesTableFilter),
          _statuses: statusSelections,
          Statuses: (statusSelections || []).reduce(
            (prev, curr) => uniq(prev.concat(QUOTE_STATUS_MAPPING[curr] ?? curr)),
            [] as QuoteStatusEnum[]
          ),
          // below are exact search and only one value is supported by backend
          RFQID: filterClausesByPropertyKey.get('RFQID')?.selections?.[0],
          ParentRFQID: filterClausesByPropertyKey.get('ParentRFQID')?.selections?.[0],
        });
        if (isEqual(curr, newFilter)) {
          return curr;
        }
        return newFilter;
      });
    },
    [changeFilter]
  );
  const dateRangeFilter = useDateRangeFilter(filter, changeFilter);

  const filterBuilderProps = useMemo(
    () => ({
      initialFilterClauses,
      properties: filterableProperties,
      onFilterClausesChanged: handleFilterClausesChanged,
    }),
    [filterableProperties, handleFilterClausesChanged, initialFilterClauses]
  ) satisfies UseFilterBuilderProps;
  const blotterTableFilterProps = useMemo(
    () => ({
      ...dateRangeFilter,
    }),
    [dateRangeFilter]
  ) satisfies Partial<BlotterTableFiltersProps>;
  return {
    initialFilter,
    filter,
    clientSideFilter,
    changeFilter,
    // shortcut to spread properties into useAccordionFilterBuilder.filterBuilderProps
    filterBuilderProps,
    // shortcut to spread props into the BlotterTableFilters component
    blotterTableFilterProps,
  };
}

export function colIDToFilterBuilderKey(id: string): keyof QuotesTableFilter | undefined {
  switch (id as keyof Quote) {
    case 'Symbol':
      return 'Symbols';
    case 'SubAccount':
      return 'SubAccounts';
    case 'Markets':
      return 'Markets';
    case 'Side':
      return 'Sides';
    case 'TradedSide':
      return 'TradedSides';
    case 'QuoteStatus':
      return '_statuses';
    case 'User':
      return 'Users';
    case 'RFQID':
      return 'RFQID';
    case 'ParentRFQID':
      return 'ParentRFQID';
  }
  return undefined;
}
