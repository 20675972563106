import {
  EMPTY_OBJECT,
  FilterClauseType,
  IconName,
  PricingModeEnum,
  TradeStatusEnum,
  cleanupInitialFilterDateRange,
  filterExistsAndExcludes,
  removeEmptyFilters,
  useDateRangeFilter,
  useSecuritiesContext,
  type BlotterTableClientLocalFilter,
  type BlotterTableFilter,
  type BlotterTableFiltersProps,
  type CustomerTrade,
  type DateRangeFilter,
  type FilterClause,
  type FilterableProperty,
  type UseFilterBuilderProps,
  type UsePersistedBlotterTable,
} from '@talos/kyoko';
import { useCounterpartyFilter } from 'hooks/filters/useCounterpartyFilter';
import { useIDFilter } from 'hooks/filters/useIDFilter';
import { useSidesFilter } from 'hooks/filters/useSidesFilter';
import { useSubAccountsFilter } from 'hooks/filters/useSubAccountsFilter';
import { useUsersFilter } from 'hooks/filters/useUsersFilter';
import { useCustomerUsers } from 'hooks/useCustomer';
import { isEqual, keys, startCase, values } from 'lodash';
import { useSubAccounts } from 'providers/SubAccountsContext';
import { useCallback, useMemo, useState, type SetStateAction } from 'react';
import { useMarketAccountsFilter } from '../../../hooks/filters/useMarketAccountsFilter';

export interface CustomerTradeFilter extends DateRangeFilter {
  Sides?: string[];
  Counterparty?: string[];
  MarketAccounts?: string[];
  CustomerUsers?: string[];
  Statuses?: string[];
  SubAccounts?: string[];
  Symbols?: string[];
  Users?: string[];
  HideApiCalls?: boolean;
  OrderID?: string;
  RFQID?: string;
  TradeID?: string;
  PricingMode?: string[];
}
export interface UseCustomerTradeFilterParams<TData extends CustomerTrade> {
  persistedBlotterTable: UsePersistedBlotterTable<TData>;
}
export function useCustomerTradeFilter<TData extends CustomerTrade>({
  persistedBlotterTable,
}: UseCustomerTradeFilterParams<TData>) {
  const { onFilterChanged: saveFilter } = persistedBlotterTable;
  const [initialFilter] = useState(() => cleanupInitialFilterDateRange(persistedBlotterTable.initialFilter));
  const [filter, setFilter] = useState<CustomerTradeFilter>((initialFilter as CustomerTradeFilter) || {});
  const { subAccountsEnabled } = useSubAccounts();
  const { securitiesList, securitiesBySymbol } = useSecuritiesContext();

  const changeFilter = useCallback(
    (action: SetStateAction<BlotterTableFilter>) => {
      const priorFilter = filter;
      const newFilter = action instanceof Function ? action(filter) : action;
      if (!isEqual(priorFilter, newFilter)) {
        setFilter(newFilter);
        saveFilter(newFilter);
      }
    },
    [filter, saveFilter]
  );

  const clientSideFilter = useCallback<BlotterTableClientLocalFilter<CustomerTrade>>(
    row => {
      const data = row.data;

      if (filterExistsAndExcludes(filter, 'MarketAccounts', data, 'MarketAccount')) {
        return false;
      }
      if (filterExistsAndExcludes(filter, 'Sides', data, 'Side')) {
        return false;
      }
      if (filterExistsAndExcludes(filter, 'Statuses', data, 'TradeStatus')) {
        return false;
      }
      if (filterExistsAndExcludes(filter, 'Users', data, 'User')) {
        return false;
      }
      if (filterExistsAndExcludes(filter, 'CustomerUsers', data, 'CustomerUser')) {
        return false;
      }
      if (filterExistsAndExcludes(filter, 'PricingMode', data, 'PricingMode')) {
        return false;
      }
      if (
        filter.SubAccounts &&
        filter.SubAccounts.length > 0 &&
        !(data?.SubAccount && filter.SubAccounts.includes(data.SubAccount))
      ) {
        return false;
      }
      return true;
    },
    [filter]
  );

  const subAccountsFilter = useSubAccountsFilter(EMPTY_OBJECT);
  const usersFilter = useUsersFilter();
  const counterpartyFilter = useCounterpartyFilter();
  const customerUsers = useCustomerUsers();
  const marketAccountsFilter = useMarketAccountsFilter();
  const sidesFilter = useSidesFilter();
  const idFilter = useIDFilter();

  const filterableProperties: FilterableProperty[] = useMemo(
    () =>
      [
        { ...counterpartyFilter, label: 'Customer' },
        { ...marketAccountsFilter, label: 'Customer Market Account' },
        sidesFilter,
        {
          key: 'Statuses',
          label: 'Status',
          icon: IconName.CheckCircle,
          options: values(TradeStatusEnum),
          getOptionLabel: (option: string) => option,
        },
        subAccountsEnabled ? subAccountsFilter : undefined,
        {
          key: 'Symbols',
          label: 'Symbol',
          icon: IconName.CurrencyDollar,
          options: securitiesList.map(sec => sec.Symbol),
          getOptionLabel: (option: string) => securitiesBySymbol.get(option)?.DisplaySymbol || '',
        },
        usersFilter,
        {
          key: 'CustomerUsers',
          label: 'Customer User',
          icon: IconName.User,
          options: customerUsers?.map(c => c.Email) ?? [],
          getOptionLabel: (option: string) => option,
        },
        {
          key: 'PricingMode',
          label: 'Pricing Mode',
          icon: IconName.CurrencyDollar,
          options: values(PricingModeEnum),
          getOptionLabel: startCase,
        },
        { ...idFilter, key: 'OrderID', label: 'Order ID' },
        { ...idFilter, key: 'RFQID', label: 'RFQ ID' },
        { ...idFilter, key: 'TradeID', label: 'Trade ID' },
      ].compact(),
    [
      securitiesBySymbol,
      securitiesList,
      subAccountsEnabled,
      subAccountsFilter,
      usersFilter,
      sidesFilter,
      idFilter,
      customerUsers,
      counterpartyFilter,
      marketAccountsFilter,
    ]
  );

  const initialFilterClauses = useMemo(() => {
    const clauses: FilterClause[] = [];
    if (filter) {
      (keys(filter) as (keyof CustomerTradeFilter)[]).forEach((key: keyof CustomerTradeFilter) => {
        switch (key) {
          case '_start':
          case 'StartDate':
          case 'EndDate':
          case 'Statuses':
            return;
          default:
            clauses.push({
              key: key,
              type: FilterClauseType.INCLUSIVE,
              selections: filter[key] as string[],
            });
        }
      });
    }
    return clauses;
  }, [filter]);

  const handleFilterClausesChanged = useCallback(
    (filterClausesByPropertyKey: Map<string, FilterClause>, propertiesByKey: Map<string, FilterableProperty>) => {
      changeFilter(curr => {
        const newFilter: CustomerTradeFilter = removeEmptyFilters<CustomerTradeFilter>({
          ...curr,
          ...(Object.fromEntries(
            [...propertiesByKey.keys()].map(key => [key, filterClausesByPropertyKey.get(key)?.selections])
          ) as unknown as CustomerTradeFilter),
          OrderID: filterClausesByPropertyKey.get('OrderID')?.selections?.[0],
          RFQID: filterClausesByPropertyKey.get('RFQID')?.selections?.[0],
          TradeID: filterClausesByPropertyKey.get('TradeID')?.selections?.[0],
        });
        if (isEqual(curr, newFilter)) {
          return curr;
        }
        return newFilter;
      });
    },
    [changeFilter]
  );
  const dateRangeFilter = useDateRangeFilter(filter, changeFilter);

  const filterBuilderProps = useMemo(
    () => ({
      initialFilterClauses,
      properties: filterableProperties,
      onFilterClausesChanged: handleFilterClausesChanged,
    }),
    [filterableProperties, handleFilterClausesChanged, initialFilterClauses]
  ) satisfies UseFilterBuilderProps;
  const blotterTableFilterProps = useMemo(
    () => ({
      ...dateRangeFilter,
    }),
    [dateRangeFilter]
  ) satisfies Partial<BlotterTableFiltersProps>;
  return {
    initialFilter,
    filter,
    clientSideFilter,
    changeFilter,
    // shortcut to spread properties into useAccordionFilterBuilder.filterBuilderProps
    filterBuilderProps,
    // shortcut to spread props into the BlotterTableFilters component
    blotterTableFilterProps,
  };
}

const colIDToFilterBuilderKeyMap = {
  Side: 'Sides',
  Symbol: 'Symbols',
  User: 'Users',
  SubAccount: 'SubAccounts',
  TradeStatus: 'Statuses',
  OrderID: 'OrderID',
  RFQID: 'RFQID',
  TradeID: 'TradeID',
} satisfies Partial<Record<keyof CustomerTrade, keyof CustomerTradeFilter>>;
export function colIDToFilterBuilderKey(id: string): keyof CustomerTradeFilter | undefined {
  return colIDToFilterBuilderKeyMap[id as keyof typeof colIDToFilterBuilderKeyMap];
}

type CustomerTradeServerFilter = Pick<
  CustomerTradeFilter,
  'StartDate' | 'EndDate' | 'Symbols' | 'OrderID' | 'RFQID' | 'TradeID'
> & {
  Counterparty?: string; // singular
};

export function onlyServerFilterKeys(filter?: CustomerTradeFilter) {
  if (filter == null) {
    return filter;
  }

  const serverFilter = {
    StartDate: filter.StartDate,
    EndDate: filter.EndDate,
    Symbols: filter.Symbols,
    OrderID: filter.OrderID,
    RFQID: filter.RFQID,
    TradeID: filter.TradeID,
    Counterparty: filter.Counterparty?.at(0),
  } satisfies CustomerTradeServerFilter;

  return serverFilter;
}
