import type { FilterableProperty, Security } from '@talos/kyoko';
import {
  ACTION,
  EntityAdminPageREST,
  getSecurityDrawerOptions,
  getTierDrawerOptions,
  HStack,
  Panel,
  useSecuritiesContext,
  useSymbolsFilter,
  type InputsAndDropdownsDrawerOption,
  type SymbolGroup,
} from '@talos/kyoko';
import { useRoleAuth } from 'hooks';
import { filter } from 'lodash';
import { useCustomerPricingTierContext, useCustomerSecuritiesBySymbol } from 'providers';
import { useCallback, useEffect, useMemo, useState } from 'react';

const GET_SYMBOL_GROUP_POST_PATH = (entity: SymbolGroup) => {
  return entity.Symbol == null ? `/symbol-groups` : `/symbol-groups/${entity.Tier}/symbols`;
};

const GET_SYMBOL_GROUP_PATCH_DELETE_PATH = (entity: SymbolGroup) => {
  return entity.Symbol == null
    ? `/symbol-groups/${entity.Tier}`
    : `/symbol-groups/${entity.Tier}/symbols/${entity.Symbol}`;
};

const GET_SYMBOL_GROUP_DRAWER_OPTIONS = (): InputsAndDropdownsDrawerOption<SymbolGroup>[] => [
  {
    field: 'Tier',
    type: 'input',
    required: true,
    disabledWhenEditing: true,
    title: 'Name',
  },
];

const GET_SYMBOL_GROUP_OVERRIDES_DRAWER_OPTIONS = (
  symbolGroups: SymbolGroup[],
  filteredSecurities: Security[]
): InputsAndDropdownsDrawerOption<SymbolGroup>[] => [
  {
    field: 'Tier',
    type: 'dropdown',
    required: true,
    disabledWhenEditing: true,
    title: 'Group Name',
    options: getTierDrawerOptions(symbolGroups),
    placeholder: 'Select Group...',
  },
  {
    field: 'Symbol',
    type: 'dropdown',
    disabledWhenEditing: true,
    required: true,
    placeholder: 'Select Symbol...',
    options: getSecurityDrawerOptions(filteredSecurities),
  },
];

export const CustomerSymbolGroups = () => {
  const { isAuthorized } = useRoleAuth();

  const [symbolGroups, setSymbolGroups] = useState<SymbolGroup[]>([]);
  const { getSymbolGroups } = useCustomerPricingTierContext();
  const { securitiesBySymbol } = useSecuritiesContext();
  const customerSecuritiesBySymbol = useCustomerSecuritiesBySymbol();

  const filteredSecurities = useMemo((): Security[] => {
    if (!customerSecuritiesBySymbol) {
      return [];
    }

    // Return an array of securities that exist in customerSecuritiesBySymbol
    return filter(Array.from(securitiesBySymbol.values()), security => customerSecuritiesBySymbol.has(security.Symbol));
  }, [customerSecuritiesBySymbol, securitiesBySymbol]);

  useEffect(() => {
    getSymbolGroups().then(({ data }) => {
      setSymbolGroups(data);
    });
  }, [getSymbolGroups]);

  const symbolGroupDrawerOptions = useMemo(() => GET_SYMBOL_GROUP_DRAWER_OPTIONS(), []);
  const symbolGroupOverridesDrawerOptions = useMemo(() => {
    if (filteredSecurities != null) {
      return GET_SYMBOL_GROUP_OVERRIDES_DRAWER_OPTIONS(symbolGroups, filteredSecurities);
    }
  }, [symbolGroups, filteredSecurities]);

  const getEntityDrawerOptions = useCallback(
    (entity?: SymbolGroup, addingChildEntity?: boolean) => {
      if (addingChildEntity) {
        return symbolGroupOverridesDrawerOptions;
      }
      const isGroupRow = entity?.Symbol == null;
      return isGroupRow ? symbolGroupDrawerOptions : symbolGroupOverridesDrawerOptions;
    },
    [symbolGroupDrawerOptions, symbolGroupOverridesDrawerOptions]
  );

  const symbolFilter = useSymbolsFilter(filteredSecurities);
  const symbolGroupFilterableProperties: FilterableProperty[] = useMemo(() => [symbolFilter], [symbolFilter]);

  return (
    <HStack h="100%" w="100%" gap="spacingTiny" overflow="hidden">
      <Panel>
        <EntityAdminPageREST<SymbolGroup>
          title="Symbol Groups"
          subtitle="Set up your Symbol Groups here."
          path="/symbol-groups?expanded=true"
          getPostPath={GET_SYMBOL_GROUP_POST_PATH}
          getPatchDeletePath={GET_SYMBOL_GROUP_PATCH_DELETE_PATH}
          columns={[
            { field: 'Tier', type: 'text', hide: true, sort: '+' },
            { field: 'Symbol', type: 'security', hide: true, sort: '+' },
          ]}
          entityIDField="Tier"
          childIDField="Symbol"
          addChildEntityButtonProps={{
            text: 'Symbol',
            width: 90,
          }}
          getEntityDrawerOptions={getEntityDrawerOptions}
          entityName="Symbol Group"
          allowAddEntity={isAuthorized(ACTION.EDIT_CUSTOMER_SYMBOL_GROUPS)}
          allowEditEntity={false}
          allowDeleteEntity={isAuthorized(ACTION.EDIT_CUSTOMER_SYMBOL_GROUPS)}
          allowModeSwitch={true}
          groupColumnDef={{
            headerName: 'Name',
            width: 300,
            valueFormatter: ({ context, value }) => {
              const security = context.current.securitiesBySymbol?.get(value);
              return security?.DisplaySymbol ?? value;
            },
          }}
          filterableProperties={symbolGroupFilterableProperties}
          persistKey="symbol-groups"
        />
      </Panel>
    </HStack>
  );
};
