import { EMPTY_OBJECT } from '@talos/kyoko';
import type React from 'react';
import type { CSSProperties } from 'react';
import ReactAutocomplete from 'react-autocomplete';
import { AutocompleteResults, AutocompleteWrapper } from './styles';

export function Autocomplete({
  style = {},
  isCentered,
  ...props
}: { style?: CSSProperties; isCentered: boolean; invalid?: boolean; id?: string } & {
  inputProps: React.ComponentProps<typeof ReactAutocomplete>['inputProps'] & { 'data-testid'?: string };
} & Omit<React.ComponentProps<typeof ReactAutocomplete>, 'renderItem' | 'inputProps'>) {
  return (
    <AutocompleteWrapper style={style} isCentered={isCentered}>
      <ReactAutocomplete
        /* menuStyle has to be provided for some reason (defaulted to {} above). Otherwise the rendering of the dropdown breaks */
        menuStyle={EMPTY_OBJECT}
        {...props}
        renderItem={({ id, label }, isHighlighted) => (
          <AutocompleteResults key={id} isHighlighted={isHighlighted}>
            {label}
          </AutocompleteResults>
        )}
      />
    </AutocompleteWrapper>
  );
}
