import type * as Highcharts from 'highcharts/highcharts';
import { BaseChart, type BaseChartProps } from './BaseChart';
import { useChartOptionsOverrides, useHighchartsRef } from './hooks';
import { useSeriesOptions } from './hooks/useSeriesOptions';
import type { SharedChartProps } from './types';

export interface BubbleChartProps extends SharedChartProps<Highcharts.SeriesBubbleOptions>, BaseChartProps {}

const DEFAULT_BUBBLECHART_OPTIONS: Highcharts.Options = {
  chart: {
    type: 'bubble',
  },
};

export function BubbleChart({ series, seriesObservable, options, onChartCreated, ...props }: BubbleChartProps) {
  const { chartRef, isLoaded, setChartObject } = useHighchartsRef({ onChartCreated });
  const seriesOptions = useSeriesOptions({ series, seriesObservable, chartRef, isLoaded });

  const combinedOptions = useChartOptionsOverrides(DEFAULT_BUBBLECHART_OPTIONS, options, seriesOptions);

  return <BaseChart options={combinedOptions} onChartCreated={setChartObject} {...props} />;
}
