import type { MapTo } from '../utils/types';
import type { ConnectionCapabilitiesEnum, ConnectionModeEnum, ConnectionStatusEnum, ConnectionTypeEnum } from './types';

// TODO pull from types.ts
export interface MarketSecurityStatus {
  Capabilities?: {
    MarketData?: boolean;
    Orders?: boolean;
    RFQ?: boolean;
    Balances?: boolean;
  };
  ConnectionType: ConnectionTypeEnum;
  Enabled: ConnectionModeEnum;
  Market: string;
  MarketAccount: string;
  MaximumSize: string;
  MinPriceIncrement: string;
  MinSizeIncrement: string;
  MinimumAmount: string;
  MinimumSize: string;
  SizeBuckets?: SizeBucket[];
  Status: ConnectionStatusEnum;
  Symbol: string;
  Timestamp: string;

  MarketCapabilities?: MapTo<ConnectionCapabilitiesEnum, boolean>;
  MarketEnabled?: ConnectionModeEnum;
  MarketMinPriceIncrement?: string;
  MarketMinSizeIncrement?: string;
  MarketMaximumSize?: string;
  MarketMinimumSize?: string;
  MarketMinimumAmount?: string;
  MarketSizeBuckets?: SizeBucket[];

  RequestedEnabled?: ConnectionModeEnum;
  RequestedMinPriceIncrement?: string;
  RequestedMinSizeIncrement?: string;
  RequestedMinimumSize?: string;
  RequestedMaximumSize?: string;
  RequestedMinimumAmount?: string;
  RequestedCapabilities?: Record<ConnectionCapabilitiesEnum, boolean>;
  RequestedSizeBuckets?: SizeBucket[];
}

export interface SizeBucket {
  Size: string;
}

export function getMarketSecurityStatusKey(marketAccount: string, symbol: string) {
  return `${marketAccount}-${symbol}`;
}
