import type { LedgerAccountTypeEnum } from '@talos/kyoko';
import { abbreviateId, EMPTY_ARRAY, type Security } from '@talos/kyoko';
import type { ResolutionString } from '@trading-view/charting_library';
import type { DockviewApi } from 'dockview';
import { maxBy } from 'lodash';
import type { MutableRefObject } from 'react';
import { createContext, useCallback, useContext, useMemo, useRef } from 'react';
import { v1 as uuid } from 'uuid';
import { setTemporaryPanel } from '../../components/AppLayout/AppLayoutSlice';
import { DEFAULT_LAYOUTS } from '../../components/AppLayout/Layouts';
import { DEFAULT_LAYOUT_ID } from '../../components/AppLayout/Layouts/DefaultLayoutId';
import type { AddPanelOptions } from '../../components/Layout/types';
import { getEnumeratedTitle } from '../../components/Layout/utils/utils';
import { MultilegComboType } from '../../components/MultilegCombo/enums';
import { getMultilegInstrumentType } from '../../components/OMS/NewOrder/utils/mappers';
import type { OrderDetailsPath, OrderRouteType } from '../../containers/Trading/Markets/OrderDetails/types';
import type { PanelConfig, PanelType } from '../../types/LayoutConfig';
import { isMultiLegSecurity } from '../../utils/security';
import { useAppStateDispatch, useAppStateSelector } from '../AppStateProvider';
import { applyLayoutSpec } from './utils/applyLayoutSpec';
import { resetLayoutToDefault } from './utils/resetLayoutToDefault';

export interface AddDeepDivePanelParams {
  security: Security | undefined;
  marketAccounts: string[] | undefined;
  markets: string[] | undefined;
}

export interface AddOptionsPanelParams {
  currency: string;
  market: string;
  underlyingCode?: string;
  expiry?: string;
}

export interface AddOrderDetailsPanelParams {
  orderID: string;
  tab: OrderDetailsPath | undefined;
  orderType: OrderRouteType | undefined;
}
export interface AddMultilegDeepDivePanelParams {
  security: Security | undefined;
  multilegInstrumentType: MultilegComboType;
}

export interface AddReconMismatchesDetailsPanelParams {
  mismatchIDs: string[];
}

export interface AddAccountLedgerEventsDetailsPanelParams {
  asset?: string;
  account: string;
  accountType: LedgerAccountTypeEnum;
}

export interface AppLayoutContextProps {
  addPanel: (type: PanelType, options: AddPanelOptions) => void;
  addPanelToMain: (type: PanelType, options: Omit<AddPanelOptions, 'groupId' | 'panelId'>) => void;
  dockviewApiRef: MutableRefObject<DockviewApi | undefined>;
  resetLayout: () => void;
  resetLayoutAndTabs: () => void;

  addTemporaryPanel: (type: PanelType, label: string, params: PanelConfig) => void;

  addAutoHedgingRulesPanel: () => void;
  addOrderDetailsPanel: (params: AddOrderDetailsPanelParams) => void;
  addOptionsPanel: (params: AddOptionsPanelParams) => void;
  addDeepDivePanel: (params: AddDeepDivePanelParams) => void;
  addMultilegDeepDivePanel: (params: AddMultilegDeepDivePanelParams) => void;
  addReconMismatchesDetailsPanel: (params: AddReconMismatchesDetailsPanelParams) => void;
  addAccountLedgerEventsDetailsPanel: (params: AddAccountLedgerEventsDetailsPanelParams) => void;
}

export const AppLayoutContext = createContext<AppLayoutContextProps | undefined>(undefined);
AppLayoutContext.displayName = 'AppLayoutContext';

export function useAppLayoutContext() {
  const context = useContext(AppLayoutContext);
  if (context === undefined) {
    throw new Error('Missing AppLayoutContext.Provider further up in the tree. Did you forget to add it?');
  }
  return context;
}

/** Provide access to methods for modifying the layout for components outside the flexible part of the layout */
export function AppLayoutContextProvider({ children }) {
  const dispatch = useAppStateDispatch();
  const temporaryPanel = useAppStateSelector(state => state.appLayout.temporaryPanel);
  const layoutSection = useAppStateSelector(state => state.appLayout.layoutSection);
  const selectedLayoutId = useAppStateSelector(state => state.appLayout.currentLayout?.id);
  const dockviewApiRef = useRef<DockviewApi | undefined>(undefined);

  const addPanel = useCallback(
    (type: PanelType, { label, labelGetter, groupId, panelId, direction, params }: AddPanelOptions = {}) => {
      const dockviewApi = dockviewApiRef.current;
      if (dockviewApi == null) {
        return;
      }
      const referenceGroup = groupId ? dockviewApi.getGroup(groupId) : undefined;
      const referencePanel = panelId ? dockviewApi.getPanel(panelId) : undefined;
      const defaultTitle = label ?? labelGetter?.() ?? type;
      const title = getEnumeratedTitle(defaultTitle, dockviewApi.panels);
      const id = uuid();

      return dockviewApi.addPanel({
        id,
        component: type,
        title,
        params: {
          ...params,
          type,
        },
        position: referenceGroup
          ? {
              referenceGroup: groupId,
              direction: direction ?? 'within',
            }
          : referencePanel
          ? {
              referencePanel: panelId,
              direction: direction ?? 'right',
            }
          : undefined,
      });
    },
    []
  );

  const addTemporaryPanel = useCallback(
    (type: PanelType, title: string, params: PanelConfig) => {
      dispatch(
        setTemporaryPanel({
          type,
          params,
          title,
        })
      );
    },
    [dispatch]
  );

  const addPanelToMain = useCallback(
    (type: PanelType, options: Omit<AddPanelOptions, 'groupId' | 'panelId'>) => {
      const dockviewApi = dockviewApiRef.current;
      const group = dockviewApi?.getGroup('main') ?? maxBy(dockviewApi?.groups, group => group.width * group.height);
      addPanel(type, { ...options, groupId: group?.api.id ?? 'main', direction: 'within' });
    },
    [addPanel]
  );

  const addDeepDivePanel = useCallback(
    ({ security, marketAccounts, markets }: AddDeepDivePanelParams) => {
      if (security == null) {
        return;
      }
      addTemporaryPanel('deepDive', security.DisplaySymbol, {
        type: 'deepDive',
        symbol: security.Symbol,
        selectedMarketAccounts: marketAccounts ?? EMPTY_ARRAY,
        selectedMarkets: markets ?? EMPTY_ARRAY,
        chartResolution: '60' as ResolutionString,
        showMarkets: false,
        closable: true,
        editable: true,
      });
    },
    [addTemporaryPanel]
  );

  const addMultilegDeepDivePanel = useCallback(
    ({ security, multilegInstrumentType }: AddMultilegDeepDivePanelParams) => {
      if (security == null) {
        addTemporaryPanel('multilegDeepDive', 'New Multileg', {
          type: 'multilegDeepDive',
          resolvedSymbol: undefined,
          instrumentType: multilegInstrumentType ?? MultilegComboType.SyntheticCross,
          initiatingLegs: [],
          compactOrderBook: false,
          closable: true,
          editable: true,
        });
        return;
      } else if (!isMultiLegSecurity(security)) {
        return;
      }
      addTemporaryPanel('multilegDeepDive', security.DisplaySymbol, {
        type: 'multilegDeepDive',
        resolvedSymbol: security.Symbol,
        instrumentType:
          getMultilegInstrumentType(security.MultilegDetails.SyntheticProductType) ??
          multilegInstrumentType ??
          MultilegComboType.SyntheticCross,
        initiatingLegs: security.MultilegDetails.Parameters.LegParams.map(leg => leg.Initiating),
        compactOrderBook: false,
        closable: true,
        editable: true,
      });
    },
    [addTemporaryPanel]
  );

  const addOrderDetailsPanel = useCallback(
    ({ orderID, tab = 'details', orderType = 'order' }: AddOrderDetailsPanelParams) => {
      const type = 'orderDetails';
      addTemporaryPanel(type, `#${abbreviateId(orderID)}`, {
        type: type,
        orderID,
        orderType,
        openTab: tab,
        closable: true,
        editable: true,
      });
    },
    [addTemporaryPanel]
  );

  const addOptionsPanel = useCallback(
    ({ currency, market, underlyingCode, expiry }: AddOptionsPanelParams) => {
      const type = 'options';
      addTemporaryPanel(type, `${currency} Options`, {
        type,
        optionFilter: {
          currency,
          market,
          underlyingCode,
          marketAccountName: null,
          expiry: expiry ?? null,
        },
        strikeFilter: {},
        closable: true,
        editable: true,
      });
    },
    [addTemporaryPanel]
  );

  const addReconMismatchesDetailsPanel = useCallback(
    ({ mismatchIDs }: AddReconMismatchesDetailsPanelParams) => {
      const type = 'reconMismatchesDetails';
      addTemporaryPanel(type, 'Recon Details', { type, mismatchIDs, closable: true, editable: true });
    },
    [addTemporaryPanel]
  );

  const addAccountLedgerEventsDetailsPanel = useCallback(
    ({ asset, account, accountType }: AddAccountLedgerEventsDetailsPanelParams) => {
      const type = 'accountLedgerEvents';
      addTemporaryPanel(type, 'Ledger Details', { type, asset, account, accountType, closable: true, editable: true });
    },
    [addTemporaryPanel]
  );

  const addAutoHedgingRulesPanel = useCallback(() => {
    const type = 'positionAutoHedgingRules';
    addTemporaryPanel(type, 'Position Auto Hedging Rules', { type, closable: true, editable: true });
  }, [addTemporaryPanel]);

  /** Move all additional panels to the main group and reset layout */
  const resetLayout = useCallback(() => {
    const dockviewApi = dockviewApiRef.current;
    if (dockviewApi == null) {
      return;
    }
    const currentLayout = dockviewApi.toJSON();
    const layoutSpec = structuredClone(DEFAULT_LAYOUTS[layoutSection][selectedLayoutId ?? DEFAULT_LAYOUT_ID]);
    const defaultLayout = layoutSpec.dockViewLayout;
    const dockViewLayout = resetLayoutToDefault(currentLayout, defaultLayout);
    const newLayoutSpec = { ...layoutSpec, dockViewLayout };
    applyLayoutSpec(dockviewApi, newLayoutSpec);
  }, [layoutSection, selectedLayoutId]);

  const resetLayoutAndTabs = useCallback(() => {
    const layoutSpec = structuredClone(DEFAULT_LAYOUTS[layoutSection][selectedLayoutId ?? DEFAULT_LAYOUT_ID]);
    const dockviewApi = dockviewApiRef.current;
    if (dockviewApi) {
      applyLayoutSpec(dockviewApi, layoutSpec);
    }
  }, [layoutSection, selectedLayoutId]);

  const value = useMemo(
    () => ({
      dockviewApiRef,
      temporaryPanel,
      addTemporaryPanel,
      addPanel,
      addPanelToMain,
      addDeepDivePanel,
      addMultilegDeepDivePanel,
      addAutoHedgingRulesPanel,
      addReconMismatchesDetailsPanel,
      addAccountLedgerEventsDetailsPanel,
      addOrderDetailsPanel,
      addOptionsPanel,
      resetLayout,
      resetLayoutAndTabs,
    }),
    [
      dockviewApiRef,
      temporaryPanel,
      addTemporaryPanel,
      addPanel,
      addPanelToMain,
      addDeepDivePanel,
      addMultilegDeepDivePanel,
      addAutoHedgingRulesPanel,
      addReconMismatchesDetailsPanel,
      addAccountLedgerEventsDetailsPanel,
      addOrderDetailsPanel,
      addOptionsPanel,
      resetLayout,
      resetLayoutAndTabs,
    ]
  );

  return <AppLayoutContext.Provider value={value}>{children}</AppLayoutContext.Provider>;
}
