import {
  CUSTOMER_BALANCE,
  EMPTY_ARRAY,
  ParameterTypeEnum,
  PresenceEnum,
  ProductTypeEnum,
  ReduceFirstEnum,
  ReduceOnlyEnum,
  getScaleFromIncrement,
  isFuture,
  isPerpetualSwap,
  useObservableValue,
  useSubscription,
  wsScanToMap,
  type BaseField,
  type Customer,
  type CustomerBalance,
  type FieldData,
  type OrderStrategy,
  type ParameterLike,
  type Security,
  type StrategyLike,
} from '@talos/kyoko';
import { useMemo } from 'react';
import { map } from 'rxjs';
import { mapParameterToField } from '../SalesOrder/Strategies';
import type { OMSSettingsState } from '../types';

// Ideally we will have one initializeStrategyParams for every form but splitting Order form & Sales order form
// for now as both have different validations
//[ UI-4182] - Change Strategy Qty fields validation in order form
export const initializeStrategyParams = ({
  security,
  strategy,
  settings,
  excludedParams = EMPTY_ARRAY,
  isModify,
}: {
  strategy: StrategyLike | OrderStrategy | undefined;
  security: Security | undefined;
  settings: OMSSettingsState;
  excludedParams?: string[];
  isModify?: boolean;
}): { [key: string]: BaseField<FieldData, any> } => {
  const params = {};
  const { defaultPriceProtection } = settings;

  if (strategy) {
    strategy.Parameters
      // Only TopLevelHidden param that exists seem to be OrdType and only applicable on Market strategy -- and we can handle that manually
      .filter(param => param.Presence !== PresenceEnum.TopLevelHidden && param.Presence !== PresenceEnum.Hidden)
      .filter(param => !excludedParams.includes(param.Type))
      .forEach(param => {
        const parameter = { ...param, DefaultValue: getParamDefaultValue(param, isModify, settings) };

        params[parameter.Name] = mapParameterToField(
          parameter,
          getScaleFromIncrement(security?.MinPriceIncrement),
          undefined, // [UI-4182] Skip NumericField scale validation as we validate all Qty fields in validateStrategyParams function in OrderSlice.ts
          defaultPriceProtection,
          isModify
        );
      });
  }

  return params;
};

export function getHedgingSecurities(security: Security | undefined, securitiesList: Security[]): Security[] {
  if (!security || security.ProductType !== ProductTypeEnum.Option) {
    return [];
  }
  const market = security.Markets?.[0];
  return securitiesList.filter(
    s =>
      s.BaseCurrency === security.BaseCurrency &&
      s.QuoteCurrency === security.UnderlyingQuoteCurrency &&
      (isPerpetualSwap(s) || isFuture(s)) &&
      s.Markets?.some(m => m === market)
  );
}

// Allow to overwrite some strategy parameters default values with user defined settings
function getParamDefaultValue(param: ParameterLike, isModify: boolean | undefined, settings: OMSSettingsState) {
  if (isModify) {
    return param.DefaultValue;
  }

  const { enableReduceFirst, enableReduceOnly } = settings;

  switch (param.Type) {
    case ParameterTypeEnum.ReduceOnly:
      return enableReduceOnly ? ReduceOnlyEnum.Enabled : param.DefaultValue;
    case ParameterTypeEnum.ReduceFirst:
      return enableReduceFirst ? ReduceFirstEnum.Enabled : param.DefaultValue;
    default:
      return param.DefaultValue;
  }
}

export const useCustomerSpecificBalances = (customer: Customer | undefined, tag: string) => {
  const customerBalanceRequest = useMemo(() => {
    if (!customer?.Name) {
      return null;
    }
    return {
      name: CUSTOMER_BALANCE,
      Counterparties: [customer?.Name],
      tag: tag,
    };
  }, [customer?.Name, tag]);

  const { data: specificCustomerBalanceObs } = useSubscription<CustomerBalance>(customerBalanceRequest);
  const specificCustomerBalances = useObservableValue(
    () =>
      specificCustomerBalanceObs.pipe(
        wsScanToMap({
          getUniqueKey: customerBalance => customerBalance.rowID,
          newMapEachUpdate: false,
        }),
        map(map => Array.from(map.values()))
      ),
    [specificCustomerBalanceObs]
  );

  return specificCustomerBalances;
};
