import {
  Alert,
  AlertVariants,
  ButtonGroup,
  ConnectionModeEnum,
  type CredentialTemplate,
  DEFAULT_CREDENTIAL_AGE_ALERT_DAYS,
  EMPTY_ARRAY,
  Flex,
  FormControlSizes,
  FormGroup,
  type Market,
  type MarketConfig,
  type MarketCredential,
  NotificationVariants,
  type ReplaceTokens,
  runValidation,
  SearchSelect,
  Toggle,
  useDynamicCallback,
  useGlobalToasts,
  useUserContext,
} from '@talos/kyoko';
import { differenceInCalendarDays } from 'date-fns';
import { omit } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { object, string } from 'yup';
import { OrgConfigurationKey, useOrgConfiguration } from '../../../../providers';
import { CredentialAge } from '../CredentialAge';
import { SelectMarketCredential } from './SelectMarketCredential';
import { CredentialsForm } from './components/CredentialsForm';
import {
  type AddCredentialFormArg,
  type AddEditCredentialForm,
  type ICredentialDrawerTab,
  OMIT_FORM_KEYS,
} from './types';
import { EMPTY_FORM, getCredentialTemplateLabel, getReplaceTokens, validateCheckboxChecked } from './utils';

interface EditCredentialGeneralTabProps extends UseEditCredentialTabProps {
  selectedMarketConfig: MarketConfig | undefined;
  form: AddEditCredentialForm;
  initialFormValues: AddEditCredentialForm;
  errors: AddEditCredentialForm;
  updateForm: (arg: AddCredentialFormArg) => void;
  resetForm: (selectedCredential: MarketCredential | undefined) => void;
  credentialTemplate: CredentialTemplate | undefined;
  setCredentialTemplate: React.Dispatch<React.SetStateAction<CredentialTemplate | undefined>>;
  tokensToReplace: ReplaceTokens;
}

export function EditCredentialGeneralTab({
  marketConfigs,
  selectedMarket,
  selectedCredential,
  externalIP,

  selectedMarketConfig,
  form,
  initialFormValues,
  errors,
  updateForm,
  credentialTemplate,
  setCredentialTemplate,
  tokensToReplace,
}: EditCredentialGeneralTabProps) {
  const { getConfig } = useOrgConfiguration();

  const availableCredentials = useMemo(() => selectedMarketConfig?.credentials || [], [selectedMarketConfig]);
  const ageAlertDays = getConfig(OrgConfigurationKey.CredentialAgeAlertDays, DEFAULT_CREDENTIAL_AGE_ALERT_DAYS);
  const credentialAge = selectedCredential.SecretsUpdatedAt
    ? differenceInCalendarDays(new Date(), new Date(selectedCredential.SecretsUpdatedAt))
    : null;

  const handleCredentialTemplateChange = useCallback(
    (newCredentialTemplate: CredentialTemplate | undefined) => {
      setCredentialTemplate(newCredentialTemplate);
    },
    [setCredentialTemplate]
  );

  useEffect(() => {
    if (availableCredentials.length > 0) {
      const foundIndex = availableCredentials.findIndex(ct => ct.type === selectedCredential?.ConnectionType);
      setCredentialTemplate(availableCredentials[foundIndex > -1 ? foundIndex : 0]);
    }
  }, [availableCredentials, selectedCredential, setCredentialTemplate]);

  return (
    <Flex flexDirection="column" w="100%">
      {credentialAge && credentialAge > ageAlertDays && (
        <Alert variant={AlertVariants.Negative} mb="spacingDefault">
          The API key has not been updated for {credentialAge} days.
        </Alert>
      )}
      <FormGroup label="Market" flex="1">
        <SelectMarketCredential
          selectedCredential={selectedCredential}
          selectedMarket={selectedMarket}
          marketConfigs={marketConfigs ?? EMPTY_ARRAY}
          onSelected={() => void 0}
        />
      </FormGroup>
      <FormGroup label="Type" flex="1">
        <SearchSelect
          options={availableCredentials}
          getLabel={getCredentialTemplateLabel}
          onChange={handleCredentialTemplateChange}
          selection={credentialTemplate}
          disabled={availableCredentials.length <= 1}
          initialSortByLabel={false}
          showDropdownSearch={false}
        />
      </FormGroup>
      {credentialAge !== null && (
        <FormGroup label="Credential Age">
          <CredentialAge ageInDays={credentialAge} />
        </FormGroup>
      )}
      <FormGroup flex="1">
        <ButtonGroup>
          <Toggle
            size={FormControlSizes.Default}
            label="Enabled"
            checked={form.mode === ConnectionModeEnum.Up}
            onChange={(val: boolean) =>
              updateForm({
                key: 'mode',
                value: val ? ConnectionModeEnum.Up : ConnectionModeEnum.Down,
              })
            }
          />
        </ButtonGroup>
      </FormGroup>
      <CredentialsForm
        key={selectedCredential.CredentialID}
        credentialTemplate={credentialTemplate}
        selectedCredential={selectedCredential}
        externalIP={externalIP}
        updateForm={updateForm}
        form={form}
        initialFormValues={initialFormValues}
        errors={errors}
        tokensToReplace={tokensToReplace}
      />
    </Flex>
  );
}

interface UseEditCredentialTabProps {
  marketCredentials: MarketCredential[];
  marketConfigs: MarketConfig[];
  selectedCredential: MarketCredential;
  selectedMarket: Market | undefined;
  externalIP: string;
  selectedMarketConfig: MarketConfig | undefined;
}

export function useEditCredentialTab(props: UseEditCredentialTabProps): ICredentialDrawerTab {
  const { add: addToast } = useGlobalToasts();
  const [form, setForm] = useState<AddEditCredentialForm>(EMPTY_FORM);
  const initialFormValues = useRef<AddEditCredentialForm>(form);
  const [errors, setErrors] = useState<AddEditCredentialForm>({});
  const { patchMarketCredential } = useUserContext();
  const { marketCredentials, selectedCredential, selectedMarket, selectedMarketConfig, externalIP } = props;
  const [credentialTemplate, setCredentialTemplate] = useState<CredentialTemplate | undefined>(undefined);
  const tokensToReplace: ReplaceTokens = getReplaceTokens(externalIP);

  const isDirty = useMemo((): boolean => {
    const modifiedFields = getModifiedFields(form, initialFormValues.current, credentialTemplate);
    const labelChanged = form.label !== selectedCredential?.Label;
    const modeChanged = form.mode !== selectedCredential?.Mode;
    const anyOtherTemplateFieldChanged = Boolean(
      credentialTemplate?.fields?.some(field => modifiedFields[field.name] != null)
    );
    return labelChanged || modeChanged || anyOtherTemplateFieldChanged;
  }, [form, selectedCredential, credentialTemplate]);

  const isDisclaimerRequired = useMemo(() => {
    return selectedMarket?.Name === 'coinbase_intl';
  }, [selectedMarket]);

  const validate = useCallback(() => {
    const values = getModifiedFields(form, initialFormValues.current, credentialTemplate);
    if (!credentialTemplate) {
      return {
        valid: false,
        values,
      };
    }
    (credentialTemplate.fields ?? []).forEach(field => {
      if (field.required && field.type === 'checkbox') {
        values[field.name] = validateCheckboxChecked(form[field.name]);
      }
    });
    const validate = object().shape(validationSchema(credentialTemplate));
    const result: AddEditCredentialForm = runValidation(validate, values);
    if (
      selectedCredential?.Label !== form?.label &&
      marketCredentials.find(m => m.Label === form?.label && m.Market === selectedMarket?.Name)
    ) {
      result['label'] = 'Name is already in use';
    }
    if (form.isDisclaimerRequired && !form.isDisclaimerChecked) {
      result['isDisclaimerRequired'] = true;
    }
    setErrors(result);
    return {
      valid: Object.keys(result).length === 0,
      values,
    };
  }, [form, marketCredentials, credentialTemplate, selectedMarket?.Name, selectedCredential?.Label]);

  const handleSaveChanges = useDynamicCallback(async (): Promise<void> => {
    return new Promise((resolve, reject) => {
      const validation = validate();
      if (!selectedCredential || !selectedMarketConfig || !validation.valid) {
        reject();
        return;
      }
      const valuesWithExplicitConnectionType = { ...validation.values, connectionType: credentialTemplate!.type };
      patchMarketCredential(selectedMarketConfig.name, selectedCredential.Name, valuesWithExplicitConnectionType)
        .then(() => {
          // Mark form tab as clean when saved successfully to not repeat same operations when other tabs fail
          resetForm({ ...selectedCredential, Label: form?.label ?? '', Mode: form.mode });
          addToast({
            text: `Credential ${form.label} updated.`,
            variant: NotificationVariants.Positive,
          });
          resolve();
        })
        .catch(e => {
          addToast({
            text: (e as Error).message,
            variant: NotificationVariants.Negative,
          });
          reject();
        });
    });
  });

  const updateForm = useDynamicCallback(({ key, value }: AddCredentialFormArg) => {
    setForm(prev => ({ ...prev, [key]: value, isDisclaimerRequired }));
    setErrors(prev => {
      delete prev[key];
      return prev;
    });
  });

  const resetForm = useCallback(
    (credential: MarketCredential | undefined) => {
      setForm({
        ...getCredentialAttributes(credential?.Attributes, credentialTemplate, false),
        label: credential?.Label,
        mode: credential?.Mode,
        isDisclaimerRequired,
      });
      initialFormValues.current = {
        ...getCredentialAttributes(credential?.Attributes, credentialTemplate, true),
        label: credential?.Label,
        mode: credential?.Mode,
        isDisclaimerRequired,
      };
      setErrors({});
    },
    [isDisclaimerRequired, credentialTemplate]
  );

  useEffect(() => {
    resetForm(selectedCredential);
  }, [selectedCredential, resetForm]);

  return {
    name: 'General',
    component: (
      <EditCredentialGeneralTab
        {...props}
        selectedMarketConfig={selectedMarketConfig}
        form={form}
        initialFormValues={initialFormValues.current}
        errors={errors}
        updateForm={updateForm}
        resetForm={resetForm}
        credentialTemplate={credentialTemplate}
        setCredentialTemplate={setCredentialTemplate}
        tokensToReplace={tokensToReplace}
      />
    ),
    isDirty,
    viewable: true,
    save: handleSaveChanges,
  };
}

const validationSchema = (config: CredentialTemplate) => {
  const schema = {
    label: string()
      .required(`Name is required`)
      .test('Does not contain slashes', 'Name cannot contain slashes', label => (label ? !label.includes('/') : false)),
  };
  for (const field of config?.fields ?? []) {
    if (field.required && field.type === 'checkbox') {
      schema[field.name] = string().test('isTrue', `This field is required`, validateCheckboxChecked);
    }
  }
  return schema;
};

/*
 * Return fields that were modified by checking current value vs initial value
 * but skip fields that are required and cannot be blanked
 */
const getModifiedFields = (
  form: AddEditCredentialForm,
  initialForm: AddEditCredentialForm,
  config: CredentialTemplate | undefined
): Omit<AddEditCredentialForm, keyof typeof OMIT_FORM_KEYS> => {
  const data = omit(form, OMIT_FORM_KEYS);
  const result: AddEditCredentialForm = {
    label: form.label,
    mode: form.mode,
  };
  const configFields = config?.fields ?? [];
  Object.keys(data).forEach(key => {
    if (data[key] !== initialForm?.[key]) {
      // If field is required it cannot be blanked
      if (configFields.find(field => field.name === key && field.required && data[key] === '')) {
        return;
      }
      result[key] = data[key];
    }
  });
  return result;
};

/*
 * Get attributes that are used in the form as initial values
 * If preview on a field is set it means that value is obfuscated and can be accessed from
 * attributes as `attributes[${field.name}_preview]`, otherwise it is just `attributes[field.name]`
 */
function getCredentialAttributes(
  attributes: Record<string, string> | undefined,
  config: CredentialTemplate | undefined,
  withObfuscatedValues: boolean
): AddEditCredentialForm | undefined {
  if (!attributes || !config || !config.fields) {
    return;
  }
  return config.fields.reduce((acc, field) => {
    if (withObfuscatedValues && field.preview && attributes[`${field.name}_preview`]) {
      acc[field.name] = getFieldValue(`${field.name}_preview`, field, attributes);
    } else if (attributes[field.name]) {
      acc[field.name] = getFieldValue(field.name, field, attributes);
    }
    return acc;
  }, {});
}

/*
 * Get field value and convert it to UI representation based on field type
 */
function getFieldValue(key, field, attributes): any {
  if (field.type === 'checkbox') {
    return attributes[key] === 'true';
  } else {
    return attributes[key];
  }
}
