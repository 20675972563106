import {
  EMPTY_ARRAY,
  Order,
  SecurityTooltipEnum,
  SecurityToursEnum,
  getAgGridColId,
  getOrderStatusColor,
  prettyPriceProtection,
  useDefaultColumns,
  type AgGridMeterProps,
  type Column,
  type ColumnDef,
  type FilledPercentColumnParams,
  type PriceParams,
  type SizeColumnParams,
} from '@talos/kyoko';
import type { CellClassParams, ValueFormatterParams } from 'ag-grid-community';
import { MultilegTooltip } from 'components/Multileg';
import { useMemo } from 'react';
import { DDHProgress } from '../../../components/DDH/DDHProgressTooltip';
import { useTradingSettings } from '../../../providers/TradingSettingsContext';

export const getMeterTooltip: AgGridMeterProps['tooltip'] = props => {
  const data: unknown = props.data;
  if (!(data instanceof Order)) {
    return null;
  }

  if (data.LegSummary) {
    return <MultilegTooltip meterProps={props.meterProps} data={data} />;
  }

  if (data.HedgeOrderStatus) {
    return <DDHProgress order={data} meterProps={props.meterProps} />;
  }

  return null;
};

const getMeterClassName = (data: Order) => {
  if (data.isDDHParentOrder) {
    return 'ddh-order-meter';
  }
  return '';
};

const DEFAULT_ORDER_COLUMNS: Map<string, ColumnDef<Order>> = new Map(
  (
    [
      { field: 'warning', type: 'warning', params: { tooltipWidth: '400px' } },
      {
        field: 'SubmitTime',
        type: 'date',
        sortable: true,
        title: 'Submit Date',
        params: { milliseconds: true },
        width: 140,
      },
      {
        field: 'Symbol',
        type: 'security',
        sortable: true,
        width: 100,
        params: {
          tooltip: SecurityTooltipEnum.UNIFIED_LIQUIDITY,
          tours: [SecurityToursEnum.DDH_ENABLED_ORDER],
        },
      },
      { field: 'Side', type: 'side' },
      {
        field: 'OrderQty',
        type: 'size',
        sortable: true,
        width: 120,
        params: { currencyField: 'Currency' } satisfies SizeColumnParams,
      },
      {
        field: 'Price',
        type: 'price',
        sortable: true,
        params: { assetField: 'Symbol', showReferencePrice: true } satisfies PriceParams,
      },
      {
        field: 'PricingReference',
        type: 'price',
        hide: true,
        params: {
          isReferencePrice: true,
        },
      },
      { field: 'Strategy', type: 'strategy', sortable: true },
      {
        field: 'CumQty',
        type: 'size',
        sortable: true,
        title: 'Filled Qty',
        params: { currencyField: 'Currency' },
        width: 120,
      },
      {
        id: 'filledPercent' satisfies ExtraColIDs,
        type: 'filledPercent',
        title: 'Filled %',
        sortable: true,
        params: {
          filledQtyField: 'CumQty',
          totalQtyField: 'OrderQty',
          getColor: (node, theme) => getOrderStatusColor(node.data, theme),
          tooltip: getMeterTooltip,
        } satisfies FilledPercentColumnParams<Order>,
        // For DDH tours (2.49), we apply a class to the filled % cell. This cell's class is the anchor of the tour.
        cellClass: (params: CellClassParams<Order>) => (params.node.data ? getMeterClassName(params.node.data) : ''),
      },
      { field: 'filledPx', type: 'price', sortable: true, title: 'Filled Price', params: { assetField: 'Symbol' } },
      {
        field: 'CumFee',
        type: 'size',
        sortable: true,
        title: 'Fees',
        params: { currencyField: 'FeeCurrency' },
        width: 80,
      },
      { field: 'OrdStatus', type: 'orderStatus', sortable: true, title: 'Order Status' },
      {
        field: 'HedgeOrderStatus.HedgeControlStatus',
        type: 'autohedgeStatus',
        title: 'Hedge Order Status',
        hide: true,
        params: {
          textField: 'HedgeOrderStatus.Text',
        },
      },
      { field: 'selectedMarkets', title: 'Selected Markets', type: 'markets', sortable: true },
      { field: 'tradedMarkets', type: 'markets', title: 'Traded Markets', sortable: true },
      { field: 'SubAccount', type: 'subAccount', sortable: true, title: 'Sub Account(s)' },
      { field: 'User', type: 'user', sortable: true, width: 140 },
      { field: 'OrderID', type: 'id' },
      { field: 'selectedAndTradedMarketAccounts', type: 'marketAccounts', title: 'Market Accounts', hide: true },
      {
        field: 'AvgPx',
        type: 'price',
        sortable: true,
        hide: true,
        title: 'Filled Price (no fees)',
        params: { assetField: 'Symbol', showFeeIcon: 'never' },
      },
      { field: 'RiskStatus', type: 'bitmap', hide: true },
      {
        field: 'AvgPxAllIn',
        type: 'price',
        sortable: true,
        hide: true,
        title: 'Filled Price (with fees)',
        params: { assetField: 'Symbol' },
      },
      { field: 'CumAmt', type: 'size', sortable: true, hide: true, params: { currencyField: 'AmountCurrency' } },
      { field: 'selectedAndTradedMarkets', type: 'markets', title: 'Markets', hide: true },
      { field: 'selectedMarketAccounts', type: 'marketAccounts', sortable: false, hide: true },
      { field: 'tradedMarketAccounts', type: 'marketAccounts', sortable: false, hide: true },
      {
        id: 'filledNotional',
        type: 'filledNotional',
        sortable: true,
        hide: true,
        title: 'Filled Notional',
        params: { securityField: 'Symbol', showFeeIcon: 'never' },
      },
      {
        id: 'openNotional',
        type: 'openNotional',
        sortable: true,
        hide: true,
        title: 'Open Notional',
        params: { securityField: 'Symbol', showFeeIcon: 'never' },
      },
      {
        id: 'totalNotional',
        type: 'totalNotional',
        sortable: true,
        hide: true,
        title: 'Total Notional',
        params: { securityField: 'Symbol', showFeeIcon: 'never' },
      },
      {
        id: 'filledCounterAmount',
        type: 'filledCounterAmount',
        sortable: true,
        hide: true,
        title: 'Filled Counter Amt',
        params: { currencyField: 'AmountCurrency', showFeeIcon: 'never' },
      },

      { field: 'Group', type: 'text', sortable: true, hide: true },

      { id: 'productType', type: 'productType', sortable: true, hide: true, title: 'Product' },

      { field: 'endTime', type: 'date', sortable: true, hide: true },
      { field: 'LastTradeTime', type: 'date', sortable: true, hide: true },
      { field: 'startTime', type: 'date', sortable: true, hide: true },
      {
        field: 'Timestamp',
        type: 'date',
        sortable: true,
        hide: true,
        title: 'Updated at',
        params: { milliseconds: true },
      },
      { id: 'baseQty', type: 'cumBaseQty', sortable: true, hide: true, title: 'Filled Base Qty' },
      { field: 'OrdType', type: 'text', sortable: true, hide: true, title: 'Order Type' },

      { field: 'ClOrdID', type: 'id', hide: true },
      {
        field: 'LeavesQty',
        type: 'size',
        sortable: true,
        hide: true,
        params: { currencyField: 'Currency' },
        title: 'Open Qty',
      },
      { field: 'remainQty', type: 'size', sortable: true, hide: true, params: { currencyField: 'Currency' } },
      { field: 'TimeInForce', type: 'text', sortable: true, hide: true },
      { field: 'Comments', type: 'text', sortable: true, hide: true },
      { field: 'DecisionStatus', type: 'text', sortable: true, hide: true },
      { field: 'ExecID', type: 'text', sortable: true, hide: true },
      { field: 'TransactTime', type: 'date', sortable: true, hide: true },
      { field: 'Text', type: 'text', sortable: true, hide: true },
      { field: 'Parameters', type: 'orderParameters', hide: true },
      {
        field: 'ExpectedFillPrice',
        type: 'price',
        sortable: true,
        hide: true,
        params: { assetField: 'Symbol', showFeeIcon: 'never' },
      },
      { field: 'ExpectedFillQty', type: 'size', sortable: true, hide: true, params: { currencyField: 'Currency' } },
      { field: 'ParentOrderID', type: 'text', sortable: true, hide: true },
      { field: 'Revision', type: 'text', sortable: true, hide: true },
      { field: 'OrigClOrdID', type: 'text', sortable: true, hide: true },
      { field: 'SessionID', type: 'text', sortable: true, hide: true },
      { field: 'Allocation', type: 'text', sortable: true, hide: true },
      { field: 'CxlRejReason', type: 'text', sortable: true, hide: true },
      { field: 'AggressorSide', type: 'side', sortable: true, hide: true },
      {
        field: 'allowedSlippageBPS',
        title: 'Allowed Slippage',
        type: 'number',
        sortable: true,
        hide: true,
        params: { currency: 'BPS' },
      },
      {
        type: 'custom',
        params: { valueFormatter: ({ value }) => prettyPriceProtection(value) },
        field: 'priceProtection',
        hide: true,
        sortable: true,
      },
      { field: 'ParentRFQID', type: 'id', sortable: true, hide: true },
      { field: 'QuoteID', type: 'id', sortable: true, hide: true },
      { field: 'RFQID', type: 'id', sortable: true, hide: true },
      { field: 'unifiedLiquidity', type: 'text', hide: true, sortable: true },
      { field: 'reduceOnly', type: 'text', hide: true, sortable: true },
      { field: 'reduceFirst', type: 'text', hide: true, sortable: true },
      {
        field: 'FixingDetails.Index',
        title: 'Fixing Index',
        type: 'text',
        hide: true,
      },
      {
        field: 'FixingDetails.Fixing',
        title: 'Fixing Price',
        type: 'price',
        hide: true,
        params: {
          // TODO fhqvst this should probably be `FixingDetails.Index`
          assetField: 'Symbol',
        },
      },
      { field: 'PricingMode', type: 'text', title: 'Pricing Mode', hide: true },
    ] satisfies ColumnDef<Order>[]
  ).map(c => [getAgGridColId(c), c])
);

const CUSTOMER_ORDER_COLUMNS: Map<string, ColumnDef<Order>> = new Map(
  (
    [
      {
        field: 'customerOrder', // a.k.a Customer Order Counterparty
        title: 'Cust Order',
        type: 'custom',
        sortable: true,
        headerGroupToggle: true,
        columnsInGroup: [
          'customerOrderID',
          'customerAccount',
          'customerQuantity',
          'customerFilledQuantity',
          'customerPrice',
          'customerAvgPx',
          'customerFilledCumAmt',
        ],
        columnGroup: true,
        width: 120,
        minWidth: 100,
        params: {
          valueFormatter: ({ value, context, data }: ValueFormatterParams<Order>) => {
            const customerOrderCounterparty = context.current?.customersByName?.get(value)?.DisplayName || value;
            if (!customerOrderCounterparty && data?.ParentOrderID) {
              // Show that there is an associated customer order (ParentOrderID) even if we haven't explicitly loaded it yet
              // Should be rare, but we can look at rendering this as an icon with a tooltip instead if it ends up being common
              return 'Customer order';
            }
            return customerOrderCounterparty;
          },
        },
      },
      {
        field: 'customerOrderID',
        title: 'Cust Order ID',
        type: 'id',
        columnGroup: true,
        width: 100,
        minWidth: 80,
      },
      {
        field: 'customerAccount',
        title: 'Cust Account',
        type: 'marketAccountSourceAccountID',
        sortable: true,
        columnGroup: true,
        width: 100,
        minWidth: 80,
      },
      {
        field: 'customerQuantity',
        title: 'Cust Qty',
        type: 'size',
        sortable: true,
        params: { currencyField: 'Currency' },
        width: 100,
        minWidth: 80,
        columnGroup: true,
      },
      {
        field: 'customerFilledQuantity',
        title: 'Cust Filled Qty',
        type: 'size',
        sortable: true,
        params: { currencyField: 'Currency' },
        width: 120,
        minWidth: 80,
        columnGroup: true,
      },
      {
        field: 'customerPrice',
        title: 'Cust Limit Price',
        type: 'price',
        sortable: true,
        params: { assetField: 'Symbol' },
        width: 120,
        minWidth: 80,
        columnGroup: true,
      },
      {
        field: 'customerAvgPx',
        title: 'Cust Avg. Filled Price',
        type: 'price',
        sortable: true,
        params: { assetField: 'Symbol' },
        width: 160,
        minWidth: 80,
        columnGroup: true,
      },
      {
        field: 'customerFilledCumAmt',
        title: 'Cust Filled Counter Amt',
        type: 'price',
        sortable: true,
        params: { quoteCurrencyField: 'customerAmountCurrency' },
        width: 160,
        minWidth: 80,
        columnGroup: true,
      },
    ] satisfies ColumnDef<Order>[]
  ).map(c => [getAgGridColId(c), c])
);

// colids which arent a field on the Order entity (this shouldnt happen, means column needs to be refactored)
type ExtraColIDs = 'filledPercent';

export const useOrderColumns = ({
  defaultColumns = EMPTY_ARRAY,
  suppressCustomerColumns,
}: {
  defaultColumns?: (keyof Order | ExtraColIDs | Partial<Column<Order>>)[];
  suppressCustomerColumns?: boolean; // Skip showing Customer columns
}) => {
  const { enableCustomerBlotterColumns } = useTradingSettings();

  const columnDefinitions = useMemo(() => {
    const definitionsMap = new Map<string, ColumnDef<Order>>(DEFAULT_ORDER_COLUMNS.entries());
    if (enableCustomerBlotterColumns && !suppressCustomerColumns) {
      definitionsMap.clear();
      const cols = [...DEFAULT_ORDER_COLUMNS.values()]
        .slice(0, 2)
        .concat([...CUSTOMER_ORDER_COLUMNS.values()])
        .concat([...DEFAULT_ORDER_COLUMNS.values()].slice(2));
      cols.forEach(col => {
        definitionsMap.set(getAgGridColId(col), col);
      });
    }

    return definitionsMap as Map<string, Column>;
  }, [enableCustomerBlotterColumns, suppressCustomerColumns]);

  return useDefaultColumns(defaultColumns, columnDefinitions);
};
