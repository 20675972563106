import {
  ACTION,
  EntityAdminPageREST,
  EntityAdminPageTabsWrapper,
  getCurrencyDrawerOptions,
  getFeeModeDrawerOptions,
  getPricingAggregationDrawerOptions,
  getSecurityDrawerOptions,
  getTierDrawerOptions,
  useCurrenciesContext,
  useFeeCurrenciesFilter,
  useMarketFeeModesFilter,
  useSecuritiesContext,
  useSymbolsFilter,
  type Aggregation,
  type AllInTier,
  type ColumnDef,
  type Currency,
  type FeeTier,
  type FilterableProperty,
  type InputsAndDropdownsDrawerOption,
  type MarketFeeModeEnum,
  type Security,
  type useEntityAdminPageProps,
} from '@talos/kyoko';
import type { GridOptions, ValueFormatterParams } from 'ag-grid-community';
import { usePricingAggregationsFilter, usePricingTierFilters, useRoleAuth } from 'hooks';
import { useAggregations } from 'hooks/useAggregations';
import { filter } from 'lodash';
import { useCustomerPricingTierContext, useCustomerSecuritiesBySymbol } from 'providers';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { DEALER_FEE_MODE_OPTIONS } from '../utils';

const GET_ALL_IN_TIER_POST_PATH = (entity: AllInTier) => {
  return entity.Symbol == null ? `/all-in-tiers` : `/all-in-tiers/${entity.Tier}/symbols`;
};
const GET_ALL_IN_TIER_PATCH_DELETE_PATH = (entity: AllInTier) => {
  return entity.Symbol == null
    ? `/all-in-tiers/${entity.Tier}`
    : `/all-in-tiers/${entity.Tier}/symbols/${entity.Symbol}`;
};

const GET_FEE_TIER_POST_PATH = (entity: FeeTier) => {
  return entity.Symbol == null ? `/fee-tiers` : `/fee-tiers/${entity.Tier}/symbols`;
};
const GET_FEE_TIER_PATCH_DELETE_PATH = (entity: FeeTier) => {
  return entity.Symbol == null ? `/fee-tiers/${entity.Tier}` : `/fee-tiers/${entity.Tier}/symbols/${entity.Symbol}`;
};

const BPS_COLUMN_WIDTH = 120;
const ALL_IN_TIER_COLUMNS: ColumnDef<AllInTier>[] = [
  { type: 'text', field: 'Tier', hide: true, sort: '+' },
  { type: 'security', field: 'Symbol', hide: true, sort: '+' },
  { type: 'bps', field: 'BidSpread', width: BPS_COLUMN_WIDTH },
  { type: 'bps', field: 'OfferSpread', width: BPS_COLUMN_WIDTH },
  { type: 'bps', field: 'SalesCommission', title: 'Commission', width: BPS_COLUMN_WIDTH },
  { type: 'pricingAggregation', field: 'PricingAggregation' },
  { type: 'bps', field: 'Fee', width: BPS_COLUMN_WIDTH },
  { type: 'feeMode', field: 'FeeMode' },
  { type: 'currency', field: 'FeeCurrency' },
  { type: 'text', field: 'QuoteTTL', title: 'Quote TTL', hide: true },
  { type: 'bps', field: 'AcceptPriceLeniency', hide: true },
];

const FEE_TIERS_COLUMNS: ColumnDef<FeeTier>[] = [
  { type: 'text', field: 'Tier', hide: true, sort: '+' },
  { type: 'security', field: 'Symbol', hide: true, sort: '+' },
  { type: 'bps', field: 'Fee', width: BPS_COLUMN_WIDTH },
  { type: 'feeMode', field: 'FeeMode' },
  { type: 'currency', field: 'FeeCurrency' },
];

const GET_SHARED_ALL_IN_TIER_DRAWER_OPTIONS_PART_1 = (
  pricingAggregations: Aggregation[]
): InputsAndDropdownsDrawerOption<AllInTier>[] => [
  {
    field: 'BidSpread',
    type: 'inputBPS',
    title: 'Bid Spread (BPS)',
  },
  {
    field: 'OfferSpread',
    type: 'inputBPS',
    title: 'Offer Spread (BPS)',
  },
  {
    field: 'SalesCommission',
    type: 'inputBPS',
    title: 'Commission (BPS)',
  },
  {
    field: 'PricingAggregation',
    type: 'dropdown',
    options: getPricingAggregationDrawerOptions(pricingAggregations),
    placeholder: 'Select Pricing Aggregation...',
  },
];

const GET_SHARED_ALL_IN_TIER_DRAWER_OPTIONS_PART_2 = (): InputsAndDropdownsDrawerOption<AllInTier>[] => [
  {
    field: 'QuoteTTL',
    type: 'input',
    title: 'Quote TTL',
  },
  {
    field: 'AcceptPriceLeniency',
    type: 'inputBPS',
    title: 'Price Leniency',
  },
];

const GET_SHARED_FEE_TIER_DRAWER_OPTIONS = (
  feeModeOptions: MarketFeeModeEnum[],
  feeCurrencyOptions: Currency[]
): InputsAndDropdownsDrawerOption<FeeTier>[] => [
  { field: 'Fee', type: 'inputBPS', title: 'Fee (BPS)' },
  {
    field: 'FeeMode',
    type: 'dropdown',
    options: getFeeModeDrawerOptions(feeModeOptions),
    placeholder: 'Select Fee Mode...',
  },
  {
    field: 'FeeCurrency',
    type: 'dropdown',
    options: getCurrencyDrawerOptions(feeCurrencyOptions),
    placeholder: 'Select Fee Currency...',
  },
];

const GET_SHARED_ALL_IN_TIER_DRAWER_OPTIONS = (
  pricingAggregations: Aggregation[],
  feeModeOptions: MarketFeeModeEnum[],
  feeCurrencyOptions: Currency[]
): InputsAndDropdownsDrawerOption<AllInTier>[] => [
  ...GET_SHARED_ALL_IN_TIER_DRAWER_OPTIONS_PART_1(pricingAggregations),
  ...GET_SHARED_FEE_TIER_DRAWER_OPTIONS(feeModeOptions, feeCurrencyOptions),
  ...GET_SHARED_ALL_IN_TIER_DRAWER_OPTIONS_PART_2(),
];

const GET_ALL_IN_TIER_DRAWER_OPTIONS = (
  pricingAggregations: Aggregation[],
  feeModeOptions: MarketFeeModeEnum[],
  feeCurrencyOptions: Currency[]
): InputsAndDropdownsDrawerOption<AllInTier>[] => [
  {
    field: 'Tier',
    type: 'input',
    required: true,
    disabledWhenEditing: true,
    title: 'Name',
  },
  { type: 'divider' },
  ...GET_SHARED_ALL_IN_TIER_DRAWER_OPTIONS(pricingAggregations, feeModeOptions, feeCurrencyOptions),
];

const GET_ALL_IN_TIER_OVERRIDES_DRAWER_OPTIONS = (
  pricingAggregations: Aggregation[],
  feeModeOptions: MarketFeeModeEnum[],
  feeCurrencyOptions: Currency[],
  allInTierGroups: AllInTier[],
  filteredSecurities: Security[]
): InputsAndDropdownsDrawerOption<AllInTier>[] => [
  {
    field: 'Tier',
    type: 'dropdown',
    required: true,
    disabledWhenEditing: true,
    title: 'Base Tier',
    options: getTierDrawerOptions(allInTierGroups),
    placeholder: 'Select Base Tier...',
  },
  {
    field: 'Symbol',
    type: 'dropdown',
    disabledWhenEditing: true,
    required: true,
    placeholder: 'Select Symbol...',
    options: getSecurityDrawerOptions(filteredSecurities),
  },
  { type: 'divider' },
  ...GET_SHARED_ALL_IN_TIER_DRAWER_OPTIONS(pricingAggregations, feeModeOptions, feeCurrencyOptions),
];

const GET_FEE_TIER_DRAWER_OPTIONS = (
  feeModeOptions: MarketFeeModeEnum[],
  feeCurrencyOptions: Currency[]
): InputsAndDropdownsDrawerOption<FeeTier>[] => {
  return [
    {
      field: 'Tier',
      type: 'input',
      required: true,
      disabledWhenEditing: true,
      title: 'Name',
    },
    { type: 'divider' },
    ...GET_SHARED_FEE_TIER_DRAWER_OPTIONS(feeModeOptions, feeCurrencyOptions),
  ];
};

const GET_FEE_TIER_OVERRIDES_DRAWER_OPTIONS = (
  feeModeOptions: MarketFeeModeEnum[],
  feeCurrencyOptions: Currency[],
  feeTierGroups: FeeTier[],
  filteredSecurities: Security[]
): InputsAndDropdownsDrawerOption<FeeTier>[] => {
  return [
    {
      field: 'Tier',
      type: 'dropdown',
      required: true,
      disabledWhenEditing: true,
      title: 'Fee Tier',
      options: getTierDrawerOptions(feeTierGroups),
      placeholder: 'Select Fee Tier...',
    },
    {
      field: 'Symbol',
      type: 'dropdown',
      disabledWhenEditing: true,
      required: true,
      placeholder: 'Select Symbol...',
      options: getSecurityDrawerOptions(filteredSecurities),
    },
    { type: 'divider' },
    ...GET_SHARED_FEE_TIER_DRAWER_OPTIONS(feeModeOptions, feeCurrencyOptions),
  ];
};

const TIER_COLUMN_DEF: GridOptions['autoGroupColumnDef'] = {
  headerName: 'Name',
  valueFormatter: ({ context, value }: ValueFormatterParams<AllInTier | FeeTier>) => {
    const security = context.current.securitiesBySymbol?.get(value);
    return security?.DisplaySymbol ?? value;
  },
};

const TIER_ADD_CHILD_ENTITY_BUTTON_PROPS: useEntityAdminPageProps<AllInTier | FeeTier>['addChildEntityButtonProps'] = {
  text: 'Symbol Override',
  width: 137,
};

const TAB_ITEMS = [
  { id: 'BaseTiersWithOverrides', label: 'Base Tiers' },
  { id: 'FeeTiersWithOverrides', label: 'Fee Tiers' },
];

export const CustomerPricingTiers = () => {
  const { isAuthorized } = useRoleAuth();

  const [allInTiers, setAllInTiers] = useState<AllInTier[]>();
  const [feeTiers, setFeeTiers] = useState<FeeTier[]>();
  const { getAllInTiers, getFeeTiers } = useCustomerPricingTierContext();
  useEffect(() => {
    getAllInTiers(true).then(({ data }) => {
      setAllInTiers(data);
    });
    getFeeTiers(true).then(({ data }) => {
      setFeeTiers(data);
    });
  }, [getAllInTiers, getFeeTiers]);

  const { aggregationsByName } = useAggregations();
  const { currenciesList } = useCurrenciesContext();
  const customerSecuritiesBySymbol = useCustomerSecuritiesBySymbol();

  const allInTierDrawerOptions = useMemo(() => {
    if (aggregationsByName != null) {
      return GET_ALL_IN_TIER_DRAWER_OPTIONS(
        Array.from(aggregationsByName.values()),
        DEALER_FEE_MODE_OPTIONS,
        currenciesList
      );
    }
  }, [aggregationsByName, currenciesList]);

  const { securitiesBySymbol } = useSecuritiesContext();

  const filteredSecurities = useMemo((): Security[] => {
    if (!customerSecuritiesBySymbol) {
      return [];
    }

    // Return an array of securities that exist in customerSecuritiesBySymbol
    return filter(Array.from(securitiesBySymbol.values()), security => customerSecuritiesBySymbol.has(security.Symbol));
  }, [customerSecuritiesBySymbol, securitiesBySymbol]);
  const allInTierOverridesDrawerOptions = useMemo(() => {
    if (aggregationsByName != null && allInTiers != null) {
      const allInTierGroups = allInTiers.filter(tier => tier.Symbol == null);
      return GET_ALL_IN_TIER_OVERRIDES_DRAWER_OPTIONS(
        Array.from(aggregationsByName.values()),
        DEALER_FEE_MODE_OPTIONS,
        currenciesList,
        allInTierGroups,
        filteredSecurities
      );
    }
  }, [aggregationsByName, allInTiers, currenciesList, filteredSecurities]);

  const feeTierDrawerOptions = useMemo(() => {
    if (currenciesList != null) {
      return GET_FEE_TIER_DRAWER_OPTIONS(DEALER_FEE_MODE_OPTIONS, currenciesList);
    }
  }, [currenciesList]);
  const feeTierOverridesDrawerOptions = useMemo(() => {
    if (currenciesList != null && feeTiers != null) {
      const feeTierGroups = feeTiers.filter(tier => tier.Symbol == null);
      return GET_FEE_TIER_OVERRIDES_DRAWER_OPTIONS(
        DEALER_FEE_MODE_OPTIONS,
        currenciesList,
        feeTierGroups,
        filteredSecurities
      );
    }
  }, [currenciesList, filteredSecurities, feeTiers]);

  const getAllInTierDrawerOptions = useCallback(
    (entity?: AllInTier, addingChildEntity?: boolean) => {
      if (addingChildEntity) {
        return allInTierOverridesDrawerOptions;
      }
      const isGroupRow = entity?.Symbol == null;
      return isGroupRow ? allInTierDrawerOptions : allInTierOverridesDrawerOptions;
    },
    [allInTierDrawerOptions, allInTierOverridesDrawerOptions]
  );

  const getFeeTierDrawerOptions = useCallback(
    (entity?: FeeTier, addingChildEntity?: boolean) => {
      if (addingChildEntity) {
        return feeTierOverridesDrawerOptions;
      }
      const isGroupRow = entity?.Symbol == null;
      return isGroupRow ? feeTierDrawerOptions : feeTierOverridesDrawerOptions;
    },
    [feeTierDrawerOptions, feeTierOverridesDrawerOptions]
  );

  const getEntityEditDrawerTitle = useCallback(
    (entity: AllInTier | FeeTier) => {
      if (entity.Symbol == null) {
        // Editing Entity: Tier
        return entity.Tier;
      } else {
        // Editing Entity: Tier > Symbol
        const displaySymbol = customerSecuritiesBySymbol?.get(entity.Symbol)?.DisplaySymbol;
        return `${entity.Tier} > ${displaySymbol ?? entity.Symbol}`;
      }
    },
    [customerSecuritiesBySymbol]
  );

  const { allInTierFilter, feeTierFilter } = usePricingTierFilters();
  const marketFeeModeFilter = useMarketFeeModesFilter();
  const feeCurrencyFilter = useFeeCurrenciesFilter(currenciesList.map(currency => currency.Symbol));
  const pricingAggregationFilter = usePricingAggregationsFilter();
  const symbolFilter = useSymbolsFilter(filteredSecurities);

  const allInTierFilterableProperties: FilterableProperty[] = useMemo(() => {
    if (allInTierFilter == null) {
      return [];
    }
    return [
      // field "AllInTier" --> Represented as "Tier" in Overrides
      { ...allInTierFilter, field: 'Tier' },
      symbolFilter,
      marketFeeModeFilter,
      feeCurrencyFilter,
      pricingAggregationFilter,
    ];
  }, [allInTierFilter, feeCurrencyFilter, marketFeeModeFilter, pricingAggregationFilter, symbolFilter]);

  const feeTierFilterableProperties: FilterableProperty[] = useMemo(() => {
    if (feeTierFilter == null) {
      return [];
    }
    return [
      // field "FeeTier" --> Represented as "Tier" in Overrides
      { ...feeTierFilter, field: 'Tier' },
      symbolFilter,
      marketFeeModeFilter,
      feeCurrencyFilter,
    ];
  }, [feeTierFilter, feeCurrencyFilter, marketFeeModeFilter, symbolFilter]);

  return (
    <EntityAdminPageTabsWrapper tabItems={TAB_ITEMS}>
      <EntityAdminPageREST<AllInTier>
        title={TAB_ITEMS[0].label}
        subtitle="Set up your Base Tiers here."
        path="/all-in-tiers?expanded=true"
        getPostPath={GET_ALL_IN_TIER_POST_PATH}
        getPatchDeletePath={GET_ALL_IN_TIER_PATCH_DELETE_PATH}
        columns={ALL_IN_TIER_COLUMNS}
        entityIDField="Tier"
        childIDField="Symbol"
        addChildEntityButtonProps={TIER_ADD_CHILD_ENTITY_BUTTON_PROPS}
        getEntityDrawerOptions={getAllInTierDrawerOptions}
        filterableProperties={allInTierFilterableProperties}
        entityName="Base Tier"
        allowAddEntity={isAuthorized(ACTION.EDIT_CUSTOMER_PRICING_TIERS)}
        allowEditEntity={isAuthorized(ACTION.EDIT_CUSTOMER_PRICING_TIERS)}
        allowDeleteEntity={isAuthorized(ACTION.EDIT_CUSTOMER_PRICING_TIERS)}
        getEditEntityName={getEntityEditDrawerTitle}
        groupColumnDef={TIER_COLUMN_DEF}
        persistKey="all-in-tiers"
      />
      <EntityAdminPageREST<FeeTier>
        title={TAB_ITEMS[1].label}
        subtitle="Set up your Fee Tiers here."
        path="/fee-tiers?expanded=true"
        getPostPath={GET_FEE_TIER_POST_PATH}
        getPatchDeletePath={GET_FEE_TIER_PATCH_DELETE_PATH}
        columns={FEE_TIERS_COLUMNS}
        entityIDField="Tier"
        childIDField="Symbol"
        addChildEntityButtonProps={TIER_ADD_CHILD_ENTITY_BUTTON_PROPS}
        getEntityDrawerOptions={getFeeTierDrawerOptions}
        filterableProperties={feeTierFilterableProperties}
        entityName="Fee Tier"
        allowAddEntity={isAuthorized(ACTION.EDIT_CUSTOMER_PRICING_TIERS)}
        allowEditEntity={isAuthorized(ACTION.EDIT_CUSTOMER_PRICING_TIERS)}
        allowDeleteEntity={isAuthorized(ACTION.EDIT_CUSTOMER_PRICING_TIERS)}
        getEditEntityName={getEntityEditDrawerTitle}
        groupColumnDef={TIER_COLUMN_DEF}
        persistKey="fee-tiers"
      />
    </EntityAdminPageTabsWrapper>
  );
};
