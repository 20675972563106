import {
  ACTION,
  ApiPermissionActionEnum,
  ROLE,
  type AgGridSearchSelectDropdownProps,
  type ApiPermissionFilter,
  type ColumnDef,
  type User,
} from '@talos/kyoko';
import type { CustomCellEditorProps } from 'ag-grid-react';
import { useRoleAuth } from 'hooks';
import { get, set } from 'lodash';
import { useMemo, type ReactNode } from 'react';
import type { UsersAutocompleteItem } from './shared';

// If the user is implicitly granted this permission, the label is followed by "all".
// Implicitness is when a user has a '*' entry and renders as 'view/read all'
function getIsActionImplicitRow(
  row: { User: User; FilterPermission: ApiPermissionFilter },
  mode: 'User' | 'Subaccount'
): boolean {
  const { FilterPermission } = row;

  if (mode === 'Subaccount') {
    return FilterPermission.Filter.SubAccount === '*';
  } else if (mode === 'User') {
    return FilterPermission.Subject.User === '*';
  }
  return false;
}

const READ_THIS = ApiPermissionActionEnum.Read;
const TRADE_THIS = ApiPermissionActionEnum.Write;

const READ_ALL = ApiPermissionActionEnum.Read + ' All';
const TRADE_ALL = ApiPermissionActionEnum.Write + ' All';

function getActionOptionsBasedOnUser(
  row: { User: User; FilterPermission: ApiPermissionFilter },
  mode: 'User' | 'Subaccount'
): string[] {
  const { User } = row;
  const isImplicit = getIsActionImplicitRow(row, mode);

  // If the role of the user is VIEWER but not TRADER
  // the user can only select VIEW
  if (User.Roles?.includes(ROLE.TRADER)) {
    if (isImplicit) {
      return [READ_ALL, TRADE_ALL];
    } else {
      return [READ_THIS, TRADE_THIS];
    }
  } else {
    if (isImplicit) {
      return [READ_ALL];
    } else {
      return [READ_THIS];
    }
  }
}

const prettifyFilterPermissionAction = (action: ApiPermissionActionEnum | string) => {
  switch (action) {
    case ApiPermissionActionEnum.Write:
      return 'Trade';
    case ApiPermissionActionEnum.Read:
      return 'View';
    case ApiPermissionActionEnum.All:
    case ApiPermissionActionEnum.Admin:
    default:
      return action?.toString();
  }
};

export const useApiPermissionActionColumn = <T = any,>(
  mode: 'User' | 'Subaccount',
  headerTooltipContent?: ReactNode
): ColumnDef<T> => {
  const { isAuthorized } = useRoleAuth();
  const canEditFilterPermissions = isAuthorized(ACTION.EDIT_FILTER_PERMISSIONS);

  return useMemo(
    () =>
      ({
        type: 'custom',
        id: 'action',
        sort: '+',
        sortIndex: 1,
        title: 'Permission',
        frozen: true,
        suppressHeaderMenuButton: true,
        width: 100,
        params: {
          colId: 'action',
          headerComponent: 'filterPermissionActionHeader',
          headerComponentParams: {
            tooltipContent: headerTooltipContent,
          },
          editable: ({ data }) => {
            if (!canEditFilterPermissions) {
              return false;
            }
            const value = get(data, 'FilterPermission.Action');
            const isImplicit = getIsActionImplicitRow(data, mode);
            if (isImplicit) {
              return false;
            }
            return !(value === ApiPermissionActionEnum.Read && getActionOptionsBasedOnUser(data, mode).length <= 1);
          },
          suppressKeyboardEvent: () => true,
          cellEditor: 'searchSelectDropdown',
          cellEditorPopup: true,
          cellEditorParams: (params: CustomCellEditorProps) => {
            return {
              ...params,
              useSearchSelectParams: {
                items: getActionOptionsBasedOnUser(params.data, mode),
                getLabel: value => prettifyFilterPermissionAction(value) || value,
              },
            } satisfies AgGridSearchSelectDropdownProps<ApiPermissionActionEnum | string>;
          },
          valueSetter: ({ newValue, data }: { newValue: UsersAutocompleteItem; data: any }): boolean => {
            if (newValue) {
              set(data, 'FilterPermission.Action', newValue);
              return true;
            }
            return false;
          },
          valueGetter: ({ data }) => {
            return get(data, 'FilterPermission.Action');
          },
          valueFormatter: ({ value, data }) => {
            return `${prettifyFilterPermissionAction(value)}${getIsActionImplicitRow(data, mode) ? ' All' : ''}`;
          },
        },
      } satisfies ColumnDef<T>),
    [canEditFilterPermissions, headerTooltipContent, mode]
  );
};

export function getApiPermissionActionFromUser(user: User): ApiPermissionFilter['Action'] {
  if (user.Roles?.includes(ROLE.TRADER)) {
    return ApiPermissionActionEnum.Write;
  }
  return ApiPermissionActionEnum.Read;
}
