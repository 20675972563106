import type {
  AnalyticsResolutions,
  DateRangeFilter,
  IBidAskSpreadsDatapoint,
  IDealerRTTDatapoint,
  IExecutionMarkoutDatapoint,
  IExecutionSpreadsDatapoint,
  IMarketOrderOutcomeDatapoint,
  IMarketUptimeDatapoint,
  INumExecutionsDatapoint,
  INumOrdersDatapoint,
  IOrdersSummaryDatapoint,
  IParentOrdersDatapoint,
  ISlippageDatapoint,
  ITotalTradingVolumeDatapoint,
  Nullish,
  OptionalProperties,
  PostTradeOrderAnalyticsDatapoint,
  ReportDimension,
  RequiredProperties,
  TabProps,
} from '@talos/kyoko';
import type { Options, Point, SeriesOptionsRegistry } from 'highcharts';
import type { BehaviorSubject, Observable } from 'rxjs';
import type { LayoutPartProps } from './Components/LayoutPart';
import type { AvailableBenchmarks } from './Components/OrdersBubbleChart';

/**
 * The series types that we are actually using in the charts.
 *
 * Note: Highcharts has a lot of series types, but we only use a subset of them, and
 * restricting generics to this type, means that we have better constraints on the
 * `data` property of the type.
 */
export type AnalyticsSeriesOptionsType = SeriesOptionsRegistry[
  | 'SeriesColumnOptions'
  | 'SeriesLineOptions'
  | 'SeriesSunburstOptions'
  | 'SeriesBubbleOptions'
  | 'SeriesArearangeOptions'
  | 'SeriesBarOptions'
  | 'SeriesTreemapOptions'];

export interface MetricOverTimeChartProps<T> extends LayoutPartProps {
  dataObservable: { data: Observable<T[]>; isLoading?: boolean | undefined };
  onSeriesClick?: (instructions: NavigationInstructions) => void;
  onPointHover?: (event: Point, hovered: boolean) => void;
  // any typed for now - I see onLegendClick called, but not used on the components, can we remove them?
  onLegendClick?: (filterUpdate: any) => void;
  startTime: string | undefined;
  endTime: string | undefined;
  resolution: AnalyticsResolutions;
  options?: Options;
  insightsOpen?: boolean;
  metric: keyof T & string;
  dimension: keyof T & Exclude<ReportDimension, 'Order'>;
  idKeys: (keyof T)[];
  getSeriesName: (key: string) => string;
  chartId: string;
}

export interface OrdersBubbleChartProps extends LayoutPartProps {
  dataObservable: Observable<PostTradeOrderAnalyticsDatapoint[]>;
  isLoading?: boolean | undefined;
  benchmarkObs: Observable<AvailableBenchmarks>;
  changeBenchmark: (newBenchmark?: AvailableBenchmarks) => void;
  symbolOptions: Option[];
  selectedSymbolOptions: Option[];
  setSelectedSymbolOptions: (options: Option[]) => void;
  productTypeOptions: Option[];
  selectedProductTypeOptions: Option[];
  setSelectedProductTypeOptions: (options: Option[]) => void;
  urgencyOptions: Option<number[]>[];
  selectedUrgencyOptions: Option<number[]>[];
  setSelectedUrgencyOptions: (options: Option<number[]>[]) => void;
  boundsControlOptions: Option<number[]>[];
  selectedBoundsControlOptions: Option<number[]>[];
  setSelectedBoundsControlOptions: (options: Option<number[]>[]) => void;
  onSeriesClick?: (instructions: NavigationInstructions) => void;
  yAxisObservable: BehaviorSubject<'Slippage' | 'MakerPct'>;
  setYAxis: (axis: 'Slippage' | 'MakerPct') => void;
  enableSlippage: boolean;
  enableMakerRate: boolean;
  updateAxisExtremes: (axisExtremes: AxisExtremes) => void;
}
export interface NavigationInstructions {
  symbol?: string;
  market?: string;
  strategy?: string;
  id?: string;
  dimension?: ReportDimension;
  startDate?: Date;
  endDate?: Date;
  displayName?: string;
  aggregationWindow?: AggregationWindowEnum;
  chartId?: string;
}

export interface NavigationTooltipState {
  left: number | undefined;
  top: number | undefined;
  active: boolean;
  point?: Point;
  navigationInstructions?: NavigationInstructions;
}

export enum ReportType {
  monthlyOverview = 'monthlyOverview',
  marketDetails = 'marketDetails',
  symbolDetails = 'symbolDetails',
  marketsOverview = 'marketsOverview',
  strategiesOverview = 'strategiesOverview',
  symbolsOverview = 'symbolsOverview',
  strategyDetails = 'strategyDetails',
}

export interface IAnalyticsReportTab extends TabProps {
  type: ReportType;
  dimension?: ReportDimension;
  isTemporary?: boolean;
  filter?: ReportFilter;
  customLabel?: boolean;
  focus?: string;
}
export interface IAnalyticsOrderDetailsTab extends TabProps {
  type: 'order-details';
  orderID: string;
  filter?: ReportFilter | undefined;
  // Unused, but makes the type compatible with the rest of the tabs
  focus?: string;
}

export interface ReportFilter extends DateRangeFilter {
  Symbols?: string[];
  Markets?: string[];
  Strategies?: string[];
  AggregationWindow?: AggregationWindowEnum;
}

type OmittedDatapointProperties = 'Timestamp' | 'SourceUpdatedAt' | 'Revision' | 'Resolution';
type OptionalDatapointProperties = 'Asset' | 'Market' | 'Strategy' | 'OrderMarketability' | 'StrategyType';
type DatapointProps<T> = Omit<T, OmittedDatapointProperties>;

export type TotalTradingVolumeStats = RequiredProperties<
  OptionalProperties<
    DatapointProps<ITotalTradingVolumeDatapoint>,
    OptionalDatapointProperties | 'TradeType' | 'AggressorFlag' | 'UpdateAction' | 'LowerVPLimit' | 'UpperVPLimit'
  >,
  'TotalVolume'
>;

export type DealerRTTDatapoint = DatapointProps<IDealerRTTDatapoint>;

export type NumOrdersDatapoint = RequiredProperties<
  OptionalProperties<DatapointProps<INumOrdersDatapoint>, OptionalDatapointProperties>,
  'NumberOfOrders'
>;

export type MarketOrderOutcomeDatapoint = DatapointProps<IMarketOrderOutcomeDatapoint>;

export type ExecutionMarkoutDatapoint = DatapointProps<IExecutionMarkoutDatapoint>;

export type OrdersSummaryDatapoint = DatapointProps<IOrdersSummaryDatapoint>;

export type ParentOrdersDatapoint = DatapointProps<IParentOrdersDatapoint>;

export type SlippageDatapoint = OptionalProperties<
  DatapointProps<ISlippageDatapoint> & { Asset?: string },
  OptionalDatapointProperties | 'ReferencePrice' | 'AnalyticsOrderDepth'
>;

export type NumExecutionsDatapoint = DatapointProps<INumExecutionsDatapoint>;

export type MarketUptimeDatapoint = DatapointProps<IMarketUptimeDatapoint>;

export type BidAskSpreadsDatapoint = OptionalProperties<DatapointProps<IBidAskSpreadsDatapoint>, 'Asset' | 'Market'>;

export type ExecutionSpreadsDatapoint = OptionalProperties<
  DatapointProps<IExecutionSpreadsDatapoint>,
  'Asset' | 'Market'
>;
export interface Option<TValue = string[]> {
  label: string;
  description?: string;
  value: TValue;
}

export interface AxisExtremes {
  minX: Nullish<number>;
  maxX: Nullish<number>;
  minY: Nullish<number>;
  maxY: Nullish<number>;
}

export type AggregationWindowOption = Option<{ AggregationWindow: AggregationWindowEnum; StartDate: Date }>;

export enum AggregationWindowEnum {
  Month = 'Month',
  Past_7_Days = 'Past_7_Days',
  Past_30_Days = 'Past_30_Days',
  Week_to_Date = 'Week_to_Date',
  Month_to_Date = 'Month_to_Date',
}

export const PREMIUM_ANALYTICS_CONFIG_ID = 'analytics.OnPlatformAnalyticsTier';

/** The maximum width for a column / bar in a chart */
export const MAX_POINT_WIDTH = 64;
/** The minimum length of a column / bar in a chart, to ensure that we can always see some kind of data point */
export const MIN_POINT_LENGTH = 3;

/** Once we have more than this many series, highcharts will render using webgl instead of svg
 * Unfortunately, rendering with webgl causes the columns to be 1px wide, which is not what we want.
 * We can't completely disable this, but we can set the threshold to a very high number, so that it
 * will (hopefully) never be triggered.
 * Needs to be higher than the number of symbols a client might have traded in the past year (probably our highest series count).
 */
export const BOOST_SERIES_THRESHOLD = 20_000;

export const ANALYTICS_TOOLTIP_ID = 'analytics-nav-tooltip';
