import { createSlice } from '@reduxjs/toolkit';
import type { AppConfigState } from 'providers/AppConfigProvider/AppConfigProvider.types';

let blotter3Actions: ReturnType<typeof createBlotter3Slice>['actions'] | undefined;
export const getBlotter3SliceActions = () => {
  if (!blotter3Actions) {
    throw new Error('AppConfigSlice not initialized');
  }
  return blotter3Actions;
};

/** Reducer for {@link AppConfigReduxState}'s blotters3, providing storage for with usePersistedBlotterTableNative (Grid and Filter state storage) */
export const createBlotter3Slice = (appConfig: AppConfigState) => {
  const result = createSlice({
    name: 'appConfig/blotters3',
    initialState: appConfig.blotters3,
    reducers: {
      setGridState: (state, action) => {
        // We definitely use aggregation within Ag-Grid (it's critical), but the gridState stores the mapping of the named aggregation you're using for the grid, and because we presently apply agg's
        // individually in the aggFuncs, we don't need to store the aggregation state in the grid state.
        // - There are situations where this may prove useful in the future, but it should be on a per-blotter basis
        delete action.payload.gridState.aggregation;
        if (!(action.payload.blotterId in state)) {
          state[action.payload.blotterId] = {};
        }
        state[action.payload.blotterId].gridState = action.payload.gridState;
      },

      resetGridState: (state, action) => {
        if (action.payload in state) {
          delete state[action.payload].gridState;
        }
      },

      setFilterState: (state, action) => {
        if (!(action.payload.blotterId in state)) {
          state[action.payload.blotterId] = {};
        }
        state[action.payload.blotterId].blotterFilter = action.payload.blotterFilter;
      },
    },
  });
  blotter3Actions = result.actions;
  return result;
};
