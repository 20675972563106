import {
  AddressRoutingTypeEnum,
  getAddressRoutingTypeOfCurrency,
  getAddressTypeDrawerOptions,
  getCurrencyDrawerOptions,
  getCustomerDrawerOptions,
  type ColumnDef,
  type Currency,
  type Customer,
  type ICustomerAddress,
  type ICustomerRoutingInfo,
  type InputsAndDropdownsDrawerOption,
} from '@talos/kyoko';
import type { ICellRendererParams, ValueGetterParams } from 'ag-grid-community';
import type React from 'react';
import type { CustomerAddressesEntity } from './index';

export function getCustomerAddressesColumns(
  RevealRoutingInfoRenderer: (params: ICellRendererParams) => React.ReactNode,
  routingInfoMap: Map<string, ICustomerRoutingInfo>
): ColumnDef<ICustomerAddress>[] {
  return [
    { type: 'addressStatus', field: 'Status' },
    { type: 'date', field: 'Timestamp', sortable: true, sort: '-' },
    { type: 'transactionType', field: 'AddressType', width: 150, sortable: true },
    { type: 'currency', field: 'Currency', width: 150 },
    { type: 'text', field: 'Name' },
    { type: 'text', field: 'CustomerAddressID', hide: true },
    { type: 'counterparty', field: 'Counterparty' },
    { type: 'marketAccountSourceAccountID', field: 'MarketAccount', hide: true },
    { type: 'text', field: 'CustomerUser', hide: true },
    {
      title: 'Wallet Address',
      id: 'WalletAddress',
      type: 'custom',
      params: {
        cellRenderer: RevealRoutingInfoRenderer,
        valueGetter: ({ data }: ValueGetterParams<ICustomerAddress>) =>
          data && routingInfoMap.get(data.CustomerAddressID)?.WalletAddress,
      },
    },
    {
      title: 'Memo',
      id: 'Memo',
      type: 'custom',
      params: {
        cellRenderer: RevealRoutingInfoRenderer,
        valueGetter: ({ data }: ValueGetterParams<ICustomerAddress>) =>
          data && routingInfoMap.get(data.CustomerAddressID)?.Memo,
      },
    },
  ];
}

export function getCustomerAddressDrawerOptions({
  customers,
  currencies,
}: {
  customers: Customer[];
  currencies: Currency[];
}): InputsAndDropdownsDrawerOption<CustomerAddressesEntity>[] {
  const currenciesBySymbol = new Map(currencies.map(currency => [currency.Symbol, currency]));
  return [
    {
      field: 'Counterparty',
      type: 'dropdown',
      title: 'Counterparty',
      options: getCustomerDrawerOptions(customers),
      required: true,
    },
    {
      field: 'Currency',
      type: 'dropdown',
      title: 'Currency',
      options: getCurrencyDrawerOptions(currencies),
      required: true,
    },
    {
      field: 'AddressType',
      type: 'dropdown',
      title: 'Address Type',
      options: getAddressTypeDrawerOptions(),
      required: true,
    },

    {
      field: 'Name',
      type: 'input',
      title: 'Name of Address',
      required: true,
      placeholder: 'Name...',
      disableField: entity => !entity.Currency,
    },
    {
      field: '_WalletAddress',
      type: 'input',
      title: 'Deposit Address',
      required: true,
      placeholder: 'Address...',
      hideField: entity =>
        getAddressRoutingTypeOfCurrency(currenciesBySymbol.get(entity.Currency)) === AddressRoutingTypeEnum.Fiat,
      disableField: entity => !entity.Currency,
    },
    {
      field: '_Memo',
      type: 'input',
      title: 'Memo of Address',
      placeholder: 'Memo...',
      disableField: entity => !entity.Currency,
    },
  ];
}
