import type { createListenerMiddleware, ThunkDispatch, UnknownAction } from '@reduxjs/toolkit';
import type { IWebSocketClient } from '@talos/kyoko';
import type { ManualRFQPricingState } from 'components/OMS/ManualRFQPricingView/types';
import type { PortfolioViewLayoutState } from 'containers/Portfolio/PortfolioManagement/stateManagement/portfolioViewLayoutSlice';
import type { AppConfigReduxState } from 'providers/AppConfigRedux/AppConfigSlice';
import type { AppLayoutState } from '../../components/AppLayout/AppLayoutSlice';
import type { MultilegComboState } from '../../components/MultilegCombo/types';
import type { ActiveOrderCardsState } from '../../components/OMS/Cards/types';
import type { CareOrderState } from '../../components/OMS/CareOrderFormView/types';
import type { DetailsDrawerState } from '../../components/OMS/Details/DetailsDrawerSlice';
import type { ManualOrderFillState } from '../../components/OMS/ManualOrderFillView/ManualOrderFillSlice';
import type { ManualTradeEntryState } from '../../components/OMS/ManualTradeView/ManualTradeSlice';
import type { OrderState } from '../../components/OMS/NewOrder/types';
import type { RFQState } from '../../components/OMS/NewRFQ/types';
import type { OrderPresetState } from '../../components/OMS/Presets/types';
import type { SalesOrderState } from '../../components/OMS/SalesOrder/types';
import type { SalesRFQState } from '../../components/OMS/SalesRFQView/types';
import type { OMSReferenceDataState, OMSState } from '../../components/OMS/types';
import type { streamingDataSlice } from './streamingDataSlice';

export type AppState = {
  appConfig: AppConfigReduxState;
  appLayout: AppLayoutState;
  cards: ActiveOrderCardsState;
  careOrder: CareOrderState;
  detailsDrawer: DetailsDrawerState;
  manualOrderFill: ManualOrderFillState;
  manualRFQPricing: ManualRFQPricingState;
  manualTrade: ManualTradeEntryState;
  multilegCombo: MultilegComboState;
  OMS: OMSState;
  order: OrderState;
  portfolioViewLayout: PortfolioViewLayoutState;
  presets: OrderPresetState;
  referenceData: OMSReferenceDataState;
  streamingData: ReturnType<typeof streamingDataSlice.reducer>;
  rfq: RFQState;
  salesOrder: SalesOrderState;
  salesRFQ: SalesRFQState;
};

export interface AppExtraArgument {
  wsClient: IWebSocketClient<unknown>;
}

export type AppStateListenerStart = ReturnType<typeof buildAppStateListener>;
export const buildAppStateListener = (listenerMiddleware: ReturnType<typeof createListenerMiddleware>) => {
  return listenerMiddleware.startListening.withTypes<
    AppState,
    ThunkDispatch<AppState, AppExtraArgument, UnknownAction>,
    AppExtraArgument
  >();
};
