import {
  type Field,
  FieldValidationLevel,
  type FieldValidationRule,
  HIDDEN_MARKET_ACCOUNT_GROUP_PREFIX,
} from '@talos/kyoko';

export const VALIDATION_RULES: { group: FieldValidationRule<Field<string>>[] } = {
  group: [
    // group must not start with "_"
    field => {
      if (field.value?.startsWith(HIDDEN_MARKET_ACCOUNT_GROUP_PREFIX)) {
        return {
          message: `Group must not start with "${HIDDEN_MARKET_ACCOUNT_GROUP_PREFIX}"`,
          level: FieldValidationLevel.Error,
        };
      }

      // OK
      return null;
    },
  ],
};
