import {
  type ConnectionType,
  type MarketSelectionAvailabilityStatus,
  useMarketAccountsContext,
  useMarketsContext,
} from '@talos/kyoko';
import { useCallback } from 'react';
import { useMSSForSymbolByAccount } from '../../providers/MarketSecurityStatusProvider';
import { useMarketConfiguredCondition } from '../MarketSelector/conditions/useMarketConfiguredCondition';
import { useMarketOnlineCondition } from '../MarketSelector/conditions/useMarketOnlineCondition';
import { useMarketSupportedCondition } from '../MarketSelector/conditions/useMarketSupportedCondition';
import { useMSSCapabilityEnabledCondition } from '../MarketSelector/conditions/useMSSCapabilityEnabledCondition';

/**
 * Returns a function that can be used to check the availability of a market.
 * Use it to see if a market supports the connectiontype. The symbol is used to narrow which market statuses to check.
 */
export function useGetSymbolMarketsAvailability({
  symbol,
  connectionType,
}: {
  symbol: string | undefined;
  connectionType: ConnectionType;
}) {
  const { marketAccountsByName } = useMarketAccountsContext();
  const { marketsByName } = useMarketsContext();
  const mssMap = useMSSForSymbolByAccount({ symbol, tag: 'useGetSymbolMarketsAvailability' });
  const { condition: marketConfiguredCondition, ready: marketConfiguredReady } = useMarketConfiguredCondition({
    connectionType: connectionType,
  });
  const { condition: marketSupportedCondition, ready: marketSupportedReady } = useMarketSupportedCondition({
    connectionType,
  });
  const { condition: marketOnlineCondition, ready: marketOnlineReady } = useMarketOnlineCondition({ connectionType });
  const { condition: mssCapabilityCondition, ready: mssCapabilityReady } = useMSSCapabilityEnabledCondition({
    mssByMarketAccount: mssMap,
    capability: 'Orders',
  });

  return useCallback(
    function getSymbolMarketAvailability(marketName: string): MarketSelectionAvailabilityStatus {
      const maybeMarketAccount = marketAccountsByName.get(marketName);
      let maybeMarket = marketsByName.get(marketName);
      if (!maybeMarketAccount && !maybeMarket) {
        return { available: false, failedReason: `Market account ${marketName} not found` };
      }

      // if we have found a market account, get use the market name from the account.
      // if we have not found a market account, assume lookup by market name.
      maybeMarket = maybeMarketAccount ? marketsByName.get(maybeMarketAccount.Market) : maybeMarket;
      if (!maybeMarket) {
        return { available: false, failedReason: `Market ${marketName} not found` };
      }

      const marketSupported = marketSupportedCondition(maybeMarket, maybeMarketAccount);
      if (marketSupportedReady && !marketSupported.available) {
        return marketSupported;
      }

      const marketConfiguredResult = marketConfiguredCondition(maybeMarket, maybeMarketAccount);
      if (marketConfiguredReady && !marketConfiguredResult.available) {
        return marketConfiguredResult;
      }

      const marketOnline = marketOnlineCondition(maybeMarket, maybeMarketAccount);
      if (marketOnlineReady && !marketOnline.available) {
        return marketOnline;
      }

      const mssCapability = mssCapabilityCondition(maybeMarket, maybeMarketAccount);
      if (mssCapabilityReady && !mssCapability.available) {
        return mssCapability;
      }

      return { available: true };
    },
    [
      marketAccountsByName,
      marketsByName,
      marketConfiguredCondition,
      marketConfiguredReady,
      marketOnlineReady,
      marketOnlineCondition,
      marketSupportedReady,
      marketSupportedCondition,
      mssCapabilityCondition,
      mssCapabilityReady,
    ]
  );
}
