import type { Column, ColumnDef, SubAccountNameColumnParams, SubAccountPositionLimit } from '@talos/kyoko';
import { compact } from 'lodash';
import { useMemo } from 'react';
import { useFeatureFlag, useRollupTreeRef } from '../../../../hooks';
import { useAssetColumn } from '../../../../utils/columns/useAssetColumn';
import {
  LIMIT_CURRENCIES_MAP,
  assetColumnCustomDataModifier,
  customSubAccountLimitComparator,
  subAccountLimitsMatchAssetFilter,
} from '../utils';
import { scopeColumn } from './scopeColumn';

export type UseSubAccountTradingLimitsColumns = Pick<
  SubAccountNameColumnParams,
  'subAccountOptionsOverride' | 'initialSelectedItem' | 'getDropdownGroup'
>;

export const useColumns = ({
  subAccountOptionsOverride,
  initialSelectedItem,
  getDropdownGroup,
}: UseSubAccountTradingLimitsColumns) => {
  const assetColumn = useAssetColumn<SubAccountPositionLimit>({
    assetKey: 'MatchAsset',
    comparator: customSubAccountLimitComparator,
    customDataModificationOnChange: assetColumnCustomDataModifier,
    assetFilter: subAccountLimitsMatchAssetFilter,
  });

  const rollupTreeRef = useRollupTreeRef();

  const { enablePositionLimitScopeColumn, showCustomerOrderAcceptanceRules } = useFeatureFlag();

  // first one is a 2.50 release flag to be removed after 2.50 release. Second is actual flag. Only show the col when thats enabled
  const showScopeColumn = enablePositionLimitScopeColumn && showCustomerOrderAcceptanceRules;

  return useMemo<Column[]>(
    () =>
      compact([
        { type: 'mode', field: 'Mode', title: 'Enabled', width: 80 },
        { type: 'text', field: 'LimitID', width: 110 },
        assetColumn,
        {
          type: 'subAccountName',
          field: 'SubAccount',
          title: 'Sub Account / Rollup',
          editable: true,
          params: {
            wildcard: true,
            subAccountOptionsOverride,
            initialSelectedItem,
            getDropdownGroup,
            rollupTree: rollupTreeRef,
          } satisfies SubAccountNameColumnParams,
        },
        { type: 'positionDirection', field: 'Direction', editable: true, width: 100 },
        // Hiding below until we build support for it
        // {
        //   type: 'size',
        //   title: 'Warn threshold',
        //   field: 'WarnThreshold',
        //   editable: true,
        //   width: 160,
        //   description: 'Display a warning in the Talos user-interface when the order size exceeds this threshold',
        //   params: { currencyField: 'ThresholdAsset' },
        // },
        {
          type: 'number',
          title: 'Reject threshold',
          field: 'RejectThreshold',
          editable: true,
          width: 160,
          description: 'Prevent the order from being submitted when the order size exceeds this threshold.',
        },
        {
          type: 'thresholdAsset',
          field: 'ThresholdAsset',
          title: 'Threshold Asset',
          editable: true,
          width: 140,
          params: {
            limitAssetsBySymbol: LIMIT_CURRENCIES_MAP,
            keyOfMatchAssetToIncludeInOptions: 'MatchAsset' satisfies keyof SubAccountPositionLimit,
          },
        },
        showScopeColumn ? scopeColumn : undefined,
        {
          type: 'text',
          field: 'Description',
          description: 'Give your limit a description to distinguish it from other limits.',
          width: 120,
          editable: true,
        },
        { type: 'filler', id: 'filler' },
        { type: 'remove', id: 'remove', pinned: 'right' },
      ] satisfies (ColumnDef<SubAccountPositionLimit> | undefined)[]),
    [subAccountOptionsOverride, initialSelectedItem, getDropdownGroup, assetColumn, rollupTreeRef, showScopeColumn]
  );
};
