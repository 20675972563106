import {
  AccordionGroup,
  ExpandableBottomPanel,
  Flex,
  HStack,
  ProductTypeEnum,
  Tab,
  TabAppearance,
  TabList,
  Tabs,
  TabSize,
  useExpandablePanel,
  useTabs,
  VStack,
} from '@talos/kyoko';
import { useCallback, useMemo, useState } from 'react';
import { ErrorBoundary } from '../../../components/ErrorBoundary';
import { BlotterWrapper, XScrollableContainer } from '../styles';
import { OperationsOverviewBlotterContainer } from './blotter';
import { ControlPanel } from './components/ControlPanel';
import {
  OperationsOverviewFiltersProvider,
  useOperationsOverviewFilters,
} from './providers/OperationsOverviewFiltersProvider';
import { OperationsOverviewInteractionsProvider } from './providers/OperationsOverviewInteractionsProvider';

import { useFeatureFlag } from '../../../hooks';
import { OperationsOverviewOld } from '../OperationsOverviewOld/OperationsOverview';
import { OpsBalancesChartModule } from './BalancesChart/OpsBalancesChartModule';
import { OpsEMRChartModule } from './EMRChart/OpsEMRChartModule';
import {
  OperationsOverviewPositionsProvider,
  useOperationsOverviewPositions,
} from './providers/OperationsOverviewPositionsProvider';

const TABS = [
  {
    label: 'Balances and Positions',
    render: () => <OperationsOverviewBlotterContainer />,
  },
];

const DEFAULT_BLOTTER_HEIGHT = 450;
const OPS_OVERVIEW_BLOTTER_HEIGHT = 'OpsOverviewBlotterHeight';

const OperationsOverviewInner = () => {
  const { initialFilterClauses } = useOperationsOverviewFilters();

  const [isBlotterMaximized, setIsBlotterMaximized] = useState(false);
  const [isBlotterMinimized, setIsBlotterMinimized] = useState(false);

  const handleMaximizeBlotter = useCallback((shouldExpand: boolean) => {
    setIsBlotterMinimized(false);
    setIsBlotterMaximized(curr => shouldExpand ?? !curr);
  }, []);

  const handleMinimizeBlotter = useCallback((shouldMinimize: boolean) => {
    setIsBlotterMaximized(false);
    setIsBlotterMinimized(curr => shouldMinimize ?? !curr);
  }, []);

  const tabs = useTabs({
    initialSelectedIndex: 0,
    initialItems: TABS,
  });

  const { containerRef, ...panelProps } = useExpandablePanel<HTMLDivElement>({
    initialHeight: parseInt(localStorage.getItem(OPS_OVERVIEW_BLOTTER_HEIGHT) ?? '0') || DEFAULT_BLOTTER_HEIGHT,
    isExpanded: isBlotterMaximized,
    isMinimized: isBlotterMinimized,
    onToggleExpanded: handleMaximizeBlotter,
    onToggleMinimize: handleMinimizeBlotter,
    onAdjustedHeight(newHeight) {
      localStorage.setItem(OPS_OVERVIEW_BLOTTER_HEIGHT, newHeight.toString());
    },
  });

  const { positionsData } = useOperationsOverviewPositions();
  const anyDerivativesPositionPresent = useMemo(
    () => positionsData?.some(p => p.AssetType !== ProductTypeEnum.Spot) ?? true, // nullish positionsData -> default this to true
    [positionsData]
  );

  return (
    <Flex h="100%" w="100%" flexDirection="column" overflow="hidden">
      <AccordionGroup>
        <ControlPanel initialFilterClauses={initialFilterClauses} />
      </AccordionGroup>

      <VStack w="100%" h="100%" overflow="hidden" position="relative" ref={containerRef}>
        <XScrollableContainer w="100%" justifyContent="center">
          <HStack minHeight="300px" w="100%" h="100%" minWidth="1200px" gap="spacingTiny">
            {/* Only show the emr chart module if there are any derivatives positions present given the positions data we are given */}
            {anyDerivativesPositionPresent && <OpsEMRChartModule entities={positionsData} />}
            <OpsBalancesChartModule entities={positionsData} />
          </HStack>
        </XScrollableContainer>
        <BlotterWrapper>
          <ExpandableBottomPanel
            {...panelProps}
            showControls
            header={
              <Tabs {...tabs} appearance={TabAppearance.Filled} size={TabSize.Small}>
                <TabList>
                  {TABS.map((tab, i) => (
                    <Tab key={i} {...tab} />
                  ))}
                </TabList>
              </Tabs>
            }
          >
            {TABS.map(
              (tab, i) =>
                tabs.selectedIndex === i && <ErrorBoundary key={`component-${i}`}>{tab.render()}</ErrorBoundary>
            )}
          </ExpandableBottomPanel>
        </BlotterWrapper>
      </VStack>
    </Flex>
  );
};

export const OperationsOverviewNew = () => {
  return (
    <OperationsOverviewFiltersProvider>
      <OperationsOverviewInteractionsProvider>
        <OperationsOverviewPositionsProvider>
          <OperationsOverviewInner />
        </OperationsOverviewPositionsProvider>
      </OperationsOverviewInteractionsProvider>
    </OperationsOverviewFiltersProvider>
  );
};

export const OperationsOverview = () => {
  const { enableNewOpsOverviewPage } = useFeatureFlag();
  return enableNewOpsOverviewPage ? <OperationsOverviewNew /> : <OperationsOverviewOld />;
};
