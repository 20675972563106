import {
  getCustomerDrawerOptions,
  getExecutionStrategiesDrawerOptions,
  getOrdTypeDrawerOptions,
  getSecurityDrawerOptions,
  getTimeDrawerOptions,
  getTimeInForceDrawerOptions,
  type ColumnDef,
  type Customer,
  type CustomerSecurity,
  type ICustomerOrderExecutionRule,
  type InputsAndDropdownsDrawerOption,
} from '@talos/kyoko';
import { isActiveRuleColumn } from '../columns';

export function getCustomerOrderExecutionRulesDrawerOptions({
  customers,
  securities,
}: {
  customers: Customer[];
  securities: CustomerSecurity[];
}): InputsAndDropdownsDrawerOption<ICustomerOrderExecutionRule>[] {
  return [
    {
      field: 'Counterparty',
      type: 'dropdown',
      title: 'Counterparty',
      options: getCustomerDrawerOptions(customers),
    },
    {
      field: 'Symbol',
      type: 'dropdown',
      title: 'Symbol',
      options: getSecurityDrawerOptions(securities),
    },
    {
      field: 'ExecutionStrategy',
      type: 'dropdown',
      title: 'Execution Strategy',
      required: true,
      options: getExecutionStrategiesDrawerOptions(),
    },
    { type: 'divider' },
    {
      field: 'TimeInForce',
      type: 'dropdown',
      title: 'Time In Force',
      options: getTimeInForceDrawerOptions(),
    },
    {
      field: 'Priority',
      type: 'inputNumeric',
      title: 'Priority',
    },
    {
      field: 'StartTime',
      type: 'dropdown',
      title: 'Start Time',
      options: getTimeDrawerOptions(),
    },
    {
      field: 'EndTime',
      type: 'dropdown',
      title: 'End Time',
      options: getTimeDrawerOptions(),
    },
    {
      field: 'OrdType',
      type: 'dropdown',
      title: 'Order Type',
      options: getOrdTypeDrawerOptions(),
    },
    {
      field: 'MinQuantityThreshold',
      type: 'input',
      title: 'Min Quantity Threshold',
    },
    {
      field: 'MaxQuantityThreshold',
      type: 'input',
      title: 'Max Quantity Threshold',
    },
    {
      field: 'MinNotionalThreshold',
      type: 'input',
      title: 'Min Notional Threshold',
    },
    {
      field: 'MaxNotionalThreshold',
      type: 'input',
      title: 'Max Notional Threshold',
    },
  ];
}

export const CUSTOMER_EXECUTION_RULES_COLUMNS: ColumnDef<ICustomerOrderExecutionRule>[] = [
  isActiveRuleColumn,
  {
    type: 'counterparty',
    field: 'Counterparty',
    title: 'Counterparty',
    width: 170,
    sortable: true,
    sort: '+',
    description: 'Counterparty this rule applies to',
  },
  {
    type: 'security',
    field: 'Symbol',
    width: 170,
    sortable: true,
    sort: '-',
    params: { showAsteriskOnEmpty: true },
  },
  {
    type: 'prettyText',
    field: 'ExecutionStrategy',
    width: 170,
    description:
      'OrderAcceptance means orders matching this rule will not be executed in the market before being confirmed to the client.',
  },
  {
    type: 'prettyText',
    field: 'TimeInForce',
    width: 130,
  },
  {
    type: 'prettyText',
    field: 'OrdType',
    width: 130,
  },
  {
    type: 'number',
    field: 'Priority',
    width: 120,
    sortable: true,
    sort: '-',
    description:
      'If multiple rules with the same granularity apply to an order, priority order will determine which takes precedence',
  },
  { type: 'time', field: 'StartTime', width: 150 },
  { type: 'time', field: 'EndTime', width: 150 },
  {
    type: 'size',
    field: 'MinQuantityThreshold',
    width: 150,
    description: `Minimum quantity (in units of base currency) this rule will apply to`,
    hide: true,
  },
  {
    type: 'size',
    field: 'MaxQuantityThreshold',
    width: 150,
    description: `Maximum quantity (in units of base currency) this rule will apply to`,
    hide: true,
  },
  {
    type: 'size',
    field: 'MinNotionalThreshold',
    width: 175,
    description: `Minimum amount ( in units of quote currency) this rule will apply to`,
    hide: true,
  },
  {
    type: 'size',
    field: 'MaxNotionalThreshold',
    width: 175,
    description: 'Maximum amount (in units of quote currency) this rule will apply to',
    hide: true,
  },
];
