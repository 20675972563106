import type { RowGroupsOpenedState } from '@talos/kyoko';
import { OperationsOverviewBlotter } from './OperationsOverviewBlotter';
import type { OperationsOverviewBlotterColumnSpecification } from './useOperationsOverviewBlotterColumns';

const sharedColumns = [
  'balance',
  'balanceEquivalent',
  'position-qty',
  'Equivalent.Amount',
  'Equivalent.UnrealizedPnL',
  'venueEquityEquivalent',
  'talosEquityEquivalent',
  'Equivalent.initialMargin',
  'Equivalent.maintenanceMargin',
] satisfies OperationsOverviewBlotterColumnSpecification[];

const defaultRowGroupsOpened: RowGroupsOpenedState = {};

const byMarketColumns = [
  'reconWarning',
  { field: 'market', rowGroupIndex: 0, rowGroup: true, hide: true },
  { field: 'MarketAccount', rowGroupIndex: 1, rowGroup: true, hide: true },
  { field: 'underlying', rowGroupIndex: 2, rowGroup: true, hide: true },
  { field: 'Asset', rowGroupIndex: 3, rowGroup: true, hide: true },
  'weight',
  ...sharedColumns,
] satisfies OperationsOverviewBlotterColumnSpecification[];

// This component wraps the blotter and specifically handles grabbing the correct blotterID and passing it to the blotter,
// also making sure to re-mount the blotter on blotterID change
export const OperationsOverviewBlotterContainer = () => {
  // Temporary additional nesting layer (/Market). We might get rid of this in time for 2.50 (todo Olof)
  const blotterID = `OPERATIONS_OVERVIEW_BLOTTER/Market`;
  return (
    <OperationsOverviewBlotter
      key={blotterID}
      blotterID={blotterID}
      defaultColumns={byMarketColumns}
      defaultRowGroupsOpened={defaultRowGroupsOpened}
    />
  );
};
