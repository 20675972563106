import {
  Checkbox,
  HelpIcon,
  ICON_SIZES,
  Icon,
  IconName,
  InlineFormattedNumber,
  NumberVariants,
  OrderFormSides,
  SideEnum,
  Tooltip,
  isCounterCurrency,
  toBigWithDefault,
  useSecurityDefaultIncrement,
  type BuyingPower,
  type Market,
  type MarketAccount,
  type Security,
} from '@talos/kyoko';
import Big from 'big.js';
import type { ChangeEventHandler } from 'react';

import { useTheme } from 'styled-components';
import { DISABLED_FOR_SYNTHETIC_CCY, SYNTHETIC_CCY_WARNING } from 'tokens/tooltips';
import { LimitWarning, MarketAccountBalances, MarketAccountName, MarketAccountRow } from './styles';

const BuyingPowerBalance = ({ side, amount, quantity, currency, isWarning, security }: BuyingPowerBalanceProps) => {
  const theme = useTheme();
  const buyingPowerSpec = useSecurityDefaultIncrement({
    security,
    isCcy: isCounterCurrency(currency, security),
  });

  return (
    <>
      <InlineFormattedNumber
        background={theme.colors.gray['010']}
        number={amount}
        currency={currency}
        increment={buyingPowerSpec}
        align="right"
      />
      {side && <span style={{ marginLeft: theme.spacingSmall }}>{side}</span>}
      {isWarning && (
        <LimitWarning>
          Exceeds By:
          <InlineFormattedNumber
            isSmall
            background={theme.colors.gray['010']}
            number={Big(quantity || '0')
              .minus(amount || '0')
              .toFixed()}
            currency={currency}
            increment={buyingPowerSpec}
            variant={NumberVariants.Warning}
            align="right"
          />
        </LimitWarning>
      )}
    </>
  );
};

const BuyingPowerRows = ({
  quantity,
  security,
  buyingPower,
  buyingPowerUseMaxOrderSize,
  buyExceedsLimit,
  sellExceedsLimit,
  side,
}: BuyingPowerRowsProps) => {
  const theme = useTheme();
  if (buyingPower == null || security == null) {
    return null;
  }

  const isTwoWay = side === OrderFormSides.Twoway;
  const showMaxBuy = isTwoWay || side === OrderFormSides.Buy || side == null;
  const showMaxSell = isTwoWay || side === OrderFormSides.Sell || side == null;

  const maxBuy = buyingPower.getMaxBuy({ useMaxOrderSize: buyingPowerUseMaxOrderSize });
  const maxSell = buyingPower.getMaxSell({ useMaxOrderSize: buyingPowerUseMaxOrderSize });

  // If we are showing both sides, we want to suffix with "Buy" or "Sell" to make the two buying powers clear what they refer to
  const showingBothSides = showMaxBuy && showMaxSell;

  return (
    <>
      {maxBuy && showMaxBuy && (
        <BuyingPowerBalance
          side={showingBothSides ? SideEnum.Buy : undefined}
          amount={maxBuy}
          currency={buyingPower.Currency}
          isWarning={buyExceedsLimit}
          security={security}
          quantity={quantity}
        />
      )}
      {maxSell && showMaxSell && (
        <div style={{ marginTop: showingBothSides ? theme.spacingSmall : 0 }}>
          <BuyingPowerBalance
            side={showingBothSides ? SideEnum.Sell : undefined}
            amount={maxSell}
            currency={buyingPower.Currency}
            isWarning={sellExceedsLimit}
            security={security}
            quantity={quantity}
          />
        </div>
      )}
    </>
  );
};

export const MarketAccountListItem = ({
  name,
  label,
  status,
  isSelected,
  hasError,
  disabledForCounterCurrency,
  showSyntheticCcyWarning,
  onCheckboxChange,
  isConfigured,
  security,
  buyingPower,
  side,
  quantity,
  showBuyingPower,
  buyingPowerUseMaxOrderSize,
}: MarketAccountListItemProps) => {
  const quantityBig = toBigWithDefault(quantity, 0);
  const maxBuy = buyingPower?.getMaxBuy({ useMaxOrderSize: buyingPowerUseMaxOrderSize });
  const maxSell = buyingPower?.getMaxSell({ useMaxOrderSize: buyingPowerUseMaxOrderSize });

  const buyExceedsLimit = isSelected && maxBuy != null && quantityBig.gt(maxBuy);
  const sellExceedsLimit = isSelected && maxSell != null && quantityBig.gt(maxSell);

  const showMarketError = isConfigured && hasError;

  const theme = useTheme();

  return (
    <MarketAccountRow key={name} data-testid={`MARKET-ACCOUNT-ROW:${name}`}>
      <MarketAccountName data-testid="market-account-name">
        <Checkbox id={name} name={name} checked={isSelected} onChange={onCheckboxChange}>
          {label}
        </Checkbox>
        {showSyntheticCcyWarning && (
          <HelpIcon
            tooltip={disabledForCounterCurrency ? DISABLED_FOR_SYNTHETIC_CCY : SYNTHETIC_CCY_WARNING}
            style={{ marginLeft: theme.spacingTiny }}
            size={ICON_SIZES.SMALL}
          />
        )}
        {showMarketError && (
          <Tooltip tooltip={`${status || 'Market Error'}`}>
            <Icon
              icon={IconName.ExclamationCircle}
              color={theme.colors.yellow.lighten}
              style={{ marginLeft: theme.spacingTiny }}
              size={ICON_SIZES.SMALL}
            />
          </Tooltip>
        )}
      </MarketAccountName>

      {showBuyingPower && security && (
        <MarketAccountBalances>
          <BuyingPowerRows
            security={security}
            buyingPower={buyingPower}
            buyExceedsLimit={buyExceedsLimit}
            sellExceedsLimit={sellExceedsLimit}
            quantity={quantityBig.toFixed()}
            buyingPowerUseMaxOrderSize={buyingPowerUseMaxOrderSize}
            side={side}
          />
        </MarketAccountBalances>
      )}
    </MarketAccountRow>
  );
};
type MarketAccountListItemProps = {
  name: string;
  label: string;
  marketAccount?: MarketAccount;
  market: Market;
  status: string;
  isSelected: boolean;
  hasError: boolean;
  disabledForCounterCurrency: boolean;
  showSyntheticCcyWarning: boolean;
  onCheckboxChange: ChangeEventHandler<HTMLInputElement>;
  isConfigured: boolean;
  security?: Security;
  buyingPower?: BuyingPower;
  quantity?: string;
  showBuyingPower?: boolean;
  buyingPowerUseMaxOrderSize?: boolean;
  side: BuyingPowerRowsProps['side'];
};

export type BuyingPowerRowsProps = {
  quantity?: string;
  security: Security;
  buyingPower?: BuyingPower;
  buyingPowerUseMaxOrderSize?: boolean;
  side?: OrderFormSides | string;
  buyExceedsLimit: boolean;
  sellExceedsLimit: boolean;
};

type BuyingPowerBalanceProps = {
  side?: OrderFormSides | string;
  amount: string;
  quantity?: string;
  currency: string;
  isWarning: boolean;
  security: Security;
};
