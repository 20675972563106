import {
  BlotterTable,
  BlotterTableExtrasMenu,
  BlotterTableFilters,
  Button,
  ButtonVariants,
  DEFAULT_BLOTTER_SELECTION_MULTI_PARAMS,
  FormControlSizes,
  IconName,
  TRANSFER,
  createCSVFileName,
  filterByCellValueMenuItem,
  removeEmptyFilters,
  useAccordionFilterBuilder,
  useBlotterTableExtrasMenu,
  useDateRangeFilter,
  useGetDefaultContextMenuItems,
  useJsonModal,
  useMixpanel,
  usePersistedBlotterTable,
  useWsBlotterTable,
  type BlotterTableFilter,
  type Column,
  type FilterClause,
  type FilterableProperty,
  type Transfer,
} from '@talos/kyoko';
import type { GetContextMenuItemsParams, MenuItemDef } from 'ag-grid-community';
import { isEqual } from 'lodash';
import { useContextBlotterFilter } from 'providers/ContextBlotterFilterProvider/useContextBlotterFilter';
import { useCallback } from 'react';
import type { AdditionalTransferColumns } from './useTransfersColumns';
import { useTransfersFilter, type TransfersBlotterFilter } from './useTransfersFilter';

export interface RecentTransfersParams {
  blotterID: string;
  defaultColumns: Column[];
  defaultFilter: TransfersBlotterFilter;
  initialIsOpen?: boolean;

  showCloneTab?: boolean;
  /** filter and columns are current state to be cloned to new tab */
  onCloneTab: (filter: BlotterTableFilter, columns: Column[]) => void;
  getContextMenuItems?: (params: GetContextMenuItemsParams) => MenuItemDef[];
}

export function FilteredTransfers({
  blotterID,
  defaultColumns,
  defaultFilter,
  initialIsOpen,
  showCloneTab,
  onCloneTab,
  getContextMenuItems: getParentContextMenuItems,
}: RecentTransfersParams) {
  const mixpanel = useMixpanel();

  const getDefaultContextMenuItems = useGetDefaultContextMenuItems();

  const { additionalFilterState } = useContextBlotterFilter();
  // only show DateRangePicker if it's not used in the additionalFilterState
  const showDateRangePicker = !(additionalFilterState && 'DateRange' in additionalFilterState);

  const persistedBlotterTable = usePersistedBlotterTable<Transfer>(blotterID, {
    columns: defaultColumns,
    filter: defaultFilter,
    sort: '-SubmitTime',
  });

  const handleDisplayedColumnsChanged = useCallback(
    (columns: Column[]) => {
      persistedBlotterTable.onColumnsChanged(columns);
    },
    [persistedBlotterTable]
  );

  const filterResults = useTransfersFilter({
    persistedBlotterTable,
  });

  const { clientSideFilter, changeFilter, filterableProperties, initialFilterClauses } = filterResults;
  const handleFilterClausesChanged = useCallback(
    (filterClausesByPropertyKey: Map<string, FilterClause>, propertiesByKey: Map<string, FilterableProperty>) => {
      changeFilter(curr => {
        const newFilter = removeEmptyFilters<TransfersBlotterFilter>({
          ...curr,
          ...(Object.fromEntries(
            [...propertiesByKey.keys()].map(key => [key, filterClausesByPropertyKey.get(key)?.selections])
          ) as unknown as TransfersBlotterFilter),
        });
        if (isEqual(curr, newFilter)) {
          return curr;
        }
        return newFilter;
      });
    },
    [changeFilter]
  );

  const dateRangeFilter = useDateRangeFilter(filterResults.filter, changeFilter, additionalFilterState?.DateRange);

  const { handleClickJson, jsonModal } = useJsonModal();

  const filterBuilderAccordion = useAccordionFilterBuilder({
    accordionProps: { initialOpen: initialIsOpen },
    filterBuilderProps: {
      initialFilterClauses,
      properties: filterableProperties,
      onFilterClausesChanged: handleFilterClausesChanged,
    },
  });

  const getContextMenuItems = useCallback(
    (params: GetContextMenuItemsParams) => [
      ...filterByCellValueMenuItem({
        params,
        openClause: filterBuilderAccordion.openClause,
        filterableProperties,
        colIDToFilterBuilderKey,
        mixpanel,
      }),
      'separator',
      {
        action: () => handleClickJson(params.node?.data),
        name: 'Show JSON',
        icon: `<i class="ag-icon ${IconName.Braces}"/>`,
      },
      'separator',
      ...(getParentContextMenuItems?.(params) || []),
      ...getDefaultContextMenuItems(params),
    ],
    [
      filterBuilderAccordion.openClause,
      filterableProperties,
      getDefaultContextMenuItems,
      getParentContextMenuItems,
      mixpanel,
      handleClickJson,
    ]
  );

  const blotterTable = useWsBlotterTable({
    initialRequest: {
      name: TRANSFER,
      tag: 'TransfersBlotter',
    },
    filter: onlyServerFilterKeys(filterResults.filter),
    initialSort: persistedBlotterTable.initialSort,
    columns: persistedBlotterTable.columns,
    rowID: 'rowID' satisfies keyof Transfer,
    clientLocalFilter: clientSideFilter,
    onSortChanged: persistedBlotterTable.onSortChanged,
    onColumnsChanged: handleDisplayedColumnsChanged,
    handleClickJson,
    gridOptions: { rowSelection: DEFAULT_BLOTTER_SELECTION_MULTI_PARAMS, getContextMenuItems },
  });

  const handleExport = useCallback(() => {
    blotterTable.exportDataAsCSV({
      fileName: createCSVFileName({
        name: 'Transfers',
      }),
    });
  }, [blotterTable]);

  const handleCloneTab = useCallback(() => {
    onCloneTab?.(filterResults.filter, blotterTable.getColumns());
  }, [blotterTable, filterResults.filter, onCloneTab]);

  const extrasMenuPopover = useBlotterTableExtrasMenu();

  return (
    <>
      <BlotterTableFilters
        {...filterBuilderAccordion}
        {...dateRangeFilter}
        {...blotterTable.blotterTableFiltersProps}
        showDateRangePicker={showDateRangePicker}
        prefix={
          showCloneTab && (
            <Button
              startIcon={IconName.Duplicate}
              variant={ButtonVariants.Default}
              size={FormControlSizes.Small}
              onClick={handleCloneTab}
            >
              Clone Tab
            </Button>
          )
        }
        suffix={
          <BlotterTableExtrasMenu {...extrasMenuPopover}>
            <Button
              startIcon={IconName.DocumentDownload}
              variant={ButtonVariants.Default}
              size={FormControlSizes.Small}
              data-testid="export-button"
              onClick={handleExport}
            >
              Export
            </Button>
          </BlotterTableExtrasMenu>
        }
      />
      <BlotterTable {...blotterTable} />
      {jsonModal}
    </>
  );
}

function onlyServerFilterKeys(filter: TransfersBlotterFilter | undefined) {
  if (!filter) {
    return filter;
  }

  return {
    StartDate: filter.StartDate,
    EndDate: filter.EndDate,
  } satisfies TransfersBlotterFilter;
}

function colIDToFilterBuilderKey(id: string): keyof TransfersBlotterFilter | undefined {
  switch (id as keyof Transfer | AdditionalTransferColumns) {
    case 'Market':
      return 'Provider';
    case 'Currency':
      return 'Currency';
    case 'Status':
      return 'Status';
    case 'OwnerAccountID':
    case 'sourceAccount':
    case 'destinationAccount':
      return 'Account';
    case 'User':
      return 'Submitter';
    default:
      return undefined;
  }
}
