import {
  HIDDEN_MARKET_ACCOUNT_GROUP_PREFIX,
  IconName,
  Text,
  type Column,
  type ColumnDef,
  type MarketAccount,
} from '@talos/kyoko';
import type { ICellRendererParams, IRowNode, ValueFormatterParams } from 'ag-grid-community';
import { useMemo } from 'react';

// this doesnt need to be a hook but leaving it as one for now since we'll likely add more here
export const useMarketAccountColumns = ({ onEditClicked }: { onEditClicked: (mktAcc: MarketAccount) => void }) => {
  const columns: Column[] = useMemo(() => {
    return [
      { field: 'DisplayName', title: 'Market Account Name', type: 'text', width: 200, sortable: true },
      { field: 'Name', title: 'Market Account Key', type: 'text', width: 150, sortable: true },
      { field: 'MarketAccountID', type: 'text', title: 'ID', sortable: true, width: 100, hide: true },
      {
        field: 'Group',
        type: 'custom',
        width: 150,
        sortable: true,
        enableRowGroup: true,
        params: {
          /* This column is just a basic text column, but we add in some extra display logic for the Hidden case for better UX */
          valueFormatter: (params: ValueFormatterParams<MarketAccount, string | undefined>) => {
            if (params.value?.startsWith(HIDDEN_MARKET_ACCOUNT_GROUP_PREFIX)) {
              return '<HIDDEN>';
            }

            return params.value ?? '';
          },
          cellRenderer: (params: ICellRendererParams<MarketAccount, string | undefined>) => {
            const isHidden = params.value?.startsWith(HIDDEN_MARKET_ACCOUNT_GROUP_PREFIX);
            if (isHidden) {
              return (
                <Text fontStyle="italic" color="colorTextSubtle">
                  {params.valueFormatted}
                </Text>
              );
            }
            return <Text>{params.valueFormatted}</Text>;
          },
        },
      },
      { field: 'Market', type: 'market', width: 100, sortable: true, enableRowGroup: true },
      { field: 'Status', type: 'text', width: 100, sortable: true },
      { field: 'Timestamp', title: 'Last Update Time', type: 'date', sortable: true },
      { field: 'Type', type: 'text', sortable: true, enableRowGroup: true },
      { field: 'SourceAccountID', type: 'text', width: 150, hide: true, sortable: true },
      { field: 'Description', type: 'text', width: 150, hide: true, sortable: true },
      { field: 'ReferenceData', type: 'text', title: 'Reference', width: 110, sortable: true, hide: true },
      { field: 'Ownership', type: 'text', sortable: true, hide: true },
      { field: 'Counterparty', title: 'Counterparty', type: 'text', sortable: true, hide: true },
      {
        field: 'CredentialID',
        title: 'Cred ID',
        type: 'text',
        width: 100,
        sortable: true,
        hide: true,
        enableRowGroup: true,
      },
      { field: 'TypeDescription', type: 'text', width: 150, sortable: true, hide: true },
      {
        id: 'edit',
        type: 'iconButton',
        pinned: 'right',
        frozen: true,
        width: 45,
        suppressColumnsToolPanel: true,
        params: {
          onClick: ({ node }: { node: IRowNode<MarketAccount> }) => node.data && onEditClicked(node.data),
          icon: IconName.Pencil,
        },
      },
    ] satisfies ColumnDef<MarketAccount>[];
  }, [onEditClicked]);

  return columns;
};
