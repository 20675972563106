import { ACTION, Button, FormControlSizes, IconName, NotificationVariants, useGlobalToasts } from '@talos/kyoko';
import { useCallback } from 'react';
import { useHedgeRuleCommandMutation } from '../../containers/Trading/Markets/PositionAutoHedgingRules/queries';
import { useRoleAuth } from '../../hooks';

export const SynchronizeHedgerButton = () => {
  const { isAuthorized } = useRoleAuth();
  const { add: addToast } = useGlobalToasts();
  const { mutateAsync, isPending } = useHedgeRuleCommandMutation();
  const sendSynchronizeCommand = useCallback(() => {
    mutateAsync({ Command: 'Synchronize' })
      .then(() => {
        addToast({
          text: 'Sent synchronize command',
          variant: NotificationVariants.Positive,
        });
      })
      .catch(() => {
        addToast({
          text: 'Failed to send Synchronize command',
          variant: NotificationVariants.Negative,
        });
      });
  }, [addToast, mutateAsync]);

  return (
    isAuthorized(ACTION.HEDGER_SYNCHRONIZATION) && (
      <Button
        loading={isPending}
        onClick={sendSynchronizeCommand}
        size={FormControlSizes.Tiny}
        startIcon={IconName.Refresh}
      >
        Synchonize (Talos only)
      </Button>
    )
  );
};
