import {
  getConnectionTypeDrawerOptions,
  getCustomerUserDrawerOptions,
  getTimeDrawerOptions,
  type ColumnDef,
  type CustomerUser,
  type ICustomerFIXConnection,
  type InputsAndDropdownsDrawerOption,
} from '@talos/kyoko';
import { isActiveRuleColumn } from '../columns';

export const CUSTOMER_FIX_CONNECTIONS_COLUMNS: ColumnDef<ICustomerFIXConnection>[] = [
  isActiveRuleColumn,
  {
    type: 'text',
    field: 'ConnectionID',
    width: 170,
    sortable: true,
    sort: '+',
    description: 'Unique ID of this connection',
    hide: true,
  },
  {
    type: 'text',
    field: 'Name',
    width: 180,
    sortable: true,
    sort: '-',
    description: 'Name of this connection',
  },
  {
    type: 'text',
    field: 'CustomerUser',
    width: 170,
    description: 'End user associated with this connection',
  },
  {
    type: 'text',
    field: 'ConnectionType',
    width: 200,
  },
  {
    type: 'text',
    field: 'URL',
    title: 'URL',
    width: 130,
  },
  {
    type: 'text',
    field: 'BeginString',
    width: 130,
    description: 'Usually FIX4.4',
  },
  {
    type: 'text',
    field: 'SenderCompID',
    width: 120,
    description: 'SenderCompId for this connection.',
  },
  {
    type: 'text',
    field: 'TargetCompID',
    width: 150,
  },
  {
    type: 'time',
    field: 'StartTime',
    width: 150,
  },
  {
    type: 'time',
    field: 'EndTime',
    width: 150,
  },
];

export function getCustomerFixConnectionDrawerOptions({
  customerUsers,
}: {
  customerUsers: CustomerUser[];
}): InputsAndDropdownsDrawerOption<ICustomerFIXConnection>[] {
  return [
    {
      field: 'CustomerUser',
      type: 'dropdown',
      title: 'Customer User',
      required: true,
      options: getCustomerUserDrawerOptions(customerUsers),
    },
    {
      field: 'ConnectionType',
      type: 'dropdown',
      title: 'Connection Type',
      options: getConnectionTypeDrawerOptions(),
      required: true,
    },
    { type: 'divider' },
    {
      field: 'URL',
      type: 'input',
      title: 'URL',
      required: true,
      placeholder: 'fix://:port-number',
    },
    {
      field: 'BeginString',
      type: 'input',
      title: 'Begin String',
      required: true,
      placeholder: 'FIX4.4',
    },
    {
      field: 'SenderCompID',
      type: 'input',
      title: 'SenderCompID',
      required: true,
    },
    {
      field: 'TargetCompID',
      type: 'input',
      title: 'TargetCompID',
      required: true,
    },
    { type: 'divider' },
    {
      field: 'StartTime',
      type: 'dropdown',
      title: 'Start Time',
      options: getTimeDrawerOptions(),
    },
    {
      field: 'EndTime',
      type: 'dropdown',
      title: 'End Time',
      options: getTimeDrawerOptions(),
    },
  ];
}
