import {
  ConnectionStatusEnum,
  isConnectionRecovering,
  type BuyingPower,
  type ConnectionType,
  type Market,
  type MarketAccount,
  type MarketCredentialWithConnectionStatus,
} from '@talos/kyoko';
import Big from 'big.js';
import { sortBy, uniq } from 'lodash';
import type { Balance } from '../../types/Balance';
import type { MarketAccountObject } from './types';

const { Starting, Stopping, Offline, Error, Unavailable } = ConnectionStatusEnum;

interface GetMarketAccountObjectsParams {
  availableMarketAccounts: string[];
  marketAccountsByName: Map<string, MarketAccount>;
  marketsByName: Map<string, Market>;
  connectionType: ConnectionType;
  isMarketConfigured: (m: string | Market, connectionType: ConnectionType) => boolean;
  isMarketAccountActive: (ma?: MarketAccount) => boolean;
  selectedMarketAccounts: string[];
  usingCounterCurrency: boolean;
  allowSyntheticCcy: boolean;
  marketAccountsByMarket: Map<string, MarketAccount[]>;
  availableMarkets?: string[];
  selectedMarkets?: string[];
  connectionStatusLookup: Map<string, MarketCredentialWithConnectionStatus>;
}

export function getMarketAccountObjects({
  availableMarketAccounts,
  marketAccountsByName,
  marketsByName,
  connectionType,
  isMarketConfigured,
  isMarketAccountActive,
  selectedMarketAccounts,
  usingCounterCurrency,
  allowSyntheticCcy,
  marketAccountsByMarket,
  availableMarkets,
  selectedMarkets,
  connectionStatusLookup,
}: GetMarketAccountObjectsParams): MarketAccountObject[] {
  const marketAccountItems = availableMarketAccounts.reduce(
    (res: MarketAccountObject[], marketAccountName: string): MarketAccountObject[] => {
      const isSelected = selectedMarketAccounts.includes(marketAccountName);
      const marketAccount = marketAccountsByName.get(marketAccountName);
      if (!marketAccount || !isMarketAccountActive(marketAccount)) {
        return res;
      }
      const market = marketsByName.get(marketAccount.Market);
      if (market == null) {
        return res;
      }
      const name = marketAccountName;
      const label = marketAccount.DisplayName;

      const obj = getMarketDataObject(
        market,
        connectionType,
        isMarketConfigured,
        usingCounterCurrency,
        allowSyntheticCcy,
        name,
        label,
        marketAccount,
        isSelected,
        connectionStatusLookup
      );

      return [...res, obj];
    },
    []
  );
  const marketItems: MarketAccountObject[] =
    availableMarkets != null
      ? availableMarkets.reduce((res: MarketAccountObject[], marketName: string): MarketAccountObject[] => {
          const isSelected = selectedMarkets?.includes(marketName) ?? false;
          const marketAccounts = marketAccountsByMarket?.get(marketName);
          if (marketAccounts && marketAccounts.length > 0) {
            // If availableMarketAccounts does not contain these market accounts add them. This weird case can happen
            // when a configuration was tied to markets which now have market accounts.
            const objs: MarketAccountObject[] = [];
            marketAccounts.forEach((ma: MarketAccount, idx: number) => {
              if (!availableMarketAccounts.includes(ma.Name)) {
                if (!isMarketAccountActive(ma)) {
                  return;
                }
                const market = marketsByName.get(marketName);
                if (market == null) {
                  return;
                }
                objs.push(
                  getMarketDataObject(
                    market,
                    connectionType,
                    isMarketConfigured,
                    usingCounterCurrency,
                    allowSyntheticCcy,
                    ma.Name,
                    ma.DisplayName,
                    ma,
                    isSelected && idx === 0, // If there are multiple market accounts make sure only one is selected
                    connectionStatusLookup
                  )
                );
              }
            });
            return res.concat(objs);
          }
          // If there are no market accounts still make the object as markets with just market data still need to
          // be selectable in some cases.
          const market = marketsByName.get(marketName);
          if (market == null) {
            return res;
          }
          const name = marketName;
          const label = market.DisplayName;

          const obj = getMarketDataObject(
            market,
            connectionType,
            isMarketConfigured,
            usingCounterCurrency,
            allowSyntheticCcy,
            name,
            label,
            undefined,
            isSelected,
            connectionStatusLookup
          );

          return [...res, obj];
        }, [])
      : [];
  return sortBy([...marketAccountItems, ...marketItems], item => item.label);
}

export function getMarketDataObject(
  market: Market,
  connectionType: ConnectionType,
  isMarketConfigured: (m: string | Market, connectionType: ConnectionType) => boolean,
  usingCounterCurrency: boolean,
  allowSyntheticCcy: boolean,
  name: string,
  label: string,
  marketAccount: MarketAccount | undefined,
  isSelected: boolean,
  connectionStatusLookup: Map<string, MarketCredentialWithConnectionStatus>
) {
  const statusTitle = market[connectionType]?.Message || '';
  const isConfigured = isMarketConfigured(market, connectionType);

  const supportsNativeCounterCurrency = market?.Flags?.SupportsNativeCounterCurrency === true;
  const disabledForCounterCurrency = usingCounterCurrency && !allowSyntheticCcy && !supportsNativeCounterCurrency;

  const credentialWithConnectionStatus = connectionStatusLookup.get(name);
  const isRecovering = isConnectionRecovering(credentialWithConnectionStatus?.connectionStatus);
  const status = isRecovering
    ? ('Recovering' as const)
    : credentialWithConnectionStatus?.connectionStatus?.Status ?? ConnectionStatusEnum.Unavailable;

  const hasError =
    isRecovering || [Starting, Stopping, Error, Offline, Unavailable].includes(status as ConnectionStatusEnum);
  const showSyntheticCcyWarning = usingCounterCurrency && !supportsNativeCounterCurrency;
  return {
    name,
    label,
    market,
    marketAccount,
    status,
    isSelected,
    statusTitle,
    hasError,
    disabledForCounterCurrency,
    showSyntheticCcyWarning,
    isConfigured,
  };
}

export function getMissingMarkets(groups: Record<string, MarketAccountObject[]>) {
  const missingMarketAccounts = Object.keys(groups).reduce(
    (res: string[], marketName) =>
      groups[marketName].every(ma => !(ma.isSelected || ma.marketAccount == null))
        ? [...res, groups[marketName][0].name]
        : res,
    []
  );
  const missingMarkets = Object.keys(groups).reduce(
    (res: string[], marketName) =>
      groups[marketName].every(ma => !(ma.isSelected || ma.marketAccount != null))
        ? [...res, groups[marketName][0].name]
        : res,
    []
  );
  return { missingMarketAccounts, missingMarkets };
}

export function filteredMarketObjGroups(marketObjGroups: Record<string, MarketAccountObject[]>, searchStr: string) {
  return Object.keys(marketObjGroups).reduce((acc: Record<string, MarketAccountObject[]>, key) => {
    const result = marketObjGroups[key as string].filter(mao => {
      return mao.label.toLowerCase().includes(searchStr);
    });
    if (result.length > 0) {
      acc[key as string] = result;
    }
    return acc;
  }, {});
}

export function getMarketObjAutocompleteGroupItems(marketObjGroups: Record<string, MarketAccountObject[]>) {
  return Object.keys(marketObjGroups).map(marketName => {
    const exchanges = marketObjGroups[marketName];
    if (exchanges.length > 1) {
      return {
        group: marketName,
        items: exchanges,
      };
    } else {
      return exchanges[0];
    }
  });
}

interface BuyingPowerSum {
  availableBuy: Big;
  availableSell: Big;
}

export const getBuyingPowerSum = (
  buyingPower: Map<string, BuyingPower> | undefined,
  markets: string[]
): BuyingPowerSum => {
  return markets.reduce(
    (acc, market) => {
      const bp = buyingPower?.get(market);
      acc.availableBuy = acc.availableBuy.add(Big(bp?.AvailableBuy ?? 0));
      acc.availableSell = acc.availableSell.add(Big(bp?.AvailableSell ?? 0));
      return acc;
    },
    {
      availableBuy: Big(0),
      availableSell: Big(0),
    }
  );
};

export const getMarketAccountBalance = (
  balancesByMarketAccountIDCurrency: Map<number, Map<string, Balance>> | undefined,
  marketAccountID: number | undefined,
  currency: string
): string => {
  const marketAccountBalances = marketAccountID ? balancesByMarketAccountIDCurrency?.get(marketAccountID) : null;
  if (currency && marketAccountBalances) {
    const balance = marketAccountBalances.get(currency);
    return balance?.AvailableAmount ?? '';
  } else {
    return '';
  }
};

/* Filter out markets that have market account(s), leave ones that do not have market account(s) */
export const filterMarkets = (
  selectedMarkets: string[],
  marketAccountsByMarket: Map<string, MarketAccount[]>
): string[] => {
  return selectedMarkets.filter(market => !marketAccountsByMarket?.has(market));
};

/* Return first (if exists) MarketAccount name for each selectedMarket and
 * all selectedMarketAccounts that are still present in marketAccountsByName */
export const filterMarketAccounts = (
  selectedMarketAccounts: string[],
  selectedMarkets: string[],
  marketAccountsByMarket: Map<string, MarketAccount[]>,
  marketAccountsByName: Map<string, MarketAccount>
) => {
  const marketsWithAccount =
    selectedMarkets.reduce((res: string[], market: string) => {
      if (marketAccountsByMarket != null && marketAccountsByMarket.has(market)) {
        const marketAccounts = marketAccountsByMarket.get(market);
        const marketAccount = marketAccounts![0].Name;
        return [...res, marketAccount];
      } else {
        return res;
      }
    }, []) ?? [];
  const stillExistingMarketAccounts = selectedMarketAccounts.filter(marketAccount =>
    marketAccountsByName?.has(marketAccount)
  );
  return uniq([...marketsWithAccount, ...stillExistingMarketAccounts]);
};
