import {
  AllocationValueTypeEnum,
  canParseAsDuration,
  DecisionStatusEnum,
  OrdTypeEnum,
  type Parameter,
  ParameterTypeEnum,
  PresenceEnum,
  SideEnum,
  useMarketAccountsContext,
  useMarketsContext,
  useSecuritiesContext,
} from '@talos/kyoko';
import { camelCase, has } from 'lodash';
import Papa from 'papaparse';
import { useStrategies, useSubAccounts } from 'providers';
import { useDisplaySettings } from 'providers/AppConfigProvider';
import type { OMSForm } from 'providers/OMSContext.types';
import { createContext, memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { type OrderStrategiesEnum, ParameterKeysEnum } from 'tokens/orderStrategy';
import { getOrdType } from '../utils';
import { type ALL_KEYS_INFO, REQUIRED_KEYS } from './tokens';

export type OrderErrors = { [key: string]: string };

export interface OrderImportContextProps {
  clear: () => void;
  parseOrders: (file: File) => Promise<void>;
  setOrderError: (order: OMSForm, key: string, error: string) => void;
  hasErrors: boolean;
  orders: OMSForm[];
  errors: OrderErrors[];
  fileErrors: string[];
  requiredKeys: Array<keyof typeof ALL_KEYS_INFO>;
  subAccount: string;
}

const OrderImportContext = createContext<OrderImportContextProps | null>(null);
OrderImportContext.displayName = 'OrderImportContext';

export function useOrderImportContext() {
  const context = useContext(OrderImportContext);
  if (context === null) {
    throw new Error('Missing OrderImportContext.Provider further up in the tree. Did you forget to add it?');
  }
  return context;
}

export const OrderImportProvider = memo(function OrderImportProvider(props: React.PropsWithChildren<unknown>) {
  const { strategiesList: strategies } = useStrategies();
  const { subAccountsByName, tradableSubAccounts } = useSubAccounts();
  const { securitiesBySymbol } = useSecuritiesContext();
  const { marketsByName } = useMarketsContext();
  const { marketAccountsByName } = useMarketAccountsContext();

  const { showAllInPrices } = useDisplaySettings();
  const [orders, setOrders] = useState<OMSForm[]>([]);
  // Index in this array is the same as index in orders array
  const [errors, setErrors] = useState<{ [key: string]: string }[]>([]);
  const [fileErrors, setFileErrors] = useState<string[]>([]);
  const [hasErrors, setHasErrors] = useState(false);

  const clear = useCallback(() => {
    setOrders([]);
    setErrors([]);
    setFileErrors([]);
  }, []);

  // Include Sub accounts if they exist in the system
  const subAccount = tradableSubAccounts && tradableSubAccounts.length > 0 ? tradableSubAccounts[0].Name : '';

  const requiredKeys = useMemo(() => {
    const result = [...REQUIRED_KEYS];
    if (subAccount !== '') {
      result.push('SubAccount');
    }
    return result;
  }, [subAccount]);

  const readOrderFile = useCallback(
    (file: File): Promise<[ParsedOrder, OrderErrors][]> => {
      setOrders([]);
      setErrors([]);
      return new Promise((resolve, reject: (reason: string[]) => void) => {
        Papa.parse<ParsedOrder>(file, {
          delimiter: ',',
          header: true,
          skipEmptyLines: true,
          transformHeader: (header: string) => {
            return header.toLowerCase().replaceAll(' ', '').trim();
          },
          transform: (value: string) => {
            return value.trim();
          },
          complete: result => {
            if (!result.meta.fields) {
              return reject(['Unable to parse header from file']);
            }
            const missingKey = requiredKeys.find((key: string) => {
              return result.meta.fields?.indexOf(key.toLowerCase()) === -1;
            });
            if (missingKey !== undefined) {
              return reject([`Missing required header: ${missingKey}`]);
            }
            if (result.errors.length > 0) {
              return reject(result.errors.map(error => `Row ${error.row + 1} ${error.message} `));
            }
            return resolve(
              result.data.map(item => {
                return [item, validateOrderNoContext(item, requiredKeys)];
              })
            );
          },
        });
      });
    },
    [requiredKeys]
  );

  const projectOrder = useCallback(
    (parsedOrder: ParsedOrder, orderErrors: OrderErrors): OMSForm => {
      const side = parseSide(parsedOrder.side);
      if (!orderErrors.symbol && !securitiesBySymbol.has(parsedOrder.symbol)) {
        orderErrors.symbol = `Unable to resolve Security: ${parsedOrder.symbol}`;
      }
      if (!orderErrors.side && side === undefined) {
        orderErrors.side = `Unable to parse Side: ${parsedOrder.side}`;
      }
      if (!orderErrors.subaccount && parsedOrder.subaccount && !subAccountsByName?.get(parsedOrder.subaccount)) {
        orderErrors.subaccount = `Unable to resolve Sub Account: ${parsedOrder.subaccount}`;
      }
      const marketsOrMarketAccounts = parsedOrder.market.split(';');

      const marketOrMarketAccountErrors = marketsOrMarketAccounts.filter(
        (marketOrMarketAccount: string) =>
          !(marketsByName.has(marketOrMarketAccount) || marketAccountsByName.has(marketOrMarketAccount))
      );
      if (marketOrMarketAccountErrors.length > 0) {
        orderErrors.orderMarketAccounts = `Unable to resolve market${
          marketOrMarketAccountErrors.length > 1 ? 's' : ''
        } ${marketOrMarketAccountErrors.join(', ')}`;
      }
      if (
        parsedOrder.initialdecisionstatus &&
        ![DecisionStatusEnum.Staged, DecisionStatusEnum.Active].some(
          status => status === parsedOrder.initialdecisionstatus
        )
      ) {
        orderErrors.initialdecisionstatus = `Invalid InitialDecisionStatus: ${parsedOrder.initialdecisionstatus}`;
      }

      const result: OMSForm = {
        symbol: parsedOrder.symbol,
        quantity: parsedOrder.orderqty,
        orderSide: side,
        price: parsedOrder.price || '',
        orderCurrency: parsedOrder.currency || '',
        orderMarketAccounts: marketsOrMarketAccounts,
        parameters: {},
        subAccountAllocations: parsedOrder.subaccount ? [{ subAccount: parsedOrder.subaccount, value: '100' }] : [],
        allocationValueType: parsedOrder.subaccount ? AllocationValueTypeEnum.Percentage : undefined,
      };

      if (parsedOrder.group) {
        result.group = parsedOrder.group;
      }

      if (parsedOrder.comments) {
        result.comment = parsedOrder.comments;
      }

      if (parsedOrder?.initialdecisionstatus === DecisionStatusEnum.Staged) {
        result.initialDecisionStatus = DecisionStatusEnum.Staged;
      }

      return result;
    },
    [marketAccountsByName, marketsByName, securitiesBySymbol, subAccountsByName]
  );

  const inferOrderFields = useCallback(
    (form: OMSForm, parsedOrder: ParsedOrder, orderErrors: OrderErrors) => {
      // Look at strategy parameters and set defaults...
      const strategy = strategies?.find(
        strat => strat.Name === parsedOrder.strategy || strat.DisplayName === parsedOrder.strategy
      );
      if (!strategy && parsedOrder.strategy) {
        orderErrors.strategy = `Strategy: ${parsedOrder.strategy} not found`;
        return form;
      }

      form.ordType = getOrdType(showAllInPrices, undefined, strategy);
      // UI-4829
      if (
        form.initialDecisionStatus === DecisionStatusEnum.Staged &&
        form.ordType !== OrdTypeEnum.Market &&
        !strategy &&
        !form.price
      ) {
        form.ordType = OrdTypeEnum.Market;
      }

      if (strategy) {
        form.strategy = strategy.Name as OrderStrategiesEnum;
        strategy.Parameters.forEach((param: Parameter) => {
          const paramValue = convertRawParameter(param, parsedOrder[param.Name.toLowerCase().replaceAll(' ', '')]);
          const key = camelCase(param.Name);
          if (param.Presence === PresenceEnum.TopLevelHidden) {
            form[key] = paramValue === undefined ? param.DefaultValue : paramValue;
          } else if (paramValue !== undefined) {
            form.parameters[key] = paramValue;
          } else if (param.DefaultValue !== undefined) {
            form.parameters[key] = param.DefaultValue;
          }
        });
      }
      return form;
    },
    [showAllInPrices, strategies]
  );

  const parseOrders = useCallback(
    async (importFile: File): Promise<void> => {
      try {
        const parsedOrders = await readOrderFile(importFile);

        const ordersAndErrors = parsedOrders.map((parsedOrderAndErrors): [OMSForm, OrderErrors] => {
          const [parsedOrder, orderErrors] = parsedOrderAndErrors;
          const projectedOrder = projectOrder(parsedOrder, orderErrors);
          // Flow constraints from model to infer other order fields
          // Let the order be validated by our validation logic
          return [inferOrderFields(projectedOrder, parsedOrder, orderErrors), orderErrors];
        });
        setFileErrors([]);
        setErrors(ordersAndErrors.map(item => item[1]));
        setOrders(ordersAndErrors.map(item => item[0]));
      } catch (e: any) {
        if (e instanceof Array) {
          setFileErrors(e);
        } else {
          setFileErrors([e?.message || e]);
        }
        setOrders([]);
        setErrors([]);
      }
    },
    [inferOrderFields, projectOrder, readOrderFile]
  );

  // Merge all of the provided errors in to our existing errors for the given order
  const setOrderError = useCallback(
    (order: OMSForm, key: string, value: string) => {
      const idx = orders.findIndex(item => item === order);
      const newErrors = structuredClone(errors);
      newErrors[idx][key] = value;
      setErrors(newErrors);
    },
    [errors, orders]
  );

  useEffect(() => {
    setHasErrors(!errors.every(orderErrors => Object.keys(orderErrors).length === 0) || fileErrors.length > 0);
  }, [errors, fileErrors]);

  return (
    <OrderImportContext.Provider
      value={{
        clear,
        parseOrders,
        setOrderError,
        hasErrors,
        orders,
        errors,
        fileErrors,
        requiredKeys,
        subAccount,
      }}
    >
      {props.children}
    </OrderImportContext.Provider>
  );
});

function convertRawParameter(param: Parameter, value: string): Date | string | null | undefined {
  if (value === undefined) {
    return undefined;
  }
  if (param.Type === ParameterTypeEnum.Date) {
    // Allow Duration as string for EndTime
    if (param.Name === ParameterKeysEnum.EndTime && canParseAsDuration(value)) {
      return value;
    }
    const rawDate = Date.parse(value);
    if (!isNaN(rawDate)) {
      return new Date(rawDate);
    }
    return undefined;
  }
  return value;
}

interface ParsedOrder {
  symbol: string;
  market: string;
  side: string;
  orderqty: string;
  strategy: string;
  currency: string;
  price?: string;
  subaccount?: string;
  group?: string;
  comments?: string;
  initialdecisionstatus?: DecisionStatusEnum;
}

function validateOrderNoContext(order: ParsedOrder, requiredKeys: Array<keyof typeof ALL_KEYS_INFO>): OrderErrors {
  const errors: OrderErrors = {};
  requiredKeys.forEach(key => {
    if (!has(order, key.toLowerCase())) {
      errors[key] = `Missing required key ${key}`;
    }
  });
  return errors;
}

function parseSide(side: string): SideEnum | undefined {
  if (side.toLowerCase() === 'buy') {
    return SideEnum.Buy;
  }
  if (side.toLowerCase() === 'sell') {
    return SideEnum.Sell;
  }
}
