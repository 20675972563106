import type { MinimalSubscriptionResponse } from '@talos/kyoko';
import { useMemo } from 'react';
import { map, type Observable } from 'rxjs';
import type { OpsPosition } from '../types';
import { MarketAccountRow, MarketRow, OpsPositionRow, UnderlierRow } from './rows';

interface UseOpsOverviewRowsParams {
  positionsObs: Observable<OpsPosition[]>;
  /** If true, will include a Market grouping level "on top of" the account grouping level */
  groupByMarket: boolean;
}

export const useOpsOverviewRows = ({
  positionsObs,
  groupByMarket,
}: UseOpsOverviewRowsParams): Observable<MinimalSubscriptionResponse<OpsOverviewBlotterRow>> => {
  const rowsObs = useMemo(
    () =>
      positionsObs.pipe(
        map(positions => {
          /**
           * Grouping structure is...
           * - (Market - toggleable)
           *   - Market Account
           *     - (Margin type - future)
           *       - Underlying (conditionally redundant - future)
           *         - Instrument (leaf node)
           */

          const { levels } = groupByMarket ? GROUPINGS.ByMarket : GROUPINGS.ByMarketAccount;

          const rowIDsCreated = new Set<string>();
          const rows: OpsOverviewBlotterRow[] = [];

          for (const position of positions) {
            // In order to not be instantiating tons of duplicate classes (rows) only to throw them away, we preview the rowIDs and only build
            // the tree row instance if it does not already exist in the blotter. I think this is the correct trade-off.
            let workingDataPath: string[] = [];
            for (const level of levels) {
              workingDataPath = [...workingDataPath, level.getPathComponent(position)]; // create new array instead of mutating!
              const rowAlreadyCreated = rowIDsCreated.has(level.getRowID(workingDataPath));
              if (rowAlreadyCreated) {
                continue;
              }

              const newRow = new level(workingDataPath, position);
              rowIDsCreated.add(newRow.rowID);
              rows.push(newRow);
            }
          }

          return {
            initial: true,
            data: rows,
          };
        })
      ),
    [positionsObs, groupByMarket]
  );

  return rowsObs;
};

export type OpsOverviewBlotterRow = MarketRow | MarketAccountRow | UnderlierRow | OpsPositionRow;
export type OpsOverviewBlotterRowClasses =
  | typeof MarketRow
  | typeof MarketAccountRow
  | typeof UnderlierRow
  | typeof OpsPositionRow;

interface GroupingSpec {
  levels: OpsOverviewBlotterRowClasses[];
  /* Will likely expand here in the future to include metadata for excluding "redundant" levels etc */
}

// We could connect this to the Operations Overview page's concept of groupings -- but keeping separated for now
// as we will likely want to reuse this blotter in PM Margin, and thus would need to decouple anyway.
type Grouping = 'ByMarket' | 'ByMarketAccount';

const GROUPINGS: { [key in Grouping]: GroupingSpec } = {
  ByMarket: {
    levels: [MarketRow, MarketAccountRow, UnderlierRow, OpsPositionRow],
  },
  ByMarketAccount: {
    levels: [MarketAccountRow, UnderlierRow, OpsPositionRow],
  },
};
