import type { Leaves } from '@talos/kyoko';
import { toBigWithDefault } from '@talos/kyoko';
import Big from 'big.js';
import { UnifiedPosition } from '../../Blotters/PositionsV3/Unified/UnifiedPosition';

export type OperationsOverviewShowByOld = 'Market' | 'MarketAccount' | 'Asset';

export interface OpsOverviewFilter {
  Symbols?: string[];
}

export const SHOW_BY_DICT = {
  Market: { showBy: 'Market', label: 'By Market', key: 'market' },
  MarketAccount: { showBy: 'MarketAccount', label: 'By Market Account', key: 'MarketAccount' },
  Asset: { showBy: 'Asset', label: 'By Underlying', key: 'underlying' },
} as const satisfies {
  [key in OperationsOverviewShowByOld]: { showBy: key; label: string; key: Leaves<OpsPosition> };
};

export class OpsPosition extends UnifiedPosition {
  marketAccountTotal: Big;

  get shareOfMarketAccountTotal(): Big | undefined {
    if (this.marketAccountTotal.eq(0)) {
      return undefined;
    }

    const positionBig = toBigWithDefault(this.Equivalent?.Amount, 0);
    if (positionBig.eq(0)) {
      return Big(0);
    }

    return positionBig.div(this.marketAccountTotal);
  }

  constructor(up: UnifiedPosition, marketAccountTotal: Big) {
    super({
      position: up,
      metadata: {
        underlying: up.underlying,
        marketAccountName: up.MarketAccount,
        marketName: up.market,
        marketAccountGroup: up.marketAccountGroup,
      },
    });

    this.marketAccountTotal = marketAccountTotal;
  }
}
