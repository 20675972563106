import {
  DefaultFavoritesConfig,
  DefaultTabsStateConfig,
  DefaultWatchlistSettingsConfig,
  type BlotterState,
  type BlotterTableNativeState,
} from '@talos/kyoko';
import { DefaultBlottersConfig } from 'providers/BlottersContext';
import { DefaultContextGuideConfig } from 'providers/ContextGuideContext';
import { DefaultDisplaySettingsConfig } from 'providers/DisplaySettingsProvider';
import { DefaultMarketTabsConfig } from 'providers/MarketTabs.types';
import type { IOrderPreset } from 'providers/OrderPresetsContext';
import { DefaultPortfolioSettingsConfig } from 'providers/PortfolioSettingContext';
import { DefaultSoundSettingsConfig } from 'providers/SoundContext';
import { DefaultTradingSettingsConfig } from 'providers/TradingSettingsContext.types';
import { DefaultLayoutsConfig } from '../AppLayoutConfig/AppLayoutConfigContext';
import { DefaultOrderEntryConfig } from '../OrderEntryContext';

export const DEFAULT_APP_CONFIG = {
  recentSymbols: {},
  blotters2: {} as {
    [key: string]: BlotterState;
  },
  blotters3: {} as BlotterTableNativeState,
  orderPresetsList: [] as IOrderPreset[],
  ...DefaultOrderEntryConfig,
  ...DefaultBlottersConfig,
  ...DefaultTabsStateConfig,
  ...DefaultDisplaySettingsConfig,
  ...DefaultMarketTabsConfig,
  ...DefaultTradingSettingsConfig,
  ...DefaultWatchlistSettingsConfig,
  ...DefaultSoundSettingsConfig,
  ...DefaultFavoritesConfig,
  ...DefaultPortfolioSettingsConfig,
  ...DefaultContextGuideConfig,
  ...DefaultLayoutsConfig,
};

export type AppConfigState = typeof DEFAULT_APP_CONFIG;
