import type { FilterableProperty, ICustomerAddress, ICustomerRoutingInfo } from '@talos/kyoko';
import {
  ACTION,
  AddressRoutingTypeEnum,
  Button,
  CUSTOMER_ADDRESS,
  EntityAdminPageWS,
  FormControlSizes,
  getAddressRoutingTypeOfCurrency,
  IconName,
  ModeEnum,
  useAddressRoutingTypesFilter,
  useCurrenciesContext,
  useCurrenciesFilter,
  useCustomerAddressStatusesFilter,
  useCustomerAddressTypesFilter,
  useDynamicCallback,
} from '@talos/kyoko';
import type { ICellRendererParams } from 'ag-grid-community';
import { useCounterpartiesFilter, useRoleAuth } from 'hooks';
import { useCustomers, useCustomersContext } from 'hooks/useCustomer';
import { useMemo, useState } from 'react';
import { getCustomerAddressDrawerOptions, getCustomerAddressesColumns } from './utils';

export interface CustomerAddressesEntity extends ICustomerAddress {
  _WalletAddress: string;
  _Memo: string;
}

const WS_REQUEST = { name: CUSTOMER_ADDRESS, tag: 'CustomerAddresses' };

export function CustomerAddresses() {
  const { isAuthorized } = useRoleAuth();
  const { currenciesBySymbol } = useCurrenciesContext();
  const customers = useCustomers();
  const { getCustomerWithdrawAddress } = useCustomersContext();
  const { currenciesList: currencies } = useCurrenciesContext();

  const [routingInfoMap] = useState<Map<string, ICustomerRoutingInfo>>(new Map());

  const statusFilter = useCustomerAddressStatusesFilter();
  const counterpartiesFilter = useCounterpartiesFilter();
  const currenciesFilter = useCurrenciesFilter();
  const addressTypesFilter = useCustomerAddressTypesFilter();
  const addressRoutingTypesFilter = useAddressRoutingTypesFilter();

  const filterableProperties: FilterableProperty[] = useMemo(
    () => [statusFilter, counterpartiesFilter, currenciesFilter, addressTypesFilter, addressRoutingTypesFilter],
    [addressRoutingTypesFilter, addressTypesFilter, counterpartiesFilter, currenciesFilter, statusFilter]
  );

  const drawerOptions = useMemo(() => {
    if (!customers || !currencies) {
      return undefined;
    }
    return getCustomerAddressDrawerOptions({ customers, currencies });
  }, [customers, currencies]);

  const getEntityForPost = useDynamicCallback((entity: CustomerAddressesEntity): ICustomerAddress => {
    const {
      _WalletAddress,
      _Memo,
      // Default by extracting the default AddressRoutingType for this currency.
      AddressRoutingType = getAddressRoutingTypeOfCurrency(currenciesBySymbol.get(entity.Currency)),
      ...rest
    } = entity;
    return {
      ...rest,
      AddressRoutingType,
      RoutingInfo: {
        // [DEAL-3250] WalletAddress is not sent for fiat currencies.
        WalletAddress: AddressRoutingType === AddressRoutingTypeEnum.Fiat ? '' : _WalletAddress,
        Memo: _Memo ?? '',
        // We currently just send this field as an empty string for all currencies.
        DestinationTag: '',
      },
      Mode: ModeEnum.Enabled,
    };
  });

  const RevealRoutingInfoRenderer = useDynamicCallback(
    ({ data, value, api }: ICellRendererParams<ICustomerAddress, string>): React.ReactNode => {
      if (value != null || data == null) {
        return value;
      }

      const handleOnReveal = () => {
        getCustomerWithdrawAddress(data.CustomerAddressID).then(res => {
          const firstRoutingInfo = res.data.at(0)?.RoutingInfo;
          routingInfoMap.set(data.CustomerAddressID, {
            WalletAddress: firstRoutingInfo?.WalletAddress ?? '* No Wallet Address *',
            Memo: firstRoutingInfo?.Memo ?? '* No Memo *',
            DestinationTag: firstRoutingInfo?.DestinationTag ?? '* No Destination Tag *',
          });
          api.refreshCells({ columns: ['WalletAddress', 'Memo'] });
        });
      };

      return (
        <Button
          data-testid="reveal-routing-info-button"
          startIcon={IconName.EyeShow}
          size={FormControlSizes.Small}
          onClick={handleOnReveal}
        >
          Reveal
        </Button>
      );
    }
  );

  const columns = useMemo(
    () => getCustomerAddressesColumns(RevealRoutingInfoRenderer, routingInfoMap),
    [RevealRoutingInfoRenderer, routingInfoMap]
  );

  return (
    <EntityAdminPageWS<ICustomerAddress, CustomerAddressesEntity>
      title="Customer Addresses"
      subtitle="Customer Address Configuration"
      entityName="Customer Address"
      path="/customer-addresses"
      wsRequest={WS_REQUEST}
      entityIDField="CustomerAddressID"
      columns={columns}
      drawerOptions={drawerOptions}
      filterableProperties={filterableProperties}
      allowAddEntity={isAuthorized(ACTION.DEALER_TRADING)}
      allowDeleteEntity={isAuthorized(ACTION.DEALER_TRADING)}
      persistKey="dealer-customer-addresses"
      getEntityForPost={getEntityForPost}
    />
  );
}
