import { useMemo } from 'react';
import { useFeatureFlag } from '../../hooks';

interface UseSymbolSelectorProps {
  showMultileg?: boolean;
}

export function useSymbolSelector(props?: UseSymbolSelectorProps) {
  const { enableCalendarSpreads, enableCFDs } = useFeatureFlag();

  const dropdownWidth = useMemo(() => {
    let result = 380;
    if (enableCalendarSpreads) {
      result += 120;
    }
    if (enableCFDs) {
      result += 70;
    }
    if (props?.showMultileg === false) {
      result -= 80;
    }
    return result;
  }, [enableCalendarSpreads, enableCFDs, props?.showMultileg]);

  return {
    dropdownWidth,
  };
}
