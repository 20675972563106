import { type ModeEnum, PositionLimitScopeEnum, type PositionSideTypeEnum, type UpdateActionEnum } from './types';

export interface SubAccountPositionLimit {
  Timestamp: string;
  LimitID: string;
  Direction: PositionSideTypeEnum;
  WarnThreshold: string;
  RejectThreshold: string;
  ThresholdAsset: string;
  SubAccount?: string;
  MatchAsset?: string;
  Mode: ModeEnum;
  UpdateAction?: UpdateActionEnum;
  Revision: number;
  UpdateUserID: string;
  // Optional due to 2.50 backwards compat. Remove question mark after 2.50 is merged.
  Scope?: PositionLimitScopeEnum;
  Description?: string;
}

export const POSITION_LIMIT_SCOPE_TEXT_MAPPING: { [key in PositionLimitScopeEnum]: string } = {
  All: 'All Orders',
  CoreTrading: 'Core Trading',
  CustomerOrderAcceptance: 'Customer Order Acceptance',
};

export const POSITION_LIMIT_SCOPE_DESCRIPTION_MAPPING: { [key in PositionLimitScopeEnum]: string } = {
  All: 'Limit applies when evaluating risk against principal order and customer order.',
  CoreTrading: 'Limit applies when evaluating risk against a principal order.',
  CustomerOrderAcceptance: 'Limit applies when evaluating risk against a customer order.',
};

export function isPositionLimitScopeEnum(value: string): value is PositionLimitScopeEnum {
  return Object.values<string>(PositionLimitScopeEnum).includes(value);
}
