export interface OpsEMRChartPointCustom {
  key: string;
  equity: number;
  emr: number;
  size: number;
}

/** Super simple function just to tuck away how ugly it is to grab these custom attributes... */
export function getPointCustomAttrs(point: Highcharts.Point): OpsEMRChartPointCustom | undefined {
  return point['custom'];
}
