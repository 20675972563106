import {
  type AgGridSearchSelectDropdownProps,
  type ColumnDef,
  isPositionLimitScopeEnum,
  POSITION_LIMIT_SCOPE_DESCRIPTION_MAPPING,
  POSITION_LIMIT_SCOPE_TEXT_MAPPING,
  PositionLimitScopeEnum,
  type SubAccountPositionLimit,
} from '@talos/kyoko';
import type { ValueFormatterParams, ValueSetterParams } from 'ag-grid-community';
import type { CustomCellEditorProps } from 'ag-grid-react';
import { set } from 'lodash';

interface Item {
  label: string;
  value: string;
}

const ITEMS = Object.values(PositionLimitScopeEnum).map(scope => ({
  value: scope,
  label: POSITION_LIMIT_SCOPE_TEXT_MAPPING[scope],
}));

const key = 'Scope' satisfies keyof SubAccountPositionLimit;

export const scopeColumn = {
  type: 'custom',
  id: key,
  sortable: true,
  title: 'Scope',
  params: {
    colId: key,
    field: key,
    editable: true,
    suppressKeyboardEvent: () => true,
    cellEditor: 'searchSelectDropdown',
    cellEditorPopup: true,
    cellEditorParams: (params: CustomCellEditorProps<SubAccountPositionLimit>) => {
      return {
        ...params,
        useSearchSelectParams: {
          items: ITEMS,
          getLabel: (s: Item) => s.label,
        },
        searchPlaceholder: 'Scope',
      } satisfies AgGridSearchSelectDropdownProps<Item>;
    },
    valueFormatter: (params: ValueFormatterParams<SubAccountPositionLimit>) => {
      if (!params.value) {
        return '';
      }

      if (isPositionLimitScopeEnum(params.value)) {
        return POSITION_LIMIT_SCOPE_TEXT_MAPPING[params.value];
      }

      return params.value;
    },
    valueSetter: (params: ValueSetterParams<SubAccountPositionLimit>) => {
      const newValue = params.newValue as Item | undefined;
      if (newValue?.value) {
        set(params.data, key, newValue.value);
      }
      return true;
    },
    headerComponent: 'tooltipHeader',
    headerComponentParams: {
      tooltip: (
        <ul style={{ margin: 0, padding: 0, paddingLeft: 20 }}>
          {ITEMS.map(({ value, label }) => (
            <li key={value}>
              {label} - {POSITION_LIMIT_SCOPE_DESCRIPTION_MAPPING[value]}
            </li>
          ))}
        </ul>
      ),
    },
  },
} satisfies ColumnDef<SubAccountPositionLimit>;
