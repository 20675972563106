import type { CurrencyConversionRate, ValueOf } from '@talos/kyoko';
import {
  CareOrderStatusEnum,
  isGridApiReady,
  logger,
  SideEnum,
  useCurrencyConversionRatesValue,
  useDynamicCallback,
  type UseBlotterTable,
} from '@talos/kyoko';
import Big from 'big.js';
import { isEqual } from 'lodash';
import { startTransition, useEffect, useState } from 'react';
import { isCareOrderRow, type CareOrderBlotterEntity, type CareOrderRow } from './types';

export interface CareOrderHUDData {
  isLoading: boolean;
  totalNotionalBuy: Big | undefined;
  notionalBuyExecuted: Big | undefined;
  notionalBuyOutstanding: Big | undefined;
  totalNotionalSell: Big | undefined;
  notionalSellExecuted: Big | undefined;
  notionalSellOutstanding: Big | undefined;
}

export const INITIAL_STATE: CareOrderHUDData = {
  isLoading: true,
  totalNotionalBuy: undefined,
  notionalBuyExecuted: undefined,
  notionalBuyOutstanding: undefined,
  totalNotionalSell: undefined,
  notionalSellExecuted: undefined,
  notionalSellOutstanding: undefined,
};

export function useCareOrderHUDData(blotterTable: UseBlotterTable<CareOrderBlotterEntity>): CareOrderHUDData {
  const [data, setData] = useState<CareOrderHUDData>(INITIAL_STATE);
  const [currencies, setCurrencies] = useState<string[]>([]);
  const rates = useCurrencyConversionRatesValue(currencies, 'USD');

  const { gridApi, getRowsAfterFilter } = blotterTable;

  const updateData = useDynamicCallback(() => {
    const rows = getRowsAfterFilter().map(node => node.data);
    const currencies = new Set<string>();
    for (const row of rows) {
      currencies.add(row.Currency!);
    }
    const next = Array.from(currencies);
    setCurrencies(prev => (isEqual(prev, next) ? prev : next));

    if (rates == null) {
      setData(INITIAL_STATE);
      return;
    }

    startTransition(() => {
      setData(computeCareOrderHUDData(rows, rates));
    });
  });

  useEffect(() => {
    updateData();
  }, [rates, updateData]);

  useEffect(() => {
    if (!isGridApiReady(gridApi)) {
      return;
    }
    gridApi.addEventListener('filterChanged', updateData);
    gridApi.addEventListener('asyncTransactionsFlushed', updateData);
    return () => {
      if (gridApi == null || gridApi.isDestroyed()) {
        return;
      }
      gridApi.removeEventListener('filterChanged', updateData);
      gridApi.removeEventListener('asyncTransactionsFlushed', updateData);
    };
  }, [gridApi, updateData, rates]);

  return data;
}

export const canceledCareOrderStatuses = new Set<ValueOf<typeof CareOrderStatusEnum>>([
  CareOrderStatusEnum.Canceled,
  CareOrderStatusEnum.FilledAndCanceled,
  CareOrderStatusEnum.Rejected,
]);

export function computeCareOrderHUDData(
  rows: CareOrderBlotterEntity[],
  rates: Map<string, CurrencyConversionRate | undefined>
): CareOrderHUDData {
  // Compute HUD data
  let totalNotionalBuy = Big(0);
  let notionalBuyExecuted = Big(0);
  let notionalBuyOutstanding = Big(0);
  let totalNotionalSell = Big(0);
  let notionalSellExecuted = Big(0);
  let notionalSellOutstanding = Big(0);

  for (const row of rows) {
    const conversionRate = rates.get(row.Currency!);

    // If there's a single row for which we cannot compute the notional,
    // we return the initial state.
    if (conversionRate == null || conversionRate.Rate == null) {
      return INITIAL_STATE;
    }

    if (!isCareOrderRow(row)) {
      continue;
    }

    const careOrder: CareOrderRow = row as CareOrderRow;
    try {
      if (careOrder.Side === SideEnum.Buy) {
        if (canceledCareOrderStatuses.has(careOrder.status)) {
          totalNotionalBuy = totalNotionalBuy.plus(Big(conversionRate.Rate).times(careOrder.CumQty ?? 0));
        } else {
          totalNotionalBuy = totalNotionalBuy.plus(Big(conversionRate.Rate).times(careOrder.OrderQty ?? 0));
        }
        notionalBuyExecuted = notionalBuyExecuted.plus(Big(conversionRate.Rate).times(careOrder.CumQty ?? 0));
        notionalBuyOutstanding = notionalBuyOutstanding.plus(Big(conversionRate.Rate).times(careOrder.LeavesQty ?? 0));
      } else {
        if (canceledCareOrderStatuses.has(careOrder.status)) {
          totalNotionalSell = totalNotionalSell.plus(Big(conversionRate.Rate).times(careOrder.CumQty ?? 0));
        } else {
          totalNotionalSell = totalNotionalSell.plus(Big(conversionRate.Rate).times(careOrder.OrderQty ?? 0));
        }
        notionalSellExecuted = notionalSellExecuted.plus(Big(conversionRate.Rate).times(careOrder.CumQty ?? 0));
        notionalSellOutstanding = notionalSellOutstanding.plus(
          Big(conversionRate.Rate).times(careOrder.LeavesQty ?? 0)
        );
      }
    } catch (e) {
      logger.error(e as Error);
      return INITIAL_STATE;
    }
  }

  return {
    isLoading: false,
    totalNotionalBuy,
    notionalBuyExecuted,
    notionalBuyOutstanding,
    totalNotionalSell,
    notionalSellExecuted,
    notionalSellOutstanding,
  };
}
