import {
  AGGRID_AUTOCOLUMN_ID,
  autoGroupSortingDefaults,
  BlotterTable,
  Box,
  Button,
  ButtonVariants,
  createCSVFileName,
  DEFAULT_BLOTTER_SELECTION_MULTI_PARAMS,
  Divider,
  EXPANDABLE_HEADER_HEIGHT,
  filterByCellValueMenuItem,
  filterByColumnMainMenuItems,
  FormControlSizes,
  getTreeRowBlotterGroupColDef,
  HStack,
  Icon,
  IconButton,
  IconName,
  Input,
  selectAll,
  treeRowAdditionalTotalRowData,
  useAggDeltaUpdatesPipe,
  useBlotterTable,
  useDynamicCallback,
  useGetDefaultContextMenuItems,
  useMixpanel,
  usePersistedBlotterTable,
  usePersistedRowGroupsOpenedState,
  VStack,
  type Leaves,
  type MinimalSubscriptionResponse,
  type RowGroupsOpenedState,
} from '@talos/kyoko';
import type { GetContextMenuItemsParams, GetMainMenuItemsParams, GridOptions } from 'ag-grid-community';
import { compact } from 'lodash';
import { useEffect, useMemo } from 'react';

import type { Observable } from 'rxjs';
import { colIDToFilterBuilderKey as positionsColIDToFilterBuilderKey } from '../../../Blotters/PositionsV3/types';
import { AsOfDateBadge } from '../../components/AsOfDateBadge';
import { usePortfolioViewStateSelector } from '../../PortfolioManagement/stateManagement/portfolioViewLayoutSlice.hooks';
import { useOperationsOverviewFilters } from '../providers/OperationsOverviewFiltersProvider';
import { useOperationsOverviewInteractions } from '../providers/OperationsOverviewInteractionsProvider';
import { useOperationsOverviewPositions } from '../providers/OperationsOverviewPositionsProvider';
import {
  useOperationsOverviewBlotterColumns,
  type OperationsOverviewBlotterColumnSpecification,
} from './useOperationsOverviewBlotterColumns';
import { useOpsOverviewRows, type OpsOverviewBlotterRow } from './useOpsOverviewRows';

const ABOVE_BLOTTER_MENU_ROW_HEIGHT = '40px';

export const aggSpecs = [
  { valuePath: 'Equivalent.Amount', currencyPath: 'Equivalent.Currency' },
  { valuePath: 'balanceEquivalent', currencyPath: 'Equivalent.Currency' },
  { valuePath: 'talosEquityEquivalent', currencyPath: 'Equivalent.Currency' },
  { valuePath: 'Equivalent.UnrealizedPnL', currencyPath: 'Equivalent.Currency' },
] satisfies { valuePath: Leaves<OpsOverviewBlotterRow>; currencyPath: Leaves<OpsOverviewBlotterRow> }[];

function getPositionKey(position: OpsOverviewBlotterRow) {
  return position.rowID;
}

interface OperationsOverviewBlotterProps {
  blotterID: string;
  defaultColumns: OperationsOverviewBlotterColumnSpecification[];
  defaultRowGroupsOpened: RowGroupsOpenedState;
}

export const OperationsOverviewBlotter = ({
  blotterID,
  defaultColumns,
  defaultRowGroupsOpened,
}: OperationsOverviewBlotterProps) => {
  const mixpanel = useMixpanel();
  const { opsOverviewShowBy } = usePortfolioViewStateSelector();
  const columns = useOperationsOverviewBlotterColumns(defaultColumns);

  const { filterableProperties } = useOperationsOverviewFilters();
  const { openClause, setGoToGroupRow } = useOperationsOverviewInteractions();

  const { positionsDataObs } = useOperationsOverviewPositions();
  const dataObs: Observable<MinimalSubscriptionResponse<OpsOverviewBlotterRow>> = useOpsOverviewRows({
    positionsObs: positionsDataObs,
    groupByMarket: opsOverviewShowBy === 'Market',
  });

  const getExtraMainMenuItems = useDynamicCallback((params: GetMainMenuItemsParams<OpsOverviewBlotterRow>) => {
    if (openClause) {
      return filterByColumnMainMenuItems({
        params,
        colIDToFilterBuilderKey: positionsColIDToFilterBuilderKey,

        openClause,
        mixpanel,
      });
    }

    return [];
  });

  const getDefaultContextMenuItems = useGetDefaultContextMenuItems();

  const getContextMenuItems = useDynamicCallback((params: GetContextMenuItemsParams<OpsOverviewBlotterRow>) => {
    const items = [selectAll(params, mixpanel), 'separator', ...getDefaultContextMenuItems(params)];

    if (openClause) {
      // In the case that we are right-clicking on the grouping column, we derive the associated column for this grouping level from the row, and use that.
      // TODO: TreeRow should probably enshrine this in some way.
      const maybeGroupColumnID =
        params.column?.getColId() === AGGRID_AUTOCOLUMN_ID ? params.node?.data?.groupColumnID : undefined;

      items.unshift(
        ...filterByCellValueMenuItem({
          params,
          filterableProperties,
          openClause,
          colIDToFilterBuilderKey: positionsColIDToFilterBuilderKey,
          mixpanel,
          colID: maybeGroupColumnID,
        })
      );
    }

    return compact(items);
  });

  const persisted = usePersistedBlotterTable(blotterID, {
    persistFilter: false, // this is handled elsewhere in operations overview
    columns,
  });

  const persistedRowGroupsOpened = usePersistedRowGroupsOpenedState(blotterID, {
    defaultRowGroupsOpened,
  });

  const pinnedRowDataPipe = useAggDeltaUpdatesPipe({
    getUniqueKey: getPositionKey,
    aggSpecs: aggSpecs,
    additionalTotalRowData: treeRowAdditionalTotalRowData,
  });

  const gridOptions: GridOptions<OpsOverviewBlotterRow> = useMemo(() => {
    return {
      ...persistedRowGroupsOpened.gridOptionsOverlay,
      getContextMenuItems,
      rowSelection: DEFAULT_BLOTTER_SELECTION_MULTI_PARAMS,
      groupDisplayType: 'singleColumn',
      treeData: true,
      getDataPath: row => row.dataPath,
      autoGroupColumnDef: {
        headerName: `${opsOverviewShowBy === 'Market' ? 'Market > ' : ''}Account > Underlying > Instrument`,
        width: 400,
        editable: false,
        suppressColumnsToolPanel: false,
        cellRendererParams: {
          suppressCount: true,
        },
        sortable: true,
        pinned: 'left',
        ...autoGroupSortingDefaults,
        ...getTreeRowBlotterGroupColDef(),
      },
    };
  }, [getContextMenuItems, persistedRowGroupsOpened.gridOptionsOverlay, opsOverviewShowBy]);

  const blotter = useBlotterTable<OpsOverviewBlotterRow>({
    dataObservable: dataObs,
    pinnedRowDataPipe: pinnedRowDataPipe,
    showPinnedRows: true,
    rowID: 'rowID' satisfies keyof OpsOverviewBlotterRow,
    getExtraMainMenuItems,
    sort: persisted.initialSort,
    columns: persisted.columns,
    onColumnsChanged: persisted.onColumnsChanged,
    onSortChanged: persisted.onSortChanged,
    gridOptions,
  });

  const { expandAllGroups, collapseAllGroups, highlightGroupRow } = blotter;

  useEffect(() => {
    setGoToGroupRow(() => highlightGroupRow);
  }, [setGoToGroupRow, highlightGroupRow]);

  const handleExport = useDynamicCallback(() => {
    blotter.exportDataAsCSV({
      fileName: createCSVFileName({
        name: 'Operations - Overview',
      }),
    });
  });

  return (
    <Box h={`calc(100% - ${EXPANDABLE_HEADER_HEIGHT}px)`}>
      <HStack
        data-testid="treasury-management-blotter-controls"
        justifyContent="space-between"
        gap="spacingComfortable"
        px="spacingDefault"
        h={ABOVE_BLOTTER_MENU_ROW_HEIGHT}
      >
        <AsOfDateBadge snapshotDate={null} /> {/* live */}
        <HStack justifyContent="flex-end" w="100%" gap="spacingSmall">
          <Input
            prefix={<Icon icon={IconName.Search} />}
            clearable={true}
            size={FormControlSizes.Small}
            width="120px"
            value={blotter.blotterTableFiltersProps.quickFilterText}
            onChange={e => blotter.blotterTableFiltersProps.onQuickFilterTextChanged(e.target.value)}
          />
          <Divider orientation="vertical" mx="spacingSmall" />
          <IconButton
            icon={IconName.ListExpand}
            size={FormControlSizes.Small}
            variant={ButtonVariants.Default}
            onClick={expandAllGroups}
            data-testid="expand-all-button"
          />
          <IconButton
            icon={IconName.ListCollapse}
            size={FormControlSizes.Small}
            variant={ButtonVariants.Default}
            onClick={collapseAllGroups}
          />
          <Divider orientation="vertical" mx="spacingSmall" />
          <Button startIcon={IconName.DocumentDownload} size={FormControlSizes.Small} onClick={handleExport}>
            Export
          </Button>
        </HStack>
      </HStack>
      <Divider />
      <VStack h={`calc(100% - ${ABOVE_BLOTTER_MENU_ROW_HEIGHT})`} w="100%">
        <Box px="spacingDefault" flex="1" w="100%">
          <BlotterTable {...blotter} />
        </Box>
      </VStack>
    </Box>
  );
};
