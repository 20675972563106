import type { Action, Middleware, ReducersMapObject, ThunkDispatch, UnknownAction } from '@reduxjs/toolkit';
import { logger } from '@talos/kyoko';

type TimingMiddleware<
  State = unknown,
  DispatchType extends ThunkDispatch<State, unknown, Action> = ThunkDispatch<State, unknown, UnknownAction>
> = Middleware<(action: UnknownAction) => any, State, DispatchType>;

/**
 * Middleware that logs the time it takes to dispatch an action.
 */
export const timingMiddleware: TimingMiddleware = api => next => action => {
  const startTime = Date.now();
  const start = performance.now();
  const result = next(action);
  const duration = performance.now() - start;
  if (duration > 1) {
    logger.trackDuration('reduxActionDispatchMs', {
      startTime,
      duration,
      description: (action as Action).type,
    });
  }
  return result;
};

/**
 * Small utility that wraps all reducers and tracks the timing for each one.
 */
export function timeReducers<R extends ReducersMapObject>(reducers: R): R {
  const timedReducers: ReducersMapObject = { ...reducers };
  Object.keys(timedReducers).forEach(name => {
    timedReducers[name] = (state, action) => {
      const startTime = Date.now();
      const start = performance.now();
      const result = reducers[name](state, action);
      const duration = performance.now() - start;
      if (duration > 1) {
        logger.trackDuration('reduxReducerMs', {
          startTime,
          duration,
          description: name,
        });
      }
      return result;
    };
  });
  return timedReducers as R;
}
