import Big from 'big.js';
import { toBig, toBigWithDefault } from './number';

/**
 * Given an exposure and a limit, calculates the usage
 */
export function getCreditUsage(exposure: string | undefined, limit: string | undefined, currency: string | undefined) {
  if (limit == null) {
    return undefined;
  }

  const limitBig = toBig(limit);
  const exposureBig = toBigWithDefault(exposure, 0); // if exposure is nullish, then we regard that as valid and 0;
  const usage = limitBig != null && !limitBig.eq(0) && exposureBig != null ? exposureBig.div(limitBig).abs() : Big(0);
  const remainder =
    limitBig != null && !limitBig.eq(0) && exposureBig != null ? limitBig.minus(exposureBig) : undefined;

  return {
    currency,
    limit: limitBig,
    exposure: exposureBig,
    usage,
    remainder,
  };
}
